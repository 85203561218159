<template>
  <CycleHeader :image="image" is-curved class="coreui__hero--blog">
    <CycleContentBlock class="panel--hero panel--blog">
      <template #header v-if="hasTitle">
        <CycleHeading :weight="1">{{ title }}</CycleHeading>
      </template>
      <template v-if="isBlogDetailsVisible" #footer>
        <CycleList v-if="hasAuthor || hasDatePublished" modifiers="bar">
          <CycleListItem v-if="hasAuthor">
            {{ author }}
          </CycleListItem>
          <CycleListItem v-if="hasDatePublished">
            {{ datePublished }}
          </CycleListItem>
        </CycleList>
        <CycleList v-if="hasTags" modifiers="flat">
          <CycleListItem v-for="tagsItem in tags" :key="tagsItem.id">
            <a :href="tagsItem.landingUrl">{{ tagsItem.name }}</a>
          </CycleListItem>
        </CycleList>
      </template>
    </CycleContentBlock>
  </CycleHeader>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})

const small = props.fields?.ImageSmall?.value ?? {}
const medium = props.fields?.ImageMedium?.value ?? {}
const large = props.fields?.ImageLarge?.value ?? {}
const alt = props.fields?.['Alt Text']?.value
const imageTitle = props.fields?.imageTitle?.value
const focus = props.fields?.SubjectFocus?.value
const image = {
  alt,
  imageTitle,
  focus,
  pictures: {
    small,
    medium,
    large
  }
}

const title = props.fields?.details?.title
const hasTitle = title?.length > 0
const datePublished = props.fields?.details?.datePublished
const hasDatePublished = datePublished?.length > 0
const author = props.fields?.details?.author
const hasAuthor = author?.length > 0
const tags = props.fields?.details?.tags || []
const hasTags = tags?.length > 0
const isBlogDetailsVisible = hasAuthor || hasDatePublished || hasTags
</script>
