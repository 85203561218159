import {
  SET_AUTHENTICATION_STATE,
  RESET_AUTHENTICATION_STATE,
  SET_AUTHENTICATION_STATE_FORCE_CLOSE
} from './mutation-types'

import { IAuthentication } from './models'

export default {
  [SET_AUTHENTICATION_STATE]: (state: IAuthentication, authenticationState: IAuthentication) => {
    state.authorizationLevel = authenticationState.authorizationLevel
    state.expires = authenticationState.expires
    state.totalSecondsLeftServer = authenticationState.totalSecondsLeftServer
    state.forcedClose = authenticationState.forcedClose
  },
  [SET_AUTHENTICATION_STATE_FORCE_CLOSE]: (state: IAuthentication) => {
    state.forcedClose = true
  },
  [RESET_AUTHENTICATION_STATE]: (state: IAuthentication) => {
    state.expires = undefined
    state.authorizationLevel = undefined
    state.forcedClose = false
    state.totalSecondsLeftServer = 0
  }
}
