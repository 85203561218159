<template>
  <CoreUI :rendering="route" v-if="!isRedirect" />
</template>

<script>
import CoreUI from './CoreUI.vue'
import config from '@/temp/config'
import { generateCanonicals } from '@/router/generateCanonicals'
import { scrollToElementWithAnchorId } from '@/router/routerHelper'

export default {
  name: 'Layout',
  components: {
    CoreUI
  },
  props: {
    route: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      metaInfoData: {
        htmlAttrs: {},
        title: '',
        meta: [],
        link: [],
        script: [],
        __dangerouslyDisableSanitizersByTagID: {}
      }
    }
  },
  computed: {
    redirect() {
      return this.$route.meta?.redirect
    },
    isRedirect() {
      return !!this.redirect
    },
    pageImage() {
      return this.route?.placeholders['jss-header']?.find(
        (component) => component?.componentName === 'CycleHeaderSitecore'
      )?.fields?.image?.value?.pictures?.small?.src
    },
    sitecoreContext() {
      return this.$jss?.sitecoreContext()
    },
    openGraphMetaItems() {
      return [
        {
          property: 'og:url',
          content: `${window?.location?.href}`
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:title',
          content: `${this.sitecoreContext?.metaData?.title}`
        },
        {
          property: 'og:description',
          content: `${this.sitecoreContext?.metaData?.description}`
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:title',
          content: `${this.sitecoreContext?.metaData?.title}`
        },
        {
          name: 'twitter:description',
          content: `${this.sitecoreContext?.metaData?.description}`
        }
      ]
    },
    openGraphImageMetaItems() {
      return this.pageImage
        ? [
            {
              name: 'image',
              property: 'og:image',
              content: `${this.pageImage}`
            },
            {
              name: 'twitter:image',
              content: `${this.pageImage}`
            }
          ]
        : []
    }
  },
  beforeMount() {
    if (typeof window !== 'undefined') {
      this.emitNewRelicConfig(window)
    }

    this.generateMetaInfo()
  },
  mounted() {
    if (document.readyState === 'complete') {
      this.addOpenGraph()
      // if page loading is finished, window.prerenderReady should be set to true
      // so prerender.io starts caching the page
      window.prerenderReady = true

      // External scroll behavior
      if (this.$route.hash) {
        scrollToElementWithAnchorId(this.$route.hash)
      }
    }
  },
  methods: {
    emitNewRelicConfig(scope) {
      const oldScope = scope['__ENV__']
      scope.newrelicConfig = {
        accountID: oldScope?.newrelicAccountID || config.newrelic?.accountID,
        licenseKey: oldScope?.newrelicLicenseKey || config.newrelic?.licenseKey,
        applicationID: oldScope?.newrelicApplicationID || config.newrelic?.applicationID,
        agentID: oldScope?.newrelicAgentID || config.newrelic?.agentID,
        trustKey: oldScope?.newrelicTrustKey || config.newrelic?.trustKey
      }
    },
    generateMetaInfo() {
      const availableLanguages = this.$store?.getters['siteSettings/availableLanguages'] ?? {}
      const currentLanguage = this.$store?.getters['siteSettings/currentLanguage']
      this.metaInfoData.htmlAttrs.lang =
        availableLanguages[this.route?.itemLanguage]?.hrefLang || this.route?.itemLanguage

      if (this.route?.name === '404') {
        this.metaInfoData.meta.push({ name: 'prerender-status-code', content: '404' })
      }

      if (this.isRedirect) {
        if (window.__ENV__?.prerender) {
          this.metaInfoData.meta.push({
            name: 'prerender-status-code',
            content: `${this.redirect.type}`
          })
          this.metaInfoData.meta.push({
            name: 'prerender-header',
            content: `Location: ${this.redirect.destination}`
          })
          this.metaInfoData.meta.push({
            name: 'prerender-header',
            content: 'Cache-Control: no-cache'
          })
        } else {
          window.location.replace(this.redirect.destination)
        }
      }

      if (typeof window !== 'undefined') {
        // fix SSR builds as generateCanonicals uses window object
        // availableLanguages is also returning currentTargetPath per language, so we dont need route here
        generateCanonicals(availableLanguages, currentLanguage).forEach((item) =>
          this.metaInfoData.link.push(item)
        )
      }
      if (!this.sitecoreContext) {
        return
      }
      this.metaInfoData.title = this.sitecoreContext.metaData?.title
      const metaFields = ['keywords', 'description', 'robots']
      metaFields.forEach((name) => {
        const content = this.sitecoreContext?.metaData?.[name]
        if (content && content !== '') {
          this.metaInfoData.meta.push({ name, content })
        }
      })

      if (this.sitecoreContext.visitorIdentificationTimestamp) {
        this.metaInfoData.script.push({
          src: `${config.sitecoreApiHost}/layouts/system/VisitorIdentification.js`
        })
        this.metaInfoData.meta.push({
          name: 'VIcurrentDateTime',
          content: this.sitecoreContext.visitorIdentificationTimestamp
        })
      }

      //set script from sitecore context
      if (this.sitecoreContext?.metaData?.script) {
        this.metaInfoData.script.push({
          vmid: 'context-script',
          innerHTML: this.sitecoreContext.metaData.script,
          type: 'text/javascript'
        })
        this.metaInfoData__dangerouslyDisableSanitizersByTagID = { 'context-script': ['innerHTML'] }
      }
    },
    addOpenGraph() {
      this.metaInfoData.meta.push(...this.openGraphMetaItems, ...this.openGraphImageMetaItems)
    }
  },
  metaInfo() {
    return this.metaInfoData
  }
}
</script>
