<template>
  <MyInvoices v-bind="textContent" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleMyInvoicesSitecore',
  components: {
    MyInvoices: Cycle.CycleMyInvoicesSitecore
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    }
  }
}
</script>
