//
//
//
//
//
//
//

/**
 * Represents a single property of a document
 *
 * This can range from policy number, to status indicator the amount due. By utilizing the default
 * slot one can create complex content. It is important to keep in mind that it has to fit within
 * the UI.
 *
 * @since 3.4.0
 */
export default {
  name: 'DocumentsProp',
  props: {
    /**
     * Flags whether this piece of information is trivial
     *
     * This flag determines whether the info should be shown under certain conditions. e.g. trivial
     * items get hidden on mobile devices.
     */
    trivial: Boolean
  }
}
