<template>
  <CycleCardList :title="header" modifiers="nomarker">
    <template #list-items>
      <CycleListItem v-if="companyName">
        <label>{{ getLabel('company') }}</label>
        <p>{{ companyName }}</p>
      </CycleListItem>
      <CycleListItem v-if="kvkNumber">
        <label>{{ getLabel('kvkNr') }}</label>
        <p>{{ kvkNumber }}</p>
      </CycleListItem>
      <CycleListItem v-if="sbiText">
        <label>{{ getLabel('sbi') }}</label>
        <p>{{ sbiText }}</p>
      </CycleListItem>

      <CycleListItem v-if="relationNumber">
        <label>{{ getLabel('relationNr') }}</label>
        <p>{{ relationNumber }}</p>
      </CycleListItem>
      <CycleListItem v-for="(address, idx) in addresses" :key="`${address}${idx}`">
        <label>{{ address.type || 'Bezoekadres' }}</label>
        <p>
          <strong>{{ getAddress(address) }}</strong>
        </p>
      </CycleListItem>
    </template>
    <template #footer v-if="linkHref && linkTitle">
      <CycleNav buttons>
        <CycleButtonLink :href="linkHref" modifiers="cta, secondary">
          {{ linkTitle }}
        </CycleButtonLink>
      </CycleNav>
    </template>
  </CycleCardList>
</template>

<script>
import { CycleButtonLink, CycleCardList, CycleListItem, CycleNav } from '@aon/cycle'

export default {
  name: 'PersonalDetailsZakelijk',
  components: {
    CycleButtonLink,
    CycleCardList,
    CycleListItem,
    CycleNav
  },
  props: {
    fields: Object,
    rendering: Object
  },
  computed: {
    header() {
      return this.fields?.heading?.value
    },
    linkTitle() {
      return this.fields?.link?.value?.title
    },
    linkHref() {
      return this.fields?.link?.value?.href
    },
    fieldData() {
      return this.fields?.additionalData?.data?.legalEntity
    },
    addresses() {
      return this.fieldData?.addresses
    },
    companyName() {
      return this.fieldData?.name
    },
    kvkNumber() {
      return this.fieldData?.kvk?.registrationNumber
    },
    sbiText() {
      return this.fieldData?.kvk?.sbi1?.text?.join(',')
    },
    relationNumber() {
      return this.fieldData?.id
    }
  },
  methods: {
    getLabel(labelName) {
      return this.fields?.[labelName]?.value || `{{ ${labelName} }}`
    },
    getAddress(address) {
      return `${address.street} ${address.houseNumber}${address.houseNumberAddition} ${address.postalCode}, ${address.town}, ${address.country.value}`
    }
  }
}
</script>
