<template>
  <Modal v-if="isModalVisible" @close="handleClose" class="modal--logout">
    <template #heading>{{ headerText }}</template>
    <template #body>
      <p>{{ authenticationExpirationExplanationText }}</p>
      <div class="timer">
        <template v-if="isMinutesSegmentVisible">
          <span class="timer__number">{{ potentialMinutesLeft }}</span>
          <h6 class="timer__text">{{ timerMinuteText }}</h6>
        </template>

        <span class="timer__number">{{ potentialSecondsLeft }}</span>
        <h6 class="timer__text">{{ timerSecondsText }}</h6>
      </div>
      <p>{{ subText }}</p>
    </template>
    <template #footer>
      <button class="btn btn--prominent" @click.prevent="handleClose">
        {{ keepLoggedInText }}
      </button>
      <a :href="logoutUrl" class="btn btn--tertiary">{{ logoutText }}</a>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import secondsToMinutes from 'date-fns/secondsToMinutes'

import { dictionaryMixin } from '@/mixins/Dictionary'
import { Modal } from '@aon/cycle'

export default {
  name: 'JssAuthenticationSitecore',
  components: {
    Modal
  },
  mixins: [dictionaryMixin],
  data: () => ({
    intervalValue: 60000,
    totalSecondsLeftClient: 0,
    decreaseTotalSecondsLeftIntervalId: null,
    visibilityTimeLimitInMinutes: 10
  }),
  computed: {
    ...mapGetters('siteSettings', ['logoutUrl']),
    ...mapGetters('authentication', ['totalSecondsLeftServer', 'forcedClose']),

    isModalVisible() {
      return !this.forcedClose && this.isModalVisibilityTimingConformed
    },
    shouldSkipAuthentication() {
      return (
        this.$jss?.sitecoreContext().pageEditing || process.env.VUE_APP_POSTMAN_MOCKED === 'true'
      )
    },
    isModalVisibilityTimingConformed() {
      return this.timerIsSet && this.potentialMinutesLeft < this.visibilityTimeLimitInMinutes
    },
    remainingTimeText() {
      const minutes = `${this.potentialMinutesLeft} MIN `
      const seconds = `${this.potentialSecondsLeft} SEC `
      return this.isMinutesSegmentVisible ? `${minutes} ${seconds}` : seconds
    },
    isMinutesSegmentVisible() {
      return this.potentialMinutesLeft > 0
    },
    potentialMinutesLeft() {
      return secondsToMinutes(this.totalSecondsLeftClient)
    },
    potentialSecondsLeft() {
      return this.totalSecondsLeftClient % 60
    },
    timerIsSet() {
      return this.potentialMinutesLeft > 0 || this.potentialSecondsLeft > 0
    },
    keepLoggedInText() {
      return this.getPhrase('authentication-keep-logged-in-link-text') || 'keepLoggedInText'
    },
    logoutText() {
      return this.getPhrase('authentication-log-out-link-text') || 'logoutText'
    },
    subText() {
      return this.getPhrase('authentication-log-out-sub-text') || 'subText'
    },
    timerMinuteText() {
      return this.getPhrase('authentication-timer-minute-text') || 'timerMinuteText'
    },
    timerSecondsText() {
      return this.getPhrase('authentication-timer-seconds-text') || 'timerSecondsText'
    },
    authenticationExpirationExplanationText() {
      return (
        this.getPhrase('authentication-expiration-explanation-text') ||
        'authenticationExpirationExplanationText'
      )
    },
    headerText() {
      return this.getPhrase('authentication-header-text') || 'headerText'
    }
  },
  mounted() {
    this.checkAuthentication()
  },
  methods: {
    setTotalSecondsLeftClient(totalSecondsLeftClient) {
      if (totalSecondsLeftClient <= 0) {
        this.handleAuthenticationExpired()
      }
      if (this.decreaseTotalSecondsLeftIntervalId) {
        clearInterval(this.decreaseTotalSecondsLeftIntervalId)
      }
      this.decreaseTotalSecondsLeftIntervalId = setInterval(this.decreaseTotalSeconds, 1000)
    },
    setTotalSecondsLeftServer(totalSecondsLeftServer) {
      this.totalSecondsLeftClient = totalSecondsLeftServer
    },
    handleAuthenticationExpired() {
      if (!this.shouldSkipAuthentication) {
        window.location.pathname = '/mijn-overzicht/uitloggen/uitgelogd'
      }
    },
    handleClose() {
      this.$store.dispatch('authentication/forceClose')
    },
    decreaseTotalSeconds() {
      if (this.totalSecondsLeftClient > 0) {
        this.totalSecondsLeftClient--
      }
    },
    checkAuthentication() {
      if (this.shouldSkipAuthentication) {
        return
      }
      this.$store.dispatch('authentication/checkAuthentication', {
        onAuthenticationExpired: this.handleAuthenticationExpired,
        interval: this.intervalValue
      })
      this.$watch('totalSecondsLeftServer', this.setTotalSecondsLeftServer)
      this.$watch('totalSecondsLeftClient', this.setTotalSecondsLeftClient)
    }
  }
}
</script>
