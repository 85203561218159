<template>
  <CycleCardList :title="header" modifiers="nomarker" data-testid="header-text">
    <template #list-items>
      <CycleListItem v-if="communication.email">
        <label>{{ getLabel('emailLabel') }}</label>
        <p>{{ communication.email }}</p>
      </CycleListItem>
      <CycleListItem v-if="communication.phone">
        <label>{{ getLabel('phoneLabel') }}</label>
        <p>{{ communication.phone }}</p>
      </CycleListItem>
      <CycleListItem v-if="communication.mobile">
        <label>{{ getLabel('phoneLabel2') }}</label>
        <p>{{ communication.mobile }}</p>
      </CycleListItem>
      <CycleListItem v-if="deliveryMethod">
        <label>{{ getLabel('deliveryMethodLabel') }}</label>
        <p>{{ deliveryMethod }}</p>
      </CycleListItem>
    </template>
    <template #footer v-if="linkHref && linkTitle">
      <CycleNav buttons>
        <CycleButtonLink :href="linkHref" modifiers="cta, secondary" data-testid="footer-link">
          {{ linkTitle }}
        </CycleButtonLink>
      </CycleNav>
    </template>
  </CycleCardList>
</template>

<script>
import { CycleButtonLink, CycleCardList, CycleHeading, CycleListItem, CycleNav } from '@aon/cycle'
import { Text } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'ContactDetailsZakelijk',
  components: {
    CycleButtonLink,
    CycleCardList,
    CycleHeading,
    CycleListItem,
    CycleNav,
    ScText: Text
  },
  props: {
    fields: Object,
    rendering: Object
  },
  computed: {
    header() {
      return this.fields?.heading?.value
    },
    communication() {
      return this.fields?.additionalData?.data?.legalEntity?.communication || {}
    },
    deliveryMethod() {
      return this.communication?.deliveryMethod?.value
    },
    linkTitle() {
      return this.fields?.link?.value?.title
    },
    linkHref() {
      return this.fields?.link?.value?.href
    }
  },
  methods: {
    getLabel(labelName) {
      return this.fields?.[labelName]?.value || `{{ ${labelName} }}`
    }
  }
}
</script>
