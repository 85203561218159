//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { intlMixin, dictionaryMixin } from '@/mixins'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { HomePremium, Coverage } from '../../models'

export default {
  name: 'HomeOwnerCoverageSelection',
  components: {
    CycleFormPage,
    CycleCheckout,
    CycleCheckoutItem,
    CycleFormSet
  },
  inject: ['formData', 'textContent', 'contentProvider'],
  mixins: [intlMixin, dictionaryMixin],
  props: {
    selectedPremium: {
      required: false,
      type: HomePremium
    },
    allPremiums: {
      required: false,
      type: HomePremium
    }
  },
  data: () => ({
    garageSelected: false
  }),
  computed: {
    totalPremium() {
      return this.selectedPremium?.totalPremium || 0
    },
    basePremium() {
      return this.allPremiums?.premiums?.basePremium
    },
    additionalGaragePremium() {
      return this.allPremiums?.premiums?.additionalGaragePremium
    }
  },
  methods: {
    handleSubmit() {},
    additionalGarageHandler(val) {
      this.garageSelected = val
      this.$emit('coverage-changed', new Coverage(val))
    }
  }
}
