import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const siteSettings = {
  mutations,
  state,
  getters,
  actions,
  namespaced: true
}

export default siteSettings
