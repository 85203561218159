<template>
  <CycleSimpleForm
    :heading="heading"
    :next-button-text="nextButtonText"
    :form-submit-action="handleContactDetailSubmission"
  >
    <p>{{ intro }}</p>

    <CycleFormSet>
      <CycleInputContainer>
        <template slot="label">
          <slot name="email-label"></slot>
        </template>
        <CycleTextInput id="email" v-model="formData.emailaddress" required email @paste.prevent />
        <template slot="subtext">
          <slot name="email-subtext"></slot>
        </template>
      </CycleInputContainer>
      <CycleInputContainer>
        <template slot="label">
          <slot name="email-label-validate"></slot>
        </template>
        <CycleTextInput
          id="email-validate"
          required
          email
          same-as="emailaddress"
          @paste.prevent
          v-model="formData.emailValidate"
        />
        <template slot="subtextvalidate">
          <slot name="email-subtext-validate"></slot>
        </template>
      </CycleInputContainer>
      <CycleInputContainer>
        <template slot="label">
          <slot name="phone-label"></slot>
        </template>
        <CycleNumberInput id="phone" v-model="formData.phone" required phone-dutch @paste.prevent />
      </CycleInputContainer>
      <template slot="subtext">
        <slot name="phone-subtext"></slot>
      </template>
      <CycleInputContainer>
        <template slot="label">
          <slot name="phone-label2"></slot>
        </template>
        <CycleNumberInput id="phone2" v-model="formData.phone2" phone-dutch @paste.prevent />
      </CycleInputContainer>
    </CycleFormSet>
    <template #confirmation-confirmed>
      <slot name="thank-you"></slot>
    </template>
    <template #confirmation-rejected>
      <slot name="failureText"></slot>
    </template>
  </CycleSimpleForm>
</template>
<script>
import CycleForm from '../CycleForm/CycleForm'
import CycleFormPage from '../CycleFormPage/CycleFormPage'
import CycleCard from '../../../card/CycleCard'
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../elements/forms/CycleNumberInput/CycleNumberInput'
import { CycleFormSet } from '../CycleFormSet'

export default {
  name: 'CycleChangeContactDetails',
  components: {
    CycleNumberInput,
    CycleTextInput,
    CycleInputContainer,
    CycleSimpleForm,
    CycleHeading,
    CycleCard,
    CycleFormPage,
    CycleForm,
    CycleFormSet
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    intro: {
      type: String,
      required: true
    },
    nextButtonText: {
      type: String,
      required: true
    },
    handleContactDetailSubmission: {
      type: Function,
      required: true
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data: () => ({
    formData: {}
  })
}
</script>
