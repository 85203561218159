import gql from 'graphql-tag'

export const policyItemHomeContents = gql`
  fragment policyItemHomeContents on Policy {
    item {
      ... on Contents {
        contentsConfiguration {
          coverages {
            name
            active
          }
        }
      }
    }
  }
`
