/**
 * A single route within our routeless application
 *
 * This model should match the Vue-router spec to keep things simple
 */
export default class Route {
  /**
   * @param {String} [path]
   * @param {String} [name]
   */
  constructor(path = undefined, name = undefined) {
    this.path = path
    this.name = name
  }

  /**
   * @param {Object} link - Sitecore link object
   * @param {String} [name]
   */
  static fromLink(link, name = undefined) {
    return new Route(link.href, name)
  }
}
