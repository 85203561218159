//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays } from 'date-fns'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'

import { dictionaryMixin } from '@/mixins'
import { ProductPremium } from '../../models'

import { currentYYYYMMDD, toYYYYMMDD } from '@/services/DateTime'

export default {
  name: 'HomeContentsStepPayment',
  inject: ['formData', 'contentProvider'],
  components: {
    CycleButton,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText,
    CycleDateInput
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      type: ProductPremium,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    minStartDate() {
      return currentYYYYMMDD()
    },
    maxStartDate() {
      return toYYYYMMDD(addDays(new Date(), 365))
    },
    paymentDetails() {
      const init = {
        paymentTerm: PaymentTerm.Monthly,
        premium: this.premium?.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }
        this.paymentDetails = newValue.paymentDetails
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
