<template>
  <div :class="defaultClass">
    <div :class="defaultClass + '__row'" v-for="row in rows" :key="row.id">
      <div v-for="cell in row.cells" :key="cell.id" :class="getCellClass(cell)">
        <div v-if="cell.label" :class="defaultClass + '__label'">{{ cell.label }}</div>
        <CycleIcon v-if="showIcon(cell)" :icon="getIcon(cell)" />
        <CycleLink v-else-if="showLink(cell)" v-bind="getLink(cell)">{{ getText(cell) }}</CycleLink>
        <template v-else>{{ getText(cell) }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import { CycleIcon } from '@/components/elements/CycleIcon'
import { CycleLink } from '@/components/cycle-link'
export default {
  name: 'CompareTable',
  components: {
    CycleIcon,
    CycleLink
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultClass: 'compare-coverages'
    }
  },
  methods: {
    getCellClass(field) {
      const cellClass = this.defaultClass + '__cell'
      const classList = [cellClass]
      if (field?.label) {
        classList.push(`${cellClass}--with-label`)
      }
      return classList
    },
    showIcon(field) {
      return !!field?.fields?.Icon?.value
    },
    showLink(field) {
      return !!field?.fields?.Link?.value.href
    },
    getLink(field) {
      return field?.fields?.Link?.value
    },
    getIcon(field) {
      return field?.fields?.Icon?.value === 'unchecked' ? 'cross' : 'check'
    },
    getText(field) {
      return field?.fields?.Text?.value || ''
    }
  }
}
</script>
