//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleLink } from '../cycle-link'
import { CycleList, CycleListItem } from '../cycle-list/'
export default {
  name: 'CycleMenuLinksBar',
  components: {
    'cycle-link': CycleLink,
    CycleList,
    CycleListItem
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: null
    }
  }
}
