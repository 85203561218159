<template>
  <CycleLink v-bind="componentData" v-if="link?.href">
    {{ linkText }}
  </CycleLink>
</template>

<script>
import { CycleLink } from '@aon/cycle'

export default {
  name: 'FixedLink',
  components: {
    CycleLink
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    link() {
      return this.fields?.link?.value
    },
    linkText() {
      return this.link?.title || this.link?.text
    },
    componentData() {
      return {
        href: this.link?.href,
        target: this.link?.target,
        title: this.linkText,
        className: this.link?.class || 'btn btn--pre-funnel'
      }
    }
  }
}
</script>
