import { logWarning } from '../../services/Logging'

const storeToLocalStorage = () => {
  logWarning('storeToLocalStorage: Not implemented')
}

const loadFromLocalStorage = () => {
  logWarning('loadFromLocalStorage: Not implemented')
}

export default {
  loadFromLocalStorage,
  storeToLocalStorage
}
