<template>
  <LanguageSwitch />
</template>
<script>
import { LanguageSwitch } from '@aon/cycle'

export default {
  name: 'CycleLanguageSwitchSitecore',
  components: {
    LanguageSwitch
  }
}
</script>
