<template>
  <CycleClientRendering>
    <CycleIframe :href="href" />
  </CycleClientRendering>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { logWarning } from '@/services/logging'

export default {
  name: 'CycleIframeSitecore',
  components: {
    CycleIframe: Cycle.CycleIframe,
    CycleClientRendering: Cycle.CycleClientRendering
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    },
    user: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    href() {
      return this.fields?.link?.value?.href
    },
    dataSource() {
      return this.rendering?.dataSource
    }
  },
  mounted() {
    logWarning(
      'CycleIframeSitecore is deprecated, if you must use iframes, use CycleAnvaOnlineIframeSitecore'
    )
  }
}
</script>
