<script>
import { registerCheckout, registerPurchase } from '@/services/Ecommerce'

export default {
  name: 'ECommerceTracker',
  props: {
    funnelName: String,
    transaction: String,
    yearPremium: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    registerCheckout(page) {
      registerCheckout(this.funnelName, page, this.yearPremium, this.transaction)
    },
    registerPurchase() {
      registerPurchase(this.funnelName, this.yearPremium, this.transaction)
    }
  },
  render() {
    return this.$scopedSlots.default({
      registerCheckout: this.registerCheckout,
      registerPurchase: this.registerPurchase
    })
  }
}
</script>
