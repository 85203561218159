<template>
  <div class="card-wrapper">
    <ScPlaceholder name="cards" :rendering="rendering" />
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
export default {
  name: 'CardWrapper',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
