<template>
  <div class="checkout-item checkout-item--payment">
    <div class="checkout-item__label checkout-item--total-premium">
      <div class="checkout-item--total-premium-label">
        <CycleHtml tag="span" :html="premiumLabel" />
      </div>
      <div class="checkout-item__total">
        <template v-if="hasTransitionParams && hasPremium">
          <ValueTransition :value="premium" :transition-params="transitionParams">
            <template #default="{ transitionValue }">
              <CoveragePremiumPrice 
                v-if="displayTotalPremiumWithProvision"
                :price="transitionValue"
                :paymentTerm="paymentTerm"
              />
              <CycleHtml
                v-else
                tag="span"
                :html="transitionValue | CycleCurrency({ locale, split: true })"
              />
            </template>
          </ValueTransition>
        </template>
        <template v-else>
          <CycleHtml tag="span" :html="premium | CycleCurrency({ locale, split: true })" />
        </template>
      </div>
    </div>
    <div class="checkout-item__info">
      <label 
        v-if="displayTotalPremiumWithProvision" 
        class="form__label form__premium-label"
        >
        <span> {{ label }} </span>
        <ModalTooltip v-if="tooltip">
          <template #tooltip-heading>
            <slot name="tooltip-heading">{{ label }}</slot>
          </template>
          <template #tooltip-content>
            <slot name="tooltip-content"><CycleHtml :html="tooltip" /></slot>
          </template>
        </ModalTooltip>
      </label>
      
      <CycleHtml tag="span" :html="premiumInfo" />
    </div>
  </div>
</template>

<script>
import ValueTransition from './ValueTransition.vue'
import { CycleHtml } from '@/components/base'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
import CoveragePremiumPrice from './CoveragePremiumPrice.vue'
import ModalTooltip from '@/components/molecules/ModalTooltip'

export default {
  name: 'TotalPremium',
  filters: {
    CycleCurrency
  },
  components: {
    ValueTransition,
    CycleHtml,
    CoveragePremiumPrice,
    ModalTooltip
  },
  mixins: [intlMixin],
  props: {
    premiumLabel: {
      type: String,
      required: false,
      default: ''
    },
    premiumInfo: {
      type: String,
      required: false,
      default: ''
    },
    premium: {
      type: [Number, String],
      required: false
    },
    transitionParams: {
      type: Object,
      default: () => ({})
    },
    displayTotalPremiumWithProvision: {
      type: Boolean,
      required: false
    },
    paymentTerm: {
      type: String,
      required: false,
      default: 'p/m'
    },
    tooltip: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  },
  computed: {
    hasTransitionParams() {
      return !!this.transitionParams?.intervalTime
    },
    hasPremium() {
      return !!this.premium
    }
  }
}
</script>
