<template>
  <CycleHeader is-rational>
    <CycleHeaderPanel v-bind="headerPanelProps">
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </CycleHeaderPanel>
    <CycleContentBlock v-if="hasImage">
      <img :src="mainImage.src" :alt="altText" :title="imageTitle" slot="image" />
    </CycleContentBlock>
  </CycleHeader>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'RationalHero',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const title = props.fields?.title?.value
    const links = props.fields?.links?.value ?? []
    const mainImage = props.fields?.mainImage?.value ?? {}
    const badgeText = props.fields?.badgeText?.value
    const bodyText = props.fields?.bodyText?.value
    const panelFooterContent = props.fields?.panelFooterContent?.value
    const hasImage = mainImage?.src?.length > 0
    const imageTitle = props.fields?.imageTitle?.value
    const altText = props.fields?.altText?.value
    const headerPanelProps = {
      title,
      bodyText,
      badgeText,
      panelFooterContent,
      links
    }
    return {
      hasImage,
      mainImage,
      headerPanelProps,
      imageTitle,
      altText
    }
  }
}
</script>
