import * as gql from '../services/data/gql'
import { logError } from '../services/Logging'
import { registerService } from '../services/data'
import { storeModuleMixinFactory } from './store'

function appendServices(context, services) {
  const { $jss } = context

  services.forEach((service) => {
    const { serviceName, serviceDefinition } = service
    registerService(serviceName, serviceDefinition, $jss)
  })
}

/**
 * Original funnelMixin.
 * Can be removed once funnelMixinFactory is implemented on all funnels.
 */
export const funnelMixin = {
  methods: {
    fetchFinalQuestions(code) {
      const variables = { inputModel: { code } }
      return this.gql
        .query(gql.queries.funnelQuestions, variables)
        .then(({ data }) => this.$set(this, 'finalQuestions', data))
        .catch(logError)
    }
  }
}

/**
 * A mixin factory that configures a funnel with its own VueX module and data service
 *
 * @param {string[]} moduleNamespace - @see storeModuleMixinFactory
 * @param {Object} storeModule - @see storeModuleMixinFactory
 * @param {string[]|boolean} stateMapping - @see storeModuleMixinFactory
 * @param {string[]|boolean} gettersMapping - @see storeModuleMixinFactory
 * @param {string[]|boolean} mutationsMapping - @see storeModuleMixinFactory
 * @param {string[]|boolean} actionsMapping - @see storeModuleMixinFactory
 * @param {string|boolean} commitRegistration - @see storeModuleMixinFactory
 * @param {object[]} services - An array of services that should be registered for the module to use
 */
export function funnelMixinFactory({
  moduleNamespace,
  storeModule,
  stateMapping,
  gettersMapping,
  mutationsMapping,
  actionsMapping,
  commitRegistration,
  services = []
}) {
  const storeMixinObj = storeModuleMixinFactory({
    moduleNamespace,
    storeModule,
    stateMapping,
    gettersMapping,
    mutationsMapping,
    actionsMapping,
    commitRegistration
  })
  const mixin = {
    beforeCreate() {
      appendServices(this, services)
      storeMixinObj.beforeCreate.call(this)
    },
    created() {
      this.$store.commit(
        moduleNamespace ? `${moduleNamespace}/setFunnelMeta` : 'setFunnelMeta',
        this.$attrs
      )
    }
  }

  return {
    ...funnelMixin,
    ...storeMixinObj,
    ...mixin,
    methods: { ...funnelMixin.methods, ...storeMixinObj.methods }
  }
}
