import { localizedPaths } from './models'
import { SiteSettingsState } from './state'

const getters = {
  currentLanguage(state: SiteSettingsState): string | undefined {
    return state.currentLanguage
  },
  availableLanguages(state: SiteSettingsState): localizedPaths {
    return state.availableLanguages
  },
  siteName(state: SiteSettingsState): string | undefined {
    return state.siteName
  },
  logoutUrl(state: SiteSettingsState): string | undefined {
    return state.logoutUrl
  },
  dictionary(state: SiteSettingsState): Object {
    return state.dictionary
  }
}
export default getters
