<template>
  <div v-if="isVisible" :class="notificationItemClassList" data-status="rejected">
    <CycleHeading :weight="4">{{ errorTitle }}</CycleHeading>
    <ul class="list list--cons">
      <li v-for="element in errorElements" :key="element.key">
        <a :href="'#' + element.key" @click.prevent="scrollTo(element.key)">
          {{ element.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { CycleHeading } from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'
export default {
  name: 'RapidFormValidationContainer',
  components: {
    CycleHeading
  },
  mixins: [validationMixin, dictionaryMixin],
  props: {
    resolveValidationMethods: {
      type: Function,
      required: false
    },
    isFieldErrorsShow: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return this.formValidations
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      'fields',
      'formData',
      'activeValidators',
      'validationProperties'
    ]),
    isVisible() {
      return !!(this.$v.$error && this.errorElements.length)
    },
    formValidations() {
      return {
        formData: this.activeValidators
      }
    },
    errorTitle() {
      return this.getPhrase('error-messages-general-001')
    },
    errorElements() {
      return Object.entries(this.$v.formData)
        .filter(([, validation]) => validation.$invalid)
        .map(([key, validation]) => ({
          key,
          error: validation.$error,
          invalid: validation.$invalid,
          label: this.fields.find((field) => field.fieldKey === key)?.label,
          errors: Object.keys(validation)
            .filter((validator) => validator[0] !== '$' && validation[validator] === false)
            .map((validator) => ({
              key,
              validator,
              ...this.validationProperties(key, validator)
            }))
        }))
    },
    invalidElements() {
      return Object.entries(this.$v.formData)
        .filter(([, validation]) => validation.$invalid)
        .map(([key]) => key)
    },
    notificationItemClassList() {
      const mainClassName = 'form__notification'
      return {
        [mainClassName]: true,
        [`${mainClassName}--field-errors`]: this.isFieldErrorsShow
      }
    }
  },
  watch: {
    $v: {
      deep: true,
      immediate: true,
      handler(validations) {
        this.setValidationStatus(validations)
      }
    },
    errorElements: {
      deep: true,
      immediate: true,
      handler(errorElements) {
        this.setErrorElements(errorElements)
      }
    },
    invalidElements: {
      immediate: true,
      handler(invalidElements) {
        this.setInvalidElements(invalidElements)
      }
    }
  },
  beforeMount() {
    if (this.resolveValidationMethods) {
      this.resolveValidationMethods(this.toggleValidation, this.resetValidation)
    }
  },
  methods: {
    ...mapActions('rapidFormPlayer', [
      'setValidationStatus',
      'setErrorElements',
      'setInvalidElements'
    ]),
    toggleValidation() {
      this.$v.$touch()
    },
    resetValidation() {
      this.$v.$reset()
    },
    scrollTo(key) {
      const bookmarks = document.getElementsByName(key)

      if (bookmarks.length) {
        bookmarks[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
}
</script>
