<template>
  <CycleFormConfirmation :status="codeToStatus">
    <CycleHtml :html="message" v-if="expectedCode" />
    <CycleHtml :html="failureText" v-if="!expectedCode" />
  </CycleFormConfirmation>
</template>

<script>
import { pathOr } from 'ramda'
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
import CycleFormConfirmation from '../CycleFormConfirmation/CycleFormConfirmation'
import CycleHtml from '../../../base/CycleHtml'
import { dictionaryMixin } from '../../../../mixins/dictionary'
export default {
  name: 'CycleFunnelConfirmation',
  components: { CycleHtml, CycleFormConfirmation, CycleHeading },
  mixins: [dictionaryMixin],
  props: {
    result: {
      type: Object,
      default: () => false
    }
  },
  computed: {
    failureText() {
      return this.phrase('error-messages-funnels-001')
    },
    message() {
      return pathOr('Probeer het later opnieuw', ['closingText'], this.result)
    },
    expectedCode() {
      return ['10', '20', '60', '99', 10, 20, 60, 99].includes(pathOr('0', ['code'], this.result))
    },
    codeToStatus() {
      const status = pathOr('99', ['code'], this.result)
      if (status === '10' || status === 10) {
        return 'confirmed'
      } else if (status === '20' || status === 20 || status === '60' || status === 60) {
        return 'pending'
      } else {
        return 'rejected'
      }
    }
  }
}
</script>
