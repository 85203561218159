export const policyStatusMap = {
  unknown: 0,
  newInProgress: 1,
  newFuturePolicy: 2,
  ongoing: 3,
  ongoingInProgress: 4,
  suspended: 5,
  cancelled: 6,
  nonContributory: 7,
  proposal: 8,
  ongoingFutureChange: 9,
  newRejected: 10
}
