<template>
  <div :class="classList">
    <div class="form__suggestion-box--header">
      <ul>
        <li>
          <CycleImage v-if="hasBannerImage" :image="avatar" class="banner-image" />
          <CycleHeading>{{ title }}</CycleHeading>
        </li>
        <li>
          <span class="payment-term">{{ label }}</span>
          <label v-if="suggestionPrice" class="price">{{ suggestionPrice }}</label>
          <ModalTooltip v-if="isModalToolTipVisible" :tooltip-has-long-text="tooltipHasLongText">
            <template #tooltip-heading>
              <slot name="tooltip-heading">{{ label }}</slot>
            </template>
            <template #tooltip-content>
              <slot name="tooltip-content">
                <CycleHtml :html="tooltip" />
              </slot>
            </template>
          </ModalTooltip>
          <button :class="[isCollapsed ? 'down' : 'up']" @click="handleToggle" />
        </li>
      </ul>
    </div>
    <div v-if="!isCollapsed" class="form__suggestion-box--content">
      <div class="box">
        <CycleHtml :html="content" />
        <div class="box--misc">
          <label v-if="suggestionPrice" class="price">{{ suggestionPrice }}</label>
          <span class="payment-term">{{ label }}</span>
          <ModalTooltip v-if="isModalToolTipVisible" :tooltip-has-long-text="tooltipHasLongText">
            <template #tooltip-heading>
              <slot name="tooltip-heading">{{ label }}</slot>
            </template>
            <template #tooltip-content>
              <slot name="tooltip-content">
                <CycleHtml :html="tooltip" />
              </slot>
            </template>
          </ModalTooltip>
        </div>
      </div>
      <div class="box">
        <a
          v-if="linkSalesFunnelUrl.length > 0"
          class="btn btn--primary"
          :href="linkSalesFunnelUrl"
          target="_blank"
          >{{ linkSalesFunnelText }}</a
        >
        <a class="link" @click="handleToggle">{{ btnCollapseText }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { ModalTooltip } from '@aon/cycle'

const props = defineProps({
  banner: {
    type: Object,
    required: true,
    default: () => {}
  },
  title: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  tooltip: {
    type: String,
    default: ''
  },
  tooltipHasLongText: {
    type: Boolean,
    default: true
  },
  content: {
    type: String,
    default: ''
  },
  suggestedOffer: {
    type: Object,
    default: () => ({})
  },
  modifiers: {
    type: Array,
    default: () => []
  },
  btnCollapseText: {
    type: String,
    default: 'Inklappen'
  }
})

const isCollapsed = ref(true)

const hasBannerImage = props.banner?.image?.url?.length > 0

const avatar = {
  src: props.banner?.image?.url,
  alt: props.banner?.image?.altText,
  width: 40,
  height: 40
}

const isModalToolTipVisible = props.tooltip?.length > 0

const suggestionPrice = props.suggestedOffer?.priceFormatted

const linkSalesFunnelText = props.suggestedOffer?.linkToSalesFunnel?.title

const linkSalesFunnelUrl = props.suggestedOffer?.linkToSalesFunnel?.href ?? ''

const classList = computed(() => {
  const mainClassName = 'form__suggestion-box'
  return {
    [`${mainClassName}`]: true,
    [`${mainClassName}--${props.modifiers}`]: true,
    [`${mainClassName}--collapsed`]: isCollapsed.value
  }
})

const handleToggle = (event) => {
  event.preventDefault()
  isCollapsed.value = !isCollapsed.value
}
</script>
