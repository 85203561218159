<template>
  <PreFunnelHomeContents
    :choose-building-type-text="fields.chooseBuildingTypeText | ScString"
    :link="fields.link | ScLink"
    :building-types="buildingTypes"
  />
</template>

<script>
import { HomeContentsPreFunnel } from '@aon/cycle'
export default {
  name: 'HomeContentsPreFunnel',
  components: {
    PreFunnelHomeContents: HomeContentsPreFunnel
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    buildingTypes() {
      return (
        this.fields?.buildingTypes?.filter(this.isBuildingTypeValid).map((bt) => {
          const { Key, Value, IsInsurable } = bt.fields
          return { key: Key.value, value: Value.value, isInsurable: IsInsurable.value }
        }) ?? []
      )
    }
  },
  methods: {
    isBuildingTypeValid(buildingType) {
      return (
        buildingType &&
        buildingType.fields &&
        buildingType.fields.Key &&
        buildingType.fields.Value &&
        buildingType.fields.IsInsurable
      )
    }
  }
}
</script>
