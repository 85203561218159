<template>
  <FunnelHome v-bind="funnelData">
    <template #collectivity-picker>
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </template>
  </FunnelHome>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import * as Cycle from '@aon/cycle'

export default {
  name: 'FunnelHomeOwnerSitecore',
  components: {
    FunnelHome: Cycle.FunnelHomeOwnerSitecore,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    funnelData() {
      return {
        ...this.fields,
        ...this.renderingWithoutFields
      }
    },
    renderingWithoutFields() {
      // Fields are somehow duplicated in the rendering prop, we'll remove them here
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
