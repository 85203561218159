<template>
  <CycleFormPage
    :next-button-text="textContent.page6NextButton"
    :prev-button-text="textContent.page6PrevButton"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="overview.header" />
      </CycleHeading>
    </template>

    <CycleFormSet :heading="textContent.overview.general.heading">
      <dl>
        <dt><ProvidedText name="overview.general.startChangeDateLabel" /></dt>
        <dd>
          {{ startDate | CycleDate({ locale }) }}
        </dd>
        <dt><ProvidedText name="overview.general.householdComposition" /></dt>
        <dd>
          <ProvidedText
            name="householdCompositionSingle"
            v-if="familyTypes.Single === familyType"
          />
          <ProvidedText
            name="householdCompositionMultiple"
            v-if="familyTypes.Multiple === familyType"
          />
        </dd>

        <template v-for="(coveragePremium, coverageName, index) in selectedCoverages">
          <dt :key="index">
            <ProvidedText :name="`overview.general.${coverageName}`" />
            <span>{{ optionCoverage(coverageName) }}</span>
          </dt>
          <template>
            <dd :key="coverageName">
              {{ coveragePremium | CycleCurrency({ locale }) }}
            </dd>
          </template>
        </template>
        <dt><ProvidedText name="overview.general.paymentTermMonthly" /></dt>
        <dd>
          {{ totalPremium | CycleCurrency({ locale }) }}
        </dd>
      </dl>
    </CycleFormSet>
    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { CycleHeading } from '@/components/elements'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { ProductPremium, Coverages } from '../../models'
import { FamilyType } from '../../helpers/constants'
import { intlMixin } from '@/mixins'

export default {
  name: 'FunnelTravelStepOverviewMultiTrip',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    familyType: {
      required: false,
      type: String
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    coverages: {
      type: Coverages
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    totalPremium() {
      return this.premium?.totalPremium
    },
    selectedCoverages() {
      const orderCoverages = [
        'baseCoverage',
        'luggage',
        'medicalCosts',
        'replacementTransportAndRepatriation',
        'accidents',
        'specialSports',
        'cancellation',
        'excessDeductibleRentalCar'
      ]
      if (this.premium && this.premium.coverages) {
        return Object.keys(this.premium.coverages)
          .sort((a, b) => {
            return orderCoverages.indexOf(a) - orderCoverages.indexOf(b)
          })
          .filter((key) => {
            return this.coverages[key]
          })
          .reduce((acc, coverage) => {
            return {
              ...acc,
              [coverage]: this.premium.coverages[coverage]
            }
          }, {})
      }
    }
  },
  methods: {
    optionCoverage(coverage) {
      const text = this.textContent.overview.coverages
      const optionalCoverages = ['cancellation', 'luggage', 'baseCoverage', 'accidents']
      const hasOption = optionalCoverages.includes(coverage)
      const option = `${coverage}Option`
      const optedValue = this.coverages[option]
      return hasOption ? ` (${text[coverage][optedValue]})` : ''
    },
    pageSubmit() {}
  }
}
</script>
