import { getDefaultState } from './state'

export const mutations = {
  SET_PORTFOLIO_INFO(state, payload) {
    if (payload) {
      const { dataSourceId, locale, portfolioInfo, clientIdentifier } = payload

      const packagePolicies = portfolioInfo.packages.reduce((acc, item) => {
        const policies = item.policies.map((policy) => {
          return {
            ...policy,
            dataSourceId,
            locale,
            clientIdentifier
          }
        })

        return [...acc, ...policies]
      }, [])

      const regularPolicies = portfolioInfo.policies.map((item) => {
        return {
          ...item,
          dataSourceId,
          locale,
          clientIdentifier
        }
      })

      state.portfolioInfo = [
        ...state.portfolioInfo,
        { ...portfolioInfo, dataSourceId, locale, clientIdentifier }
      ]
      state.policies = [...state.policies, ...packagePolicies, ...regularPolicies]
    }
  },
  SET_POLICY_INFO(state, { policyNumber, policyInfo }) {
    if (policyNumber && policyInfo) {
      const policyIndex = state.policies.findIndex(
        (policy) => policy.numberEncrypted === policyNumber
      )

      if (policyIndex > -1) {
        state.policies[policyIndex] = {
          ...state.policies[policyIndex],
          ...policyInfo
        }

        // Updating the whole array is necessary to make changes observable.
        state.policies = [...state.policies]
      }
    }
  },
  SET_DIRECT_LINKS(state, payload) {
    if (payload) {
      const { packages, policies } = payload

      const packagePolicies = packages.reduce((acc, item) => {
        const policies = item.policies

        return [...acc, ...policies]
      }, [])

      state.directLinks = [...packagePolicies, ...policies]
    }
  },
  SET_DOCUMENTS(state, payload) {
    if (payload) {
      state.documents = payload
    }
  },
  SET_CLIENT_IDENTIFIER(state, { clientIdentifier }) {
    if (clientIdentifier) {
      state.clientIdentifier = clientIdentifier
    }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}
