<template>
  <div class="tile">
    <input type="radio" class="tile__toggle" :id="_cycleUid" :checked="isActive" />
    <label
      class="tile__button"
      :for="_cycleUid"
      :aria-selected="isActive ? 'true' : 'false'"
      @click="$emit('update-url', option)"
    >
      <CycleIcon :modifiers="option.class" />
      <span class="heading" v-if="option.text">{{ option.text }}</span>
    </label>
  </div>
</template>

<script>
import { CycleIcon } from '../elements'

export default {
  name: 'CycleTileNavigationItem',
  components: {
    CycleIcon
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: true
    }
  }
}
</script>
