//
//
//
//
//
//
//
//
//
//
//
//

import { CycleLink } from '@/components//cycle-link'
import { CycleHeading } from '@/components/elements/CycleHeading'
import { CycleCard } from '@/components/card'

export default {
  name: 'CycleLinkCard',
  components: {
    CycleCard,
    CycleLink,
    CycleHeading
  },
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: '_self'
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    tag: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
