import { CoreUIBreakpoints } from '@/constants'

const mapPictureData = (pictures) => {
  return Object.entries(CoreUIBreakpoints).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [value]: pictures[key]?.src
    }
  }, {})
}

export default mapPictureData
