<template>
  <CycleSection>
    <div class="location-map">
      <div id="googlemap"></div>
      <div id="infowindow-content">
        <template v-if="selectedAddress.lat && selectedAddress.lng">
          <div v-if="selectedAddress.address" class="poi-info-window">
            <div>
              <img :src="selectedAddress.icon" width="16" height="16" />
              <div class="title full-width">{{ selectedAddress.name }}</div>
              <div class="gm-address">
                <div class="address-line full-width">
                  {{ selectedAddress.address }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="location-map__searchbar">
        <i class="icon icon--search" />
        <input
          type="text"
          id="location-map__searchbar__input"
          :placeholder="placeholderText"
          data-track-location-map-search-click
        />
      </div>
      <div v-if="closestLocations?.length > 0" class="location-map__closest">
        <div
          @click="toggleClosestLocations"
          class="location-map__closest-toggle"
          data-track-location-map-closest-location-toggle
        >
          {{ showClosestText }} <i class="icon icon--chevron-down" />
        </div>
        <ul v-if="areClosestLocationsVisible" class="location-map__searchresult">
          <li
            v-for="location in closestLocations"
            :key="location.name"
            @click="setSelectedLocation(location)"
            :data-track-location-map-closest-locations-click="
              location.name + ' ± ' + location.distance + ' km'
            "
          >
            <div class="searchresult-icon"></div>
            <div class="location-title">
              {{ location.name }} <span class="distance">± {{ location.distance }} km</span>
            </div>
            <div>{{ location.street }} {{ location.houseNumberComplete }}</div>
            <div>{{ location.zipCodeComplete }} {{ location.city }}</div>
          </li>
        </ul>
      </div>
    </div>
  </CycleSection>
</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { markerClusterer } from './markerclusterer'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})

onMounted(() => {
  loadMapsScript()
  initMap()
})

const loadMapsScript = () => {
  ;((g) => {
    let h,
      a,
      k,
      p = 'The Google Maps JavaScript API',
      c = 'google',
      l = 'importLibrary',
      q = '__ib__',
      m = document,
      b = window
    b = b[c] || (b[c] = {})
    const d = b.maps || (b.maps = {}),
      r = new Set(),
      e = new URLSearchParams(),
      u = () =>
        h ||
        (h = new Promise((f, n) => {
          a = m.createElement('script')
          e.set('libraries', [...r] + '')
          for (k in g)
            e.set(
              k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()),
              g[k]
            )
          e.set('callback', c + '.maps.' + q)
          a.src = `https://maps.${c}apis.com/maps/api/js?` + e
          d[q] = f
          a.onerror = () => (h = n(Error(p + ' could not load.')))
          a.nonce = m.querySelector('script[nonce]')?.nonce || ''
          m.head.append(a)
        }))
    d[l]
      ? console.warn(p + ' only loads once. Ignoring:', g)
      : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)))
  })({
    key: 'AIzaSyCjeGwaoCXG58_SW1_N-ojA0wG5ikiISv0',
    v: 'weekly'
    // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
    // Add other bootstrap parameters as needed, using camel case.
  })
}

// dictionary
const dictionaryService = inject('dictionaryService')
const placeholderText = dictionaryService.value.phrases['searchbar-placeholder-text']
const showClosestText = dictionaryService.value.phrases['show-closest-locations-text']
const myLocationText = dictionaryService.value.phrases['location-my-location']

// closest locations
const isUnableToGetClosestLocations = computed(
  () =>
    !selectedAddress.value?.lat || !selectedAddress.value?.lng || !geometryLibrary?.value?.spherical
)
const repairShopPriority = props.fields?.repairShopPriority?.value ?? ''
const closestLocations = computed(() => {
  if (isUnableToGetClosestLocations.value) {
    return []
  }
  return props.fields.locations
    .map((location) => ({
      ...location,
      distance: distanceBetweenPositionsInKilometer(selectedAddress.value, {
        lat: Number(location.latitude),
        lng: Number(location.longitude)
      })
    }))
    .sort((location1, location2) => location1.distance - location2.distance)
    .slice(0, props.fields.repairShopLimit.value)
    .sort(
      (location1, location2) =>
        location2.name.startsWith(repairShopPriority) -
        location1.name.startsWith(repairShopPriority)
    )
})
const areClosestLocationsVisible = ref(false)
const toggleClosestLocations = () => {
  areClosestLocationsVisible.value = !areClosestLocationsVisible.value
}
const distanceBetweenPositionsInKilometer = (sourcePosition, targetPosition) => {
  return (
    geometryLibrary.value.spherical.computeDistanceBetween(sourcePosition, targetPosition) / 1000
  ).toFixed(1)
}

// location search
const setSelectedLocation = (location) => {
  clearSelectedMarkers()
  const latLng = new window.google.maps.LatLng(location.latitude, location.longitude)
  focusOnLocation(latLng)
  areClosestLocationsVisible.value = false
}

//libraries
const geometryLibrary = ref({})
const markerLibrary = ref({})

const selectedMarkers = ref([])
// centralized map
const map = ref({})
const markers = []
const selectedAddress = ref({})
const initMap = async () => {
  await window.google.maps.importLibrary('maps')
  await window.google.maps.importLibrary('places')
  const myLatLng = { lat: 51.96449394349552, lng: 5.475833591910115 }
  const mapDiv = document.getElementById('googlemap')
  map.value = new window.google.maps.Map(mapDiv, {
    center: myLatLng,
    zoom: 9,
    fullscreenControl: false,
    mapId: 'MAP_ID',
    mapTypeControl: false,
    streetViewControl: false
  })
  const marker = await window.google.maps.importLibrary('marker')
  const geometry = await window.google.maps.importLibrary('geometry')
  geometryLibrary.value = geometry
  markerLibrary.value = marker
  addMarkersForLocations()
  addSearchBar()
  addMyLocationButton()
  setCurrentLocation()
}
const addMyLocationButton = () => {
  const locationButton = document.createElement('button')
  locationButton.title = myLocationText
  locationButton.innerHTML = '<span class="gm-crosshair"></span>'
  locationButton.classList.add('custom-map-control-button')
  locationButton.setAttribute('data-track-location-map-my-location', '')
  map.value.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton)
  locationButton.addEventListener('click', setCurrentLocation)
}
const addMarkersForLocations = () => {
  const locations = props.fields.locations
  const infoWindows = []

  for (const location of locations) {
    const latlng = { lat: Number(location.latitude), lng: Number(location.longitude) }
    const contentString = `
          <div class="poi-info-window">
            <div>
              <div class="title full-width">${location.name}</div>
              <div class="gm-address">
                <div class="address-line full-width" >
                  ${location.street} ${location.houseNumberComplete}
                </div>
                <div class="address-line full-width" >
                  ${location.zipCode} ${location.zipCodeLetters}  ${location.city}
                </div>
              </div>
              <hr />
              <div>
                <div class="gm-contact">
                  <div class="contact-line full-width" ><i class="icon icon--phone"></i>
                    ${location.phoneNumber}
                  </div>
                  <div class="contact-line full-width" ><i class="icon icon--envelope"></i>
                    <a
                    href="mailto:${location.mail}"
                    title="${location.mail}"
                    data-track-location-map-mail="${location.mail}"
                    >
                      ${
                        dictionaryService.value.phrases['location-send-email'] || 'Stuur een e-mail'
                      }
                    </a>
                  </div>
                </div>
                <a class="gm-navigate" target="_blank" data-track-location-map-navigate-to="
                ${location.name}
                " href="https://www.google.nl/maps/dir//
              ${location.latitude},${location.longitude}" title="
              ${
                dictionaryService.value.phrases['location-navigate'] ||
                'Routebeschrijving naar dit punt'
              }"></a>
              </div>
            </div>
            `
    const parser = new DOMParser()
    // A marker with a custom inline SVG.
    const pinSvgString = `
            <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              width="800px" height="800px" viewBox="0 0 447.645 447.645"
              xml:space="preserve">
              <g>
                <path d="M447.639,244.402c0-8.805-1.988-17.215-5.578-24.909c-0.37-1.956-0.793-3.909-1.322-5.89l-38.884-96.365l-0.263-0.867
          c-13.605-40.509-32.963-78.001-82.049-78.001H131.868c-50.296,0-68.069,38.421-81.972,77.776l-40.673,96.6
          C3.343,222.167,0,232.944,0,244.402v29.986c0,4.636,0.548,9.171,1.59,13.539C0.577,290.566,0,293.41,0,296.408v89.185
          c0,13.078,10.602,23.682,23.68,23.682h49.14c13.071,0,23.673-10.604,23.673-23.682v-44.599h257.46v44.599
          c0,13.078,10.604,23.682,23.683,23.682h46.326c13.083,0,23.683-10.604,23.683-23.682v-89.195c0-2.987-0.583-5.844-1.588-8.474
          c1.038-4.375,1.588-8.905,1.588-13.54v-29.981H447.639z M78.754,125.821c15.483-43.683,27.934-57.018,53.114-57.018h187.664
          c24.995,0,38.913,14.873,53.056,56.83l28.375,57.502c-9.265-3.431-19.461-5.335-30.173-5.335H76.849
          c-9.645,0-18.862,1.551-27.366,4.358L78.754,125.821z M103.129,285.776H51.281c-9.335,0-16.906-7.578-16.906-16.912
          c0-9.337,7.571-16.91,16.906-16.91h51.848c9.339,0,16.91,7.573,16.91,16.91C120.039,278.198,112.463,285.776,103.129,285.776z
          M286.284,282.389h-120.6c-5.913,0-10.704-4.794-10.704-10.704c0-5.921,4.791-10.713,10.704-10.713h120.6
          c5.92,0,10.71,4.792,10.71,10.713C296.994,277.595,292.204,282.389,286.284,282.389z M395.051,285.776h-51.846
          c-9.343,0-16.91-7.578-16.91-16.912c0-9.337,7.573-16.91,16.91-16.91h51.846c9.343,0,16.916,7.573,16.916,16.91
          C411.967,278.198,404.394,285.776,395.051,285.776z"/>
              </g>
            </svg>
            `
    const pinSvg = parser.parseFromString(pinSvgString, 'image/svg+xml').documentElement

    const glyphSvgPinElement = new markerLibrary.value.PinElement({
      glyph: pinSvg
    })

    const marker = new markerLibrary.value.AdvancedMarkerElement({
      position: latlng,
      content: glyphSvgPinElement.element,
      title: location.name
    })
    const infoWindow = new window.google.maps.InfoWindow({
      content: contentString,
      ariaLabel: 'ariaLabel'
    })
    infoWindows.push(infoWindow)

    marker.addListener('click', () => {
      //close all
      for (let i = 0; i < infoWindows.length; i++) {
        infoWindows[i].close()
      }
      infoWindow.open({
        anchor: marker,
        map: map.value
      })
    })

    map.value.addListener('click', () => {
      infoWindow.close()
    })
    markers.push(marker)
  }

  new markerClusterer.MarkerClusterer({ markers, map: map.value })
}
const setCurrentLocation = () => {
  clearSelectedMarkers()
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      selectedAddress.value = pos
      const myLocationPin = new markerLibrary.value.PinElement({
        scale: 1.5
      })
      const marker = new markerLibrary.value.AdvancedMarkerElement({
        map: map.value,
        position: selectedAddress.value,
        title: myLocationText,
        content: myLocationPin.element
      })
      // add a marker for the current location of the client/user
      selectedMarkers.value.push(marker)
      focusOnLocation(selectedAddress.value)
    })
    // make closestLocations visible as the location is chaned
    areClosestLocationsVisible.value = true
  }
}
const addSearchBar = () => {
  const searchBoxInputElement = document.getElementById('location-map__searchbar__input')
  // make the map autocomplete functionality restricted to the Netherlands
  const options = {
    componentRestrictions: { country: 'nl' }
  }

  let searchBox = new window.google.maps.places.Autocomplete(searchBoxInputElement, options)
  map.value.addListener('bounds_changed', () => {
    searchBox.setBounds(map.value.getBounds())
  })
  const infowindow = new window.google.maps.InfoWindow()
  const infowindowContent = document.getElementById('infowindow-content')

  infowindow.setContent(infowindowContent)
  searchBox.addListener('place_changed', () => {
    // close the infowindow of previous location
    infowindow.close()
    clearSelectedMarkers()

    const place = searchBox.getPlace()
    if (place.geometry.viewport) {
      map.value.fitBounds(place.geometry.viewport)
    } else {
      focusOnLocation(place.geometry.location)
    }
    selectedAddress.value = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      icon: place.icon,
      name: place.name,
      address: place.formatted_address
    }
    const scaledPin = new markerLibrary.value.PinElement({
      scale: 1.5
    })
    const marker = new markerLibrary.value.AdvancedMarkerElement({
      map: map.value,
      position: selectedAddress.value,
      title: place.name,
      content: scaledPin.element
    })

    // add the marker of the selected place
    selectedMarkers.value.push(marker)
    // open the window with the marker of seleced location
    infowindow.open(map, marker)
    // make closestLocations visible as the location is chaned
    areClosestLocationsVisible.value = true
    // reset the searchbar
    searchBoxInputElement.value = ''
  })
}

// clear the markers of previously selected locations
const clearSelectedMarkers = () => {
  selectedMarkers.value.forEach((marker) => {
    marker.setMap(null)
  })
  selectedMarkers.value = []
}

const focusOnLocation = (location) => {
  map.value.setCenter(location)
  map.value.setZoom(17)
}
</script>
