<template>
  <CycleFormSet>
    <CContainer>
      <template slot="label">
        {{ textContent.zipcodeLabel }}
        <CycleTooltip v-if="textContent.zipcodeTooltip">
          <template slot="tooltip-heading">
            {{ textContent.zipcodeLabel }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.zipcodeTooltip" />
          </template>
        </CycleTooltip>
      </template>
      <CText
        v-model="formData.zipcode"
        :placeholder="textContent.zipcodePlaceholder"
        postcode
        required
        :disabled="disabled.includes('zipcode')"
        @input="handleHousenumberZipcodeInput"
      />
    </CContainer>
    <CContainer>
      <template slot="label">
        {{ textContent.housenumberLabel }}
        <CycleTooltip v-if="textContent.housenumberTooltip">
          <template slot="tooltip-heading">
            {{ textContent.housenumberLabel }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.housenumberTooltip" />
          </template>
        </CycleTooltip>
      </template>
      <CNumber
        v-model="formData.housenumber"
        required
        numeric
        :max-length="25"
        :disabled="disabled.includes('housenumber')"
        inline
        @input="handleHousenumberZipcodeInput"
      />
      <CText
        v-model="formData.housenumberAddition"
        :placeholder="phrase('myzone-forms-optional', 'Optioneel')"
        :disabled="disabled.includes('housenumberAddition')"
        graphql-safe
        :max-length="5"
        inline
      />
    </CContainer>
    <template v-if="showStreetCityInputs">
      <CContainer>
        <template slot="label">
          {{ textContent.streetLabel }}
          <CycleTooltip v-if="textContent.streetTooltip">
            <template slot="tooltip-heading">
              {{ textContent.streetLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.streetTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CText
          v-model="formData.street"
          required
          alpha-ext
          :max-length="35"
          :disabled="!manualEntry || disabled.includes('street')"
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.cityLabel }}
          <CycleTooltip v-if="textContent.cityTooltip">
            <template slot="tooltip-heading">
              {{ textContent.cityLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.cityTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CText
          v-model="formData.city"
          required
          alpha-ext
          :max-length="35"
          :disabled="!manualEntry || disabled.includes('city')"
        />
      </CContainer>
    </template>
    <template v-if="showErrorMessage">
      <CContainer>
        <CycleFormConfirmation status="rejected">
          <CycleHtml :html="textContent.zipcodeCheckFailureText" />
        </CycleFormConfirmation>
      </CContainer>
    </template>
  </CycleFormSet>
</template>

<script>
import { debounce } from 'lodash'

import { dictionaryMixin } from '@/mixins'
import CycleInputContainer from './../../../forms/CycleInputContainer/CycleInputContainer'
import CycleFormConfirmation from '../../../forms/CycleFormConfirmation/CycleFormConfirmation'
import CycleTextInput from '../../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleTooltip from '../../../../cycle-tooltip/CycleTooltip'
import { CycleHtml } from '../../../../base'
import { CycleFormSet } from '../../CycleFormSet'

export default {
  name: 'CyclePostcodeCheck',
  components: {
    CycleHtml,
    CycleTooltip,
    CycleFormConfirmation,
    CycleFormSet,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData'],
  mixins: [dictionaryMixin],
  props: {
    postcodeFound: {
      type: Boolean,
      required: false
    },
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    },
    hasManualEntry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    manualEntry() {
      return !this.postcodeFound
    },
    showStreetCityInputs() {
      if (!this.hasManualEntry) {
        return this.postcodeFound
      }
      return this.postcodeFound !== undefined
    },
    showErrorMessage() {
      return !this.hasManualEntry && this.postcodeFound === false
    }
  },
  methods: {
    fetchAddress: debounce(function (e) {
      this.$emit('input', e)
    }, 1000),
    handleHousenumberZipcodeInput() {
      if (this.formData.zipcode == null || this.formData.housenumber == null) {
        return
      }

      if (this.formData.zipcode.length >= 6 && this.formData.housenumber.length > 0) {
        this.fetchAddress(this.formData)
      }
    }
  }
}
</script>
