import Vue from 'vue'
import { createApp } from './createApp'

// let webpack compile the core-ui styling so the url to fonts are set properly for SSR
// webpack magic comment to have the fonts in the main chunk and available on load.
if (process.env.XE_EDITOR) {
  import(
    /* webpackMode: "eager" */ `@aon/core-ui/src/styles/${process.env.VUE_APP_COREUI_LABEL}.scss`
  )
}
Vue.config.silent = !!process.env.VUE_APP_CONFIG_SILENT

Vue.config.devtools =
  process.env.VUE_APP_ENABLE_DEVTOOLS === 'true' || process.env.NODE_ENV === 'development'

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide the window.__JSS_STATE__ object
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__: any = null
const ssrRawJson = document.getElementById('__JSS_STATE__')
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML)
}
const initialState = __JSS_STATE__ || undefined
// @ts-ignore
const initApp = () => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root')

  const { app } = createApp(initialState)

  if (rootElement) {
    app.$mount(rootElement)
  } else {
    console.error('main.ts: app.$mount rootElement #root not found')
  }
}
initApp()
