<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :next-button-text="contentProvider.getContentByPath('steps.coverages.nextButton')"
    :prev-button-text="contentProvider.getContentByPath('steps.coverages.backButton')"
  >
    <template #header>
      <CycleHeading :weight="3"><ProvidedText name="steps.coverages.heading" /></CycleHeading>
    </template>
    <CycleCheckout
      :amount="totalPremium"
      :amount-description="contentProvider.getContentByPath('steps.coverages.totalAmount')"
      :amount-subtext="contentProvider.getContentByPath('steps.coverages.totalAmountSubText')"
    >
      <template #checkout-item>
        <CycleFormSet
          :heading="
            contentProvider.getContentByPath('steps.coverages.checkOutItems.basicInsuranceHeading')
          "
        >
          <CycleCheckoutItem
            :label="
              contentProvider.getContentByPath('steps.coverages.checkOutItems.basicInsuranceLabel')
            "
            :tooltip="
              contentProvider.getContentByPath(
                'steps.coverages.checkOutItems.basicInsuranceTooltip'
              )
            "
            :price="basePremium"
            selected
          />
        </CycleFormSet>
        <CycleFormSet
          :heading="
            contentProvider.getContentByPath(
              'steps.coverages.checkOutItems.extendedInsuranceHeading'
            )
          "
        >
          <CycleCheckoutItem
            :label="
              contentProvider.getContentByPath(
                'steps.coverages.checkOutItems.extendedInsuranceLabel'
              )
            "
            :description="
              contentProvider.getContentByPath(
                'steps.coverages.checkOutItems.extendedInsuranceIntro'
              )
            "
            :tooltip="
              contentProvider.getContentByPath(
                'steps.coverages.checkOutItems.extendedInsuranceTooltip'
              )
            "
            :price="additionalGaragePremium"
            :model-value="garageSelected"
            @change="additionalGarageHandler"
          />
        </CycleFormSet>
      </template>
    </CycleCheckout>
  </CycleFormPage>
</template>

<script>
import { intlMixin, dictionaryMixin } from '@/mixins'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { HomePremium, Coverage } from '../../models'

export default {
  name: 'HomeOwnerCoverageSelection',
  components: {
    CycleFormPage,
    CycleCheckout,
    CycleCheckoutItem,
    CycleFormSet
  },
  inject: ['formData', 'textContent', 'contentProvider'],
  mixins: [intlMixin, dictionaryMixin],
  props: {
    selectedPremium: {
      required: false,
      type: HomePremium
    },
    allPremiums: {
      required: false,
      type: HomePremium
    }
  },
  data: () => ({
    garageSelected: false
  }),
  computed: {
    totalPremium() {
      return this.selectedPremium?.totalPremium || 0
    },
    basePremium() {
      return this.allPremiums?.premiums?.basePremium
    },
    additionalGaragePremium() {
      return this.allPremiums?.premiums?.additionalGaragePremium
    }
  },
  methods: {
    handleSubmit() {},
    additionalGarageHandler(val) {
      this.garageSelected = val
      this.$emit('coverage-changed', new Coverage(val))
    }
  }
}
</script>
