//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { CycleFormSet } from '@/components/functional'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { WeddingCoverageTypes, ProductPremium } from '../../models'
import { debounce } from 'lodash'

export default {
  name: 'WeddingStepCoverages',
  inject: ['formData', 'textContent', 'contentProvider'],
  components: {
    CycleFormPage,
    CycleFormSet,
    ProvidedText,
    ProvidedTooltip,
    CycleInputContainer,
    CycleCheckout,
    CycleCheckoutItem
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    selectedCoverages: {
      type: Array,
      default: () => []
    },
    premium: {
      type: ProductPremium,
      required: false
    }
  },
  data() {
    return {
      coverageTypes: WeddingCoverageTypes,
      localSelectedCoverages: []
    }
  },
  computed: {
    totalPremium() {
      return this.premium?.totalPremium
    }
  },
  watch: {
    selectedCoverages: {
      immediate: true,
      handler(selectedCoverages) {
        this.localSelectedCoverages = selectedCoverages.map((c) => c.coverageType)
        //this.updateSelectedCoverages()
      }
    }
  },
  methods: {
    updateSelectedCoverages: debounce(function () {
      this.$emit('coverage-update', this.localSelectedCoverages)
    }, 500),

    handleSubmit() {},
    premiumCoverage(coverage) {
      return this.premium?.coverages[coverage]
    }
  }
}
