<template>
  <CycleCardList
    v-if="areDocumentsVisible"
    :title="textContent.documentsTitle"
    modifiers="nomarker"
    data-testid="documentsCard"
  >
    <template #list-items>
      <CycleListItem v-if="recentPolicySheetUrl">
        <CycleLinkIcon modifiers="file" :href="recentPolicySheetUrl" target="_blank">
          {{ getPhrase('policy-sheet') }}
        </CycleLinkIcon>
      </CycleListItem>
      <CycleListItem v-else-if="downloadLink" data-testid="documentsCardDownloadLink">
        <CycleLinkIcon modifiers="file" :href="downloadLink" target=""> Polisblad </CycleLinkIcon>
      </CycleListItem>
      <CycleListItem v-if="greenCardDownloadLink">
        <CycleLinkIcon modifiers="file" :href="greenCardDownloadLink" target="">
          {{ greenCardLabelText }}
        </CycleLinkIcon>
      </CycleListItem>
      <CycleListItem
        v-for="condition in policyConditions"
        :key="condition.conditionNumber"
        data-testid="documentsCardPolicyConditions"
      >
        <CycleLinkIcon modifiers="file" :href="condition.url" target="_blank">
          {{ condition.title }}
        </CycleLinkIcon>
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleListItem, CycleCardList, CycleLinkIcon, CycleIcon } from '@aon/cycle'
import { isGreenCard } from '@aon/cycle/src/services/Document'
import { dictionaryMixin } from '@/mixins/Dictionary'
import { documentCode } from '@/store/MyZone/maps/policyMap'

export default {
  name: 'DocumentsCard',
  components: {
    CycleCardList,
    CycleListItem,
    CycleLinkIcon,
    CycleIcon
  },
  mixins: [dictionaryMixin],
  provide() {
    return {
      textContent: this.textContent
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['documents', 'getPolicyInfo', 'verifySourceSystem']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    policyConditions() {
      return this.policyInfo?.policy?.conditions ?? []
    },
    downloadLink() {
      const policySchedule = this.documents.find(
        (document) =>
          document.documentReference[0]?.number === this.policyInfo?.number &&
          document.type?.code === documentCode
      )

      if (!policySchedule?.id) {
        return null
      }

      return policySchedule.url
    },
    greenCardLabelText() {
      return this.textContent?.greenCardDownloadLinkText
    },
    greenCardDownloadLink() {
      const greenCard = this.policyInfo?.documents?.find(isGreenCard)
      if (!greenCard?.id) {
        return null
      }
      return greenCard.url
    },
    recentPolicySheetUrl() {
      return this.policyInfo?.policySheetUrl
    },
    areDocumentsVisible() {
      return this.downloadLink || this.hasPolicyConditions || this.recentPolicySheetUrl
    },
    hasPolicyConditions() {
      return this.policyConditions?.length > 0
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
