//
//
//
//
//
//
//
//
//

import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
export default {
  name: 'CycleFormConfirmation',
  components: { CycleHeading },
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasHeadingSlot() {
      return !!this.$slots.heading
    }
  }
}
