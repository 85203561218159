<template>
  <CycleRadioInput
    :value="value"
    :model-value="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    v-show="isVisible"
    v-if="isShown"
    :has-input-error="hasInputError"
  >
    <slot />
  </CycleRadioInput>
</template>

<script>
import { CycleRadioInput } from '@aon/cycle'
import { mapGetters } from 'vuex'
import { dynamicExpressionFactory } from '../../../../mixins/DynamicExpression'
import {
  fieldDynamicExpressionProps,
  dynamicExpressionPropsGetters
} from '../RapidFormField.constants'

export default {
  name: 'RapidFormRadioWrapper',
  components: {
    CycleRadioInput
  },
  mixins: [
    dynamicExpressionFactory('isShown', 'showIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('isVisible', 'visibleIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('isEnabled', 'enableIf', fieldDynamicExpressionProps, true)
  ],
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      required: false,
      default: () => ''
    },
    hasInputError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', dynamicExpressionPropsGetters)
  }
}
</script>
