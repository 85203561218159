<template>
  <div class="coreui__topbar">
    <div class="topbar__left">
      <slot name="leftContent" />
    </div>
    <div class="topbar__center">
      <slot name="centerContent" />
    </div>
    <div class="topbar__right">
      <slot name="rightContent" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Topbar'
}
</script>
