import { logWarning } from '@/services/logging'

export function evaluateExpression(scope, properties, expression) {
  try {
    const expressionAsFunction = new Function(...properties, `return ${expression}`)
    const args = propertiesToArguments(scope, properties)

    return expressionAsFunction(...args)
  } catch (ex) {
    logWarning(`ExpressionService:evaluateExpression: ${expression}, ${ex.message || ex}`)
  }

  return null
}

export const propertiesToArguments = (localScope, properties) => {
  return properties.reduce((acc, current) => [...acc, localScope[current]], [])
}
