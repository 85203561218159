<template>
  <IFrameSizeEmitter v-bind="data" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleIFrameSizeEmitterSitecore',
  components: {
    IFrameSizeEmitter: Cycle.CycleIFrameSizeEmitterSitecore
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value)
    },
    data() {
      return {
        ...this.textContent,
        ...this.renderingWithoutFields
      }
    },
    renderingWithoutFields() {
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
