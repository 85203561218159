import gql from 'graphql-tag'

export const policyItemTravel = gql`
  fragment policyItemTravel on Policy {
    item {
      ... on Travel {
        travelHouseholdComposition
        numberOfTravelDays
        travelConfiguration {
          travelConfigurationCoverageArea
          mainCoverage {
            travelConfigurationMainCoverageBasis {
              active
            }
          }
          additionalCoverages {
            luggage {
              active
              insuredAmount
            }
            medicalCosts {
              active
            }
            replacementTransportAndRepatriation {
              active
            }
            accidents {
              active
            }
            accidentsSpecialSports {
              active
            }
            cancellation {
              active
              canselationCosts
            }
            excessDeductibleRentalCar {
              active
            }
            specialSports {
              active
            }
          }
        }
      }
    }
  }
`
