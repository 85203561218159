//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/** old */
import { CycleCard } from '@/components/card'
import { CycleHeading } from '@/components/elements'
import { CycleButtonLink } from '@/components/cycle-link'

/** new, but relative */
import { Route, Address, Person } from '@/models'
import { PersonDateOfBirth } from '../../Person'
import ProvidedText from '../../Content/ProvidedText'
import { CycleNav } from '@/components/cycle-nav'
import { AddressLine, InformationLines, NamedInformation, PersonName } from '@/components/atoms'

export default {
  name: 'PersonalData',
  components: {
    AddressLine,
    CycleButtonLink,
    CycleCard,
    CycleHeading,
    CycleNav,
    InformationLines,
    NamedInformation,
    PersonDateOfBirth,
    PersonName,
    ProvidedText
  },
  props: {
    person: {
      type: Person,
      required: false
    },
    address: {
      type: Address,
      required: false
    },
    postalAddress: {
      type: Address,
      required: false
    },
    showPostalAddress: {
      type: Boolean,
      required: false
    },
    dateOfBirth: {
      type: String,
      required: false
    },
    editRoute: {
      type: Route,
      required: true
    }
  }
}
