<template>
  <div :class="comparisonClassList">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CycleCompareCoverage',
  props: {
    showPriceOnTop: {
      type: Boolean,
      required: false,
      default: false
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    showCheckMark: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSingleCoverBlock: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    comparisonClassList() {
      const mainClassName = 'comparison'
      return {
        [mainClassName]: true,
        [`${mainClassName}--price-order`]: this.showPriceOnTop,
        [`${mainClassName}--buttons`]: this.showButtons,
        [`${mainClassName}--check-mark`]: this.showCheckMark,
        [`${mainClassName}--single-coverblock`]: this.hasSingleCoverBlock
      }
    }
  }
}
</script>
