<template>
  <label class="form__checkbox">
    <input
      type="checkbox"
      v-bind="Object.assign({}, attributes, $attrs)"
      v-on="Object.assign({}, listeners, $listeners, { change: updateValue })"
      :value="value"
      :checked="checked"
    />
    <span>
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleCheckboxInput',
  extends: BaseInput,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    // Deprecated
    label: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [Array, Boolean],
      required: false,
      default: () => []
    }
  },
  computed: {
    checked() {
      if (this.multiple) {
        return this.modelValue.includes(this.value)
      }
      return this.modelValue
    },
    multiple() {
      return this.value != null && this.value != ''
    }
  },
  methods: {
    updateValue(event) {
      const isChecked = event.target.checked
      let newValue = isChecked
      if (this.multiple) {
        newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
      }
      this.$emit('change', newValue)
    }
  }
}
</script>
