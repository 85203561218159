<template>
  <div class="modalform">
    <Modal
      ref="modal"
      v-show="isModalVisible"
      :is-shown="isModalVisible"
      :disallow="disallow"
      @close="closeModal"
    >
      <slot v-for="slot in slotKeys" :name="slot" :slot="slot" />
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'

export default {
  name: 'ModalForm',
  components: {
    Modal
  },
  props: {
    cta: {
      type: Object,
      required: false
    },
    disallow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalVisible: true
    }
  },
  computed: {
    slotKeys() {
      return Object.keys(this.$slots)
    }
  },
  methods: {
    async showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    }
  }
}
</script>
