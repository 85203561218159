<template>
  <ContactInformation
    :email="email"
    :primary-phone="phone"
    :secondary-phone="phone2"
    :show-newsletter="showNewsletter"
    :newsletter="newsletter"
    :edit-route="editRoute"
  />
</template>
<script>
import { pathOr } from 'ramda'
import { Route } from '../../../models'
import { ContactInformation } from '../../organisms'
import { dictionaryMixin } from '@/mixins'
import { NewsletterStatus, NewsletterOptionsFallback } from '@/constants'

export default {
  name: 'CycleContactDetailsSitecore',
  components: {
    ContactInformation
  },
  props: {
    heading: String,
    emailLabel: String,
    emptyEmail: String,
    phoneLabel: String,
    phoneLabel2: String,
    newsletterLabel: String,
    emptyPhone: String,
    link: Object,
    // Magic GQL query injection
    mypersonalinfo: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mixins: [dictionaryMixin],
  computed: {
    email() {
      return this.personalData.emailAddress
    },
    phone() {
      return this.personalData.telephoneNumber
    },
    phone2() {
      return this.personalData.mobileNumber
    },
    showNewsletter() {
      return this.personalData.enableEmailOptIn
    },
    newsletter() {
      const newsletterOption = this.personalData.emailOptIn
      return newsletterOption === NewsletterStatus.Subscribed ? this.phrase('general-yes',NewsletterOptionsFallback.Subscribed) :
             newsletterOption === NewsletterStatus.UnSubscribed ? this.phrase('general-no',NewsletterOptionsFallback.UnSubscribed) : 
             this.phrase('general-unknown',NewsletterOptionsFallback.Unknown)
    },
    personalData() {
      return pathOr({}, ['personalData'], this.mypersonalinfo)
    },
    editRoute() {
      return Route.fromLink(this.link)
    }
  },
  provide() {
    return {
      textContent: {
        contactInformation: {
          header: this.heading,
          labels: {
            email: this.emailLabel,
            primaryPhone: this.phoneLabel,
            secondaryPhone: this.phoneLabel2,
            newsletter: this.newsletterLabel,
            edit: this.link.title
          },
          placeholders: {
            email: this.emptyEmail,
            primaryPhone: this.emptyPhone,
            secondaryPhone: this.emptyPhone
          }
        }
      }
    }
  }
}
</script>
