
import * as vueValidators from 'vuelidate/lib/validators'

import customValidators from '../../../constants/validators'
import { CycleUidMixin, testableMixin } from '../../../mixins'

const isCycleInputContainerRegex = /CycleInputContainer/

export default {
  name: 'BaseInput',
  mixins: [CycleUidMixin, testableMixin],
  inheritAttrs: false,
  inject: ['pageName', 'formErrors', 'formTree', 'formValidations'],
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      required: false,
      default: ''
    },
    validationPath: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      default: false,
      required: false
    },
    hasInputError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputContainer: null
    }
  },
  computed: {
    name() {
      return (
        this.validationPath ||
        this.$attrs.name ||
        (this.$vnode.data.model ? this.$vnode.data.model.expression : '')
      )
    },
    id() {
      return `${this.name}:${this._cycleUid}`
    },
    nestedName() {
      return `form.${this.pageName}.${this.name}`
    },
    hasError() {
      const errorObj = this.formErrors(this.nestedName)
      if (this.hasInputError) return this.hasInputError
      if (errorObj) return errorObj.$error
    },
    attributes() {
      return {
        name: this.name,
        'aria-invalid': this.hasError,
        ref: this.name
      }
    },
    validations() {
      return Object.keys(this.$attrs).reduce((acc, elem) => {
        const name = elem.replace(/(-[a-z])/g, (match) => match.replace('-', '').toUpperCase())
        const validator = this.validators[name]

        if (typeof validator === 'function') {
          const attributes = this.$attrs[elem]
          if (validator.length === 0) {
            acc[name] = validator
          } else if (validator.length === 1) {
            acc[name] = validator(attributes)
          } else {
            acc[name] = validator(...attributes)
          }
        }

        return acc
      }, {})
    },
    validators() {
      return { ...customValidators, ...vueValidators }
    },
    listeners() {
      return {}
    },
    formInputClass() {
      return {
        form__input: true,
        'form__input--inline': this.inline
      }
    }
  },
  created() {
    this.inputContainer = this.findInputContainer(this.$parent)

    if (!this.inputContainer) {
      return
    }

    this.inputContainer.registerChildComponent(this)

    const label =
      this.inputContainer.validationLabel ||
      this.inputContainer.label ||
      (this.inputContainer.$slots.label &&
        this.inputContainer.$slots.label.map((e) => e.text).join(''))
    this.setReactive(this.formTree, this.nestedName.split('.'), label)

    if (this.name.includes('.')) {
      this.setReactive(this.formValidations, this.nestedName.split('.'), this.validations)
    }
  },
  destroyed() {
    if (this.inputContainer) {
      this.inputContainer.unRegisterChildComponent(this)
      const otherComponents = this.inputContainer.childComponents.some(
        (c) => c.nestedName === this.nestedName
      )
      if (otherComponents) {
        return
      }
    }

    this.setReactive(this.formValidations, this.nestedName.split('.'), {})
  },
  methods: {
    // Makes any nested additions to `object` also reactive
    setReactive(object, pathArr, newVal) {
      if (pathArr.length == 1) {
        this.$set(object, pathArr[0], newVal)
      } else {
        if (!object[pathArr[0]]) this.$set(object, pathArr[0], {})
        this.setReactive(object[pathArr[0]], pathArr.splice(1), newVal)
      }
    },
    findInputContainer(viewModel) {
      if (!viewModel.$vnode || !viewModel.$vnode.tag) {
        return null
      }

      if (isCycleInputContainerRegex.test(viewModel.$vnode.tag)) {
        return viewModel
      }

      return viewModel.$parent ? this.findInputContainer(viewModel.$parent) : null
    }
  }
}
