<template>
  <portal-target :name="portalName" v-if="portalName" />
</template>

<script>
export default {
  name: 'PortalTargetSitecore',
  props: {
    fields: {
      type: Object
    }
  },
  computed: {
    portalName() {
      return this.fields['portal-name']?.value
    }
  }
}
</script>
