<template>
  <div
    class="grid grid--blogs"
    :class="{ 'grid--blogs-double': blogQuery?.blogItems?.length === 2 }"
  >
    <select @change="filterCategory" id="tag" name="tag">
      <option value="">{{ emptySelectionPhrase }}</option>
      <option
        v-for="tag in fields.siteTags"
        :value="tag.id"
        :key="tag.id"
        :selected="filterTagIds.includes(tag.id)"
      >
        {{ tag.name }}
      </option>
    </select>
    <CycleHeading v-if="blogQuery?.blogItems?.length">
      {{ fields.title }} ({{ currentAmount }} / {{ totalItems }})
    </CycleHeading>
    <HorizontalCard
      has-blog-modifier
      v-if="firstBlogItem && blogQuery?.blogItems?.length === 1"
      :image="{ src: firstBlogItem.thumbnail, alt: firstBlogItem.articleTitle }"
      :heading="firstBlogItem.articleTitle"
      :image-link="{ src: firstBlogItem.relativePath }"
    >
      <template #body>
        <CycleHtml :html="firstBlogItem.textPreview" />
      </template>
      <template #footer>
        <CycleLink :href="firstBlogItem.relativePath">
          {{ readMoreText }}
        </CycleLink>
        <div class="date">{{ firstBlogItem.datePublished }}</div>
      </template>
    </HorizontalCard>
    <CycleSubGrid v-else>
      <CycleCard v-for="item in blogQuery?.blogItems" :key="item.id">
        <template #image>
          <CycleLink :href="item.relativePath">
            <img :alt="item.articleTitle" :src="item.thumbnail" />
          </CycleLink>
        </template>
        <template #header>
          <CycleHeading>{{ item.articleTitle }}</CycleHeading>
        </template>
        <template #body>
          <CycleHtml :html="item.textPreview" />
        </template>
        <template #footer>
          <CycleLink :href="item.relativePath">
            {{ readMoreText }}
          </CycleLink>
          <div class="date">{{ item.datePublished }}</div>
        </template>
      </CycleCard>
    </CycleSubGrid>
    <div class="grid__pagination">
      <CycleButton v-if="areThereMoreItems" modifiers="next" @click="increasePresses">
        {{ loadMorePhrase }}
      </CycleButton>
    </div>
    <BusyIndicator v-if="$apollo.loading" />
  </div>
</template>

<script>
import { blogSearchQuery } from '@/gql/queries'
import { dictionaryMixin } from '@/mixins/Dictionary'

export default {
  name: 'BlogSearch',
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      blogQuery: {},
      loadMorePresses: 0,
      filterSelection: ''
    }
  },
  computed: {
    firstBlogItem() {
      return this.blogQuery?.blogItems?.[0]
    },
    emptySelectionPhrase() {
      return this.fields.selectOneMessage
    },
    loadMorePhrase() {
      return this.fields.loadMoreMessage
    },
    filterTagIds() {
      if (this.filterSelection) {
        return [this.filterSelection]
      } else {
        if (!this.fields?.filterTags?.length) {
          return []
        }
        return this.fields.filterTags.map(function (obj) {
          return obj.id
        })
      }
    },
    requestItemAmount() {
      return this.fields.resultsPerPage * (this.loadMorePresses + 1)
    },
    currentAmount() {
      if (this.requestItemAmount > this.totalItems) {
        return this.totalItems
      }
      return this.requestItemAmount
    },
    totalItems() {
      let currentAmount = 0
      if (this.filterTagIds.length === 0) {
        return this.blogQuery?.total
      }

      return this.filterTagIds.reduce((acc, curr) => {
        const count = this.blogQuery?.facets?.find((item) => item?.tag?.id === curr)?.count || 0
        return acc + count
      }, currentAmount)
    },
    areThereMoreItems() {
      if (this.filterTagIds.length === 0) {
        return this.requestItemAmount < this.totalItems
      }
      return this.requestItemAmount < this.totalItems
    },
    readMoreText() {
      return this.fields.readMoreText
    },
    root() {
      return this.fields?.root
    }
  },
  methods: {
    increasePresses() {
      this.loadMorePresses = this.loadMorePresses + 1
    },
    filterCategory(event) {
      this.loadMorePresses = 0
      this.filterSelection = event?.target?.value || ''
    }
  },
  apollo: {
    blogQuery: {
      query: blogSearchQuery,
      variables() {
        return {
          inputModel: {
            take: this.requestItemAmount,
            skip: 0,
            tags: this.filterTagIds,
            root: this.root
          }
        }
      }
    }
  }
}
</script>
