import { logWarning } from '@/services/logging'
import { rapidFormStoreKey, myZoneStoreKey, authenticationStoreKey } from '@/store'
import {
  CLEAR_RAPIDFORM_STORE,
  CLEAR_MYZONE_STORE,
  CLEAR_AUTHENTICATION_STORE
} from '@/store/mutation-types'

export default {
  install(_Vue, { router, store }) {
    router.beforeEach((to, _from, next) => {
      try {
        if (to.query.jlo) {
          store.dispatch('clearVuexPersistState', CLEAR_RAPIDFORM_STORE)
          window.sessionStorage.removeItem(rapidFormStoreKey)
          store.dispatch('clearVuexPersistState', CLEAR_MYZONE_STORE)
          window.sessionStorage.removeItem(myZoneStoreKey)
          store.dispatch('clearVuexPersistState', CLEAR_AUTHENTICATION_STORE)
          window.sessionStorage.removeItem(authenticationStoreKey)
        }
      } catch (e) {
        logWarning('beforeEach handler ran into problems: ', e)
      }
      next()
    })
  }
}
