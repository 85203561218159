<script>
import { h } from 'vue'
export default {
  name: 'BlogTag',
  props: {
    blogTag: {
      type: Object,
      default: () => ({})
    },
    shouldAppendComma: {
      type: Boolean
    }
  },
  setup(props) {
    return () =>
      h(
        'a',
        { attrs: { class: 'blogdivider__tag', href: props.blogTag?.landingUrl } },
        props.shouldAppendComma ? `${props.blogTag?.name}, ` : props.blogTag?.name
      )
  }
}
</script>
