//
//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CycleTextarea',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    }
  }
}
