//
//
//
//
//
//

import { CycleRadioInput } from '@/components/elements'

export default {
  name: 'CycleRadioNavigationItem',
  components: {
    CycleRadioInput
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeValue() {
      return this.isActive ? this.option : null
    }
  },
  methods: {
    valueChanged(newVal) {
      if (newVal === null) {
        return
      }
      this.$emit('update-url', newVal)
    }
  }
}
