<template>
  <ChangeContactDetails v-bind="componentData" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleChangeContactDetailsSitecore',
  components: {
    ChangeContactDetails: Cycle.CycleChangeContactDetailsSitecore
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    componentData() {
      return {
        ...mapValues(this.fields, (item) => item.value ?? item),
        ...this.rendering
      }
    }
  }
}
</script>
