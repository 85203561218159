//
//
//
//

import { logInfo } from '@/services/Logging'
import defaults from '@/constants/defaults'
import { formatOptions, isValid8601Date } from '@/filters/cycle-date/cycle-date.constants'

/**
 * Safely renders a date in the Aon standard
 */
export default {
  name: 'CycleDate',

  props: {
    /**
     * date to show
     */
    date: [Number, String, Date],
    /**
     * locale to use for formatting, will use system wide locale by default
     */
    locale: {
      type: String,
      required: false,
      default: null
    },
    /**
     * DateTimeFormat object to define formatting
     */
    format: {
      type: [Object, Intl.DateTimeFormat],
      required: false,
      default: () => formatOptions
    }
  },
  computed: {
    _locale() {
      const { defaultEnglishLocalisedPath, defaultEnglishLocale, defaultLocale } = defaults

      if (this.locale === defaultEnglishLocalisedPath) {
        return defaultEnglishLocale
      }
      if (this.locale == null) {
        return defaultLocale
      }
      return this.locale
    },
    isDate() {
      return !isNaN(this.timestamp)
    },
    ISOString() {
      if (!this.isDate) {
        return
      }
      return new Date(this.timestamp).toISOString()
    },
    timestamp() {
      switch (typeof this.date) {
        case 'number':
          return this.date

        case 'string':
          if (isValid8601Date.test(this.date)) {
            return Date.parse(this.date)
          }
          return NaN
      }

      if (this.date instanceof Date) {
        return this.date.getTime()
      }

      return Date.parse(this.date)
    },
    formattedDate() {
      if (!this.isDate) {
        return
      }
      try {
        const date = new Date(this.timestamp)
        return new Intl.DateTimeFormat(this._locale, this.format).format(date)
      } catch (e) {
        logInfo(`CycleDate: couldn't format ${this.date}`, e)
        return this.date
      }
    }
  }
}
