import { serviceNames } from './service-names'

const _registeredServices = {}

function checkServiceName(name) {
  if (typeof serviceNames[name] === 'undefined') {
    throw new Error(`Unknown service name '${name}`)
  }
}

export function registerService(name, serviceDefinition, $jss) {
  checkServiceName(name)

  if (!$jss) {
    throw new Error(`Missing '$jss' param while registering service '${name}'`)
  }

  if (_registeredServices[name] != null) {
    return
  } else {
    _registeredServices[name] = new serviceDefinition($jss)
  }
}

export function getService(name) {
  checkServiceName(name)

  if (typeof _registeredServices[name] === 'undefined') {
    throw new Error(`Service '${name}' not found. Please register it with registerService`)
  }
  return _registeredServices[name]
}
