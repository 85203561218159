//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'
import DetailDataOverview from '@/components/organisms/Form/DetailDataOverview'

export default {
  name: 'HomeContentsStepOverview',
  components: {
    DetailDataOverview,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['contentProvider'],
  props: {
    overviewData: {
      required: true,
      type: Object
    },
    value: { type: Object, required: true },
    displayCyberCoverage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currencyFormat: {
        ...this.locale,
        formatOptions: {
          minimumFractionDigits: 0
        }
      }
    }
  },
  computed: {
    totalPremium() {
      return this.overviewData?.premium?.totalPremium
    }
  },
  methods: {
    premiumCoverage(coverage) {
      return this.overviewData?.premium?.coverages[coverage]
    },
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
