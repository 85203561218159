<template>
  <CompareTable :rows="generatedRows" />
</template>

<script>
import { CompareTable } from '@aon/cycle'
import { computed } from 'vue'
export default {
  name: 'CompareTableSitecore',
  components: { CompareTable },
  props: {
    fields: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    //normally sitecore delivers json like we want it, but for this table we need to parse the data
    //sitecore delivers the data as rows in columns, but html like columns in rows
    //this code counts the columns, flattens the whole thing in an array of items and
    //then generates rows with the same number of items as columns
    const fields = props.fields
    // flatten the data from sitecore and add labels
    const getFlatData = (columns, rowCount) => {
      const items = columns.flatMap((column, columnIndex) => {
        const colItems = column?.fields?.Cells?.Contents?.map(function (row, index) {
          const item = row
          //create a unique id
          item.id = item.id + index + columnIndex
          //add nudge text as label
          if (column?.fields?.['Nudge Enabled']?.value && index === 0) {
            item.label = column?.fields?.['Nudge Text']?.value
          }
          return item
        })
        //add empty entries if one column is shorter than the max
        if (colItems.length < rowCount) {
          const rowsMissing = rowCount - colItems.length
          for (let i = 0; i < rowsMissing; i++) {
            colItems.push({
              id: 'emptyItem' + columnIndex + (colItems.length + i),
              displayName: '',
              fields: {}
            })
          }
        }
        return colItems
      })
      return items
    }
    //loop through all columns to see which one has the most items
    const getMaxRowCount = () => {
      let maxRowCount = 0
      fields?.Columns?.forEach((col) => {
        if (col?.fields?.Cells?.Contents?.length > maxRowCount) {
          maxRowCount = col?.fields?.Cells?.Contents?.length
        }
      })
      return maxRowCount
    }
    //split all items in array of rows with the same item count as number of columns
    const getRows = (items, rowCount) => {
      const columnCount = fields?.Columns?.length || 1
      const rows = []
      for (let i = 0; i < rowCount; i++) {
        const row = { id: i, cells: [] }
        for (let ai = 0; ai < columnCount; ai++) {
          row.cells.push(items[i + rowCount * ai])
        }
        rows.push(row)
      }
      return rows
    }
    const generatedRows = computed(() => {
      if (fields?.Columns) {
        const rowCount = getMaxRowCount()
        const flatData = getFlatData(fields.Columns, rowCount)
        return getRows(flatData, rowCount)
      }
      return []
    })
    return {
      generatedRows
    }
  }
}
</script>
