import { phrases } from '@/constants/Dictionary'

export default {
  install(Vue) {
    Vue.config.errorHandler = (err, vm) => {
      const friendlyMessage = vm?.$root?.getPhrase?.(
        phrases.errorMessagesComponentsFriendlyMessage.key
      )

      showLogicalError(err, vm, vm, friendlyMessage)
      return false
    }
  }
}

/**
 * Recursive function which finds the first non faulty ancestor and renders the error message there
 *
 * @param {Error} err
 * @param {Vue} vm
 * @param {Vue} original
 * @param {string} [friendlyMessage] - user friendly message
 */
export const showLogicalError = async (
  err,
  vm,
  original,
  friendlyMessage = phrases.errorMessagesComponentsFriendlyMessage.default
) => {
  await vm.$nextTick() // Give Vue a chance to change the DOM and inner state
  if (vm._isBeingDestroyed) {
    return showLogicalError(err, vm.$parent, original, friendlyMessage)
  }

  // Using the parents name here shows us the actual faulty component.
  // Otherwise we're looking at the root element within the error throwing component.
  const componentName = original.$parent?.$options?._componentTag
  vm.$el.innerHTML = `<div class="notify notify--warning">
        ${friendlyMessage} 
      </div>
      <div class="notify notify--info hidden">
        <pre>
          ${componentName}
          ${err}
          ${err.stack}
        </pre>
      </div>`
}
