//
//
//

import { logWarning } from '@/services/Logging'
export default {
  name: 'CycleRadioNavigationSitecore',
  mounted() {
    logWarning(
      'CycleRadioNavigationSitecore is deprecated, please use CycleSimpleNavigationSitecore'
    )
  }
}
