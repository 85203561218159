<template>
  <option :value="value" :selected="modelValue == value">
    <slot />
  </option>
</template>

<script>
export default {
  name: 'CycleOption',
  inject: ['modelValue'],
  props: {
    value: {
      type: [String, Number, Array, Object, Function],
      required: false
    }
  }
}
</script>
