<template>
  <div class="radio-bar">
    <slot v-if="$slots.default" />
  </div>
</template>

<script>
export default {
  name: 'CycleRadioBar'
}
</script>
