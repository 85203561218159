<template>
  <CycleSection :section-style="sectionStyle" :id="anchorId">
    <template slot="header" v-if="hasHeader">
      <ScText :field="heading" tag="CycleHeading" :weight="2" />
      <ScRichText :field="gridIntroText" tag="div" class="grid__intro" />
    </template>
    <ScPlaceholder
      v-if="!!rendering.placeholders['jss-content']"
      name="jss-content"
      :rendering="rendering"
    />
    <ScPlaceholder
      v-if="!!rendering.placeholders['jss-section']"
      name="jss-section"
      :rendering="rendering"
    />
  </CycleSection>
</template>

<script>
import { Text, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-vue'
import { CycleSection } from '@aon/cycle'

export default {
  name: 'CycleSectionSitecore',
  components: {
    ScPlaceholder: Placeholder,
    ScText: Text,
    ScRichText: RichText,
    CycleSection
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    sectionStyle() {
      const sectionStyleFromFields = this.fields?.sectionStyle?.value
      if (sectionStyleFromFields) {
        return sectionStyleFromFields
      }
      return this.params?.sectionStyle
    },
    anchorId() {
      return this.fields.anchorId?.value || this.params?.id || null
    },
    heading() {
      return this.fields.heading ?? {}
    },
    gridIntroText() {
      return this.fields.gridIntroText ?? {}
    },
    hasHeader() {
      return !!(this.heading.value || this.gridIntroText.value)
    }
  }
}
</script>
