<template>
  <div class="documents documents--declarations">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Declarations'
}
</script>
