<template>
  <CycleCookieConsent
    :heading="heading"
    :button-approve-text="approveText"
    :link-decline-text="declineText"
    v-if="isClient"
  >
    <template slot="jss-content">
      <ScRichText :field="fields.bodytext" />
    </template>
  </CycleCookieConsent>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CycleCookieConsentSitecore',
  components: {
    ScRichText: RichText
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isClient: false
    }
  },
  computed: {
    heading() {
      return this.fields.heading?.value
    },
    approveText() {
      return this.fields.buttonApproveText?.value
    },
    declineText() {
      return this.fields.linkDeclineText?.value
    }
  },
  mounted() {
    this.isClient = true // Mounted hook is not triggered in SSR
  }
}
</script>
