<template>
  <CycleContentBlock v-if="hasImage" centered>
    <template #image>
      <CycleImage :image="image" modifier="logo" />
    </template>
  </CycleContentBlock>
</template>

<script>
export default {
  name: 'CollectivityLogoSitecore',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    image() {
      return {
        src: this.fields.imageUrl,
        alt: this.fields.altText,
        title: this.fields.titleText
      }
    },
    hasImage() {
      return this.fields?.imageUrl?.length > 0
    }
  }
}
</script>
