<template>
  <component :is="cycleHeading" :class="cycleHeadingClass">
    <slot />
  </component>
</template>

<script>
import { getHyphenatedContent } from '@/services/Hyphenator'

export default {
  name: 'CycleHeading',
  props: {
    weight: {
      type: Number,
      required: false,
      default: 3,
      validator: (w) => w > 0 && w < 7
    },
    modifiers: {
      type: [String, Array],
      required: false,
      default: ''
    }
  },
  created() {
    this.hyphenateSlotContent()
  },
  methods: {
    hyphenateSlotContent() {
      const content = []
      this.$slots.default?.forEach((slotContent) => {
        if (slotContent?.children?.length > 0) {
          slotContent.children.forEach((subNode) => {
            subNode.text = getHyphenatedContent(subNode.text)
          })
          content.push(slotContent)
          return
        }
        if (slotContent.text) {
          slotContent.text = getHyphenatedContent(slotContent.text)
        }
        content.push(slotContent)
      })
      this.$slots.default = content
    }
  },
  computed: {
    cycleHeadingClass() {
      const baseClass = 'heading'
      const cycleHeadingClass = { [baseClass]: true }
      const modifiers = Array.isArray(this.modifiers)
        ? this.modifiers
        : String(this.modifiers).split(/[^\w-]+/gm)

      modifiers
        .filter((_type) => !!_type) // discard empty strings
        .forEach((_type) => (cycleHeadingClass[`${baseClass}--${_type}`] = true))

      return cycleHeadingClass
    },
    cycleHeading() {
      return `h${this.weight}`
    }
  }
}
</script>
