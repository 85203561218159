import { pick } from 'lodash'

import DataService from './DataService'
import * as gql from './gql'

/**
 * Shared data services for policy funnels
 */
export class FunnelService extends DataService {
  /**
   * Fetch finalQuestions for given product. Returns the Graphql promise.
   *
   * @param {string} code - Productcode for which to fetch questions
   */
  getFinalQuestions(code) {
    return this.query(gql.queries.funnelQuestions, { inputModel: { code } })
  }

  /**
   * Creates or mutates a policy
   *
   * @param {object} policyDetails  - All details on the policy to be send
   * @param {object} formDefinition - Meta information required for authenticating and validating the call
   */
  async writePolicy(policyDetails, formDefinition) {
    const values = policyDetails
    if (formDefinition.formDefinitionId == null) {
      formDefinition.formDefinitionId = formDefinition.dataSource
    }
    return await this.mutate(
      gql.mutations.writePolicy,
      {
        values,
        formDefinition: pick(formDefinition, ['formDefinitionId', 'antiForgeryToken'])
      },
      {}
    )
  }
}
