<template>
  <CycleContactForm v-bind="formData" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleContactFormRequestSitecore',
  components: {
    CycleContactForm: Cycle.CycleContactFormRequestSitecore
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    antiForgeryToken() {
      return this.rendering.antiForgeryToken
    },
    dataSource() {
      return this.rendering.dataSource
    },
    formData() {
      return {
        ...this.textContent,
        antiForgeryToken: this.antiForgeryToken,
        dataSource: this.dataSource
      }
    }
  }
}
</script>
