<template>
  <div class="contacts-list-area card">
    <div class="contacts-list-area__title">{{ heading }}</div>

    <div class="table-wrapper">
      <EmptyTablePlaceholder v-if="contacts.length < 1" no-results-text="Empty" />
      <div class="contacts-table" v-else>
        <div class="heading">{{ getText('Naam') }}</div>
        <div class="heading">{{ getText('Functie') }}</div>
        <div class="heading show-lg">{{ getText('Telefoon') }}.</div>
        <div class="heading show-lg">{{ getText('Mobiel') }}</div>
        <div class="heading show-lg">{{ getText('Emailadres') }}</div>
        <div class="heading">
          <span class="show-lg">{{ getText('Wijzigen') }}</span>
        </div>
      </div>
      <div
        v-for="contact in contacts"
        :key="`contactPeople${contact.person.id}`"
        class="row-wrapper"
      >
        <div class="items" @click="handleDetailClick(`${contact.person.id}`)">
          <div>
            {{ contact.person.firstName }} {{ contact.person.prefix }} {{ contact.person.lastName }}
          </div>
          <div>{{ contact.function }}</div>
          <div class="hide-md hide-sm">{{ contact.communication.phone }}</div>
          <div class="hide-md hide-sm">{{ contact.communication.mobile }}</div>
          <div class="hide-md hide-sm">{{ contact.communication.email }}</div>
          <div class="center">
            <a :href="getContactEditHref(contact.person.idEncrypted)">
              <div class="hide-md hide-sm">
                <span class="icon icon--pencil"></span>
              </div>
              <div class="hide-lg">
                <span class="icon icon--plus"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="detail hide-lg">
          <div :class="getOpenDetailsClass(`${contact.person.id}`)">
            <p class="hide-lg">
              <strong> {{ getText('Functie') }}. </strong>: {{ contact.function }}
            </p>
            <p class="hide-lg">
              <strong> {{ getText('Telefoon') }}. </strong>: {{ contact.communication.phone }}
            </p>
            <p class="hide-lg">
              <strong> {{ getText('Mobiel') }} </strong>: {{ contact.communication.mobile }}
            </p>
            <p class="hide-lg">
              <strong> {{ getText('Emailadres') }} </strong>:
              {{ contact.communication.email }}
            </p>
            <p class="hide-lg">
              <a :href="getContactEditHref(contact.person.idEncrypted)">
                <span class="icon icon--pencil"></span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="contacts-list-area__cta">
      <CycleButtonLink :href="newLink.href" modifiers="cta, secondary" data-testid="footer-link">
        {{ newLink.title }}
      </CycleButtonLink>
    </div>
  </div>
</template>

<script>
import { dictionaryMixin } from '@/mixins/Dictionary'
import { CycleButtonLink, EmptyTablePlaceholder } from '@aon/cycle'
export default {
  name: 'ContactPeople',
  components: {
    EmptyTablePlaceholder,
    CycleButtonLink
  },
  mixins: [dictionaryMixin],
  props: {
    fields: Object,
    rendering: Object
  },
  data() {
    return {
      expandedContacts: []
    }
  },
  computed: {
    contacts() {
      return this.fields.additionalData?.data?.legalEntity?.contactPeople || []
    },
    newLink() {
      return this.fields.linkNewContact?.value || {}
    },
    editLink() {
      return this.fields.linkEditContact?.value || {}
    },
    heading() {
      return this.fields.heading?.value || ''
    },
    getQueryStringIdentifier() {
      return this.fields.queryStringContactIdentifier?.value
    }
  },
  methods: {
    getText(header) {
      return this.getPhrase(header) || header
    },
    handleDetailClick(index) {
      if (this.expandedContacts.includes(index)) {
        this.expandedContacts.splice(this.expandedContacts.indexOf(index), 1)
      } else {
        this.expandedContacts.push(index)
      }
    },
    getOpenDetailsClass(index) {
      return this.expandedContacts.includes(index) ? 'open' : ''
    },
    getContactEditHref(contact) {
      return `${this.editLink.href}?${this.getQueryStringIdentifier}=${contact}`
    }
  }
}
</script>
