//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCard } from '../../../card'
import { CycleHeading } from '../../../elements'
import { CycleList, CycleListItem } from '../../../cycle-list'
import ProvidedText from '../../Content/ProvidedText'

export default {
  name: 'LoginCredentials',
  components: {
    CycleCard,
    CycleHeading,
    CycleList,
    CycleListItem,
    ProvidedText
  }
}
