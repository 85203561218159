import Vue from 'vue'
import Meta from 'vue-meta'
import Vuex from 'vuex'
import VueApollo from 'vue-apollo'
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue'
import PortalVue from 'portal-vue'

import AppRoot from '@/AppRoot'
import { createRouter } from '@/router'
import SitecoreJssStorePlugin from '@/lib/SitecoreJssStorePlugin'
import GraphQLClientFactory from '@/lib/GraphQLClientFactory'
import config from '@/temp/config'
import * as CycleComponents from '@aon/cycle'
import componentFactory from '@/temp/componentFactory'
import { createStore } from '@/store'
import CycleTesting from '@/plugins/CycleTesting.client'
import FieldFilters from '@/plugins/FieldFilters'
import { mutations, queries } from '@/gql'
import NavigationInterceptor from '@/plugins/NavigationInterceptor'
import ErrorHandling from '@/plugins/ErrorHandling'
import { logError } from '@/services/logging'
import { sitecoreProxy, ashxExtension, aspxExtension } from '@/constants/Defaults'
import CleanStateAfterLogout from '@/plugins/CleanStateAfterLogout'
import { computed } from 'vue'

Vue.use(CycleTesting)
Vue.use(Meta)
Vue.use(Vuex)
Vue.use(PortalVue)
Vue.use(SitecoreJssStorePlugin)
Vue.use(SitecoreJssPlaceholderPlugin, {
  componentFactory: (name) => componentFactory(name) || CycleComponents[name]
})
Vue.use(VueApollo)
Vue.use(FieldFilters)

Object.keys(CycleComponents).forEach((name) => {
  Vue.component(name, CycleComponents[name])
})

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState) {
  const router = createRouter()
  const store = createStore()
  const graphQLProvider = createGraphQLProvider(initialState, store)

  Vue.use(ErrorHandling)
  Vue.use(CleanStateAfterLogout, { router, store })
  Vue.use(NavigationInterceptor, {
    router,
    ignorePatterns: [
      new RegExp(`^${sitecoreProxy}/`, 'g'),
      new RegExp(`${ashxExtension}`, 'g'),
      new RegExp(`${aspxExtension}`, 'g')
    ]
  })
  const vueOptions = {
    apolloProvider: graphQLProvider,
    router,
    store,
    computed: {
      dictionaryService() {
        return {
          phrases:
            initialState?.sitecore?.context?.dictionary?.phrases ??
            store.getters['siteSettings/dictionary']?.phrases ??
            {}
        }
      }
    },
    provide() {
      return {
        dictionaryService: computed({
          get: () => this.dictionaryService
        })
      }
    },
    methods: {
      getPhrase(phraseKey) {
        return this.dictionaryService.phrases
          ? this.dictionaryService.phrases[phraseKey]
          : undefined
      }
    },
    metaInfo() {
      return {
        title: initialState?.sitecore?.context?.metaData?.title,
        script: [
          {
            vmid: 'context-script',
            innerHTML: initialState?.sitecore?.context?.metaData?.script,
            type: 'text/javascript'
          }
        ]
      }
    },
    render: (createElement) => createElement(AppRoot)
  }

  const app = new Vue(vueOptions)

  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    app.$jss.store.setSitecoreData(initialState)
  }

  // Merge gql with existing $jss

  app.$jss.gql = {
    ...app.$jss.gql,
    query: (query, variables = {}, options = {}) => {
      return app.$apollo.provider.defaultClient
        .query({ query, variables, ...options })
        .then((data) => {
          return data
        })
        .catch((error) => {
          logError('error in $jss.query: ', error)
        })
    },
    mutate: (mutation, variables = {}, options = {}) => {
      return app.$apollo.provider.defaultClient
        .mutate({ mutation, variables, ...options })
        .catch((error) => {
          logError('error in $jss.mutate: ', error)
        })
    },
    queryByName: (queryName, variables = {}) => {
      const query = app.$jss.gql.queries[queryName]
      return app.$jss.gql.query(query, variables)
    },
    mutationByName: (mutationName, variables = {}) => {
      const mutation = app.$jss.gql.mutations[mutationName]
      return app.$jss.gql.mutate(mutation, variables)
    },
    queries,
    mutations
  }

  return { app, router, graphQLProvider }
}

export function createGraphQLProvider(initialState, store) {
  const client =
    initialState && initialState.APOLLO_STATE
      ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE, store)
      : GraphQLClientFactory(config.graphQLEndpoint, true, false, store)

  return new VueApollo({
    defaultClient: client,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache'
      }
    }
  })
}
