//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, format } from 'date-fns'

import { CycleDateInput } from '@/components/elements/forms'
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleRadioInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { CycleCurrency } from '@/filters'
import { intlMixin, dictionaryMixin } from '@/mixins'

import { FamilyType, InsurableAmounts } from '../../constants'

export default {
  name: 'LiabilityStepSetupChange',
  inject: ['formData', 'textContent'],
  components: {
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTooltip,
    ProvidedText,
    ProvidedTooltip,
    CycleDateInput
  },
  filters: { CycleCurrency },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      familyType: null,
      insuredAmount: null
    }
  },
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    familyTypes() {
      return FamilyType
    },
    insurableAmounts() {
      return InsurableAmounts
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.familyType = newValue.familyType
        this.insuredAmount = newValue.insuredAmount
        if (newValue.startDate || this.formData.startDate == null) {
          // Using $set ensures Vue's reactivity system is triggered, ensuring working validations.
          this.$set(this.formData, 'startDate', newValue.startDate)
        }
      },
      immediate: true
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('submit', {
        familyType: this.familyType,
        insuredAmount: this.insuredAmount,
        startDate: this.formData.startDate
      })
    }
  }
}
