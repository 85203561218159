<template>
  <div>
    <!-- Swap me for a fraction -->
    <section
      v-for="(packageItem, packageItemIndex) in constructData"
      :key="packageItem.policyNumber"
    >
      <CycleHeading :weight="2" :modifiers="['centered']" v-if="packageItem.name">
        {{ packageItem.name }}
      </CycleHeading>
      <div class="subgrid subgrid--widgets">
        <div
          class="subgrid__column"
          v-for="(item, index) in packageItem.policies"
          :key="index + item.policyNumber"
        >
          <!-- MYZ-989: Will be reverted once Biztalk sends correct name -->
          <CycleMyInsurancesItem
            :policy="item"
            :heading="item.heading === 'Vuurwapen' ? 'Jachtuitrusting' : item.heading"
            :subheading="item.policyNumber"
            :location="item.locatie"
            :licenseplate="item.kenteken"
            :description="item.omschrijving"
            :status="item.status"
            :links="item.detailPageLinks"
            :claim-fallback="item.fallbackText"
            :text-content="textContent"
            :icon="policyIcon(item.icon)"
          />
        </div>
      </div>
      <div :class="gridDividerClass(packageItemIndex)"></div>
    </section>
  </div>
</template>

<script>
import { pathOr, isEmpty } from 'ramda'
import { InsuranceProduct } from '@/constants'
import CycleMyInsurancesItem from './my-insuranceitem/CycleMyInsurancesItem.vue'
import { PolicyStatus } from '../../models'
import { CycleHeading } from '@/components/elements'

function mapPolis(packageItem) {
  return {
    ...packageItem,
    heading: pathOr('Verzekering', ['product', 'name'], packageItem),
    policyNumber: packageItem.number,
    omschrijving: pathOr('Onbekend', ['product', 'name'], packageItem),
    locatie: pathOr('', ['reference'], packageItem),
    status: PolicyStatus.fromObject(packageItem.status),
    kenteken: pathOr('', ['licensePlate'], packageItem),
    sourceSystem: pathOr('', ['sourceSystem'], packageItem)
  }
}

export default {
  name: 'MyInsurancesList',
  components: {
    CycleMyInsurancesItem,
    CycleHeading
  },
  inject: ['textContent'],
  props: {
    portfolioInfo: Object,
    textContent: Object
  },
  computed: {
    constructData() {
      const portfolioInfo = pathOr(this.portfolioInfo || [], ['portfolioInfo'], this.portfolioInfo)
      let packages = pathOr([], ['packages'], portfolioInfo)
      const policies = pathOr([], ['policies'], portfolioInfo)

      packages = [{ policies }, ...packages]

      return packages
        .map((packageItem) => ({
          name: pathOr('', ['product', 'name'], packageItem),
          policies: pathOr([], ['policies'], packageItem)
            .filter(({ product }) => product != null)
            .map(mapPolis)
        }))
        .filter(({ policies }) => !isEmpty(policies))
    }
  },
  methods: {
    gridDividerClass(index) {
      const baseClass = 'grid__divider'
      if (index + 1 < this.constructData?.length) {
        return baseClass
      }

      return `${baseClass} ${baseClass}--last`
    },
    policyIcon(icon) {
      return InsuranceProduct[icon] || 'shield'
    }
  }
}
</script>
