<template>
  <CycleTiles
    :style="'--column-count:' + defaultNumberOfColumns"
    :is-vertical="isVertical"
    :is-imaged="isImaged"
  >
    <template v-for="(tile, index) in jssTiles">
      <component :is="getComponent(tile)" :key="`tile${index}`" />
    </template>
  </CycleTiles>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CycleTilesSitecore',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    numberOfColumns() {
      return this.params.numberOfColumns
    },
    defaultNumberOfColumns() {
      return this.numberOfColumns ?? this.rendering.placeholders['jss-tiles']?.length ?? 0
    },
    isVertical() {
      return !!this.params?.isVertical
    },
    isImaged() {
      return !!this.params?.isImaged
    }
  },
  withPlaceholder: {
    // you can alias the computed prop name for the placeholder or pass an array of placeholders
    placeholders: {
      placeholder: 'jss-tiles',
      computedPropName: 'jssTiles'
    }
  },
  methods: {
    getComponent(comp) {
      if (comp?.props?.rendering?.fields != null) {
        comp.props.rendering.fields.isVertical = this.isVertical
        comp.props.rendering.fields.isImaged = this.isImaged
      }
      return comp
    }
  }
}
</script>
