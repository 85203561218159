export const blockTypes = {
  card: {
    baseClass: 'card',
    modifiers: ['dashboard', 'bordered', 'tooltip']
  },
  panel: {
    baseClass: 'panel',
    modifiers: ['bordered', 'cta', 'centered', 'contrast']
  }
}

export const modifierProperties = [
  'bordered',
  'centered',
  'contrast',
  'cta',
  'dashboard',
  'tooltip'
]
