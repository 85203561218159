<template>
  <CycleCard :bordered="true">
    <template slot="header">
      <CycleHeading>{{ heading }}</CycleHeading>
    </template>
    <template slot="body">
      <slot name="body" />
      <slot />
    </template>
  </CycleCard>
</template>

<script>
import CycleCard from '../card/CycleCard'
import CycleHeading from '../elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleConfirmation',
  components: { CycleCard, CycleHeading },
  props: {
    heading: {
      type: String,
      required: false
    }
  }
}
</script>
