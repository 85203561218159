<template>
  <tr>
    <td>{{ document.date | cycleDate({ locale }) }}</td>
    <td>{{ document.information }}</td>
    <td v-if="!isDocumentAvailable">
      <CycleLinkIcon
        v-if="document.id"
        modifiers="file"
        :href="document.url"
        target="_blank"
      >
        {{ textContent.downloadLinkText }}
      </CycleLinkIcon>
      <span v-else>&nbsp;</span>
    </td>
    <td v-else>
      {{ textContent.noDocumentAvailableText }}
    </td>
  </tr>
</template>
<script>
import { CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { CycleLinkIcon } from '@/components/cycle-link/'
import { FILTERED_DOCUMENT_ID } from '../constants'

export default {
  name: 'CycleMydocument',
  components: { CycleLinkIcon },
  filters: { CycleDate },
  mixins: [intlMixin],
  props: {
    document: {
      type: Object,
      required: true
    },
    textContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDocumentAvailable() {
      return this.document.id.includes(FILTERED_DOCUMENT_ID)
    }
  }
}
</script>
