import { defaultLanguage } from '@/constants/Defaults'

const getHrefLang = (languages, langKey) => {
  return languages[langKey]?.hrefLang ?? langKey
}
const getHref = (languages, langKey) => {
  const origin = window.location.origin
  const currentPath = languages[langKey]?.currentTargetPath
  const href = `${origin}${currentPath}`

  return window.location.href.endsWith('/') && !href.endsWith('/') ? `${href}/` : href
}

const createLink = (rel, languages, langKey) => ({
  rel,
  hreflang: getHrefLang(languages, langKey),
  href: getHref(languages, langKey)
})

export const generateCanonicals = (languages = {}, currentLanguage) => {
  const links = []
  const canonicalLink = {
    rel: 'canonical',
    href: getHref(languages, currentLanguage)
  }
  // add cononical Link
  links.push(canonicalLink)

  // add alternate links per available language
  links.push(
    ...Object.keys(languages).map((langKey) => createLink('alternate', languages, langKey))
  )

  // add alternate defaultLink
  const alternateDefaultLink = {
    rel: 'alternate',
    hreflang: 'x-default',
    href: getHref(languages, defaultLanguage)
  }
  links.push(alternateDefaultLink)

  return links
}
