<template>
  <CycleCompareCoverage>
    <!-- Should only accept `CycleCoverage` instances -->
    <slot name="jss-comparison" />
  </CycleCompareCoverage>
</template>

<script>
import { CycleCompareCoverage } from '../../functional/CycleCompareCoverage'

/**
 * Sitecore interface for CycleCompareCoverage
 */
export default {
  name: 'CycleCompareCoverageSitecore',
  components: {
    CycleCompareCoverage
  }
}
</script>
