<template>
  <DateSingleInput
    :value="cycleDateValue"
    @input="handleInput"
    @change="handleChange"
    v-bind="$attrs"
    :is-disabled="$attrs.disabled"
    :has-input-error="hasInputError"
  ></DateSingleInput>
</template>

<script>
import { DateSingleInput } from '@aon/cycle'

export default {
  name: 'RapidFormDateWrapper',
  components: {
    DateSingleInput
  },
  props: {
    value: {
      type: String
    },
    hasInputError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cycleDateValue() {
      return this.value?.split('T')[0]
    }
  },
  methods: {
    handleInput(emittedValue) {
      const dateInput = emittedValue ? new Date(emittedValue).toISOString() : emittedValue
      this.$emit('input', dateInput)
    },
    handleChange(event) {
      this.$emit('change', event)
    }
  }
}
</script>
