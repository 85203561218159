<template>
  <CycleCoverage
    :title="title"
    :bodytext="bodytext"
    :link="link"
    :list="list"
    :most-popular-text="mostPopularText"
  />
</template>
<script>
import { CycleCoverage } from '@aon/cycle'

export default {
  name: 'CycleCoverageSitecore',
  components: {
    CycleCoverage
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    title() {
      return this.fields.title?.value
    },
    bodytext() {
      return this.fields.bodytext?.value
    },
    link() {
      return this.fields.link?.value
    },
    list() {
      return this.fields?.list?.map(({ fields }) => ({
        text: fields?.Text?.value,
        icon: { name: fields?.Icon?.fields?.Name?.value }
      }))
    },
    mostPopularText() {
      return this.fields.mostPopularText?.value
    }
  }
}
</script>
