<script>
import DocumentsHeading from './DocumentsHeading'
/**
 * Wrapper for claim documents
 *
 * Provides a 5 column layout with the option to fold out details on mobile.
 */
export default {
  name: 'DocumentClaims',
  functional: true,
  props: {
    /**
     * Toggles the new interface which allows for injecting the heading columns.
     */
    v2: Boolean
  },
  /**
   * In v1 one can build it's full document layout here
   * With the v2 interface, just place an article for each document
   * @slot default
   */
  /**
   * This slot only works in the v2 interface, place a document prop for each column
   * @slot heading
   */
  render(h, context) {
    if (context.props.v2) {
      return h('div', { class: 'documents documents--claims' }, [
        h(DocumentsHeading, context.slots().heading),
        ...context.slots().default
      ])
    }

    return h('div', { class: 'documents documents--claims' }, context.children)
  }
}
</script>
