export const policySwitch = (
  policy,
  caseAonDirect70,
  caseAonLegalAid,
  caseAonLiability,
  caseAonCar,
  caseAonTravel,
  caseAonShortTravel,
  caseAonEffects,
  caseAonProperty,
  caseAonWedding,
  caseMeeus,
  caseIAK,
  caseMijnWerk,
  caseMijnWerkLiability,
  caseMijnWerkTravel,
  caseMijnWerkCar,
  caseMijnWerkLegal,
  caseMijnWerkHomeOwner,
  caseMijnWerkHomeContents,
  caseAonVerzekeringen,
  caseAonDirect10,
  caseOHIZorg,
  caseNKC,
  caseKNMV,
  caseVGA,
  caseDefault
) => {
  const is = policyCheck(policy)
  if (is('AnvaC70', null, null, null, null)) {
    return caseAonDirect70
  }
  if (is('LB3', null, 'M001', 'RB', '821')) {
    return caseAonLegalAid
  }
  if (is('LB3', null, 'M001', 'AP', '824')) {
    return caseAonLiability
  }
  if (
    is('LB3', null, 'M001', 'MR', '764') ||
    is('LB3', null, 'M001', 'MR', '765') ||
    is('LB3', null, 'M001', 'MR', '766')
  ) {
    return caseAonCar
  }
  if (is('LB3', null, 'V085P', 'RE', '817')) {
    return caseAonTravel
  }
  if (is('LB3', null, 'V085P', 'RE', '802')) {
    return caseAonShortTravel
  }
  if (is('LB3', null, 'M001', 'BR', '816')) {
    return caseAonEffects
  }
  if (is('LB3', null, 'M001', 'BR', '826')) {
    return caseAonProperty
  }
  if (is('LB3', null, 'M001', 'VA', '191')) {
    return caseAonWedding
  }
  if (is('LB3')) {
    return caseMeeus
  }
  if (is('LB2')) {
    return caseIAK
  }
  if (is('AnvaC50', '105', null, '06000')) {
    return caseMijnWerkLiability
  }
  if (is('AnvaC50', '105', null, '04000')) {
    return caseMijnWerkTravel
  }
  if (is('AnvaC50', '105', null, '02000')) {
    return caseMijnWerkCar
  }
  if (is('AnvaC50', '105', null, '09000')) {
    return caseMijnWerkLegal
  }
  if (is('AnvaC50', '105', null, '05000', '05200')) {
    return caseMijnWerkHomeOwner
  }
  if (is('AnvaC50', '105', null, '05000', '05100')) {
    return caseMijnWerkHomeContents
  }
  if (is('AnvaC50', '105')) {
    return caseMijnWerk
  }
  if (is('AnvaC50', '128')) {
    return caseAonVerzekeringen
  }
  if (is('AnvaC10')) {
    return caseAonDirect10
  }
  if (is('40')) {
    return caseOHIZorg
  }
  if (is('AnvaC90')) {
    return caseNKC
  }
  if (is('AnvaC20')) {
    return caseKNMV
  }
  if (is('AnvaC95')) {
    return caseVGA
  }

  return caseDefault
}

/**
 * @param {Object} policy
 */
const policyCheck = (policy) =>
  /**
   * @param {String} source
   * @param {String} websiteId
   * @param {String} company
   * @param {String} insurance
   * @param {String} product
   * @returns {Boolean}
   */
  (source, websiteId, company, insurance, product) => {
    if (policy == null || !policy.sourceSystem || !policy.product || !policy.branch) {
      return false
    }

    return (
      policy.sourceSystem.system.code === source &&
      (!websiteId || policy.websiteId === websiteId) &&
      (company == null || policy.product.company.code === company) &&
      (insurance == null || policy.product.hBCode === insurance) &&
      (product == null || policy.branch.code === product)
    )
  }
