<template>
  <div class="grid zakelijk-wrapper">
    <InsuranceEntryZakelijk
      v-for="(key, index) in insuranceKeys"
      :key="key"
      :name="key"
      :value="fields.insurances[key]"
      :is-folded="index !== 0"
    >
    </InsuranceEntryZakelijk>
    <p>{{ getPhrase('Zakelijk.MoreInfo') }}</p>
  </div>
</template>

<script>
import InsuranceEntryZakelijk from './InsuranceEntryZakelijk.vue'
import { dictionaryMixin } from '@/mixins/Dictionary'

export default {
  name: 'MyInsurancesZakelijk',
  components: {
    InsuranceEntryZakelijk
  },
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    let insuranceKeys = Object.keys(this.fields?.insurances) || []
    insuranceKeys = insuranceKeys.sort()
    return { insuranceKeys }
  }
}
</script>
