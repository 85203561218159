<template>
  <CycleCardList
    v-if="policyInfo"
    :title="textContent.insuranceTitle"
    modifiers="nomarker"
    data-testid="detailsCard"
  >
    <template #list-items>
      <CycleListItem data-testid="detailsCardNumber">
        <label>{{ textContent.policyNumberLabel }}</label>
        <p>{{ policyInfo.number }}</p>
      </CycleListItem>
      <CycleListItem v-if="startingDate" data-testid="detailsCardStartingDate">
        <label>{{ textContent.startingDateLabel }}</label>
        <p>{{ startingDate }}</p>
      </CycleListItem>
      <CycleListItem v-if="isNKC && isCamperRental" data-testid="detailsCardEndDateNKC">
        <label>{{ textContent.endDateLabel }}</label>
        <p>{{ textContent.endDateText }}</p>
      </CycleListItem>
      <CycleListItem v-if="isNKC && (isCamperRental || isTravel)" data-testid="detailsCardDuration">
        <label>{{ textContent.durationLabel }}</label>
        <p>{{ textContent.durationText }}</p>
      </CycleListItem>
      <CycleListItem v-if="isNKC && mutationDate" data-testid="detailsCardMutationDate">
        <label>{{ textContent.mutationDateLabel }}</label>
        <p>{{ mutationDate }}</p>
      </CycleListItem>
      <CycleListItem v-if="showPremiumDueDate" data-testid="detailsCardPremiumDueDate">
        <label>{{ textContent.premiumDueDateLabel }}</label>
        <p>{{ premiumDueDate }}</p>
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleCardList, CycleListItem } from '@aon/cycle'
import { policyCodeMap } from '@/store/MyZone/maps/policyMap'
import { formatDate } from '@/services/formatting'

export default {
  name: 'DetailsCard',
  components: {
    CycleCardList,
    CycleListItem
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo', 'verifySourceSystem', 'verifyPolicyStatus']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    reference() {
      return this.policyInfo?.reference || this.policyInfo?.number
    },
    startingDate() {
      return formatDate({
        date: this.policyInfo?.policy?.startingDate || this.policyInfo?.startingDate,
        locale: this.$store.getters.currentLanguage
      })
    },
    endDate() {
      return formatDate({
        date: this.policyInfo?.policy?.endDate || this.policyInfo?.endDate,
        locale: this.$store.getters.currentLanguage
      })
    },
    mutationDate() {
      return formatDate({
        date: this.policyInfo?.policy?.mutationDate || this.policyInfo?.mutationDate,
        locale: this.$store.getters.currentLanguage
      })
    },
    premiumDueDate() {
      return formatDate({
        date: this.policyInfo?.policy?.premiumDueDate || this.policyInfo?.premiumDueDate,
        locale: this.$store.getters.currentLanguage
      })
    },
    isPolicyOngoing() {
      return this.verifyPolicyStatus({ policyNumber: this.policyNumber, policyStatus: 'ongoing' })
    },
    isPolicyCancelled() {
      return this.verifyPolicyStatus({ policyNumber: this.policyNumber, policyStatus: 'cancelled' })
    },
    statusValue() {
      if (this.isPolicyOngoing) {
        return this.getPhrase('policy-status-ongoing')
      } else if (this.isPolicyCancelled) {
        return this.getPhrase('policy-status-cancelled')
      }

      return null
    },
    isNKC() {
      return this.verifySourceSystem({
        policyNumber: this.policyNumber,
        client: 'NKC'
      })
    },
    isCamperRental() {
      return (
        this.policyInfo?.productType === 'CamperRental' ||
        (this.policyInfo?.branch?.code === policyCodeMap.CamperRentalBranch &&
          this.policyInfo?.product?.hBCode === policyCodeMap.CamperRentalProduct)
      )
    },
    isTravel() {
      return (
        this.policyInfo?.productType === 'Travel' ||
        this.policyInfo?.branch?.code === policyCodeMap.TravelBranch
      )
    },
    showPremiumDueDate() {
      return this.isNKC && this.premiumDueDate
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
