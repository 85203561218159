<template>
  <Review :rating="rating" :title="title" :review-date="reviewDate" :description="description" />
</template>

<script>
import { Review } from '@aon/cycle'
import { formatDate } from '@/services/formatting'
const MinDateValueFromSitecoresDateField = '0001-01-01T00:00:00'

export default {
  name: 'ReviewSitecore',
  components: {
    Review
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    rating() {
      return Number.parseFloat(this.fields?.rating?.value) || 0
    },
    title() {
      return this.fields?.title?.value ?? ''
    },
    isDateFieldVisible() {
      return (
        this.dateFieldValue?.length > 0 && this.dateFieldValue != MinDateValueFromSitecoresDateField
      )
    },
    dateFieldValue() {
      return this.fields?.date?.value
    },
    reviewDate() {
      return this.isDateFieldVisible ? formatDate({ date: this.dateFieldValue }) : ''
    },
    description() {
      return this.fields?.description?.value ?? ''
    }
  }
}
</script>
