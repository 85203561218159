<template>
  <CycleFixedBox v-bind="componentData">
    <component v-for="(comp, index) in jssFixedBox" :is="comp" :key="`fixedComponent${index}`" />
  </CycleFixedBox>
</template>
<script>
import { FixedBox as CycleFixedBox } from '@aon/cycle'
import { mapValues } from 'lodash'
export default {
  name: 'FixedBox',
  components: { CycleFixedBox },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  withPlaceholder: {
    placeholders: [
      {
        placeholder: 'jss-fixed-box',
        computedPropName: 'jssFixedBox'
      }
    ]
  },
  computed: {
    componentData() {
      return {
        ...mapValues(this.fields, (item) => item.value ?? item),
        isInPageEditMode: this.$jss?.sitecoreContext().pageEditing
      }
    }
  }
}
</script>
