<template>
  <div class="form__field form__field--survey">
    <CycleHtml :html="description" inline />
    <CycleTooltip v-if="question.tooltip">
      <template #tooltip-heading>
        <ProvidedText :name="question.description" />
      </template>
      <template #tooltip-content>
        <ProvidedHtml :name="question.tooltip" />
      </template>
    </CycleTooltip>
    <CycleInputContainer>
      <CycleRadioInput
        :name="name"
        value="J"
        :label="labels.booleanYes"
        required
        @change="handleChange"
        :validation-path="validationPath"
      />
      <CycleRadioInput
        :name="name"
        value="N"
        :label="labels.booleanNo"
        required
        @change="handleChange"
        :validation-path="validationPath"
      />
      <template v-if="showError">
        <p class="form__errormessage">{{ question.validationMessage }}</p>
      </template>
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleInputContainer } from '@/components/functional/forms'
import { CycleRadioInput } from '@/components/elements'

import { QuestionMixin } from './QuestionMixin'
import { BooleanAnswer, EmptyAnswer } from '../models'
import { ProvidedTooltip } from '@/components/organisms'
export default {
  name: 'QuestionBoolean',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleRadioInput,
    ProvidedTooltip
  },
  mixins: [QuestionMixin],
  inject: ['textContent'],
  props: {
    labels: {
      type: Object,
      required: true,
      default: () => ({
        booleanYes: 'Ja',
        booleanNo: 'Nee'
      })
    },
    value: {
      type: Object,
      required: true,
      default: () => new EmptyAnswer()
    },
    validateOnInputValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    showError() {
      if (this.validateOnInputValue) {
        return this.value.answer === this.validateOnInputValue
      }
      return false
    }
  },
  methods: {
    handleChange(newValue) {
      this.$emit('input', new BooleanAnswer(newValue))
    }
  }
}
</script>
