import { createNamespacedHelpers } from 'vuex'

// Prevent hot reloading this module
if (module.hot) {
  module.hot.decline()
}

/**
 * A function that maps store properties to a given mixin property
 * @param {string[]|boolean} mappingOptions - Array of properties that you want to mix from the store to the component properties. True if you want all properties.
 * @param {function} mappingFunction - The store mapping function, one of: mapState, mapGetters, mapMutations, mapActions
 * @param {object} storeModuleProperty - The store module property to map from
 * @param {object} targetMixinProperty - The mixin property to map to
 */
function checkAndMerge(mappingOptions, mappingFunction, storeModuleProperty, targetMixinProperty) {
  if (!storeModuleProperty || !targetMixinProperty || !mappingOptions) return

  Object.assign(targetMixinProperty, {
    ...mappingFunction(
      typeof mappingOptions === 'boolean' ? Object.keys(storeModuleProperty) : mappingOptions
    )
  })
}

/**
 * Turns a key value list of mutation types into getters for computed props
 * @param {object} mutationTypes - Basically the export of your mutation-types file
 */
const mutationTypesToGetters = (mutationTypes) => {
  if (mutationTypes == null) {
    return {}
  }

  return Object.entries(mutationTypes).reduce(
    (acc, [type, name]) => ({ ...acc, [type]: () => name }),
    {}
  )
}

/**
 * An mixin factory to configure a component with its own vuex store module.
 * @param {string[]} moduleNamespace - Array containing the "path" to the component.
 * @param {Object} storeModule - Component module object.
 * @param {string[]|boolean} stateMapping - Array of state properties that you want to mix from the store to the component computed properties. True if you want all state's properties.
 * @param {string[]|boolean} gettersMapping - Array of getters methods that you want to mix from the store to the component computed properties. True if you want all methods.
 * @param {string[]|boolean} mutationsMapping - Array of mutations methods that you want to mix from the store to the component methods. True if you want all methods.
 * @param {string[]|boolean} actionsMapping - Array of actions methods that you want to mix from the store to the component methods. True if you want all methods.
 * @param {string|boolean} commitRegistration - Commit name to be executed to inform Vuex that a module has been registered. Helps to make Vue DevTools to know that a new module exists. String containing the commit name, or a boolean to use default 'ModuleRegistered'.
 */
export function storeModuleMixinFactory({
  moduleNamespace,
  storeModule,
  stateMapping,
  gettersMapping,
  mutationsMapping,
  actionsMapping,
  commitRegistration
}) {
  const namespace =
    typeof moduleNamespace === 'object' ? moduleNamespace.join('/') : moduleNamespace
  const mixin = {
    computed: {
      ...mutationTypesToGetters(mutationsMapping)
    },
    methods: {
      commit(mutationType, ...args) {
        this.$store.commit(`${namespace}/${mutationType}`, ...args)
      }
    },
    beforeCreate() {
      // Avoid registering module more than once
      if (storeModule.alreadyRegistered) return
      this.$store.registerModule(moduleNamespace, storeModule, {
        preserveState: !!this.$store.state[moduleNamespace]
      })
      storeModule.alreadyRegistered = true
      // Since the Vuex Store won't update on Vue DevTools Store Modules until there is a commit...
      // After the module has been registered we do a commit (could be a empty commit, but let's be a bit informative)
      // After this, Time Travel works.
      // Caveat: Again, if you use hot-reload, and the store loses it's information, it will register again and do a new commit.
      if (commitRegistration) {
        this.$store.commit(
          typeof commitRegistration === 'string' ? commitRegistration : 'ModuleRegistered',
          {
            moduleNamespace,
            storeModule,
            stateMapping,
            gettersMapping,
            mutationsMapping: mutationsMapping.values(),
            actionsMapping
          }
        )
      }
    }
  }

  const { mapState, mapGetters, mapActions } = createNamespacedHelpers(namespace)

  // process mappingOptions (stateMapping, gettersMapping, mutationsMapping, actionsMapping)
  // for each type, map either all, selected, or no properties to the mixin
  checkAndMerge(stateMapping, mapState, storeModule.state, mixin.computed) // map selected state to the mixin's computed
  checkAndMerge(gettersMapping, mapGetters, storeModule.getters, mixin.computed) // map selected getters to the mixin's computed
  checkAndMerge(actionsMapping, mapActions, storeModule.actions, mixin.methods) // map selected actions to the mixin's methods
  return mixin
}
