export const removeBlanksFromChildren = (children) =>
  children.reduce((acc, child) => {
    if (child.text === ' ') {
      return acc
    }
    if (child.tag === 'template' && child.children == null) {
      return acc
    }
    if (child.tag === 'template' && child.children.length > 0) {
      const childChildren = removeBlanksFromChildren(child.children)
      if (childChildren.length === 0) {
        return acc
      }
    }

    return [...acc, child]
  }, [])
