<template>
  <CycleCard>
    <header v-if="title" class="card__header">
      <Heading :weight="3">{{ title }}</Heading>
    </header>

    <CycleList v-if="hasSlot('list-items')" :modifiers="modifiers">
      <slot name="list-items" />
    </CycleList>

    <template #footer v-if="hasSlot('footer')">
      <slot name="footer" />
    </template>
  </CycleCard>
</template>

<script>
import CycleCard from '../card/CycleCard'
import { CycleList } from '../cycle-list/'
import Heading from '../elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleCardList',
  components: {
    CycleCard,
    CycleList,
    Heading
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    modifiers: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$scopedSlots[slotName] || !!this.$slots[slotName]
    }
  }
}
</script>
