//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleRadioInput from '../CycleRadioInput/CycleRadioInput'

export default {
  name: 'CycleRadioInput',
  extends: CycleRadioInput,
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
