//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleSelect, CycleOption } from '@/components/elements'
import { CycleForm, CycleFormPage } from '@/components/functional/forms'
import { storeModuleMixinFactory } from '@/mixins'
import { FamilyType, TravelType } from '@/modules/FunnelTravel/helpers/constants'

import store from '@/stores/profile'

export default {
  name: 'TravelPreFunnel',
  components: {
    CycleForm,
    CycleFormPage,
    CycleSelect,
    CycleOption
  },
  mixins: [
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      actionsMapping: ['submitFamilyType', 'submitTravelType']
    })
  ],
  props: {
    link: {
      type: Object,
      default: () => {}
    },
    singleFamilyTypeText: {
      type: String
    },
    multipleFamilyTypeText: {
      type: String
    },
    chooseFamilyTypeText: {
      type: String
    }
  },
  provide() {
    return {
      shouldUseDefaultBrowserScrollBehaviour: true,
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        familyType: null,
        prefilledTravelType: TravelType.Long
      }
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    stepNumber() {
      return this.formData?.familyType ? 2 : 1
    },
    url() {
      return this.link?.href ? this.link?.href + '?page=' + this.stepNumber : null
    },
    linkTitle() {
      return this.link?.title
    }
  },

  methods: {
    async submitForm() {
      const { familyType, prefilledTravelType } = this.formData
      await this.submitFamilyType(familyType)
      await this.submitTravelType(prefilledTravelType)
      if (this.url) {
        this.$router.push(this.url)
      }
    }
  }
}
