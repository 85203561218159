//
//
//
//

import { PageIntro } from '../../atoms'
/**
 * Sitecore interface for CyclePageintro
 */
export default {
  name: 'CyclePageIntroSitecore',
  components: {
    PageIntro
  },
  props: {
    /**
     * The pageintro text
     */
    pageintro: {
      type: String,
      required: false
    }
  }
}
