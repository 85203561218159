<template>
  <div class="card-product">
    <header class="card-product__header">
      <CycleHeading class="heading--dashboard">{{ heading }}</CycleHeading>
      <CycleIcon :modifiers="icon" hexagon />
    </header>
    <div class="card-product__body">
      <CycleHtml :html="bodytext" />
    </div>
    <footer class="card-product__footer">
      <CycleNav v-if="buttonList.length > 0" buttons  data-testid="footer-nav">
        <CycleButtonLink
          v-for="button in buttonList"
          :key="button.title"
          modifiers="cta,secondary"
          :href="button.href"
          :target="button.target"
        >
          {{ button.text }}
        </CycleButtonLink>
      </CycleNav>
    </footer>
  </div>
</template>

<script>
import { CycleHeading, CycleIcon } from '@/components/elements'
import { CycleHtml } from '@/components/base'
import { CycleButtonLink } from '@/components/cycle-link'
import { CycleNav } from '@/components/cycle-nav'

export default {
  name: 'ProductCard',
  components: {
    CycleButtonLink,
    CycleHeading,
    CycleHtml,
    CycleIcon,
    CycleNav
  },
  props: {
     heading: {
      type: String,
      required: false
    },
    icon:{
      type: String,
      required: false
    },
    bodytext: {
      type: String,
      required: false
    },
    buttonList: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
