//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dictionaryMixin } from '@/mixins'
import { CycleIcon } from '@/components/elements'

export default {
  name: 'FaqFeedback',
  components: {
    CycleIcon
  },
  mixins: [dictionaryMixin],
  props: {
    message: {
      type: String,
      default: ''
    },
    subject: {
      type: String,
      default: 'fallBackTitle'
    }
  },
  methods: {
    handleFeedback(event) {
      const clickedElement = event.currentTarget
      const faqFeedbackContainer = this.$refs.feedbackContainer

      if (faqFeedbackContainer.getAttribute('data-faq-feedback-container') === 'active') {
        clickedElement.setAttribute('data-faq-feedback-status', 'selected')
        faqFeedbackContainer.setAttribute('data-faq-feedback-container', 'inactive')
      }
    }
  }
}
