<template>
  <div>
    <BusyIndicator v-if="isLoading" />
    <ScPlaceholder name="jss-content" :rendering="rendering" v-if="submitState == null" />

    <form class="form" @submit.prevent="onSubmit">
      <ScRichText
        :field="fields.success"
        data-status="confirmed"
        class="confirmation"
        v-if="submitState === true"
      />
      <ScRichText
        :field="fields.failure"
        data-status="rejected"
        class="confirmation"
        v-if="submitState === false"
      />

      <div class="form__footer">
        <div class="form__buttons">
          <template v-if="submitState == null">
            <CycleButtonLink
              class="btn--back btn--link"
              :href="`${backLink}?insurance=${policyNumber}`"
            >
              <ScText :field="fields.cancel" />
            </CycleButtonLink>
            <CycleButton :modifiers="['cta', 'secondary']" @click="requestType = 'Post'">
              <ScText :field="fields.post" />
            </CycleButton>
            <CycleButton :modifiers="['cta', 'secondary']" @click="requestType = 'Email'">
              <ScText :field="fields.email" />
            </CycleButton>
          </template>
          <CycleButtonLink
            v-else
            class="btn--cta btn--secondary"
            :href="`${backLink}?insurance=${policyNumber}`"
            ><ScText :field="fields.finish" />
          </CycleButtonLink>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { BusyIndicator, CycleButton, CycleButtonLink, CycleFormConfirmation } from '@aon/cycle'
import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-vue'
import gql from 'graphql-tag'

export default {
  name: 'GreenCardRequestForm',
  components: {
    BusyIndicator,
    CycleButton,
    CycleButtonLink,
    CycleFormConfirmation,
    ScPlaceholder: Placeholder,
    ScRichText: RichText,
    ScText: Text
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoading: false,
    policyNumber: null,
    requestType: null,
    submitState: null
  }),
  computed: {
    backLink() {
      return this.fields?.policyDetailsLink?.value?.href
    }
  },
  beforeMount() {
    this.policyNumber = this.$route.query.insurance
  },
  methods: {
    async onSubmit() {
      this.isLoading = true
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation (
              $formValues: RequestGreenCardInputModel!
              $formDefinition: FormDefinitionInputModel!
            ) {
              requestGreenCard(formValues: $formValues, formDefinition: $formDefinition) {
                success
              }
            }
          `,
          variables: {
            formValues: {
              policyUri: this.policyNumber,
              requestType: this.requestType
            },
            formDefinition: {
              formDefinitionId: this.rendering.dataSource,
              antiForgeryToken: this.rendering.antiForgeryToken.value
            }
          }
        })
        this.submitState = res.data?.requestGreenCard?.success
      } catch {
        this.submitState = false
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
