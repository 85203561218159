<template>
  <CycleHeader :image="image" myzone>
    <CycleHeroCardMyzone :heading="heading" :name-data="personalData" :empty-name="emptyName" />
  </CycleHeader>
</template>

<script>
export default {
  name: 'CycleHeaderMyzoneSitecore',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      error: undefined
    }
  },
  computed: {
    image() {
      return this.fields.image?.value || {}
    },
    heading() {
      return this.fields.cardTitle?.value || 'Welkom'
    },
    personalData() {
      if (this.fields?.additionalData?.data?.personalData) {
        return this.fields.additionalData.data.personalData
      }

      if (this.fields?.additionalData?.data?.legalEntity?.name) {
        return {
          lastName: this.fields.additionalData.data.legalEntity.name
        }
      }

      return {}
    },
    emptyName() {
      return this.fields.emptyName?.value
    }
  }
}
</script>
