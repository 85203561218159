<template>
  <ECommerceTracker
    :funnel-name="funnelName"
    :year-premium="premium ? premium.totalPremium : 0"
    :transaction="transaction"
  >
    <template #default="{registerCheckout, registerPurchase}">
      <FormContainer
        :on-submit="formSubmit"
        @page-view="registerCheckout"
        @submit-success="registerPurchase"
        store-namespace="funnelTravel"
      >
        <template #header="{state}">
          <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
          <slot name="collectivity-picker" v-if="state.index === 0" />
        </template>

        <FunnelTravelStepSetup @submit="stepSetupSubmit" />

        <FunnelTravelStepCoverages
          :premium="premium"
          :is-multi-trip="isMultiTrip"
          @coverage-update="updateCoverages"
          @submit="commit(SET_COVERAGE_OPTIONS, $event)"
        />

        <FunnelTravelStep3
          @submit="commit(SET_PERSONAL_DATA, $event)"
          :is-verified-email-address="isVerifiedEmailAddress"
        >
          <template #postalcode>
            <CyclePostcodeCheck
              @input="handleLookupAddress"
              :postcode-found="postcodeFound"
              :text-content="textContentMerged"
            />
          </template>
        </FunnelTravelStep3>

        <FunnelTravelStep4
          :is-multi-trip="isMultiTrip"
          :premium="premium"
          :value="stepPaymentData"
          @submit="commit(SET_PAYMENT_DETAILS, $event)"
        />

        <FunnelTravelStep5 :value="questions" @submit="commit(SET_FINAL_QUESTIONS, $event)">
          <FunnelQuestionsContainer
            :product-code="selectedProductCode"
            v-model="questions"
            :render-type="questionRenderType.Final"
          />
          <ServiceInformation :service-info="serviceInfo" />
        </FunnelTravelStep5>

        <FunnelTravelStepOverviewMultiTrip v-if="isMultiTrip" v-bind="fullState">
          <FunnelQuestionsContainer
            :product-code="selectedProductCode"
            v-model="questions"
            :render-type="questionRenderType.Agreement"
          />
        </FunnelTravelStepOverviewMultiTrip>
        <FunnelTravelStepOverviewSingleTrip v-else v-bind="fullState">
          <FunnelQuestionsContainer
            :product-code="selectedProductCode"
            v-model="questions"
            :render-type="questionRenderType.Agreement"
          />
        </FunnelTravelStepOverviewSingleTrip>

        <template #confirmation>
          <CycleFunnelConfirmation :result="submitResult" />
        </template>
      </FormContainer>
    </template>
  </ECommerceTracker>
</template>

<script>
import { merge } from 'lodash'
import { PolicyAction } from '@/constants'
import { funnelMixinFactory } from '@/mixins/funnel'
import { uuid4 } from '@/services/UUID'
import { serviceNames, FunnelService } from '@/services/data'

import { CyclePostcodeCheck } from '@/components/functional'
import { BusyIndicator } from '@/components/molecules'
import { FormContainer, ServiceInformation, ECommerceTracker } from '@/components/organisms'
import CycleBusstop from '@/components/cycle-busstop/CycleBusstop'
import CycleFunnelConfirmation from '@/components/functional/forms/CycleFunnelConfirmation/CycleFunnelConfirmation'

import { FunnelQuestionsContainer } from '@/modules/FunnelQuestions'
import { QuestionRenderType } from '@/modules/FunnelQuestions/models'

import { TravelType, FunnelNameSingleTrip, FunnelNameMultiTrip } from '../helpers/constants'
import { labelProps, textMap } from '../helpers/labels'
import { writePolicyParams } from '../helpers/queryParams'
import { Coverages } from '../models'
import { default as storeModule } from '../store'
import {
  SET_PAYMENT_DETAILS,
  SET_PERSONAL_DATA,
  SET_COVERAGE_OPTIONS,
  SET_FINAL_QUESTIONS,
  SET_POLICY_ACTION
} from '../store/mutation-types'
import { FunnelTravelService, AddressLookupService } from '../services'

import {
  FunnelTravelStepSetup,
  FunnelTravelStepCoverages,
  FunnelTravelStep3,
  FunnelTravelStep4,
  FunnelTravelStep5,
  FunnelTravelStepOverviewMultiTrip,
  FunnelTravelStepOverviewSingleTrip
} from './steps'

export default {
  name: 'FunnelTravelSitecore',
  components: {
    BusyIndicator,
    FunnelTravelStepSetup,
    FunnelTravelStepCoverages,
    FunnelTravelStep3,
    FunnelTravelStep4,
    FunnelTravelStep5,
    FunnelTravelStepOverviewMultiTrip,
    FunnelTravelStepOverviewSingleTrip,
    FunnelQuestionsContainer,
    FormContainer,
    CycleBusstop,
    CycleFunnelConfirmation,
    CyclePostcodeCheck,
    ServiceInformation,
    ECommerceTracker
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelTravel',
      storeModule,
      stateMapping: ['travelType', 'isVerifiedEmailAddress'],
      actionsMapping: [
        'setFormData',
        'initFunnelTravel',
        'handleStepSetup',
        'updateCoverages',
        'updatePremium',
        'submitFunnel',
        'lookupAddress'
      ],
      gettersMapping: [
        'premium',
        'fullState',
        'submitResult',
        'stepPaymentData',
        'selectedProductCode'
      ],
      mutationsMapping: {
        SET_PAYMENT_DETAILS,
        SET_PERSONAL_DATA,
        SET_COVERAGE_OPTIONS,
        SET_FINAL_QUESTIONS,
        SET_POLICY_ACTION
      },
      services: [
        {
          serviceName: serviceNames.FunnelTravelService,
          serviceDefinition: FunnelTravelService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        },
        {
          serviceName: serviceNames.AddressLookupService,
          serviceDefinition: AddressLookupService
        }
      ]
    })
  ],
  provide() {
    return { formData: this.formData, textContent: this.textContentMerged }
  },
  props: {
    ...labelProps,
    transaction: {
      type: String,
      required: false,
      default: () => uuid4()
    },
    serviceInfo: {
      type: Array,
      required: false
    },
    textContent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      postcodeFound: undefined,
      formData: {
        returnDate: undefined,
        departureDate: undefined,
        travelType: undefined,
        familyType: undefined,
        coverages: new Coverages(),
        travelSum: undefined,
        bookingDate: undefined,
        street: undefined,
        city: undefined,
        paymentDetails: {}
      },
      questions: {}
    }
  },
  computed: {
    textContentMerged() {
      return merge({}, textMap(this), this.textContent)
    },
    funnelName() {
      return this.isMultiTrip ? FunnelNameMultiTrip : FunnelNameSingleTrip
    },
    steps() {
      return [
        this.textContentMerged.page1title,
        this.textContentMerged.page2title,
        this.textContentMerged.page3title,
        this.textContentMerged.page4title,
        this.textContentMerged.page5title,
        this.textContentMerged.page6title
      ]
    },
    isMultiTrip() {
      return this.formData.travelType === TravelType.Long
    },
    questionRenderType() {
      return QuestionRenderType
    }
  },
  created() {
    this.initFunnelTravel({ type: PolicyAction.Create })
  },
  methods: {
    async stepSetupSubmit(e) {
      await this.handleStepSetup(e)
      this.updatePremium()
    },
    async formSubmit() {
      await this.submitFunnel(writePolicyParams)
    },
    async handleLookupAddress(res) {
      await this.lookupAddress(res)
      this.formData.street = this.fullState.address.street
      this.formData.city = this.fullState.address.city
      this.postcodeFound = this.fullState.postalCodeFound
    }
  }
}
</script>
