//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleIcon } from '../elements'

export default {
  name: 'CycleTileNavigationItem',
  components: {
    CycleIcon
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: true
    }
  }
}
