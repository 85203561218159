<script>
/**
 * Wraps content in a link when an href is defined
 *
 * @since 3.9.0
 */
export default {
  name: 'optionalLink',
  functional: true,
  props: { href: String },
  render(createElement, context) {
    if (context.props.href == null || context.props.href === '') {
      return context.children
    }
    return createElement(
      'a',
      { ...context.data, attrs: { ...context.props, ...context.data.attrs } }, // move href back into the attributes
      context.children
    )
  }
}
</script>
