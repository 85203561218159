//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleForm from './../../forms/CycleForm/CycleForm'
import CycleFormSet from './../../forms/CycleFormSet/CycleFormSet'
import CycleFormPage from './../../forms/CycleFormPage/CycleFormPage'
import CycleInputContainer from './../../forms/CycleInputContainer/CycleInputContainer'

import {
  CycleTextInput,
  CycleRadioInput,
  CycleDateInput,
  CycleButton,
  CycleCheckboxInput
} from '../../../elements'
import CycleConfirmation from '../../../cycle-confirmation/CycleConfirmation'
import { CycleFinalQuestions, CyclePersonalData } from '../shared'
import { CycleList, CycleListItem } from '../../../cycle-list'
import CycleCard from '../../../card/CycleCard'
import { CycleCheckout, CycleCheckoutItem } from '../../CycleCheckout'
import CycleAdviceBlock from '../../../cycle-advice-block/CycleAdviceBlock'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'
import CycleBusstop from '../../../cycle-busstop/CycleBusstop'
import { CycleHtml } from '../../../base'
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
import { debounce } from 'lodash'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import { CycleCurrency } from '../../../../filters/cycle-currency/cycle-currency'
import { CycleDate } from '../../../../filters/cycle-date/cycle-date'
import { intlMixin, dictionaryMixin } from '../../../../mixins'

export default {
  name: 'CycleFunnelLegalAid',
  filters: {
    CycleCurrency,
    CycleDate
  },
  components: {
    CycleForm,
    CycleFormSet,
    CycleFormPage,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CCheckbox: CycleCheckboxInput,
    CDate: CycleDateInput,
    CycleFinalQuestions,
    CyclePersonalData,
    CycleButton,
    CycleCard,
    CycleList,
    CycleListItem,
    CycleConfirmation,
    CycleAdviceBlock,
    CycleTooltip,
    CycleBusstop,
    CycleHtml,
    CycleCheckout,
    CycleCheckoutItem,
    CycleHeading
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    getPremiumsAction: { type: Function, required: false },
    formSubmitAction: { type: Function, required: false },
    chooseInsurerAction: { type: Function, required: false },
    premiumInformation: { type: Object, required: false },
    finalQuestions: { type: Object, required: false }
  },
  computed: {
    steps() {
      return [
        this.textContent.page1title,
        this.textContent.page2title,
        this.textContent.page3title,
        this.textContent.page4title,
        this.textContent.page5title,
        this.textContent.page6title
      ]
    },
    coverages() {
      return this.formData.coverages
    },
    paymentTerm() {
      if (this.formData.paymentterm !== '12') {
        return this.textContent.paymenttermMonth
      }
      return this.textContent.paymenttermYear
    },
    agreementQuestions() {
      const { agreement = [] } = this.finalQuestions
      return agreement.reduce(
        (acc, q) => (q.type === 'CheckBox' ? [...acc, q.description] : acc),
        []
      )
    },
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 90), 'yyyy-MM-dd')
    }
  },
  watch: {
    coverages: debounce(function () {
      this.getPremiumsAction(false)
    }, 1000)
  },
  mounted() {
    this.getPremiumsAction(true)
  }
}
