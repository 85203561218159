//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleFunnelLegalAid } from './../../functional'
import { pathOr } from 'ramda'
import { pick } from 'lodash'
import { logError } from '../../../services/Logging'
import { isPolicyResultSuccess } from '../../../services/PolicyResults'
import { registerPurchase, registerCheckout } from '../../../services/Ecommerce'
import { labels, labelProps } from './labels'
import { writePolicyParams, premiumCalculationParams } from './queryParams'
import { jssMixin } from '../../../mixins'
import { funnelMixin } from '../funnel'
import CycleFunnelConfirmation from '../../functional/forms/CycleFunnelConfirmation/CycleFunnelConfirmation'
import premiumCalculation from './gql/queries/premiumCalculation'
import { uuid4 } from '../../../services/UUID'

const funnelName = 'Legal Assistance'

export default {
  name: 'CycleFunnelLegalAidSitecore',
  components: {
    CycleFunnelConfirmation,
    CycleFunnelLegalAid
  },
  mixins: [jssMixin, funnelMixin],
  provide() {
    return {
      formData: this.formData
    }
  },
  props: {
    ...labelProps,
    transaction: {
      type: String,
      required: false,
      default: () => uuid4()
    }
  },
  data() {
    const finalQuestions = {}
    return {
      submissionResults: {},
      premiumResults: {},
      productInfoResults: {},
      finalQuestionResults: [],
      forceRecompute: 0,
      finalQuestions,
      formData: {
        finalQuestions,
        coverages: [],
        mailinglist: ['y'],
        paymenttype: 'VM',
        paymentterm: '1'
      }
    }
  },
  computed: {
    textContent() {
      return {
        ...pick(this, labels)
      }
    },
    premiumInformation() {
      this.forceRecompute
      try {
        return this.premiumResults.premium.configurations.reduce((accumulator, configuration) => {
          const premium = pathOr(
            undefined,
            ['item', 'mainCoverage', 'consumerAndLiving', 'coveragePremium', 'termPremium'],
            configuration
          )
          const totalAmount = pathOr(
            undefined,
            ['item', 'productPremium', 'termPremium'],
            configuration
          )
          const productCode = configuration.item.premiumRequestProductCode
          const insurer = configuration.configurationId.replace('PremiumRequestConfig-', '')
          const productUSP = this.productInfoResults[productCode].premiumRequestProduct
            .uSPDescription
          const coverages = Object.entries(configuration.item.additionalCoverages).reduce(
            (acc, [key, value]) => {
              acc[key] = pathOr(0, ['coveragePremium', 'termPremium'], value)
              return acc
            },
            {}
          )
          accumulator[insurer] = {
            premium,
            totalAmount,
            productCode,
            productUSP,
            coverages
          }
          return accumulator
        }, {})
      } catch {
        return {
          'Aon-LegalAid': {
            premium: undefined,
            totalAmount: undefined,
            productCode: 'XXXXX-999',
            coverages: {
              traffic: 0,
              taxAndCapital: 0,
              income: 0,
              divorceMediation: 0
            }
          }
        }
      }
    },
    acceptanceInformation() {
      try {
        return this.submissionResults.write.policyResults[0].result.closingText
      } catch {
        return ''
      }
    }
  },
  watch: {
    premiumInformation: {
      handler: function (newVal, oldVal) {
        Object.keys(oldVal).map((item) => {
          Object.entries(oldVal[item].coverages).map(([key, value]) => {
            if (!newVal[item].coverages[key]) newVal[item].coverages[key] = value
          })
        })
      },
      deep: true
    }
  },
  methods: {
    getPremiums(getDefaults) {
      this.$refs.funnel.$refs.form.isSubmitting = true
      const defaults = {
        coverages: ['mediation', 'income', 'traffic', 'finance']
      }
      const params = premiumCalculationParams(getDefaults ? defaults : this.formData)

      return this.gql
        .query(premiumCalculation, params)
        .then(({ data }) => {
          this.premiumResults = data
          this.forceRecompute++
        })
        .then(() => {
          if (getDefaults) {
            const productInfoPromises = this.premiumResults.premium.configurations.map((e) =>
              this.getProductInfo(`${e.item.premiumRequestProductCode}`)
            )

            return Promise.allSettled(productInfoPromises)
          }
        })
        .then(() => {
          if (getDefaults) {
            return this.getPremiums(false)
          }
        })
        .finally(() => {
          this.$refs.funnel.$refs.form.isSubmitting = false
        })
        .catch(logError)
    },
    formSubmit() {
      return this.gql
        .mutationByName('funnelWritePolicy', writePolicyParams(this.formData))
        .then(({ data }) => {
          this.submissionResults = pathOr({}, ['write', 'policyResults', '0', 'result'], data)
          this.handlePostSubmit()
        })
        .catch(logError)
    },
    handlePostSubmit() {
      if (isPolicyResultSuccess(this.submissionResults)) {
        registerPurchase(
          funnelName,
          this.premiumInformation['Aon-LegalAid'].totalAmount * 12,
          this.transaction
        )
      }
    },
    handlePageView(page) {
      registerCheckout(
        funnelName,
        page,
        this.premiumInformation['Aon-LegalAid'].totalAmount * 12,
        this.transaction
      )
    },
    getProductInfo(code) {
      return this.gql
        .queryByName('funnelProductInfo', {
          inputModel: {
            code
          }
        })
        .then(({ data }) => {
          this.$nextTick(() => {
            this.productInfoResults[code] = data
            this.forceRecompute++
          })
        })
        .catch(logError)
    },
    chooseAdvice() {
      let insurer = 'Aon-LegalAid'
      this.$set(this.formData, 'insurer', 'Aon-LegalAid')
      this.fetchFinalQuestions(this.premiumInformation[insurer].productCode)
    }
  }
}
