<template>
  <CycleSimpleForm
    :next-button-text="textContent.sendFormLabel"
    :form-submit-action="formSubmit"
    :has-next-button="shouldFormBeVisible"
  >
    <CycleFormSet :heading="textContent.setHelpHeading">
      <CycleInputContainer :label="textContent.choiceLabel" render-list>
        <CycleRadioInput :label="textContent.changeOption" value="change" v-model="requestedHelp" />
        <CycleRadioInput
          :label="textContent.questionOption"
          value="question"
          v-model="requestedHelp"
        />
        <CycleRadioInput :label="textContent.damageOption" value="damage" v-model="requestedHelp" />
      </CycleInputContainer>
    </CycleFormSet>
    <CycleFormSet v-if="requestedHelpChange" :heading="textContent.changeRequestHeading">
      <CycleInputContainer :label="textContent.changeRequestChoiceLabel" render-list>
        <CycleRadioInput
          :key="changeRequestOption.mailId"
          v-for="changeRequestOption in changeRequestOptions"
          :label="changeRequestOption.title"
          :value="changeRequestOption.mailId"
          v-model="formData.mailId"
        />
      </CycleInputContainer>
    </CycleFormSet>
    <CycleFormSet v-if="requestedHelpQuestion" :heading="textContent.questionRequestHeading">
      <CycleInputContainer :label="textContent.questionRequestChoiceLabel" render-list>
        <CycleRadioInput
          :key="changeRequestOption.mailId"
          v-for="changeRequestOption in questionRequestOptions"
          :label="changeRequestOption.title"
          :value="changeRequestOption.mailId"
          v-model="formData.mailId"
        />
      </CycleInputContainer>
    </CycleFormSet>
    <template v-if="shouldFormBeVisible">
      <CycleFormSet :heading="descriptionHeading">
        <CycleInputContainer :label="descriptionLabel">
          <CycleTextarea
            v-model="formData.descriptionText"
            basic-text-with-numbers-and-special-characters
            required
          />
        </CycleInputContainer>
      </CycleFormSet>

      <CycleFormSet :heading="textContent.setContactInfoHeading">
        <CycleInputContainer :label="textContent.nameLabel">
          <CycleTextInput
            v-model="formData.name"
            :valid-name="formData.name"
            :placeholder="textContent.namePlaceholderText"
          />
        </CycleInputContainer>
        <CycleInputContainer :label="textContent.phonenumberLabel">
          <CycleTextInput v-model="formData.phoneNumber" required phone-dutch />
        </CycleInputContainer>
        <CycleInputContainer :label="textContent.emailLabel">
          <CycleTextInput v-model="formData.email" required email />
        </CycleInputContainer>
        <CycleInputContainer :label="textContent.postalCodeLabel">
          <CycleTextInput
            postcode
            v-model="formData.postalCode"
            :placeholder="textContent.postalCodePlaceholder"
          />
        </CycleInputContainer>
        <CycleInputContainer :label="textContent.houseNumberLabel">
          <CycleTextInput v-model="formData.houseNumber" numeric :min-value="1" />
        </CycleInputContainer>
        <CycleInputContainer :label="textContent.houseNumberAdditionLabel">
          <CycleTextInput v-model="formData.houseNumberAddition" />
        </CycleInputContainer>
      </CycleFormSet>
      <CycleFormSet :heading="textContent.setPrivacyHeading">
        <CycleHtml :html="textContent.privacyStatementText" />
      </CycleFormSet>
    </template>
    <CycleFormSet v-if="requestedHelpDamage" :heading="textContent.setDamageHeading">
      <CycleHtml :html="textContent.damageText" />
      <p>
        <i class="icon icon--phone"></i>
        <a :href="'tel: ' + textContent.damageClaimsNumber">{{ textContent.damageClaimsNumber }}</a>
      </p>
    </CycleFormSet>
    <template #confirmation-confirmed>
      <CycleHtml :html="textContent.postSucceededMessageText" />
    </template>
    <template #confirmation-rejected>
      <CycleHtml :html="textContent.postFailedMessageText" />
    </template>
  </CycleSimpleForm>
</template>

<script>
import CycleFormSet from '../CycleFormSet/CycleFormSet'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleTextarea from '../../../elements/forms/CycleTextarea/CycleTextarea'
import CycleRadioInput from '../../../elements/forms/CycleRadioInput/CycleRadioInput'
import { CycleDateInput } from '../../../elements/forms'
import CycleButton from '../../../elements/CycleButton/CycleButton'
import CycleSelect from '../../../elements/forms/CycleSelect/CycleSelect'
import CycleOption from '../../../elements/forms/CycleSelect/CycleOption/CycleOption'
import CycleConfirmation from '../../../cycle-confirmation/CycleConfirmation'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleCheckout from '../../CycleCheckout/CycleCheckout'
import CycleToolTip from '../../../cycle-tooltip/CycleTooltip'
import CycleCheckboxInput from '../../../elements/forms/CycleCheckboxInput/CycleCheckboxInput'
import CycleHtml from '../../../base/CycleHtml'

export default {
  name: 'CycleContactForm',
  components: {
    CycleSimpleForm,
    CycleFormSet,
    CycleInputContainer,
    CycleTextInput,
    CycleTextarea,
    CycleRadioInput,
    CycleDateInput,
    CycleButton,
    CycleSelect,
    CycleOption,
    CycleConfirmation,
    CycleCheckout,
    CycleToolTip,
    CycleCheckboxInput,
    CycleHtml
  },
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    changeRequestOptions: {
      type: Array,
      default: () => []
    },
    questionRequestOptions: {
      type: Array,
      default: () => []
    },
    formSubmitAction: {
      type: Function,
      required: true
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        descriptionText: null,
        name: null,
        email: null,
        phoneNumber: null,
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        mailId: null
      },
      requestedHelp: null,
      isFormPosted: true,
      postSucceeded: false,
      submitSuccess: null
    }
  },
  computed: {
    descriptionHeading() {
      if (this.requestedHelpQuestion) {
        return this.textContent.setQuestionHeading
      }
      if (this.requestedHelpChange) {
        return this.textContent.setChangeHeading
      }
      return ''
    },
    descriptionLabel() {
      if (this.requestedHelpQuestion) {
        return this.textContent.questionLabel
      }
      if (this.requestedHelpChange) {
        return this.textContent.changeLabel
      }
      return ''
    },
    requestedHelpQuestion() {
      return this.isSelectedOption(this.requestedHelp, 'question')
    },
    requestedHelpChange() {
      return this.isSelectedOption(this.requestedHelp, 'change')
    },
    requestedHelpDamage() {
      return this.isSelectedOption(this.requestedHelp, 'damage')
    },
    setCurrentCustomer() {
      return this.isSelectedOption(this.formData.currentCustomer, 'Ja')
    },
    shouldFormBeVisible() {
      return this.formData.mailId !== null && this.isRequestSelected
    },
    isRequestSelected() {
      return this.requestedHelp !== null
    }
  },
  watch: {
    requestedHelp(newVal) {
      if (newVal) {
        this.formData.mailId = null
      }
    }
  },
  methods: {
    isSelectedOption(source, value) {
      return source === value
    },
    formSubmit({ ...rest }) {
      const formData = {
        ...rest
      }
      return this.formSubmitAction(formData).then(() => {
        this.submitSuccess = true
      })
    }
  }
}
</script>
