import Vue from 'vue'
import {
  SET_ACTIVE_STEP_BY_INDEX,
  SET_FORM_DEFINITION,
  SET_FORM_FIELD_VALUE,
  SET_FORM_FIELD_VALUES,
  SET_FORM_STATE,
  SET_BUSINESS_DATA,
  CLEAR_BUSINESS_DATA_VALUE,
  SET_ERRORS,
  CLEAR_ERRORS,
  RESET_FORM,
  SET_FORM_DEFINITION_ID,
  SET_ACTIVE_FIELD,
  REMOVE_ACTIVE_FIELD,
  SET_VALIDATION_STATUS,
  SET_ERROR_ELEMENTS,
  SET_INVALID_ELEMENTS,
  SET_COMMUNICATION_START,
  SET_COMMUNICATION_SUCCESS,
  SET_COMMUNICATION_FAILURE,
  SET_COMMUNICATION_BLOCK_UI_LOADER,
  SET_FORM_DATA_FORMATTED,
  CLEAR_ERRORS_BY_KEY,
  CLEAR_SUCCESS_BY_KEY,
  SET_PREVIOUS_STEP,
  RESET_COMMUNICATION_STATE,
  SET_TOUCHED_ELEMENT,
  SET_ANALYTICS_DATA
} from './mutation-types'
import { confirmationStepKey } from '../constants/api'
import { clearPersistentState } from '@/store'

export const mutations = {
  [SET_FORM_DEFINITION_ID](state, { formDefinitionId }) {
    state.formDefinitionId = formDefinitionId
  },
  [SET_FORM_DEFINITION](state, { definition, formData = {}, formState = {}, businessData = {} }) {
    const { name, dictionary, steps, fieldSets, fieldGroups, fields, firstStep } = definition
    const validFirstStepKey = firstStep && steps.some((s) => s.key === firstStep)
    const firstStepIndex = validFirstStepKey ? steps.findIndex((s) => s.key === firstStep) : 0

    state.name = name
    state.dictionary = dictionary
    state.steps = steps.slice(firstStepIndex)
    state.fieldSets = fieldSets
    state.fieldGroups = fieldGroups
    state.fields = fields
    state.formState['step'] = state.formState['step'] ?? formState.step
    state.formState['token'] = state.formState['token'] ?? formState.token

    Object.entries(formData).forEach(([key, value]) => {
      if (
        fields.find((field) => field.key === key)?.defaultValue !== value ||
        typeof state.formData[key] === 'undefined'
      ) {
        Vue.set(state.formData, key, value)
      }
    })

    Object.entries(businessData).forEach(([key, value]) => {
      Vue.set(state.businessData, key, value)
    })
  },
  [SET_FORM_FIELD_VALUE](state, { fieldKey, value }) {
    Vue.set(state.formData, fieldKey, value)
  },
  [CLEAR_BUSINESS_DATA_VALUE](state, { fieldKey }) {
    Vue.delete(state.businessData, fieldKey)
  },

  /***
   * Saves multiple values to the store, most likely from the load step call.
   * This also ensures that overwritten values for example postcode is backed up as _postcode after login
   *
   * @param state - vuex state
   * @param values - new formData values
   */
  [SET_FORM_FIELD_VALUES](state, values = {}) {
    Object.entries(values).forEach(([key, value]) => {
      if (key in state.formData && !(`_${key}` in values) && !(`_${key}` in state.formData)) {
        const originalValue = state.formData[key]
        Vue.set(state.formData, `_${key}`, originalValue)
      }

      Vue.set(state.formData, key, value)
    })
  },
  [SET_FORM_STATE](state, formState = {}) {
    Object.entries(formState)
      .filter(([, value]) => value)
      .forEach(([key, value]) => {
        if (key === 'step' && value === confirmationStepKey) {
          clearPersistentState()
        }
        Vue.set(state.formState, key, value)
      })
  },
  [SET_BUSINESS_DATA](state, businessData = {}) {
    Object.entries(businessData).forEach(([key, value]) => {
      Vue.set(state.businessData, key, value)
    })
  },
  [SET_ANALYTICS_DATA](state, analyticsData = {}) {
    Object.entries(analyticsData).forEach(([key, value]) => {
      Vue.set(state.analyticsData, key, value)
    })
  },
  [SET_ERRORS](state, errors = {}) {
    Object.entries(errors).forEach(([key, value]) => {
      Vue.set(state.errors, key, value)
    })
  },
  [CLEAR_ERRORS](state) {
    Object.keys(state.errors).forEach((key) => {
      Vue.delete(state.errors, key)
    })
  },
  [RESET_FORM](state) {
    state.errors.lengh = 0
    state.formData = {}
    state.businessData = {}
    state.formState.step = null
    state.formState.token = null
    state.formState.validationStatus = {}
    state.formState.validationVisible = false
    state.formState.activeFields.length = 0
  },
  [SET_ACTIVE_STEP_BY_INDEX](state, newActiveStepIndex) {
    const activeStepKey = state.steps[newActiveStepIndex].key
    if (activeStepKey === confirmationStepKey) {
      clearPersistentState()
    }

    state.formState['step'] = activeStepKey
  },
  [SET_ACTIVE_FIELD](state, fieldKey) {
    if (!state.formState.activeFields.includes(fieldKey)) {
      state.formState.activeFields.push(fieldKey)
    }
  },
  [SET_VALIDATION_STATUS](state, validationStatus) {
    Object.entries(validationStatus)
      .filter(([key]) => key[0] === '$')
      .forEach(([key, value]) => {
        Vue.set(state.formState.validationStatus, key, value)
      })
  },
  [REMOVE_ACTIVE_FIELD](state, fieldKey) {
    const index = state.formState.activeFields.indexOf(fieldKey)
    if (index >= 0) {
      state.formState.activeFields.splice(index, 1)
    }
  },
  [SET_ERROR_ELEMENTS](state, errorElements) {
    state.formState.errorElements.length = 0
    state.formState.errorElements.push(...errorElements)
  },
  [SET_INVALID_ELEMENTS](state, invalidElements) {
    state.formState.invalidElements.length = 0
    state.formState.invalidElements.push(...invalidElements)
  },
  [SET_TOUCHED_ELEMENT](state, elementKey) {
    if (!state.formState.touchedElements.includes(elementKey)) {
      state.formState.touchedElements.push(elementKey)
    }
  },
  [SET_COMMUNICATION_START](state, { key, cancelFunction, blockUI, promise }) {
    Vue.set(state.communication, key, {
      blockUI,
      cancelFunction,
      promise,
      isPending: true,
      isFulfilled: false,
      isRejected: false,
      result: undefined
    })
  },
  [SET_COMMUNICATION_SUCCESS](state, { key }) {
    const original = state.communication[key]

    Vue.set(state.communication, key, {
      ...original,
      isPending: false,
      isFulfilled: true,
      isRejected: false
    })
  },
  [SET_COMMUNICATION_BLOCK_UI_LOADER](state, { loadUrl, loadText }) {
    Vue.set(state.communication, 'block-ui-loader', {
      url: loadUrl,
      text: loadText
    })
  },
  [SET_COMMUNICATION_FAILURE](state, { key, reason }) {
    const original = state.communication[key]
    Vue.set(state.communication, key, {
      ...original,
      isPending: false,
      isFulfilled: false,
      isRejected: true,
      result: reason
    })
  },
  [RESET_COMMUNICATION_STATE](state) {
    Object.entries(state.communication).forEach(([key, value]) => {
      Vue.set(state.communication, key, {
        ...value,
        isPending: false
      })
    })
  },
  [SET_FORM_DATA_FORMATTED](state, formDataFormatted = {}) {
    Object.entries(formDataFormatted).forEach(([key, value]) => {
      Vue.set(state.formDataFormatted, key, value)
    })
  },
  [SET_PREVIOUS_STEP](state, prevStep) {
    state.prevStepIndex = prevStep
  },
  [CLEAR_ERRORS_BY_KEY](state, serviceErrorItems) {
    serviceErrorItems.forEach((item) => {
      Vue.delete(state.errors, item)
    })
  },
  [CLEAR_SUCCESS_BY_KEY](state, serviceSuccessItems) {
    const itemInteractionSuccess = state?.fields?.filter(
      (field) => field.key === serviceSuccessItems.key
    )[0]?.interaction?.success
    if (itemInteractionSuccess) {
      Object.entries(itemInteractionSuccess).forEach(([key, value]) => {
        const val = typeof value === 'string' ? '' : false
        Vue.set(itemInteractionSuccess, key, val)
      })
    }

    const successStatus = state?.formData[serviceSuccessItems.key] ? true : false
    if (!successStatus) {
      Vue.set(state.communication, serviceSuccessItems.key, {
        isFulfilled: false
      })
    }
  }
}
