export const scrollToElementWithAnchorId = (anchorId) => {
  if (!elementExists(anchorId)) {
    return
  }
  const elementToBeScrolledTo = getElementToBeScrolledTo(anchorId)
  const bottomOfMenu = document.querySelector('.coreui__menus')?.getBoundingClientRect()?.bottom
  const isMenuInTheViewPort = bottomOfMenu >= 0

  window.scrollTo({
    left: 0,
    top: isMenuInTheViewPort
      ? elementToBeScrolledTo.offsetTop - bottomOfMenu
      : elementToBeScrolledTo.offsetTop,
    behavior: 'smooth'
  })
}

const elementExists = (anchorId) => {
  if (anchorId === '' || anchorId === '#') {
    return false
  }
  const elementToBeScrolledTo = getElementToBeScrolledTo(anchorId)
  return !!elementToBeScrolledTo
}
const getElementToBeScrolledTo = (anchorId) => {
  return (
    document.querySelector(anchorId) ||
    document.querySelector(`[data-test="${anchorId?.substring(1)}"]`) ||
    document.querySelector(`[name="${anchorId?.substring(1)}"]`)
  )
}
