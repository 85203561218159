<template>
  <RapidFormCanvas
    :on-submit="submitStep"
    v-if="hasDefinition"
    :show-next-button="activeStepNavigation.showNext"
    :enable-next-button="activeStepNavigation.enableNext"
    :show-back-button="activeStepNavigation.showBack"
    :modifiers="formModifiers"
  >
    <template #header>
      <RapidFormStepIndication
        :steps="steps"
        :has-busstop-stepper="hasBusstopStepper"
        :highest-step-index="highestStepIndex"
      />
      <portal-target :name="activeStep.key + '-header'" />
      <div class="form__header-content">
        <CycleHeading v-if="isStepTitleVisible" :weight="headingWeight">
          {{ activeStep.name }}
        </CycleHeading>
        <CycleHtml :html="activeStep.intro" />
      </div>
    </template>
    <template v-for="fieldSet in activeFieldSetsInSlot()">
      <portal-target :name="fieldSet.key + '-top'" :key="fieldSet.key + '-top-portal'" />
      <header class="form__header" v-if="fieldSet.heading" :key="fieldSet.key">
        <CycleHeading :weight="3">{{ fieldSet.heading }}</CycleHeading>
      </header>
      <RapidFormFieldSet v-bind="getRapidFormFieldSetProps(fieldSet)" :key="fieldSet.key">
        <CycleHtml :html="fieldSet.intro" />
        <template #heading v-if="!!fieldSet.title && !hasShowAsSticky(fieldSet)">{{
          fieldSet.title
        }}</template>
        <div v-if="hasShowAsSticky(fieldSet)" class="premium-price-content">
          <CycleHeading>{{ fieldSet.title }}</CycleHeading>
          <div v-if="activeStepNavigation.receiptAsStickyForDesktop" class="premium-price-label">
            <span class="payment-term">{{ productPaymentTerm }}</span>
            <label class="price">{{ productTotalFormat }}</label>
            <CoveragePremiumPrice :price="productTotal" :payment-term="productPricePaymentTerm" />
          </div>
        </div>
        <template v-for="field in getFields(fieldSet.key)">
          <RapidFormField
            v-bind="getRapidFormFieldProps(field, fieldSet)"
            :key="`${fieldSet.key}_${field.key}`"
          />
        </template>
      </RapidFormFieldSet>
      <portal-target :name="fieldSet.key + '-bottom'" :key="fieldSet.key + '-bottom-portal'" />
    </template>

    <template #bottomSlot>
      <template v-for="fieldSet in activeFieldSetsInSlot('bottom-slot')">
        <RapidFormFieldSet v-bind="getRapidFormFieldSetProps(fieldSet)" :key="fieldSet.key">
          <CycleHtml :html="fieldSet.intro" />
          <template #heading v-if="!!fieldSet.title">{{ fieldSet.title }}</template>
          <template v-for="field in getFields(fieldSet.key)">
            <RapidFormField
              v-bind="getRapidFormFieldProps(field, fieldSet)"
              :key="`${fieldSet.key}_${field.key}`"
            />
          </template>
        </RapidFormFieldSet>
      </template>
    </template>

    <!-- Active Field Set As Sticky -->
    <RapidFormFieldSetStickyWrapper
      v-if="isFieldSetSticky"
      v-slot="scope"
      :is-sticky-for-desktop="activeStepNavigation.receiptAsStickyForDesktop"
    >
      <template v-for="fieldSet in activeFieldSetInStepAsSticky">
        <RapidFormFieldSet v-bind="getRapidFormFieldSetProps(fieldSet)" :key="fieldSet.key">
          <div
            class="stick-header"
            :class="{ 'stick-content-scroll': scope.isStickyScrollingContent }"
          >
            <ul>
              <li>
                <button
                  :class="[scope.isCollapsed ? 'up' : 'down']"
                  @click="scope.handleSlide"
                ></button>
              </li>
              <li>
                <CycleHeading>{{ fieldSet.title }}</CycleHeading>
              </li>
              <li>
                <span class="payment-term">{{ productPaymentTerm }}</span>
                <label class="price">{{ productTotalFormat }}</label>
                <CoveragePremiumPrice
                  v-if="activeStepNavigation.receiptAsStickyForDesktop"
                  :price="productTotal"
                  :payment-term="productPricePaymentTerm"
                />
              </li>
            </ul>
          </div>
          <transition name="stick-slide">
            <div v-if="!scope.isCollapsed">
              <div class="stick-content">
                <template v-for="group in getFieldSetGroup(fieldSet.key)">
                  <template v-for="field in getGroupFields(group.key)">
                    <RapidFormField
                      v-bind="getRapidFormFieldProps(field, fieldSet)"
                      :key="`${fieldSet.key}_${field.key}`"
                    />
                  </template>
                </template>
              </div>
              <div
                class="stick-footer"
                :class="{ 'stick-content-scroll': scope.isStickyScrollingContent }"
              >
                <template v-for="field in getFieldsWithoutGroup(fieldSet.key)">
                  <RapidFormField
                    v-bind="getRapidFormFieldProps(field, fieldSet)"
                    :key="`${fieldSet.key}_${field.key}`"
                  />
                </template>
              </div>
            </div>
          </transition>
        </RapidFormFieldSet>
      </template>
    </RapidFormFieldSetStickyWrapper>

    <ModalForm v-if="openModalName" :disallow="disallow">
      <template #heading>
        <slot name="heading">{{ openModalName }}</slot>
      </template>
      <template #body>
        <slot name="body">
          <template v-for="fieldSet in activeFieldSetInModal()">
            <header class="form__header" v-if="fieldSet.heading" :key="fieldSet.key">
              <CycleHeading :weight="3">{{ fieldSet.heading }}</CycleHeading>
            </header>
            <RapidFormFieldSet v-bind="getRapidFormFieldSetProps(fieldSet)" :key="fieldSet.key">
              <CycleHtml :html="fieldSet.intro" />
              <template #heading v-if="!!fieldSet.title">{{ fieldSet.title }}</template>
              <template v-for="field in getFields(fieldSet.key)">
                <RapidFormField
                  v-bind="getRapidFormFieldProps(field, fieldSet)"
                  :key="`${fieldSet.key}_${field.key}`"
                />
              </template>
            </RapidFormFieldSet>
          </template>
        </slot>
      </template>
    </ModalForm>
  </RapidFormCanvas>
  <BusyIndicator v-else />
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import { mapGetters, mapActions } from 'vuex'
import RapidFormPlayerState from '../store'
import * as RapidFormComponents from './RapidFormComponents'
import {
  CycleFormPage,
  CycleHtml,
  BusyIndicator,
  CycleHeading,
  ModalForm,
  CoveragePremiumPrice
} from '@aon/cycle'

export default {
  name: 'RapidFormPlayer',
  components: {
    ...RapidFormComponents,
    ScPlaceholder: Placeholder,
    CycleFormPage,
    CycleHtml,
    CycleHeading,
    BusyIndicator,
    ModalForm,
    CoveragePremiumPrice
  },
  provide() {
    return {
      scrollIntoView: this.formDefinition?.definition?.scrollToSpinner || false,
      formState: {
        active: null,
        next() {}
      },
      formData: {}
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      'hasDefinition',
      'name',
      'formState',
      'steps',
      'getFields',
      'getGroupFields',
      'getFieldsWithoutGroup',
      'getFieldSetGroup',
      'activeStep',
      'activeStepNavigation',
      'activeStepIndex',
      'prevStepIndex',
      'formDefinitionId',
      'activeFieldSetsInSlot',
      'getFieldGroup',
      'activeFieldSetInModal',
      'openModalName',
      'activeFieldSetInStepAsSticky',
      'isFieldSetSticky',
      'productTotalFormat',
      'highestStepIndex',
      'productPaymentTerm',
      'productTotal',
      'productPricePaymentTerm',
      'disallow'
    ]),
    formModifiers() {
      return this.fields.definition?.modifiers?.join(' ')
    },
    hasBusstopStepper() {
      return this.fields.definition?.modifiers?.includes('busstop-stepper-progressbar')
    },
    formDefinition() {
      return {
        id: this.fields.definition?.id,
        formData: this.fields.data ?? this.fields.formData,
        businessData: this.fields.businessData,
        formState: this.fields.state,
        definition: this.fields.definition,
        formDataFormatted: this.fields.formDataFormatted
      }
    },
    headingWeight() {
      return this.activeStep.headingWeight || 3
    },
    hasCollectivityPicker() {
      return !!this.rendering.placeholders?.['collectivity-picker']
    },
    isStepTitleVisible() {
      return this.activeStep.name && this.activeStep.showName
    },
    shouldPreserveState() {
      const {
        rapidFormPlayer: { formState, steps, formDefinitionId }
      } = this.$store.state

      return (
        !this.$route.query['jlo'] &&
        formDefinitionId === this.fields.definition.id &&
        formState.step !== steps?.[steps?.length - 1].key
      )
    }
  },
  async created() {
    let preserveState = !!this.$store.state.rapidFormPlayer && this.shouldPreserveState
    this.$store.registerModule('rapidFormPlayer', RapidFormPlayerState, {
      preserveState
    })
    preserveState = this.fields.businessData?.userDataLoadedFromXconnect ?? preserveState
    await this.initFormDefinition({ formDefinition: this.formDefinition })
    await this.loadStepData({ regenerateDefinition: preserveState })

    this.unwatch = this.$store.watch(
      (state, getters) => getters['rapidFormPlayer/activeStep'],
      (activeStep, oldActiveStep) => {
        if (activeStep.id === oldActiveStep.id) {
          return
        }
        this.loadStepData({ regenerateDefinition: false })
      },
      { deep: true }
    )
  },
  mounted() {
    if (window) {
      window.onpopstate = (event) => {
        this.updateFormStateFromHistory({ formState: event.state })
      }
    }
  },
  beforeDestroy() {
    this.unwatch && this.unwatch()
  },
  destroyed() {
    this.$store.unregisterModule('rapidFormPlayer')
  },
  methods: {
    ...mapActions('rapidFormPlayer', [
      'initFormDefinition',
      'submitStep',
      'loadStepData',
      'setDataLayer',
      'updateFormStateFromHistory'
    ]),
    parsableValue(val) {
      return val ? '`' + val + '`' : val
    },
    getGroupFieldCondition(val) {
      if (!val) {
        return undefined
      }

      const fieldGroup = this.getFieldGroup(val)
      return fieldGroup?.showIf
    },
    getRapidFormFieldSetProps(fieldSet) {
      return {
        modifiers: fieldSet.modifiers,
        'show-fields-as-labels': fieldSet.showFieldsAsLabels,
        subtext: fieldSet.outro,
        'show-if': fieldSet.showIf,
        'data-test': fieldSet.key,
        'data-testid': fieldSet.id
      }
    },
    getRapidFormFieldProps(field, fieldSet) {
      return {
        'field-definition': field,
        'field-set': fieldSet,
        'show-if': field.showIf,
        'group-show-if': this.getGroupFieldCondition(field.fieldGroup),
        'get-value': field.getValue,
        'dynamic-options': field.optionsFromFormDefinition,
        'dynamic-label': this.parsableValue(field.label),
        'dynamic-content': this.parsableValue(field.content),
        'enable-if': field.enableIf,
        'visible-if': field.visibleIf
      }
    },
    hasShowAsSticky(fieldSet) {
      return (
        fieldSet?.modifiers?.includes('show-as-sticky') &&
        this.activeStepNavigation?.receiptAsStickyForDesktop
      )
    }
  }
}
</script>
