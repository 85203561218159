<template>
  <CycleDocumentsFilter
    :text-content="textContent"
    :document-codes-filter="fields.documentCodesFilter"
    @filter-change="updateFilterValues"
    v-if="!isInitializing"
  />
</template>

<script>
import { DocumentsFilter, ContentToggle } from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'DocumentsFilter',
  components: {
    CycleDocumentsFilter: DocumentsFilter,
    ContentToggle
  },
  inject: ['setFilter', 'isDataFetched'],
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  data() {
    return {
      filter: null,
      isInitializing: true
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value)
    }
  },
  watch: {
    filter(newVal) {
      this.setFilter({ filter: newVal })
    },
    isDataFetched() {
      this.isInitializing = false
    }
  },
  methods: {
    updateFilterValues(filterVals) {
      this.filter = filterVals
    }
  }
}
</script>
