<template>
  <label class="licenseplate">
    <span class="licenseplate__countrycode"></span>
    <input
      type="text"
      v-bind="Object.assign({}, attributes, $attrs)"
      v-on="Object.assign({}, listeners, $listeners, { input: updateValue })"
      :value="value"
      maxlength="8"
      class="form__input"
      :placeholder="placeholder"
      ref="licenseplate"
      @keydown="handleKeydown"
    />
  </label>
</template>

<script>
import BaseInput from '../BaseInput'
import {
  licensePlateFormat,
  newCursorPosition,
  isLicensePlateValid
} from '@/services/LicensePlateFormat'

export default {
  name: 'CycleLicensePlateInput',
  extends: BaseInput,
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    restrictAlphaNum: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.$route?.query?.kenteken) {
      this.formatValue(this.$route.query.kenteken)
    }
  },
  methods: {
    updateValue(event) {
      this.formatValue(event.target.value)
    },
    formatValue(value) {
      const alphaNum = /[^A-Z0-9]/gi
      const newVal = this.restrictAlphaNum ? value.replace(alphaNum, '') : value
      const licensePlate = licensePlateFormat(newVal)
      this.$emit('input', licensePlate)

      // Handle cursor position on backspace or delete or any change in licenseplate
      this.handleCursor()
    },
    handleCursor() {
      const element = this.$refs.licenseplate
      const value = element.value
      // Look for cursor position until licenseplate valid
      if (!isLicensePlateValid(value)) {
        const position = element.selectionStart
        const newPosition = newCursorPosition(position, value)
        // Set cursor new position and focus on it
        this.$nextTick(() => {
          element.selectionStart = newPosition
          element.selectionEnd = newPosition
          element.focus()
        })
      }
    },
    handleKeydown(event) {
      // Prevent all special char to type on input plate
      const testIfNumeric = /[^a-zA-Z0-9]/
      if (testIfNumeric.test(event.key)) {
        event.preventDefault()
      }
    }
  }
}
</script>
