//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleInputContainer } from '@/components/functional/forms'
import { CycleRadioInput } from '@/components/elements'

import { QuestionMixin } from './QuestionMixin'
import { BooleanAnswer, EmptyAnswer } from '../models'
import { ProvidedTooltip } from '@/components/organisms'
export default {
  name: 'QuestionBoolean',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleRadioInput,
    ProvidedTooltip
  },
  mixins: [QuestionMixin],
  inject: ['textContent'],
  props: {
    labels: {
      type: Object,
      required: true,
      default: () => ({
        booleanYes: 'Ja',
        booleanNo: 'Nee'
      })
    },
    value: {
      type: Object,
      required: true,
      default: () => new EmptyAnswer()
    },
    validateOnInputValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    showError() {
      if (this.validateOnInputValue) {
        return this.value.answer === this.validateOnInputValue
      }
      return false
    }
  },
  methods: {
    handleChange(newValue) {
      this.$emit('input', new BooleanAnswer(newValue))
    }
  }
}
