import { mergeDeepWith, concat, pathOr } from 'ramda'
import format from 'date-fns/format'
import { buildKeyValueParams, buildVehicleObject, buildFinalQuestionAnswers } from './helpers'

export const writePolicyParams = (params) => {
  const {
    coverage,
    salutation,
    initials,
    preposition,
    lastname,
    birthdate,
    street,
    housenumber,
    housenumberAddition,
    zipcode,
    city,
    emailaddress,
    phonenumber,
    startdate,
    vehicleInformation,
    licenseplate,
    finalQuestions,
    mailinglist
  } = params

  const writePolicyParamValues = {
    values: {
      premiumRequestCode: 'Car-request-AonDirect',
      premiumRequestConfigMapperCode: coverage,
      startingDate: startdate,
      newsLetterSubscription: mailinglist.includes('y'),
      parameters: buildKeyValueParams(params, [
        'LicencePlate',
        'AccountNumber',
        'SecurityClass',
        'SignCode',
        'CarMilage',
        'PostalCode',
        'DateOfBirth',
        'ClaimFreeYears',
        'AmountAccessories'
      ]),
      person: {
        initials: initials,
        prefix: preposition,
        lastName: lastname,
        dateOfBirth: birthdate,
        gender: salutation,
        maritalStatus: '9',
        emailAddress: emailaddress,
        telephoneNumber: phonenumber,
        mobileNumber: phonenumber,
        residentialAddress: {
          street: street,
          houseNumber: housenumber,
          houseNumberAddition: housenumberAddition,
          postalCode: zipcode,
          town: city,
          countryCode: 'NL'
        },
        correspondence: 'E-mail'
      },
      vehicle: {
        ...buildVehicleObject(vehicleInformation),
        licencePlate: licenseplate, // notice the typo
        vehicleType: 'P'
      },
      acceptanceQuestionsInputModel: {
        questions: buildFinalQuestionAnswers(finalQuestions)
      }
    }
  }

  return writePolicyParamValues
}

export const premiumCalculationParams = (params, initialRequest = false) => {
  const accessoryAmount = initialRequest ? 1250 : params.accessories
  return {
    values: {
      premiumRequestCode: 'Car-request-AonDirect',
      vehicle: {
        ...buildVehicleObject(params.vehicleInformation),
        licencePlate: params.licenseplate // upstream typo
      },
      parameters: [
        {
          key: 'CarMilage',
          value: params.distanceperyear
        },
        {
          key: 'PostalCode',
          value: params.zipcode
        },
        {
          key: 'DateOfBirth',
          value: params.birthdate
        },
        {
          key: 'ClaimFreeYears',
          value: `${params.claimfreeyears}`
        },
        {
          key: 'AmountAccessories',
          value: `${accessoryAmount}`
        }
      ]
    }
  }
}

export const premiumCalculationParamsForConfiguration = (params, fetchAllAdditions = false) => {
  const premiumRequestConfigurationCode = pathOr('', ['coverage'], params)
  const passengerDamage = fetchAllAdditions ? true : params.coverages.includes('passengerDamage')
  const legalAid = fetchAllAdditions ? true : params.coverages.includes('legalAid')

  return mergeDeepWith(concat, premiumCalculationParams(params), {
    values: {
      premiumRequestConfigurationCode,
      parameters: [
        {
          key: 'CarAdditionalCoveragePassengerDamage',
          value: String(passengerDamage)
        },
        {
          key: 'CarAdditionalCoverageLegalAid',
          value: String(legalAid)
        }
      ]
    }
  })
}

export const checkAcceptanceParams = (params) => {
  return {
    values: {
      premiumRequestCode: 'Car-request-AonDirect',
      premiumRequestConfigMapperCode: 'PremiumRequestConfig-Aon-Car-WA',
      vehicle: {
        ...buildVehicleObject(params.vehicleInformation),
        licencePlate: params.licenseplate
      },
      startingDate: format(new Date(), 'yyyy-MM-dd'),
      parameters: buildKeyValueParams(params, ['CarMilage', 'PostalCode', 'DateOfBirth'])
    }
  }
}
