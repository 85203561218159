<template>
  <ScText
    :field="fields.title"
    tag="CycleHeading"
    v-if="fields.title && fields.title.value"
    :weight="1"
  />
</template>
<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue'
import { CycleHeading } from '@aon/cycle'

export default {
  name: 'CycleHeadingSitecore',
  components: {
    ScText: Text,
    CycleHeading
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
