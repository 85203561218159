<script>
import { Address } from '../../../models'

export default {
  name: 'FullHouseNumber',
  functional: true,
  props: {
    address: {
      type: Address,
      required: true
    }
  },
  render(createElement, context) {
    const {
      props: { address }
    } = context

    if (address.houseNumberAddition != null) {
      return createElement('span', {}, [`${address.houseNumber} ${address.houseNumberAddition}`])
    }

    return createElement('span', {}, [address.houseNumber])
  }
}
</script>
