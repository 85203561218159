<template>
  <!-- On initial load, show busy indicator -->
  <BusyIndicator v-if="isLoading && !claims" />

  <!-- After loading, when there's no data, show the empty placeholder -->
  <EmptyTablePlaceholder
    v-else-if="claims.length === 0"
    :no-results-text="fields.noResultsText | ScString"
  />

  <!-- After loading, where there is data, show the data -->
  <div v-else>
    <!-- And the busy indicator when one switches pages -->
    <BusyIndicator v-if="isLoading" />

    <StatusLegend :filter="['confirmed', 'in-progress']" />
    <DocumentClaimsDetails :claims="claims" :fields="fields"></DocumentClaimsDetails>

    <!-- Pagination -->
    <CyclePagination
      :total-number-of-items="totalNumberOfLines"
      :number-of-items-on-page="numberOfLinesOnPage"
      :current-page="pageNumber"
      :pagination-item-text="fields.paginationItemText | ScString"
      :pagination-text="fields.paginationText | ScString"
      @page-change="handlePageChange"
    />
  </div>
</template>
<script>
import { BusyIndicator, EmptyTablePlaceholder, CyclePagination, StatusLegend } from '@aon/cycle'
import DocumentClaimsDetails from './DocumentClaimsDetails'
import schadeOverview from './gql/schadeOverview.gql'

export default {
  name: 'MyDamages',
  components: {
    BusyIndicator,
    EmptyTablePlaceholder,
    DocumentClaimsDetails,
    CyclePagination,
    StatusLegend
  },
  filters: {
    asCycleStatus(isSettled) {
      return isSettled ? 'confirmed' : 'in-progress'
    }
  },
  inject: ['dictionaryService'],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selected: [],
    claims: undefined,
    isLoading: 1,
    numberOfLinesOnPage: 0,
    pageNumber: 1,
    totalNumberOfLines: 0,
    queryString: ''
  }),
  methods: {
    handlePageChange(newPage) {
      this.isLoading++
      this.pageNumber = newPage
    },
    updateVisibilityOfDetail(index) {
      if (this.selected.includes(index)) {
        this.selected.splice(this.selected.indexOf(index), 1)
      } else {
        this.selected.push(index)
      }
    },
    openDetails(index) {
      if (this.selected.includes(index)) {
        return this.isOpen
      }

      return ''
    },
    translatedStatus(claim) {
      return this.dictionaryService?.phrases[
        `generic-status-${this.$options.filters.asCycleStatus(claim.isSettled)}`
      ]
    },
    scDateToJs(dateString = null) {
      if (dateString == null) {
        return null
      }
      const date = new Date(`${dateString}Z`)
      if (date.getFullYear() === 1) {
        return null
      }
      return date.toISOString().split('T')[0]
    }
  },
  apollo: {
    claims: {
      prefetch: false, // Not prefetching because we don't have authentication
      query: schadeOverview,
      variables() {
        const endDate = this.scDateToJs(this.fields?.ClaimsTo?.value)
        const startDate = this.scDateToJs(this.fields?.ClaimsFrom?.value)
        const currentYear = new Date().getFullYear()
        return {
          inputModel: {
            startDate: startDate || '1970-01-01',
            endDate: endDate || `${currentYear}-12-31`,
            numberOfLinesOnPage: this.fields?.schadesPerPage || '10',
            pageNumber: `${this.pageNumber}`
          }
        }
      },
      error(e) {
        console.error(e)
        this.isLoading--
        this.totalNumberOfLines = schadeOverview?.result?.totalNumberOfLines
        this.numberOfLinesOnPage = schadeOverview?.result?.numberOfLinesOnPage
        return []
      },
      update({ schadeOverview }) {
        this.isLoading--
        this.totalNumberOfLines = schadeOverview?.result?.totalNumberOfLines
        this.numberOfLinesOnPage = schadeOverview?.result?.numberOfLinesOnPage
        schadeOverview?.items?.map((item) => {
          item.target = item.actionLinks[0]?.target
          item.method = item.actionLinks[0]?.method?.Method
          if (item.actionLinks[0]?.queryString && item.actionLinks[0]?.linkType) {
            if (item.method === 'POST') {
              let queryStringObject = {}
              try {
                queryStringObject = JSON.parse(item.actionLinks[0]?.queryString)
                item.params = []
                for (const k of Object.keys(queryStringObject)) {
                  item.params.push([k.toLowerCase(), queryStringObject[k]])
                }
              } catch (e) {
                console.error('invalid json:', e)
              }

              item.detailsUrl = item.actionLinks[0]?.href || ''
              item.detailsText = this.fields.cedDetailsText.value
            } else {
              item.detailsUrl = `${this.fields?.cedDemageDetailPage.url}${item.actionLinks[0]?.queryString}`
              item.detailsText = this.fields.cedDetailsText.value
            }
            item.detailTextExists = true
          } else {
            item.detailTextExists = false
            item.detailsText = this.fields.noCedDetailsText.value
          }
        })
        return schadeOverview?.items
      }
    }
  }
}
</script>
