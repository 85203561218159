<template>
  <CycleSubGrid :modifiers="buttonsClass">
    <template v-for="(grid, index) in gridPlaceholder">
      <component :is="grid" :key="`grid${index}`" />
    </template>
  </CycleSubGrid>
</template>

<script>
import { CycleSubGrid } from '@aon/cycle'

export default {
  name: 'SubGridSitecore',
  components: { CycleSubGrid },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  withPlaceholder: {
    // you can alias the computed prop name for the placeholder or pass an array of placeholders
    placeholders: {
      placeholder: 'jss-grid',
      computedPropName: 'gridPlaceholder'
    }
  },
  computed: {
    buttonsClass() {
      return this.params?.IsForButtons ? 'buttons' : undefined
    }
  }
}
</script>
