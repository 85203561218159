<template>
  <LicensePlate :link="link" :placeholder="placeholder" :append-query-string="appendQueryString" />
</template>

<script>
import { LicensePlateSitecore } from '@aon/cycle'

export default {
  name: 'LicensePlateSitecore',
  components: {
    LicensePlate: LicensePlateSitecore
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    link() {
      return this.fields.link?.value
    },
    placeholder() {
      return this.fields.placeholderText?.value
    },
    appendQueryString() {
      return this.fields?.appendQueryString?.value
    }
  }
}
</script>
