import { set } from 'lodash'
import { getSubmitContext, submitForm, formatResponse } from '../services/FormService'
import {
  SET_FORM_DEFINITION_ID,
  SET_ACTIVE_STEP_BY_INDEX,
  SET_FORM_DEFINITION,
  SET_FORM_FIELD_VALUE,
  SET_FORM_FIELD_VALUES,
  SET_FORM_STATE,
  SET_BUSINESS_DATA,
  CLEAR_BUSINESS_DATA_VALUE,
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_ACTIVE_FIELD,
  REMOVE_ACTIVE_FIELD,
  SET_VALIDATION_STATUS,
  SET_ERROR_ELEMENTS,
  SET_INVALID_ELEMENTS,
  SET_COMMUNICATION_START,
  SET_COMMUNICATION_FAILURE,
  SET_COMMUNICATION_SUCCESS,
  SET_COMMUNICATION_BLOCK_UI_LOADER,
  SET_FORM_DATA_FORMATTED,
  CLEAR_ERRORS_BY_KEY,
  CLEAR_SUCCESS_BY_KEY,
  SET_PREVIOUS_STEP,
  SET_TOUCHED_ELEMENT,
  RESET_COMMUNICATION_STATE,
  SET_ANALYTICS_DATA
} from './mutation-types'

import { submitContext, loadContext } from '../constants/api'
import { logWarning } from '@/services/logging'
import { prefillFields } from '../constants/prefill'
import { lazyLoadDataLayer } from '@/services/gtm'
import { evaluateExpression } from '../services/ExpressionService'
import { expressionScope } from '../constants/expressions'

export const actions = {
  updateFormStateFromHistory({ commit, getters }, { formState }) {
    const newStepIndex = getters.steps.findIndex(
      (element) => element.step === formState.RFPState.step
    )
    if (getters.activeStepIndex < newStepIndex) {
      window?.history.back()
    } else {
      commit(SET_FORM_STATE, formState.RFPState)
    }
  },

  initFormDefinition({ commit, dispatch }, { formDefinition }) {
    commit(SET_FORM_DEFINITION_ID, { formDefinitionId: formDefinition.id })
    commit(SET_FORM_DEFINITION, formDefinition)
    commit(SET_FORM_DATA_FORMATTED, formDefinition.formDataFormatted)
    commit(RESET_COMMUNICATION_STATE)
    dispatch('prefillForm')
  },
  async prefillForm({ rootGetters, getters, dispatch }) {
    let fieldValue
    let fieldObject
    for (const item of prefillFields) {
      fieldValue = rootGetters[item.value]
      fieldObject = getters['fields'].find((field) => field.key == item.fieldKey)
      if (fieldObject && fieldValue) {
        await dispatch('updateField', { fieldKey: fieldObject.key, value: fieldValue })
        await dispatch('handleFieldTouch', { fieldObject })
      }
    }
  },
  updateField({ commit }, { fieldKey, value }) {
    commit(SET_FORM_FIELD_VALUE, { fieldKey, value })
  },
  clearBusinessDataValue({ commit }, { fieldKey }) {
    commit(CLEAR_BUSINESS_DATA_VALUE, { fieldKey })
  },
  async submitData(
    { commit, state, getters },
    { elementKey, submitContext, blockUI, blockUiLoader, blockUiLoaderText }
  ) {
    commit(CLEAR_ERRORS)
    const previousRequest = state.communication[elementKey]
    if (previousRequest?.cancelFunction) {
      previousRequest.cancelFunction(`submitData: cancelled previous request ${elementKey}`)
    }
    let result
    try {
      const { cancelFunction, response } = submitForm(
        state.formDefinitionId,
        submitContext.path,
        submitContext.action,
        false,
        elementKey,
        state.formState,
        state.formData,
        state.businessData
      )

      // Set Block UI Loader with custom animated image for group/fields (If interaction.blocking is true/false)
      commit(SET_COMMUNICATION_BLOCK_UI_LOADER, {
        loadUrl: blockUiLoader || null,
        loadText: blockUiLoaderText || null
      })

      commit(SET_COMMUNICATION_START, {
        key: elementKey,
        cancelFunction,
        blockUI,
        promise: response
      })

      result = await response
    } catch (ex) {
      logWarning('Failed to submit data: ', elementKey, ex.message)
      commit(SET_COMMUNICATION_BLOCK_UI_LOADER, { loadUrl: null, loadText: null })
      commit(SET_COMMUNICATION_FAILURE, { key: elementKey, reason: ex.message })
      result = formatResponse(ex.response)
    } finally {
      if (result) {
        const { definition, formData, formState, businessData, errors, formDataFormatted } = result
        if (definition) {
          commit(SET_FORM_DEFINITION, { definition })
        }
        commit(SET_FORM_FIELD_VALUES, formData)
        commit(SET_BUSINESS_DATA, businessData)
        commit(SET_ERRORS, errors)
        commit(SET_PREVIOUS_STEP, getters.activeStepIndex)
        commit(SET_FORM_STATE, formState)
        commit(SET_FORM_DATA_FORMATTED, formDataFormatted)

        if (!state.communication[elementKey]?.isRejected) {
          commit(SET_COMMUNICATION_SUCCESS, { key: elementKey })
        }
      }
    }
  },
  async loadData({ commit, state, getters }, { elementKey, loadContext }) {
    const key = `${elementKey}`
    if (!loadContext.regenerateDefinition) {
      commit(CLEAR_ERRORS)
    }
    let result
    try {
      const { cancelFunction, response } = submitForm(
        state.formDefinitionId,
        loadContext.path,
        loadContext.action,
        loadContext.regenerateDefinition,
        elementKey,
        state.formState,
        state.formData,
        state.businessData
      )
      if (loadContext.regenerateDefinition || getters.isProductLoadedAtInitialStep) {
        commit(SET_COMMUNICATION_BLOCK_UI_LOADER, { loadUrl: null, loadText: null })
        commit(SET_COMMUNICATION_START, { key, cancelFunction })
      }
      result = await response
      if (result) {
        const { businessData, errors, definition } = result
        if (definition) {
          commit(SET_FORM_DEFINITION, { definition })
        }
        commit(SET_BUSINESS_DATA, businessData)
        commit(SET_ERRORS, errors)
      }

      commit(SET_COMMUNICATION_SUCCESS, { key })
    } catch (ex) {
      logWarning('Failed to load data: ', ex.message)
      commit(SET_COMMUNICATION_FAILURE, { key, reason: ex.message })
    }
  },
  async loadStepData({ getters, dispatch }, { regenerateDefinition }) {
    const activeStep = getters.activeStep

    if (!activeStep?.key) {
      return
    } else if (
      activeStep.loadDataFromServer &&
      (!getters.prevStepIndex ||
        getters.activeStepIndex > getters.prevStepIndex ||
        regenerateDefinition)
    ) {
      regenerateDefinition = regenerateDefinition && !getters.isProductLoadedAtInitialStep
      await dispatch('loadData', {
        elementKey: activeStep.key,
        loadContext: { ...loadContext.step, regenerateDefinition }
      })
    }
    if (activeStep.loadAnalytics) {
      await dispatch('setDataLayer', { analyticsObject: activeStep.loadAnalytics })
    }
    dispatch('updateBrowserHistory')
  },
  /**
   * @param commit
   * @param state
   * @param analyticsData
   * @description parses analytics object and push to the data layer.
   */
  setDataLayer({ commit, state }, { analyticsObject }) {
    if (analyticsObject == undefined || analyticsObject.analyticsData == undefined) {
      return
    }

    const analyticsData = {
      event: {
        value: analyticsObject.eventName,
        jsExpression: !!analyticsObject.eventNameIsJsExpression
      },
      ...analyticsObject.analyticsData
    }

    const processedAnalyticsData = Object.entries(analyticsData).reduce((acc, [key, value]) => {
      if (!value || !('value' in value)) {
        return acc
      }

      if (!!value.enableIf && !evaluateExpression(state, expressionScope, value.enableIf)) {
        return acc
      }

      const propertyObject = { ...acc }
      const calculatedValue = value.jsExpression
        ? evaluateExpression(state, expressionScope, value.value)
        : value.value

      set(propertyObject, key, calculatedValue)

      return propertyObject
    }, {})

    commit(SET_ANALYTICS_DATA, processedAnalyticsData)
    lazyLoadDataLayer()?.push({
      ...processedAnalyticsData
    })
  },
  updateStep({ commit, getters }, selectedStep) {
    commit(SET_PREVIOUS_STEP, getters.activeStepIndex)
    commit(SET_ACTIVE_STEP_BY_INDEX, selectedStep)
  },
  showPrevStep({ commit, getters }) {
    commit(SET_PREVIOUS_STEP, getters.activeStepIndex)
    commit(SET_ACTIVE_STEP_BY_INDEX, getters.activeStepIndex - 1)
  },
  setActiveField({ commit }, fieldKey) {
    commit(SET_ACTIVE_FIELD, fieldKey)
  },
  removeActiveField({ commit }, fieldKey) {
    commit(REMOVE_ACTIVE_FIELD, fieldKey)
  },
  setValidationStatus({ commit }, validationStatus) {
    commit(SET_VALIDATION_STATUS, validationStatus)
  },
  setErrorElements({ commit }, errorElements) {
    commit(SET_ERROR_ELEMENTS, errorElements)
  },
  setInvalidElements({ commit }, invalidElements) {
    commit(SET_INVALID_ELEMENTS, invalidElements)
  },
  /**
   *
   * @param dispatch
   * @param fieldObject could be of formDefinition item: step | field | fieldSet | fieldGroup
   * @description checks on blur of each field if we should send data to BE and making sure the correct values are send to build up the REST endpoint in the service.
   */
  async handleFieldTouch({ dispatch, commit, state }, { fieldObject }) {
    commit(SET_TOUCHED_ELEMENT, fieldObject.key)
    const expressionFlag = fieldObject.dontSendIf
      ? evaluateExpression(state, expressionScope, fieldObject.dontSendIf)
      : false
    if (!fieldObject.sendToServer || expressionFlag === true) {
      return
    }
    await dispatch('clearErrorsByKey', fieldObject)
    let dispatcher
    switch (getSubmitContext(fieldObject)) {
      case submitContext.fieldGroup:
        dispatcher = 'submitFieldGroup'
        break
      default:
        dispatcher = 'submitField'
    }
    await dispatch(dispatcher, { fieldObject })
  },
  async submitFieldGroup({ dispatch, state }, { fieldObject }) {
    const FIELD_GROUP = submitContext.fieldGroup.path
    const fieldGroupKey = fieldObject[FIELD_GROUP]
    const fieldGroup = state.fieldGroups.find((group) => group.key == fieldGroupKey)
    const isInvalid = state.fields
      .filter((f) => f.fieldGroup === fieldGroupKey)
      .map((f) => f.key)
      .reduce((acc, curr) => state.formState.invalidElements.includes(curr) || acc, false)
    const blockUiLoader = fieldGroup.interaction?.blocking
      ? fieldGroup.loader?.url
      : fieldObject.interaction?.blocking
      ? fieldObject.loader?.url
      : null
    const blockUiLoaderText = fieldGroup.interaction?.blocking
      ? fieldGroup.loader?.text
      : fieldObject.interaction?.blocking
      ? fieldObject.loader?.text
      : null
    const blockUI = fieldGroup.interaction?.blocking || fieldObject.interaction?.blocking || false

    if (!isInvalid) {
      await dispatch('submitData', {
        elementKey: fieldObject[FIELD_GROUP],
        submitContext: submitContext[FIELD_GROUP],
        blockUI: blockUI,
        blockUiLoader,
        blockUiLoaderText
      })
      await dispatch('setDataLayer', { analyticsObject: fieldGroup?.saveAnalytics })
    }
  },
  async submitField({ dispatch, state }, { fieldObject }) {
    const submitPath = 'field'
    const keyType = 'key'
    const isInvalid = state.formState.invalidElements.includes(fieldObject[keyType])
    const elementKey = fieldObject[keyType]
    const blockUI = fieldObject.interaction?.blocking || false
    const blockUiLoader = fieldObject.loader?.url || null
    const blockUiLoaderText = fieldObject.loader?.text || null

    if (fieldObject.sendOnClick || !isInvalid) {
      await dispatch('submitData', {
        elementKey,
        submitContext: submitContext[submitPath],
        blockUI,
        blockUiLoader,
        blockUiLoaderText
      })
      await dispatch('setDataLayer', { analyticsObject: fieldObject?.saveAnalytics })
    }
  },
  async submitStep({ state, dispatch }, { elementKey }) {
    const step = state.steps.find((s) => s.key === elementKey)

    await dispatch('setDataLayer', { analyticsObject: step?.saveAnalytics })
    await dispatch('submitData', { elementKey, submitContext: submitContext.step })
  },
  updateBrowserHistory({ getters }) {
    if (typeof window === 'undefined') {
      return
    }
    const currentHistoryState = window?.history?.state?.RFPState

    if (currentHistoryState && currentHistoryState.step != getters.formState.step) {
      window?.history.pushState({ RFPState: getters.formState }, getters.formState.step)
    } else {
      window?.history.replaceState({ RFPState: getters.formState }, getters.formState.step)
    }
  },
  clearErrorsByKey({ commit, state }, fieldObject) {
    const serviceErrorItems = Object.keys(state.errors).filter((item) => {
      return item.includes(fieldObject.fieldGroup) || item.includes(fieldObject.key)
    })
    commit(CLEAR_ERRORS_BY_KEY, serviceErrorItems)
  },
  clearSuccessByKey({ commit }, fieldObject) {
    commit(CLEAR_SUCCESS_BY_KEY, fieldObject)
  },
  async settleCommunication({ state }) {
    const promises = Object.values(state.communication)
      .filter((com) => com.isPending)
      .map((com) => com.promise)

    try {
      await Promise.all(promises)
      // eslint-disable-next-line
    } catch {}
  }
}
