<template>
  <div>
    <template v-for="animation in animations">
      <component
        v-if="isShown(animation)"
        :is="getComponent(animation.animationType)"
        :options="animation.details"
        :canvas-id="`canvas_${animation.animationType}_${stepKey}`"
      />
    </template>
  </div>
</template>

<script>
import { logWarning } from '@/services/logging'
import FireWorks from './FireWorks'

export default {
  name: 'Animations',
  components: {
    FireWorks
  },
  props: {
    animations: {
      type: Array,
      required: false,
      default: () => ({})
    },
    stepKey: {
      type: String,
      required: false
    },
    storeData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    properties: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  methods: {
    getComponent(cName) {
      if(cName === 'fireworks') {
        return FireWorks
      }
      return
    },
    isShown(animation) {
      const expression = animation.showIf
      // If showIf is empty/not set (return true as default value)
      if(!expression) {
        return true
      }
      return this.evaluateExpression(expression)
    },
    evaluateExpression(expression) {
      try {
        // Function to return Boolean, if Dynamic Expression String (From SC) matches with properties or not
        const expressionAsFunction = new Function(...this.properties, `return ${expression}`)
        // Format store data into array of object (indexed) [0: {}, 1: {}]
        const args = this.properties.reduce((acc, current) => [...acc, this.storeData[current]], [])
        // Return Boolean based on expression check
        return expressionAsFunction(...args) ?? false
      } catch (ex) {
        logWarning(`ExpressionService:evaluateExpression: ${expression}, ${ex.message || ex}`)
      }

      return null
    }
  }
}
</script>