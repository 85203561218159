import config from '@/temp/config'

export const confirmationStepKey = 'confirmation'
export const defaultApiEndpoint = `${config.sitecoreApiHost}/forms`
export const defaultUrlContext = 'insurance'
export const submitContext = {
  step: {
    path: 'step',
    action: 'save'
  },
  field: {
    path: 'field',
    action: 'save'
  },
  fieldGroup: {
    path: 'fieldGroup',
    action: 'save'
  }
}

export const loadContext = {
  step: {
    path: 'step',
    action: 'load'
  }
}
