<template>
  <div class="modaltooltip">
    <a :class="tooltipClassList" @click.prevent="showModal" tabindex="1" v-if="hasContent">
      {{ hasTooltipLabelText ? tooltipLinkText.text : linkText }}
    </a>
    <Modal
      ref="modal"
      v-show="isModalOpen"
      :is-shown="isModalVisible"
      :tooltip-has-long-text="tooltipHasLongText"
      @close="closeModal"
    >
      <slot v-for="slot in slotKeys" :name="slot" :slot="slot" />
      <template v-if="cta" #footer>
        <CycleButtonLink :href="cta.href" modifiers="secondary" :target="cta.target">
          {{ cta.text }}
        </CycleButtonLink>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import CycleButtonLink from '@/components/cycle-link/CycleButtonLink'

export default {
  name: 'ModalTooltip',
  components: {
    Modal,
    CycleButtonLink
  },
  props: {
    linkText: {
      type: String,
      required: false,
      default: ''
    },
    cta: {
      type: Object,
      required: false
    },
    tooltipHasLongText: {
      type: Boolean,
      required: false
    },
    tooltipLinkText: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  computed: {
    tooltipClassList() {
      return {
        'tooltip': !this.hasTooltipLabelText,
        'tooltip--tooltip-link-text-active': this.hasTooltipLabelText && this.isModalVisible,
        [`tooltip--tooltip-link-text icon icon--${this.tooltipLinkText?.icon}`]: this.hasTooltipLabelText
      }
    },
    hasContent() {
      return (
        this.$slots['tooltip-content']?.length > 0 ||
        this.$slots['tooltip-heading']?.length > 0 ||
        this.linktext?.length > 0
      )
    },
    slotKeys() {
      return Object.keys(this.$slots)
    },
    isModalOpen() {
      return this.tooltipHasLongText ? true : this.isModalVisible
    },
    hasTooltipLabelText() {
      return !!this.tooltipLinkText?.text
    }
  },
  methods: {
    async showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    }
  }
}
</script>
