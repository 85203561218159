export const expressionScope = [
  'fields',
  'formState',
  'formData',
  'formDataFormatted',
  'businessData',
  'invalidElements',
  'errorElements',
  'errors',
  'activeStep'
]
