<template>
  <div class="coreui">
    <h1 class="heading">Server Error</h1>
    <div class="coreui__main">
      <section class="grid">
        <div class="panel panel--centered">
          <div class="panel__body">
            <p>Something went wrong with the connection to the server.</p>
            <p v-if="context && context.site">
              Site: {{ context.site.name }}
              <br />
              Language: {{ context.language }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo() {
    return {
      title: 'Page not found'
    }
  },
  props: {
    context: {
      type: Object
    }
  }
}
</script>
