import {
  CLEAR_RAPIDFORM_STORE,
  CLEAR_MYZONE_STORE,
  CLEAR_AUTHENTICATION_STORE
} from './mutation-types'

export default {
  [CLEAR_RAPIDFORM_STORE]: (state: any) => {
    delete state.rapidFormPlayer
  },
  [CLEAR_MYZONE_STORE]: (state: any) => {
    delete state.myZoneStore
  },
  [CLEAR_AUTHENTICATION_STORE]: (state: any) => {
    delete state.authentication
  }
}
