<template>
  <ContentToggle
    :open-action-text="openActionText"
    :close-action-text="closeActionText"
    :button-background="buttonBackground"
    :is-plain="isPlain"
    :is-default-open="isEditableInEE"
  >
    <template #hidden-content>
      <component v-for="(comp, index) in jssContent" :is="comp" :key="`toggleComponent${index}`" />
    </template>
  </ContentToggle>
</template>

<script>
import { ContentToggle } from '@aon/cycle'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import jssMixin from '@/services/jss-mixin'

export default {
  name: 'ContentToggleSitecore',
  components: {
    ContentToggle,
    ScPlaceholder: Placeholder
  },
  mixins: [jssMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  withPlaceholder: {
    placeholders: [
      {
        placeholder: 'jss-content',
        computedPropName: 'jssContent'
      }
    ]
  },
  computed: {
    openActionText() {
      return this.fields.openActionText?.value
    },
    closeActionText() {
      return this.fields.closeActionText?.value
    },
    buttonBackground() {
      return this.fields.buttonBackground?.value
    },
    isPlain() {
      return this.fields.isPlain?.value
    }
  }
}
</script>
