<template>
  <div v-if="portfolioInfo">
    <CycleHeading :weight="2" :modifiers="['centered']" v-if="textContent.title">{{
      textContent.title
    }}</CycleHeading>
    <br />
    <p v-if="!!textContent.subTitle">{{ textContent.subTitle }}</p>
    <ContentToggle
      is-plain
      :is-default-open="isDefaultOpen"
      :open-action-text="textContent.showDetails"
      :close-action-text="textContent.hideDetails"
    >
      <MyInsurancesList :portfolio-info="portfolioInfo || {}" />
    </ContentToggle>
  </div>
  <BusyIndicator v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MyInsurancesList, ContentToggle, CycleHeading, BusyIndicator } from '@aon/cycle'
import { mapValues, isEmpty } from 'lodash'

export default {
  name: 'CycleMyInsurancesListSitecore',
  components: {
    MyInsurancesList,
    ContentToggle,
    CycleHeading,
    BusyIndicator
  },
  provide() {
    return {
      statusLabels: this.statusLabels,
      textContent: this.textContent,
      links: this.links
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    links() {
      return Object.entries(this.fields)
        .filter(([key]) => /^link/.test(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value.value || {} }), {})
    },
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    statusLabels() {
      return (
        this.fields.statusLabels?.value || {
          statusInProgress: 'In behandeling',
          statusSuspended: 'Tijdelijk stopgezet',
          statusOngoing: 'Lopend',
          statusCancelled: 'Vervallen'
        }
      )
    },
    isDefaultOpen() {
      return this.params?.isDefaultOpen > 0
    },
    dataSource() {
      return this.rendering.dataSource
    },
    portfolioInfo() {
      return this.getPortfolioInfo({
        dataSourceId: this.dataSource,
        locale: this.currentLanguage,
        clientIdentifier: this.clientIdentifier
      })
    },
    ...mapGetters(['getPortfolioInfo', 'documents', 'clientIdentifier']),
    ...mapGetters('siteSettings', ['currentLanguage'])
  },
  created() {
    if (!this.portfolioInfo) {
      this.fetchPortfolioInfo({
        dataSourceId: this.dataSource,
        locale: this.currentLanguage,
        overviewPage: true
      })
    }

    if (isEmpty(this.documents)) {
      this.fetchDocuments()
    }
  },
  methods: {
    ...mapActions(['fetchPortfolioInfo', 'fetchDocuments'])
  }
}
</script>
