<template>
  <CycleProductCard
    :heading="heading"
    :icon="icon"
    :bodytext="bodytext"
    :button-list="buttonList"
  />
</template>

<script>
import { ProductCard as CycleProductCard } from '@aon/cycle'

export default {
  name: 'ProductCard',
  components: {
    CycleProductCard
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    heading() {
      return this.fields.heading?.value
    },
    icon() {
      return this.fields.icon?.value
    },
    bodytext() {
      return this.fields.bodytext?.value
    },
    buttonList() {
      return this.fields.button?.value ?? []
    }
  }
}
</script>
