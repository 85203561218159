<template>
  <div v-if="editable">
    <span>Portal zone: {{ to }}</span>
    <ScPlaceholder name="jss-portal" :rendering="rendering" />
  </div>
  <div v-else>
    <portal :to="to"><slot /><ScPlaceholder name="jss-portal" :rendering="rendering" /></portal>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'PortalSitecore',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    editable() {
      return this.$jss?.sitecoreContext().pageEditing
    },
    to() {
      return this.fields.to?.value
    }
  }
}
</script>
