<template>
  <CycleFormSet v-if="isShown" :subtext="subtext" :modifiers="modifiers">
    <template #heading>
      <slot name="heading" />
    </template>
    <div v-if="showCols" class="fieldset__columns">
      <slot />
    </div>
    <template v-else-if="isCheckout">
      <div class="checkout-item" :class="{ 'checkout-item--selected': bordered }">
        <slot />
      </div>
    </template>
    <slot v-else />
  </CycleFormSet>
</template>

<script>
import { CycleFormSet, CycleCheckout } from '@aon/cycle'
import { mapGetters } from 'vuex'
import { dynamicExpressionFactory } from '../../../mixins/DynamicExpression'
import {
  fieldDynamicExpressionProps,
  dynamicExpressionPropsGetters
} from '../RapidFormField/RapidFormField.constants'

export default {
  name: 'RapidFormFieldSet',
  components: {
    CycleFormSet,
    CycleCheckout
  },
  mixins: [dynamicExpressionFactory('isShown', 'showIf', fieldDynamicExpressionProps, true)],
  props: {
    subtext: {
      type: String,
      required: false
    },
    showFieldsAsLabels: {
      type: Boolean,
      required: false
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [...dynamicExpressionPropsGetters, 'getFields']),
    showCols() {
      return this.$slots.default && this.$slots.default.length > 1 && this.showFieldsAsLabels
    },
    isCheckout() {
      return this.modifiers.includes('checkout')
    },
    bordered() {
      return !!this.getFields(this.$vnode.key).find(this.hasCheckedCheckout)
    }
  },
  methods: {
    hasCheckedCheckout(field) {
      return (
        (field.type === 'Checkbox' &&
          field.modifiers?.includes('checkout') &&
          this.formData[field.key] === true) ||
        field.type === 'BaseProducts'
      )
    }
  }
}
</script>
