//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleButtonLink } from '@/components/cycle-link'
import { CycleIcon } from '@/components/elements'
import { CycleButton } from '../elements/CycleButton'

export default {
  name: 'LoginContainer',
  data(){
    return {
      showCustomerDetails: false
    }
  },
  components: {
    CycleHtml,
    CycleButtonLink,
    CycleIcon,
    CycleButton
  },
  props: {
    linkIntroText: {
      type: String,
      default: ''
    },
    link: {
      type: Object,
      default: () => {}
    },
    showNewCustomerLink: {
      type: Boolean,
      default: false
    },
    customerLinkIntroText: {
      type: String,
      default: ''
    },
    customerLinkText: {
      type: String,
      default: ''
    },
    customerLinkToggleText: {
      type: String,
      default: ''
    },
    customerDetailsFieldValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonUrl() {
      let url =
        this.link?.href?.replace(
          '##returnUrl##',
          typeof window !== 'undefined' ? window.location.pathname + window.location.search : ''
        ) || ''

      if (url && !url.includes('jli=1')) {
        url = url.includes('?') ? url + '&jli=1' : url + '?jli=1'
      }
      return url
    },
    buttonTarget() {
      return this.link?.target || ''
    },
    buttonText() {
      return this.link?.text || ''
    },
    customerToggleText() {
      return this.showCustomerDetails ? this.customerLinkToggleText : this.customerLinkText
    },
    introText() {
      return this.showCustomerDetails ? this.customerLinkIntroText : this.linkIntroText
    },
    addCustomerClass() {
      return this.showCustomerDetails ? '' : 'btn--customer-login'
    }
  },
  mounted() {
    this.showCustomerDetails = this.customerDetailsFieldValue
  },
  methods: {
    showHideCustomerDetails() {
      this.showCustomerDetails = !this.showCustomerDetails
      this.$emit('input', this.showCustomerDetails)
    }
  }
}
