<template>
  <div class="form__field form__field--survey">
    <CycleHtml v-if="description" :html="description" />
    <CycleInputContainer>
      <CycleCheckboxInput
        :name="name"
        :label="labels.checkbox"
        must-be-checked
        @change="handleChange"
        :value="true"
        :validation-path="validationPath"
      />
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleCheckboxInput } from '@/components/elements'
import { CycleHtml } from '@/components/base'
import { CycleInputContainer } from '@/components/functional/forms'

import { QuestionMixin } from './QuestionMixin'
import { EmptyAnswer, CheckboxAnswer } from '../models'

export default {
  name: 'QuestionCheckBox',
  components: {
    CycleCheckboxInput,
    CycleHtml,
    CycleInputContainer
  },
  mixins: [QuestionMixin],
  props: {
    labels: {
      type: Object,
      required: true,
      default: () => ({
        checkbox: 'Ik ga akkoord'
      })
    },
    value: {
      type: Object,
      required: true,
      default: () => new EmptyAnswer()
    }
  },
  methods: {
    handleChange(boxesChecked) {
      this.$emit('input', new CheckboxAnswer(boxesChecked.length > 0))
    }
  }
}
</script>
