//
//
//
//

import CycleCard from '../card/CycleCard'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { CycleHeaderPanel } from '../cycle-header-panel'

const SPACE = ' '

export default {
  name: 'CycleHeroCardMyzone',
  components: { CycleCard, CycleHeading, CycleHeaderPanel },
  props: {
    nameData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    heading: {
      type: String,
      default: 'Welkom'
    },
    emptyName: {
      type: String,
      default: ''
    }
  },
  computed: {
    fullName() {
      const { initials, prefix, lastName } = this.nameData
      return [initials, prefix, lastName].filter((value) => !!value).join(SPACE) || this.emptyName
    }
  }
}
