export const labels = [
  'heading',
  'intro',
  'zipcodeLabel',
  'zipcodeTooltip',
  'housenumberLabel',
  'housenumberTooltip',
  'streetLabel',
  'streetTooltip',
  'cityLabel',
  'cityTooltip',
  'moveDateLabel',
  'moveDateTooltip',
  'nextButtonLabel',
  'zipcodeCheckFailureText'
]
