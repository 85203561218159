<template>
  <div class="card card--author">
    <div v-if="isCardBodyVisible" class="card__body">
      <CycleHeading v-if="title" :weight="4">{{ title }}</CycleHeading>
      <p v-if="bodyText">{{ bodyText }}</p>
      <a
        v-if="authorPage.href"
        :href="authorPage.href"
        :target="authorPage.target"
        :title="authorPage.title"
        >{{ authorPage.text }}</a
      >
    </div>
    <div v-if="image.src" class="card__image">
      <a :href="authorPage.href">
        <img :src="image.src" :alt="image.alt" :title="imageTitle" />
      </a>
    </div>
  </div>
</template>

<script>
import { CycleHeading } from '@aon/cycle'

export default {
  name: 'BlogAuthor',
  components: {
    CycleHeading
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const authorPage = props.fields?.authorPage?.value ?? {}
    const bodyText = props.fields?.bio?.value
    const image = props.fields?.image?.value ?? {}
    const imageTitle = props.fields?.imageTitle?.value
    const title = props.fields?.intro?.value
    const isCardBodyVisible = title || bodyText || authorPage?.href
    return {
      authorPage,
      bodyText,
      image,
      imageTitle,
      isCardBodyVisible,
      title
    }
  }
}
</script>
