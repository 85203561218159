<template>
  <CycleForm>
    <CycleFormPage :on-submit="submitForm" :pre-funnel-button-text="link.title" is-pre-funnel>
      <CycleLicensePlateInput :placeholder="placeholder" v-model.trim="formData.licensePlate" />
    </CycleFormPage>
  </CycleForm>
</template>

<script>
import { CycleLicensePlateInput } from '@/components/elements/forms'
import { CycleForm, CycleFormPage } from '@/components/functional/forms'
import { CycleHtml } from '@/components/base'
import { storeModuleMixinFactory } from '@/mixins'
import store from '@/stores/profile'
import { LicensePlateQueryStringKey } from './constants'

export default {
  name: 'LicensePlateSitecore',
  components: {
    CycleLicensePlateInput,
    CycleForm,
    CycleFormPage,
    CycleHtml
  },
  mixins: [
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      actionsMapping: ['submitLicensePlate']
    })
  ],
  props: {
    link: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    appendQueryString: {
      type: Boolean
    }
  },
  provide() {
    return {
      shouldUseDefaultBrowserScrollBehaviour: true,
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        licensePlate: ''
      }
    }
  },
  methods: {
    async submitForm() {
      await this.submitLicensePlate(this.formData.licensePlate)
      if (!this.link?.href) {
        return
      }
      this.updateRoute()
    },
    updateRoute() {
      this.$router.push(this.newRoute)
    }
  },
  computed: {
    newRoute() {
      return this.shouldAppendQueryString ? this.queryStringAppendedUrl : this.link.href
    },
    unDashedLicensePlate() {
      return this.formData.licensePlate?.replace(/-/g, '')
    },
    isLicensePlateFilledIn() {
      return this.formData.licensePlate?.length > 0
    },
    shouldAppendQueryString() {
      return this.appendQueryString && this.isLicensePlateFilledIn
    },
    queryStringAppendedUrl() {
      return `${this.link.href}?${LicensePlateQueryStringKey}=${this.unDashedLicensePlate}`
    }
  }
}
</script>
