<template>
  <div :class="formParentClassList">
    <CycleImage v-if="hasPrependImage" :image="prependImageSrc" class="banner-image" />
    <div :class="formFieldClassList">
      <label v-if="hasSlotContent" class="form__label">
        <slot name="label"> {{ label }} </slot>
        <!-- tooltip icon for new toggle tooltip -->
        <a
          class="tooltip"
          @click.prevent="isToggled = !isToggled"
          tabindex="1"
          v-if="tooltip && hasToggleTooltip"
        ></a>
        <!-- Modal Tooltip -->
        <ModalTooltip
          v-if="showModalTooltip"
          :tooltip-has-long-text="tooltipHasLongText"
          :tooltip-link-text="tooltipLinkText"
        >
          <template #tooltip-heading>
            <slot name="tooltip-heading">{{ label }}</slot>
          </template>
          <template #tooltip-content>
            <slot name="tooltip-content">
              <CycleHtml :html="tooltip" />
              <template v-if="tooltipFeedback">
                <TooltipFeedback :subject="label" />
              </template>
            </slot>
          </template>
        </ModalTooltip>

        <!-- Popover Tooltip based on certain conditions-->
        <PopoverTooltip v-if="tooltip && showPopoverTooltip" :tooltip-link-text="tooltipLinkText">
          <template #tooltip-content>
            <slot name="tooltip-content">
              <CycleHtml :html="tooltip" />
              <template v-if="tooltipFeedback">
                <TooltipFeedback :subject="label" />
              </template>
            </slot>
          </template>
        </PopoverTooltip>
      </label>
      <p :class="subTextClassList" v-if="($slots.subtext || subtext) && renderList">
        <slot name="subtext">
          {{ subtext }}
        </slot>
      </p>
      <div :class="formInputClassList">
        <a v-if="anchorName" :name="anchorName" />
        <div v-if="hasPrepend || hasAppend" class="form__input-group">
          <div v-if="hasPrepend" class="form__input-group-addon form__input-prepend">
            <slot name="prepend"></slot>
          </div>
          <slot />
          <div v-if="hasAppend" class="form__input-group-addon form__input-append">
            <slot name="append"></slot>
          </div>
        </div>
        <template v-else>
          <slot />
        </template>
      </div>

      <p :class="fieldProgressMessageClass" v-if="isLoading">
        <CycleImage v-if="fieldLoader" :image="getFieldLoader" />
        <slot name="loading"> </slot>
      </p>
      <p class="form__successmessage" v-if="showSuccessMessage">
        <slot name="successMessage"> </slot>
      </p>
      <p class="form__errormessage" v-if="showErrorMessage">
        <slot name="errorMessage" />
      </p>
      <p :class="subTextClassList" v-if="($slots.subtext || subtext) && !renderList">
        <slot name="subtext">
          {{ subtext }}
        </slot>
      </p>
    </div>
    <!-- Toggle Tooltip based on certain conditions-->
    <ToggleTooltip v-if="showToggleTooltip" @close="isToggled = false">
      <template #tooltip-content>
        <slot name="tooltip-content">
          <CycleHtml :html="tooltip" />
          <template v-if="tooltipFeedback">
            <TooltipFeedback :subject="label" />
          </template>
        </slot>
      </template>
    </ToggleTooltip>
  </div>
</template>

<script>
import { CycleHtml, ModalTooltip, ToggleTooltip } from '@aon/cycle'
import PopoverTooltip from './molecules/PopoverTooltip'
import TooltipFeedback from './molecules/TooltipFeedback'

export default {
  name: 'CycleInputContainer',
  components: { ModalTooltip, ToggleTooltip, CycleHtml, PopoverTooltip, TooltipFeedback },
  inject: ['formErrors', 'pageName'],
  props: {
    label: {
      type: String,
      required: false
    },
    subtext: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    renderList: {
      type: Boolean,
      required: false
    },
    renderHorizontal: {
      type: Boolean,
      required: false
    },
    renderMinWidth: {
      type: Boolean,
      required: false
    },
    renderHalfWidth: {
      type: Boolean,
      required: false
    },
    renderHighlightedLabel: {
      type: Boolean,
      required: false
    },
    renderAddMarginTop: {
      type: Boolean,
      required: false
    },
    hasToggleTooltip: {
      type: Boolean,
      required: false
    },
    renderRemoveMarginsBottom: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showErrorMessage: {
      type: Boolean,
      default: false
    },
    inputsBelowLabel: {
      type: Boolean,
      default: false
    },
    showSuccessMessage: {
      type: Boolean,
      default: false
    },
    fieldLoader: {
      type: String,
      required: false
    },
    renderCompact: {
      type: Boolean,
      default: false
    },
    hasPopoverTooltip: {
      type: Boolean,
      required: false
    },
    prependImage: {
      type: Object,
      required: true,
      default: () => {}
    },
    tooltipHasLongText: {
      type: Boolean,
      required: false
    },
    tooltipLinkText: {
      type: Object,
      required: false,
      default: () => {}
    },
    tooltipFeedback: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      childComponents: [],
      isToggled: false,
      hasSlotContent: false
    }
  },
  computed: {
    formParentClassList() {
      const mainClassName = 'form__set'
      return {
        [`${mainClassName}--banner`]: this.hasPrependImage
      }
    },
    formFieldClassList() {
      const mainClassName = 'form__field'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--block`]: this.inputsBelowLabel,
        [`${mainClassName}--compact`]: this.renderCompact
      }
    },
    formInputClassList() {
      const mainClassName = 'form__inputs'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--list`]: this.renderList,
        [`${mainClassName}--horizontal`]: this.renderHorizontal,
        [`${mainClassName}--min-width`]: this.renderMinWidth,
        [`${mainClassName}--half-width`]: this.renderHalfWidth,
        [`${mainClassName}--highlightedlabel`]: this.renderHighlightedLabel,
        [`${mainClassName}--add-margin-top`]: this.renderAddMarginTop,
        [`${mainClassName}--remove-margins-bottom`]: this.renderRemoveMarginsBottom,
        [`${mainClassName}--prepend`]: this.hasPrepend,
        [`${mainClassName}--append`]: this.hasAppend
      }
    },
    subTextClassList() {
      const mainClassName = 'form__text'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--vertical`]: this.renderList
      }
    },
    anchorName() {
      if (!this.childComponents.length) {
        return null
      }
      return `form.${this.pageName}.${this.childComponents[0].name}`
    },
    fieldProgressMessageClass() {
      return this.fieldLoader ? 'form__fieldprogressmessage' : 'form__progressmessage'
    },
    getFieldLoader() {
      return {
        src: this.fieldLoader,
        width: 20
      }
    },
    showToggleTooltip() {
      return (this.tooltip && this.hasToggleTooltip && this.isToggled) ?? false
    },
    hasPrepend() {
      return !!this.$slots.prepend
    },
    hasAppend() {
      return !!this.$slots.append
    },
    showPopoverTooltip() {
      return (this.tooltip && this.hasPopoverTooltip) ?? false
    },
    showModalTooltip() {
      return (this.tooltip && !this.hasToggleTooltip && !this.showPopoverTooltip) ?? false
    },
    hasPrependImage() {
      return !!this.prependImage?.url
    },
    prependImageSrc() {
      return {
        src: this.prependImage?.url,
        alt: this.prependImage?.altText
      }
    }
  },
  beforeUpdate() {
    this.hasSlotContent = this.hasContent()
  },
  created() {
    this.hasSlotContent = this.hasContent()
  },
  methods: {
    registerChildComponent(component) {
      this.childComponents.push(component)
    },
    unRegisterChildComponent(component) {
      const componentIndex = this.childComponents.indexOf(component)

      if (componentIndex < 0) {
        return
      }

      this.childComponents.splice(componentIndex, 1)
    },
    hasContent() {
      let checkForContent = (content, node) => {
        return content || node.tag || (node.text && node.text.trim())
      }
      return (
        (this.$slots.default &&
          this.$slots.default.reduce(checkForContent, false) &&
          this.label != null) ||
        !!this.$slots.label ||
        !!(this.$scopedSlots.label && this.$scopedSlots.label())
      )
    }
  }
}
</script>
