<template>
  <CycleChangeAddress
    :postcode-found="postcodeFound"
    :on-submit="handleAddressSubmission"
    :fetch-address="fetchAddress"
  >
    <template slot="thank-you">
      <CycleHtml :html="thankYouText"></CycleHtml>
    </template>
    <template slot="confirmation-rejected">
      <CycleHtml :html="failureText"></CycleHtml>
    </template>
  </CycleChangeAddress>
</template>

<script>
import { size } from 'lodash'

import { currentCountryCode } from '@/services/i18n'
import { CycleChangeAddress } from '@/components/functional/forms'
import { CycleHtml } from '@/components/base'
import { jssMixin, labelsMixin } from '@/mixins'
import { labels } from './labels'
import getPersonalData from './getPersonalData'
import { logError } from '@/services/Logging'

export default {
  name: 'CycleChangeAddressSitecore',
  components: { CycleHtml, CycleChangeAddress },
  mixins: [jssMixin, labelsMixin(labels)],
  provide() {
    return {
      formData: this.formData
    }
  },
  props: {
    thankYouText: {
      type: String,
      required: false
    },
    failureText: {
      type: String,
      required: false,
      default: 'Probeer het later opnieuw.'
    }
  },
  data() {
    return {
      formData: {
        email: null,
        housenumber: null,
        housenumberAddition: null,
        zipcode: null,
        street: null,
        city: null,
        moveDate: null
      },
      postcodeFound: undefined
    }
  },
  beforeMount() {
    this.preload()
  },
  methods: {
    async preload() {
      const res = await this.gql.query(getPersonalData)
      this.formData.email = res.data.personalData.emailAddress
      this.formData.housenumber = res.data.personalData.residentialAddress.houseNumber
      this.formData.housenumberAddition = res.data.personalData.residentialAddress.houseNumberAddition
      this.formData.zipcode = res.data.personalData.residentialAddress.postalCode
      this.formData.street = res.data.personalData.residentialAddress.street
      this.formData.city = res.data.personalData.residentialAddress.town

      this.postcodeFound = size(this.formData.zipcode) > 0 && size(this.formData.street) > 0
    },
    handleAddressSubmission(formData) {
      const variables = {
        formValues: {
          startDate: formData.moveDate,
          address: {
            street: formData.street,
            houseNumber: formData.housenumber,
            houseNumberAddition: formData.housenumberAddition,
            postalCode: formData.zipcode,
            town: formData.city,
            countryCode: currentCountryCode()
          }
        }
      }
      return this.gql.mutationByName('changeAddress', variables)
    },
    fetchAddress(formData) {
      this.postcodeFound = true
      const queryName = 'addressFromPostalCodeAndHouseNumber'
      const { zipcode, housenumber } = formData

      return this.gql
        .queryByName(queryName, {
          inputModel: {
            postalCode: zipcode,
            houseNumber: housenumber
          }
        })
        .then((res) => {
          this.postcodeFound = false
          if (!res.data || !res.data[queryName]) {
            return
          }

          const { street, town: city } = res.data[queryName]
          if (street && city) {
            this.postcodeFound = true
            this.formData.street = street
            this.formData.city = city
          }
        })
        .catch(logError)
    }
  }
}
</script>
