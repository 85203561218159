<template>
  <div class="tile">
    <input
      type="radio"
      class="tile__toggle"
      v-on="{ change: updateValue }"
      :value="value"
      :checked="checked"
    />
    <label v-bind="{ for: $attrs.id }" class="tile__button" @click="updateValue" tabindex="0">
      <CycleIcon v-if="value" :modifiers="value" />
      <CycleHeading v-if="text">{{ text }}</CycleHeading>
    </label>
  </div>
</template>

<script>
import { CycleHeading, CycleIcon } from '@/components/elements'

export default {
  name: 'InputRadioTile',
  components: { CycleHeading, CycleIcon },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: () => ''
    }
  },
  computed: {
    checked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
</script>
