import gql from 'graphql-tag'

export const policyItemHomeOwner = gql`
  fragment policyItemHomeOwner on Policy {
    item {
      ... on Home {
        homeConfiguration {
          coverages {
            name
            active
          }
          options {
            glass
            extraDurableRecovery
            homeAutomation
            stormDamage
          }
        }
      }
    }
  }
`
