<template>
  <div v-if="active">
    <CycleHtml v-if="description" :html="description" />
    <CycleInputContainer>
      <CycleTextarea graphql-safe v-model="formData.finalQuestions[code]" :name="name" required />
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleHtml } from '../../../../../base'
import { CycleInputContainer } from '../../../CycleInputContainer'
import { CycleTextarea } from '../../../../../elements'
import { mixin } from './mixin.js'

export default {
  name: 'CycleFinalQuestionText',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleTextarea
  },
  mixins: [mixin]
}
</script>
