import config from '@/temp/config'
import axios from 'axios'

/**
 * Implements a data fetcher using Axios - replace with your favorite
 * SSR-capable HTTP or fetch library if you like. See HttpJsonFetcher<T> type
 * in sitecore-jss library for implementation details/notes.
 * @param {string} url The URL to request; may include query string
 * @param {any} data Optional data to POST with the request.
 */

export function dataFetcher(url, data, cancelToken = null) {
  return axios({
    url,
    method: data ? 'POST' : 'GET',
    data,
    // note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
    // which is necessary for analytics and such
    withCredentials: true,
    cancelToken,
    headers: {
      sc_apikey: config.sitecoreApiKey
    }
  })
}

export function dataFetcherWithCancel(url, data) {
  let cancelFunction = null
  const cancelToken = new axios.CancelToken((c) => {
    cancelFunction = c
  })

  return {
    cancelFunction,
    response: dataFetcher(url, data, cancelToken)
  }
}
