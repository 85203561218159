//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleButtonIconLink } from '@/components/cycle-link'

export default {
  name: 'ButtonsPanel',
  components: {
    CycleButtonIconLink
  },
  props: {
    links: { type: Array }
  },
  computed: {
    hasLinks() {
      return this.links?.length > 0
    }
  },
  methods: {
    href(item) {
      return item?.href
    },
    target(item) {
      return item?.target
    },
    modifier(item) {
      // set default icon-next icon for the new style header
      return item?.modifier || 'icon-next'
    },
    icon(item) {
      return item?.icon
    }
  }
}
