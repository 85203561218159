<template>
  <div class="jss-loading" v-if="visible" />
</template>

<script>
export default {
  props: {
    delay: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      visible: false
    }
  },
  created() {
    setTimeout(() => {
      this.visible = true
    }, this.delay)
  }
}
</script>
