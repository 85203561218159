<template>
  <PreFunnelTravel
    :single-family-type-text="fields.singleFamilyTypeText | ScString"
    :multiple-family-type-text="fields.multipleFamilyTypeText | ScString"
    :choose-family-type-text="fields.chooseFamilyTypeText | ScString"
    :link="fields.link | ScLink"
  />
</template>

<script>
import { TravelPreFunnel } from '@aon/cycle'
export default {
  name: 'TravelPreFunnel',
  components: {
    PreFunnelTravel: TravelPreFunnel
  },
  props: {
    fields: {
      type: Object
    }
  }
}
</script>
