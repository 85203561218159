//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  StatusIndicator,
  InformationLines,
  NamedInformation,
  DocumentsHeading,
  DocumentClaims
} from '../../atoms'
import { DocumentsItem } from '../../molecules'

export default {
  name: 'ClaimsTable',
  components: {
    DocumentClaims,
    DocumentsHeading,
    DocumentsItem,
    StatusIndicator,
    NamedInformation,
    InformationLines
  },
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
