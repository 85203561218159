//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleCookieToggle from '../../cycle-cookie-toggle/CycleCookieToggle'
import CycleHtml from '../../base/CycleHtml'
export default {
  name: 'CycleCookieToggleSitecore',
  components: {
    CycleHtml,
    CycleCookieToggle
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    acceptText: {
      type: String,
      required: false,
      default: ''
    },
    declineText: {
      type: String,
      required: false,
      default: ''
    }
  }
}
