//
//
//
//
//
//
//
//
//
//
//

import ContentToggle from './ContentToggle'
import { CycleCheckboxInput } from '@/components/elements/forms'

export default {
  name: 'ToggleCheckbox',
  components: { ContentToggle, CycleCheckboxInput },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    openActionText: {
      type: String,
      default: ''
    },
    closeActionText: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    }
  }
}
