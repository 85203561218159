<template>
  <div class="tooltip-feedback" data-tooltip-feedback-container="active" ref="feedbackContainer">
    <div class="tooltip-feedback__title">
      {{ getPhrase('tooltip-feedback-title', 'Heeft het antwoord u geholpen?') }}
    </div>
    <div class="tooltip-feedback__items">
      <span
        class="tooltip-feedback__item tooltip-feedback__item--positive"
        data-tooltip-feedback=""
        data-tooltip-feedback-value="positive"
        :data-tooltip-feedback-subject="subject"
        data-tooltip-feedback-type="thumb"
        data-tooltip-feedback-status=""
        :title="getPhrase('tooltip-feedback-label-positive', 'Duim omhoog')"
        @click="handleFeedback"
      >
        <span class="icon icon--thumbs-up"></span>
        <span class="icon icon--thumbs-up-solid icon--selected"></span>
      </span>
      <span class="tooltip-feedback__message">
        {{ getPhrase('tooltip-feedback-message-positive', 'Bedankt!') }}
      </span>
      <span
        class="tooltip-feedback__item tooltip-feedback__item--negative"
        data-tooltip-feedback=""
        data-tooltip-feedback-value="negative"
        :data-tooltip-feedback-subject="subject"
        data-tooltip-feedback-type="thumb"
        data-tooltip-feedback-status=""
        :title="getPhrase('tooltip-feedback-label-negative', 'Duim omlaag')"
        @click="handleFeedback"
      >
        <span class="icon icon--thumbs-up"></span>
        <span class="icon icon--thumbs-up-solid icon--selected"></span>
      </span>
      <span class="tooltip-feedback__message">
        {{
          getPhrase(
            'tooltip-feedback-message-negative',
            'Bedankt voor uw reactie! We doen ons best een beter antwoord te vinden.'
          )
        }}
      </span>
    </div>
  </div>
</template>
<script>
import { dictionaryMixin } from '@/mixins/Dictionary'
import { CycleIcon } from '@aon/cycle'
export default {
  name: 'TooltipFeedback',
  components: {
    CycleIcon
  },
  mixins: [dictionaryMixin],
  props: {
    subject: {
      type: String,
      default: 'fallBackTitle'
    }
  },
  methods: {
    handleFeedback(event) {
      const clickedElement = event.currentTarget
      const tooltipFeedbackContainer = this.$refs.feedbackContainer

      if (tooltipFeedbackContainer.getAttribute('data-tooltip-feedback-container') === 'active') {
        clickedElement.setAttribute('data-tooltip-feedback-status', 'selected')
        tooltipFeedbackContainer.setAttribute('data-tooltip-feedback-container', 'inactive')
      }
    }
  }
}
</script>
