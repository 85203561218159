<template>
  <BusyIndicator v-if="loadingPortfolioInfo > 0" />
  <CycleChangePolicy
    v-else-if="policy"
    :policy="policy"
    :text-content="textContent"
    :reasons="reasons"
    @submit="handleSubmit"
    :submit-state="submitState"
    is-cancel-form
  />
  <p v-else>{{ dictionaryService.phrases['forms-integration-error'] }}</p>
</template>

<script>
import { BusyIndicator, CycleChangePolicy } from '@aon/cycle'
import { props } from '@/SitecoreDefaults'
import cancelPolicy from './gql/cancelPolicy.gql'
import { portfolioInfoQuery } from '@/gql/queries'
import { portfolioReducer } from '@/services/portfolio'

const notNull = (item) => !!item
const reasonToReason = (reason) => ({
  ...reason,
  value: reason.fields?.Wording?.value
})

export default {
  name: 'CycleCancelPolicySitecore',
  components: {
    BusyIndicator,
    CycleChangePolicy
  },
  inject: ['dictionaryService'],
  props,
  data: () => ({
    loadingPortfolioInfo: 0,
    portfolioInfo: {},
    submitState: null
  }),
  computed: {
    policy() {
      return {
        sourceSystem: this.portfolioInfo?.[this.policyNumber]?.sourceSystem?.system?.value,
        policyUniqueIdentifier: this.portfolioInfo?.[this.policyNumber]?.policyUniqueIdentifier,
        business: this.portfolioInfo?.[this.policyNumber]?.product?.name,
        policyNumber: this.portfolioInfo?.[this.policyNumber]?.number
      }
    },
    policyNumber() {
      return this.$route.query.insurance
    },
    reasons() {
      return this.fields?.reasons?.filter?.(notNull).map(reasonToReason) || []
    },
    textContent() {
      return [
        'heading',
        'nextButtonText',
        'intro',
        'reasonLabel',
        'descriptionLabel',
        'changeDateLabel',
        'changeDateTooltip',
        'postSucceededMessageText',
        'postFailedMessageText'
      ].reduce((acc, key) => ({ ...acc, [key]: this.fields[key].value }), {})
    }
  },
  methods: {
    handleSubmit(formValues) {
      this.submitState = this.$apollo.mutate({
        mutation: cancelPolicy,
        variables: {
          formValues,
          formDefinition: {
            formDefinitionId: this.rendering.dataSource,
            antiForgeryToken: this.rendering.antiForgeryToken.value
          }
        }
      })
    }
  },
  apollo: {
    portfolioInfo: {
      prefetch: false, // Not prefetching because we don't have authentication
      query: portfolioInfoQuery,
      variables() {
        return {
          inputModel: {}
        }
      },
      loadingKey: 'loadingPortfolioInfo',
      update: portfolioReducer('numberEncrypted'),
      notifyOnNetworkStatusChange: true // required for loading state to work, @see https://github.com/vuejs/vue-apollo/issues/263
    }
  }
}
</script>
