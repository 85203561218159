<template>
  <CycleClientRendering>
    <CycleIframe
      :href="link.href"
      :form-method="formMethod"
      :form-path="formPath"
      :form-parameters="formParameters"
    />
  </CycleClientRendering>
</template>

<script>
import * as Cycle from '@aon/cycle'

export default {
  name: 'CycleAnvaOnlineIframeSitecore',
  components: {
    CycleIframe: Cycle.CycleIframe,
    CycleClientRendering: Cycle.CycleClientRendering
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    link() {
      const user = this.fields?.user
      let href = this.fields?.link?.value?.href
      if (href !== undefined) {
        if (user !== undefined) {
          if (href.indexOf('?') >= 0) {
            href += '&'
          } else {
            href += '?'
          }
          href += 'isBestaandeKlant=true'
        }
      }
      return { href }
    },
    formMethod() {
      return this.fields?.method
    },
    formPath() {
      return this.fields?.link?.url
    },
    formParameters() {
      return this.fields?.parameters
    }
  }
}
</script>
