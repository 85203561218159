/* eslint-disable no-console */
/**
 * Encapsulate the logging system for easy abstraction.
 */

const functionalLogger = (level) => {
  return (...args) => {
    if (console && console[level]) {
      console[level](args)
    }
  }
}

export const logInfo = functionalLogger('info')
export const logWarning = functionalLogger('warn')
export const logError = functionalLogger('error')
