<template>
  <CycleTile
    :title="title"
    :icon="icon"
    :summary="summary"
    :selected="selected"
    :is-vertical="isVertical"
    :is-imaged="isImaged"
    :image="image"
  >
    <ScPlaceholder name="jss-tile" :rendering="rendering" />
  </CycleTile>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CycleTileSitecore',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    title() {
      return this.fields.title?.value
    },
    icon() {
      return this.fields.icon?.value
    },
    summary() {
      return this.fields.summary?.value
    },
    selected() {
      return this.fields.selected?.value
    },
    isVertical() {
      return this.rendering?.fields?.isVertical
    },
    isImaged() {
      return this.rendering?.fields?.isImaged
    },
    image() {
      return this.fields?.image?.value
    }
  }
}
</script>
