<template>
  <CycleCookieToggle>
    <template #heading>
      {{ heading }}
    </template>
    <template #accept-text>
      <ScRichText :field="fields.acceptText" />
    </template>
    <template #decline-text>
      <ScRichText :field="fields.declineText" />
    </template>
  </CycleCookieToggle>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue'
import * as Cycle from '@aon/cycle'

export default {
  name: 'CycleCookieToggleSitecore',
  components: {
    ScRichText: RichText,
    CycleCookieToggle: Cycle.CycleCookieToggle
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    heading() {
      return this.fields.heading?.value
    }
  }
}
</script>
