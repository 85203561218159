export const labels = [
  'page1title',
  'page2title',
  'page3title',
  'page4title',
  'page5title',
  'page6title',
  'page7title',

  'page1NextButton',
  'page2NextButton',
  'page3NextButton',
  'page4NextButton',
  'page5NextButton',
  'page6NextButton',
  'page7NextButton',

  'page2PrevButton',
  'page3PrevButton',
  'page4PrevButton',
  'page5PrevButton',
  'page6PrevButton',
  'page7PrevButton',

  'licenseplateLabel',
  'licenseplateTooltip',
  'licenseplateButton',
  'catalogueLabel',
  'catalogueTooltip',
  'claimfreeyearsLabel',
  'claimfreeyearsTooltip',
  'distanceperyearLabel',
  'distanceperyearTooltip',

  'chosenCoverages',
  'optionalCoverages',
  'coverageWa',
  'coverageWaTooltip',
  'coverageWaPlus',
  'coverageWaPlusTooltip',
  'coverageWaExtended',
  'coverageWaExtendedTooltip',
  'legalaid',
  'legalaidTooltip',
  'passengers',
  'passengersTooltip',
  'accessories',
  'accessoriesTooltip',

  'licenseholderLabel',
  'licenseholderTooltip',
  'regulardriverLabel',
  'regulardriverTooltip',
  'regulardriverSameaddressLabel',
  'regulardriverSameaddressTooltip',
  'signCodeLabel',
  'signCodeTooltip',
  'signCodeValidationErrorText',
  'signCodeValidationSuccessText',
  'carsecurityLabel',
  'carsecurityTooltip',
  'onlyprivateLabel',
  'onlyprivateTooltip',
  'noBusinessText',

  'salutationLabel',
  'salutationTooltip',
  'initialsLabel',
  'initialsTooltip',
  'prepositionLabel',
  'prepositionTooltip',
  'lastnameLabel',
  'lastnameTooltip',
  'birthdateLabel',
  'birthdateTooltip',
  'zipcodeLabel',
  'zipcodePlaceholder',
  'zipcodeTooltip',
  'housenumberLabel',
  'housenumberTooltip',
  'housenumberAdditionLabel',
  'streetLabel',
  'streetTooltip',
  'cityLabel',
  'cityTooltip',
  'phonenumberLabel',
  'phonenumberTooltip',
  'emailaddressLabel',
  'emailaddressTooltip',
  'emailaddressRepeatLabel',
  'emailaddressRepeatTooltip',
  'emailaddressSubtext',
  'mailinglistLabel',
  'mailinglistText',
  'paymenttypeLabel',
  'paymenttermLabel',
  'paymenttypeTooltip',
  'ibanLabel',
  'ibanPlaceholder',
  'ibanTooltip',
  'paymenttermMonth',

  'premiumTaxesText',
  'premiumTaxesHeader',
  'premiumTaxesTooltip',
  'premiumInfoText',
  'existingCustomerText',
  'startdateText',
  'paymenttypeText',
  'overviewText',

  'contactTitle',
  'personaldataTitle',
  'startdateTitle',
  'paymenttypeTitle',
  'generalTitle',
  'licenseplateTitle',
  'driverTitle',
  'cardetailsTitle',
  'highlightedCoverageToggleOpenText',
  'highlightedCoverageToggleCloseText'
]

export const labelProps = labels.reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: { type: String, required: false }
    }),
  {}
)
