<template>
  <CycleHtml :html="htmlContent" v-if="htmlContent" />
</template>

<script>
import { CycleHtml } from '@aon/cycle'
export default {
  name: 'HtmlSitecore',
  components: {
    CycleHtml
  },
  props: {
    fields: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    htmlContent() {
      return this.fields?.htmlContent?.value
    }
  }
}
</script>
