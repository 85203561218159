<template>
  <div>
    <CarouselStacked
      v-if="hasCarouselTypeStackedReview && !isInEditMode"
      :fields="fields"
      :carousel-type="carouselType"
      :slides="slides"
      :class-name="className"
      :options="options"
    />
    <CarouselSsr
      v-bind="options"
      v-if="!hasCarouselTypeStackedReview && !isInEditMode"
      :class="className"
    >
      <div class="slide" v-for="(component, index) in slides" :key="index">
        <component :is="component" :key="`carousel${index}`" />
      </div>
    </CarouselSsr>
    <ScPlaceholder v-if="isInEditMode" name="jss-carousel" :rendering="rendering" />
  </div>
</template>

<script>
import carouselTypes from './carouselTypes.json'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import CarouselStacked from './CarouselStacked.vue'
import Vue from 'vue'
Vue.use(ssrCarouselCss)

export default {
  name: 'Carousel',
  components: {
    ScPlaceholder: Placeholder,
    CarouselSsr: SsrCarousel,
    CarouselStacked
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  withPlaceholder: {
    placeholders: {
      placeholder: 'jss-carousel',
      computedPropName: 'slides'
    }
  },
  computed: {
    options() {
      return carouselTypes[this.carouselType]?.options ?? carouselTypes['cards'].options
    },
    isInEditMode() {
      return this.$jss?.sitecoreContext().pageEditing
    },
    className() {
      return carouselTypes[this.carouselType]?.classOverride ?? ''
    },
    carouselType() {
      return this.fields.carouselType?.value
    },
    hasCarouselTypeStackedReview() {
      return this.carouselType === 'stackedReviews'
    }
  }
}
</script>
