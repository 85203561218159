<template>
  <ScText :field="fields.pageintro" tag="PageIntro" v-if="fields.pageintro" />
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CyclePageIntroSitecore',
  components: {
    ScText: Text
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
