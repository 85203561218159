<template>
  <FunnelLiability v-bind="funnelData">
    <template #collectivity-picker>
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </template>
  </FunnelLiability>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'FunnelLiabilitySitecore',
  components: {
    FunnelLiability: Cycle.FunnelLiabilitySitecore,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    funnelData() {
      return {
        ...this.textContent,
        ...this.funnelWithoutFields,
        serviceInfo: this.fields.serviceInfo
      }
    },
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    funnelWithoutFields() {
      // Fields are somehow duplicated in the rendering prop, we'll remove them here
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
