<template>
  <div class="documents documents--invoices">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Invoices'
}
</script>
