//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { CycleLinkIcon } from '@/components/cycle-link/'
import { FILTERED_DOCUMENT_ID } from '../constants'

export default {
  name: 'CycleMydocument',
  components: { CycleLinkIcon },
  filters: { CycleDate },
  mixins: [intlMixin],
  props: {
    document: {
      type: Object,
      required: true
    },
    textContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDocumentAvailable() {
      return this.document.id.includes(FILTERED_DOCUMENT_ID)
    }
  }
}
