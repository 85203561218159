<template>
  <LoginCredentials />
</template>

<script>
import { LoginCredentials } from '../../organisms'

export default {
  name: 'CycleAccountDetailsSitecore',
  components: {
    LoginCredentials
  },
  props: {
    heading: {
      type: String,
      default: () => 'Accountgegevens'
    },
    usernameLabel: {
      type: String,
      default: () => 'Gebruikersnaam'
    },
    emptyUsername: {
      type: String,
      default: () => 'Onbekend'
    },
    passwordLabel: {
      type: String,
      default: () => 'Wachtwoord'
    },
    emptyPassword: {
      type: String,
      default: () => '*********'
    }
  },
  provide() {
    return {
      textContent: {
        loginCredentials: {
          labels: {
            username: this.usernameLabel,
            password: this.passwordLabel
          },
          placeholders: {
            username: this.emptyUsername,
            password: this.emptyPassword
          },
          header: this.heading
        }
      }
    }
  }
}
</script>
