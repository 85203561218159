<template>
  <CycleHeader :image="image" is-curved>
    <CycleHeaderPanel v-if="hasBadge" v-bind="definedProps">
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </CycleHeaderPanel>
    <ButtonsPanel v-if="alternative" :links="iconLinks" />
  </CycleHeader>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import { InsuranceProduct } from '@/components/Modules/WSM/constants'

export default {
  name: 'CycleHeaderSitecore',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    image() {
      return { ...this.fields?.image?.value, title: this.imageTitle, alt: this.altText }
    },
    altText() {
      return this.fields?.altText?.value
    },
    imageTitle() {
      return this.fields?.imageTitle?.value
    },
    alternative() {
      return this.fields?.showIconLinks?.value
    },
    iconLinks() {
      return this.fields?.iconLinks.map((obj) => ({
        ...obj?.fields?.link?.value,
        icon: InsuranceProduct[obj?.fields?.icon?.value] || ''
      }))
    },
    links() {
      return this.fields?.links?.value
    },
    panelTitle() {
      return this.fields?.paneltitle?.value
    },
    panelBadge() {
      return this.fields?.panelbadge?.value
    },
    panelBodyText() {
      return this.fields?.panelbodytext?.value
    },
    hasBadge() {
      return this.panelTitle || this.panelBadge || this.panelBodyText
    },
    panelFooterContent() {
      return this.fields?.panelFooterContent?.value
    },
    definedProps() {
      return {
        title: this.panelTitle,
        badgeText: this.panelBadge,
        bodyText: this.panelBodyText,
        links: this.links,
        alternative: this.alternative,
        panelFooterContent: this.panelFooterContent
      }
    }
  }
}
</script>
