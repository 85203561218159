//
//
//
//
//
//

export default {
  name: 'CycleLink',
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false
    }
  },
  computed: {
    hardenExternalLink() {
      if (this.target !== '' && /^(?:http|https|\/\/)/.test(this.href)) {
        return 'noopener noreferrer'
      }

      return false
    }
  }
}
