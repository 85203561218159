const labelsWithDefaults = {
  description:
    '<p>Heeft u een nota die u bij ons wilt indienen? Dat kan eenvoudig en veilig online.</p>',
  optionsTitle: 'Welk type nota wil je declareren?',
  optionsTooltip: ''
}

export const labels = Object.keys(labelsWithDefaults)

export const labelProps = Object.keys(labelsWithDefaults).reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: {
        required: false,
        default:
          labelsWithDefaults[label] instanceof Array
            ? () => labelsWithDefaults[label]
            : labelsWithDefaults[label]
      }
    }),
  {}
)
