import { logWarning, logInfo } from '@/services/logging'
import { propertiesToArguments } from '../services/ExpressionService'
/***
 *
 * @param {string} functionName - Computed property that contains the result of the expression
 * @param {string} expressionProp - Property that contains the expression to be evaluated
 * @param {string[]} properties - Properties that will be scoped to the expression
 * @param {*} [defaultValue] - when evaluation fails this will be default
 */
export function dynamicExpressionFactory(
  functionName,
  expressionProp,
  properties,
  defaultValue = true
) {
  const functionExpressionName = functionName + 'Expression'
  const functionPropertiesName = functionName + 'Properties'

  return {
    data() {
      return {
        [functionExpressionName]: undefined,
        [functionPropertiesName]: []
      }
    },
    props: {
      [expressionProp]: {
        type: [String, Boolean, Number]
      }
    },
    watch: {
      [expressionProp]: {
        immediate: true,
        deep: true,
        handler(expression) {
          const expressionType = typeof expression
          if (expressionType === 'boolean' || expressionType === 'string') {
            try {
              this[functionPropertiesName] = properties.reduce((acc, current) => {
                const retVal = [...acc]
                if (expressionType === 'string' && expression.includes(current)) {
                  retVal.push(current)
                }
                return retVal
              }, [])
              this[functionExpressionName] = new Function(
                ...this[functionPropertiesName],
                `return ${expression}`
              )
            } catch (ex) {
              logWarning(`Failed to create function with expression: ${expression}`, ex)
              this[functionExpressionName] = undefined
            }
          } else {
            this[functionExpressionName] = undefined
          }
        }
      }
    },
    computed: {
      [functionName]() {
        const expression = this.$props[expressionProp]
        if (typeof this[functionExpressionName] !== 'function') {
          return defaultValue
        }

        try {
          // provide limited "this" scope for safeties sake
          const result = this[functionExpressionName].apply(
            null,
            propertiesToArguments(this, this[functionPropertiesName])
          )
          logInfo(`Evaluated ${expression} to ${result}`)
          return result
        } catch (e) {
          logWarning(`Error evaluating expression: ${expression}`, e)
        }
        return defaultValue
      }
    }
  }
}
