import gql from 'graphql-tag'
import { policyItemCar } from './fragments/policyItemCar'
import { policyItemLegalAid } from './fragments/policyItemLegalAid'
import { policyItemLiability } from './fragments/policyItemLiability'
import { policyItemTravel } from './fragments/policyItemTravel'
import { policyItemWedding } from './fragments/policyItemWedding'
import { policyItemHomeOwner } from './fragments/policyItemHomeOwner'
import { policyItemHomeContents } from './fragments/policyItemHomeContents'
import { policyItemMotorcycle } from './fragments/policyItemMotorcycle'

export const policyInfoQuery = (productType) => {
  let fragment = policyItemCar

  if (productType === 'LegalAid') {
    fragment = policyItemLegalAid
  } else if (productType === 'Liability') {
    fragment = policyItemLiability
  } else if (productType === 'Travel') {
    fragment = policyItemTravel
  } else if (productType === 'Wedding') {
    fragment = policyItemWedding
  } else if (productType === 'HomeOwner') {
    fragment = policyItemHomeOwner
  } else if (productType === 'HomeContents') {
    fragment = policyItemHomeContents
  } else if (productType === 'MotorCycle') {
    fragment = policyItemMotorcycle
  } else {
    productType = 'Car' // Different Camper prodcuts are also using car fragment
  }

  return gql`
    ${fragment}

    query PolicyDetailPolicyInfo($inputModel: PolicyInfoInputModel!) {
      policyInfo(policyInfoInputModel: $inputModel) {
        paymentDetails {
          paymentTerm
        }
        policy {
          conditions {
            conditionNumber
            title
            url
          }
          ${'...policyItem' + productType}
          startingDate
          mutationDate
          premiumDueDate
        }
        termPremium
        reference
        number
        startingDate
        licensePlate
      }
    }
  `
}
