<template>
  <CycleCard>
    <template #header>
      <CycleHeading>
        <ProvidedText name="contactInformation.header" />
      </CycleHeading>
    </template>

    <template #body>
      <InformationLines>
        <NamedInformation>
          <ProvidedText name="contactInformation.labels.email" />
          <span>{{ email }}</span>
        </NamedInformation>
        <NamedInformation>
          <ProvidedText name="contactInformation.labels.primaryPhone" />
          <span>{{ primaryPhone }}</span>
        </NamedInformation>
        <NamedInformation v-if="secondaryPhone">
          <ProvidedText name="contactInformation.labels.secondaryPhone" />
          <span>{{ secondaryPhone }}</span>
        </NamedInformation>
        <NamedInformation v-if="showNewsletter">
          <ProvidedText name="contactInformation.labels.newsletter" />
          <span> {{ newsletter }} </span>
        </NamedInformation>
      </InformationLines>
    </template>

    <template #footer>
      <CycleNav buttons>
        <CycleButtonLink :href="editRoute.path" modifiers="cta, secondary">
          <ProvidedText name="contactInformation.labels.edit" />
        </CycleButtonLink>
      </CycleNav>
    </template>
  </CycleCard>
</template>

<script>
/** old */
import { CycleCard } from '@/components/card'
import { CycleHeading } from '@/components/elements'
import { CycleButtonLink } from '@/components/cycle-link'

/** new, but relative */
import { Route } from '@/models'
import ProvidedText from '../../Content/ProvidedText'
import { CycleNav } from '@/components/cycle-nav'
import { InformationLines, NamedInformation } from '@/components/atoms'

export default {
  name: 'ContactInformation',
  components: {
    CycleButtonLink,
    CycleCard,
    CycleHeading,
    CycleNav,
    InformationLines,
    NamedInformation,
    ProvidedText
  },
  props: {
    email: {
      type: String,
      required: false
    },
    primaryPhone: {
      type: String,
      required: false
    },
    secondaryPhone: {
      type: String,
      required: false
    },
    showNewsletter: {
      type: Boolean,
      required: false
    },
    newsletter: {
      type: String,
      required: false
    },
    editRoute: {
      type: Route,
      required: true
    }
  }
}
</script>
