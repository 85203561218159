<template>
  <GridComponentAdapter :text-content="textContent">
    <ScPlaceholder name="jss-content" :rendering="rendering" />
  </GridComponentAdapter>
</template>

<script>
import { mapValues } from 'lodash'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'GridComponentAdapterSitecore',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value)
    }
  }
}
</script>
