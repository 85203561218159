//
//
//
//
//
//

import { CycleUidMixin } from '../../../mixins/uid'

export default {
  name: 'CycleTiles',
  mixins: [CycleUidMixin],
  props: {
    isVertical: {
      type: Boolean
    },
    isImaged: {
      type: Boolean
    }
  },
  data() {
    return {
      tilesId: `tiles-${this._cycleUid}`,
      currentValue: null
    }
  },
  provide() {
    const tilesProvider = {}

    Object.defineProperty(tilesProvider, 'tilesId', {
      enumerable: true,
      get: () => this.tilesId
    })

    Object.defineProperty(tilesProvider, 'selectedValue', {
      enumerable: true,
      get: () => this.currentValue
    })

    Object.defineProperty(tilesProvider, 'setSelectedValue', {
      enumerable: true,
      value: this.setSelectedValue.bind(this)
    })
    return { tilesProvider }
  },
  computed: {
    className() {
      const mainClassName = 'tiles'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--vertical`]: this.isVertical,
        [`${mainClassName}--images`]: this.isImaged
      }
    }
  },
  methods: {
    setSelectedValue(newValue) {
      this.currentValue = newValue
    }
  }
}
