<template>
  <li :class="menuItemClass">
    <LanguageSwitch is-for-navigation v-if="isLanguageSwitch" />
    <a
      v-else
      :href="href"
      :class="{ 'btn btn__login': showAsButton }"
      :title="title"
      :data-navigation-name="title"
      @click="closeMenu"
      >{{ title }}</a
    >
  </li>
</template>

<script>
import LanguageSwitch from '../../language-switch/LanguageSwitch'

export default {
  name: 'NavigationItem',
  components: {
    LanguageSwitch
  },
  props: {
    href: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    showAsButton: {
      type: Boolean,
      required: false
    },
    isLanguageSwitch: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menuItemClass() {
      return {
        navigation__item: true,
        'navigation__item--login': this.showAsButton,
        'navigation__item--active': this.isActive
      }
    }
  },
  methods: {
    closeMenu() {
      if (this.href.includes('#')) {
        const mobileMenuCheckbox = document?.querySelector('.nav-toggle--hamburger')
        if (mobileMenuCheckbox) {
          mobileMenuCheckbox.checked = false
        }
      }
    }
  }
}
</script>
