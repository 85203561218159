<template>
  <ol
    class="breadcrumb"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
    v-if="hasBreadcrumbs"
  >
    <BreadcrumbItem
      v-for="(breadcrumbItem, index) in breadcrumbs"
      :key="index"
      :position-index="index"
      :path="breadcrumbItem.path"
      :title="breadcrumbItem.title"
    />
  </ol>
</template>

<script>
import BreadcrumbItem from './BreadcrumbItem.vue'
export default {
  name: 'BreadcrumbItemList',
  components: {
    BreadcrumbItem
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasBreadcrumbs() {
      return this.breadcrumbs?.length > 0
    }
  }
}
</script>
