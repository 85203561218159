//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PolicyStatus } from '../../../models'
import { CycleHeading } from '../../elements'
import { CycleButtonLink } from '../../cycle-link'
import { SourceMatrixSwitch } from '../../organisms'
import { LinkToLegacy, PolicyActions, HeadingIcon } from '@/components/molecules'

export default {
  name: 'CycleMyInsurancesItem',
  components: {
    PolicyActions,
    LinkToLegacy,
    CycleHeading,
    CycleButtonLink,
    HeadingIcon,
    SourceMatrixSwitch
  },
  props: {
    textContent: Object,
    heading: String,
    subheading: String,
    licenseplate: String,
    description: String,
    location: String,
    status: PolicyStatus,
    icon: String,
    locationlabel: {
      type: String,
      default: ''
    },
    descriptionlabel: {
      type: String,
      default: 'Omschrijving'
    },
    legacyText: {
      type: String,
      default: 'Wilt u uw verzekering inzien, wijzigen of schade melden?'
    },
    legacyLinkText: {
      type: String,
      default: 'Log dan in op uw oude omgeving.'
    },
    claimFallback: {
      type: String
    },
    policy: Object,
    links: Array
  },
  computed: {
    getDataTestIdForHeading() {
      return this.heading?.replace(' ', '-')?.toLowerCase() || 'policy'
    },
    getDataTestIdForPolicyNumber() {
      return this.getDataTestIdForHeading + "-policy-number"
    },
    hasOverviewContent() {
      return this.policy?.overviewContent ? true : false
    }
  }
}
