<template>
  <CycleContentBlock
    v-if="isContentBlockListVisible"
    :contrast-color="isBackgroundVisible"
    v-bind="modifierList"
  >
    <template #image v-if="hasImage">
      <img :src="imageSrc" :title="imageTitle" :alt="imageAlt" loading="lazy" />
    </template>
    <template v-if="hasTitle" #header>
      <CycleIcon v-if="hasHeaderIcon" :modifiers="[headerIcon]" hexagon />
      <CycleHeading :weight="headingWeight">{{ title }}</CycleHeading>
    </template>
    <template v-if="hasBodyText || hasList" #body>
      <CycleHtml v-if="hasBodyText" :html="bodyText" />
      <CycleList v-if="hasList" :modifiers="listType">
        <CycleListItem v-for="(listItem, index) in list" :key="index">
          {{ listItem }}
        </CycleListItem>
      </CycleList>
    </template>
    <template v-if="hasFooterText || hasLinks" #footer>
      <CycleHtml v-if="hasFooterText" :html="footerText" />
      <template v-if="hasLinks">
        <CycleButtonLink
          v-for="(link, index) in links"
          :key="index"
          :href="link.href"
          :target="link.target"
          :modifiers="link.modifiers"
        >
          {{ link.title }}
        </CycleButtonLink>
      </template>
    </template>
  </CycleContentBlock>
</template>
<script setup>
import { defaultHeadingWeight } from '@/constants/Defaults'
import { computed } from 'vue'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  },
  params: {
    type: Object,
    default: () => ({})
  }
})
const listType = props.fields?.listBulletType?.value
const list = props.fields?.listItems?.value || []
const hasList = list?.length > 0
const title = props.fields?.heading?.value
const hasTitle = title?.length > 0
const bodyText = props.fields?.panelBodyText?.value
const hasBodyText = bodyText?.length > 0
const footerText = props.fields?.panelFooterText?.value
const hasFooterText = footerText?.length > 0
const links = props.fields?.links?.value
const hasLinks = links?.length > 0
const isBackgroundVisible = props.fields?.setTransparent?.value
const headerIcon = props.fields?.icon?.value
const hasHeaderIcon = headerIcon?.length > 0
const isContentBlockListVisible = hasTitle || hasBodyText || hasList || hasFooterText
const headingWeight = Number.parseInt(props.fields?.weight?.value) || defaultHeadingWeight
const image = props.fields?.image?.value
const imageSrc = image?.src
const imageAlt = image?.alt
const imageTitle = image?.title
const hasImage = imageSrc?.length > 0

const modifiers = computed(() => props.params?.modifiers)
const transparent = computed(
  () => props.params?.transparent === 0 || props.params?.transparent === '0'
)
const modifierList = computed(() => {
  const modifiersClasses = {}
  if (modifiers.value && modifiers.value.length) {
    modifiersClasses[modifiers.value] = true
  }
  if (transparent.value) {
    modifiersClasses['contrastColor'] = true
  }
  return modifiersClasses
})
</script>
