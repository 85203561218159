<template>
  <HorizontalCardSitecore v-bind="definedPropsForHorizontalCardSitecore">
    <template slot="body">
      <p v-if="mainUspText" class="card-horizontal__usp-main">{{ mainUspText }}</p>
      <span v-if="nudgeText" class="card-horizontal__usp-nudge">{{ nudgeText }}</span>

      <CycleHtml v-if="!!bodyText" :html="bodyText" />

      <template v-if="hasUspList">
        <ul class="list list--pros list--card-horizontal">
          <li v-for="usp in uspList" :key="usp">{{ usp }}</li>
        </ul>
      </template>
    </template>
    <template slot="footer">
      <ScPlaceholder name="jss-content" v-if="hasJssContentPlaceHolder" :rendering="rendering" />
      <template v-if="hasLinksForFooter">
        <CycleButtonLink
          v-for="(link, index) in mappedLinksForFooter"
          :key="index"
          :href="link.href"
          :target="link.target"
          :modifiers="link.modifiers"
        >
          {{ link.title }}
        </CycleButtonLink>
      </template>
    </template>
  </HorizontalCardSitecore>
</template>

<script>
import { defaultHeadingWeight } from '@/constants/Defaults'
import { HorizontalCard, CycleHtml, CycleButtonLink } from '@aon/cycle'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
export default {
  name: 'HorizontalCard',
  components: {
    HorizontalCardSitecore: HorizontalCard,
    CycleHtml,
    CycleButtonLink,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    links() {
      return this.fields?.links?.value || []
    },
    bodyText() {
      return this.fields?.bodytext?.value
    },
    definedPropsForHorizontalCardSitecore() {
      return {
        ...this.paramateredProps,
        ...this.imageProps,
        ...this.textProps
      }
    },
    paramateredProps() {
      return {
        hasFlipModifier: !!this.params?.flip,
        hasCenterModifier: !!this.params?.center,
        hasMiddleModifier: !!this.params?.middle,
        hasTransparentModifier: !!this.params?.transparent,
        hasSmallerImageModifier: !!this.params?.smallerImage,
        hasLogoImageModifier: !!this.params?.logoImage,
        hasIconImageModifier: !!this.params?.iconImage
      }
    },
    imageProps() {
      return {
        image: this.fields?.image?.value,
        imageLink: this.fields?.imageLink?.value
      }
    },
    textProps() {
      return {
        heading: this.fields?.heading?.value,
        headingWeight: Number.parseInt(this.fields?.weight?.value) || defaultHeadingWeight,
        icon: this.fields?.icon?.value,
        badgeText: this.fields?.badgeText?.value
      }
    },
    mainUspText() {
      return this.fields?.mainUspText?.value
    },
    nudgeText() {
      return this.fields?.nudgeText?.value
    },
    uspList() {
      return this.fields?.uspList?.value?.filter((u) => !!u) ?? []
    },
    hasUspList() {
      return this.uspList?.length > 0
    },
    mappedLinksForFooter() {
      return this.links?.map((link) => ({
        href: link?.href || '',
        target: link?.target || '',
        modifiers: link?.className || 'cta,secondary',
        title: link?.title || link?.text || ''
      }))
    },
    hasLinksForFooter() {
      return this.mappedLinksForFooter?.length > 0
    },
    hasJssContentPlaceHolder() {
      const placeholders = this?.rendering?.placeholders
      return !!(placeholders && placeholders['jss-content'])
    }
  }
}
</script>
