//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pathOr } from 'ramda'
import { CycleInputContainer } from '../../../forms/CycleInputContainer'
import { CycleFormSet } from '../../../forms/CycleFormSet'
import { CyclePostcodeCheck } from '../CyclePostcodeCheck'
import { CycleTextInput, CycleRadioInput } from '../../../../elements'
import { CycleDateInput } from '../../../../elements/forms'
import { dictionaryMixin } from '../../../../../mixins'
import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import { CycleHtml } from '../../../../base'

export default {
  name: 'CyclePersonalData',
  components: {
    CycleHtml,
    CContainer: CycleInputContainer,
    CycleFormSet: CycleFormSet,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CDate: CycleDateInput,
    CPostcodeCheck: CyclePostcodeCheck
  },
  mixins: [dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    },
    max: {
      type: [String, Date],
      default: format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    min: {
      type: [String, Date],
      default: format(addDays(subYears(new Date(), 111), 1), 'yyyy-MM-dd')
    }
  },
  data() {
    return {
      postcodeFound: undefined
    }
  },
  methods: {
    checkPostcode() {
      if (this.$jss) {
        this.$jss.gql
          .queryByName('addressFromPostalCodeAndHouseNumber', {
            inputModel: {
              postalCode: this.formData.zipcode,
              houseNumber: this.formData.housenumber
            }
          })
          .then((res) => {
            const foundStreet = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'street'],
              res
            )
            const foundCity = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'town'],
              res
            )
            if (foundStreet && foundCity) {
              this.postcodeFound = true
              this.formData.street = foundStreet
              this.formData.city = foundCity
            } else {
              this.postcodeFound = false
            }
          })
          .catch(() => {
            this.postcodeFound = false
          })
      } else {
        this.postcodeFound = false
      }
    }
  }
}
