import { Commit } from 'vuex'
import { dataFetcher } from '@/dataFetcher'
import config from '@/temp/config'
import HttpStatusCodes from '@/constants/HttpStatusCodes'
import { logError } from '@/services/logging'

import {
  SET_AUTHENTICATION_STATE,
  RESET_AUTHENTICATION_STATE,
  SET_AUTHENTICATION_STATE_FORCE_CLOSE
} from './mutation-types'
import { IAuthentication } from './models'

export default {
  checkAuthentication(
    { commit, state }: { commit: Commit; state: IAuthentication },
    {
      onAuthenticationExpired,
      interval = 60000
    }: { onAuthenticationExpired: Function; interval: number }
  ) {
    const intervalId = setInterval(() => {
      dataFetcher(config.sitecoreApiHost + config.authEndpoint, null)
        .then(({ status, data }) => {
          if (status !== HttpStatusCodes.OK) {
            commit(RESET_AUTHENTICATION_STATE)
            onAuthenticationExpired()
            clearInterval(intervalId)
            return
          }
          const incomingTotalSecondsLeft = data.SecondsLeft
          const existingTotalSecondsLeft = state.totalSecondsLeftServer

          const isSessionExtended =
            existingTotalSecondsLeft > 0 && incomingTotalSecondsLeft > existingTotalSecondsLeft
          const forcedClose = isSessionExtended ? false : state.forcedClose

          commit(SET_AUTHENTICATION_STATE, {
            expires: data.Expires,
            authorizationLevel: data.AuthorizationLevel,
            totalSecondsLeftServer: incomingTotalSecondsLeft,
            forcedClose
          })
        })
        .catch((error) => {
          logError(error)
          commit(RESET_AUTHENTICATION_STATE)
          onAuthenticationExpired()
          clearInterval(intervalId)
        })
    }, interval)
  },
  forceClose({ commit }: { commit: Commit }) {
    commit(SET_AUTHENTICATION_STATE_FORCE_CLOSE)
  }
}
