//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleHeading from '../elements/CycleHeading/CycleHeading'
import CycleButton from '../elements/CycleButton/CycleButton'
import { COOKIE_CONSENT_KEY } from '../../settings'

export default {
  name: 'CycleCookieConsent',
  components: {
    CycleHeading,
    CycleButton
  },
  props: {
    heading: {
      type: String,
      required: false
    },
    bodytext: {
      type: String,
      required: false
    },
    buttonApproveText: {
      type: String,
      required: false
    },
    linkDeclineText: {
      type: String,
      required: false
    }
  },
  data: () => ({
    consent: null
  }),
  mounted() {
    this.notifyGTMOfConsent((this.consent = this.getCookieConsent()))
  },
  methods: {
    getCookieConsent: () => JSON.parse(window.localStorage.getItem(COOKIE_CONSENT_KEY)),
    setCookieConsent: (consent) => {
      window.localStorage.setItem(COOKIE_CONSENT_KEY, consent)

      const cookieDate = new Date()
      cookieDate.setFullYear(cookieDate.getFullYear() + 1)
      document.cookie = `${COOKIE_CONSENT_KEY}=${consent}; expires=${cookieDate.toUTCString()};`
    },

    notifyGTMOfConsent: (consent) => {
      if (consent) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: COOKIE_CONSENT_KEY })
      }
    },

    consentEvent: function (consent) {
      this.consent = consent
      this.setCookieConsent(consent)
      this.notifyGTMOfConsent(consent)
    }
  }
}
