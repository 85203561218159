export function formatDate({ date, locale = 'nl-NL', options = { dateStyle: 'short' } }) {
  if (!date) {
    return null
  }

  return new Date(date).toLocaleDateString(locale, options)
}

export function formatCurrency({
  amount,
  locale = 'nl-NL',
  options = { style: 'currency', currency: 'EUR' }
}) {
  if (!amount) {
    return null
  }

  return new Intl.NumberFormat(locale, options).format(amount)
}
