//
//
//
//
//
//

export default {
  name: 'CycleOption',
  inject: ['modelValue'],
  props: {
    value: {
      type: [String, Number, Array, Object, Function],
      required: false
    }
  }
}
