<template>
  <CycleCard v-if="!loading && !hasEmail" data-testid="show-card">
    <template #header>
      <CycleHeading>
        <ProvidedText name="correspondence.header" />
      </CycleHeading>
    </template>

    <template #body>
      <InformationLines>
        <NamedInformation>
          <ProvidedText name="correspondence.labels.correspondenceLabel" />
          <p>{{ correspondenceText }}</p>
        </NamedInformation>
      </InformationLines>
    </template>

    <template #footer>
      <div>
        <CycleNav buttons>
          <CycleButtonLink
            :href="buttonLink.href"
            modifiers="cta, secondary"
            data-testid="footer-nav"
          >
            {{ buttonText }}
          </CycleButtonLink>
        </CycleNav>
      </div>
    </template>
  </CycleCard>
</template>

<script>
import { default as PersonalInfo } from './gql/mypersonalinfo.gql'
import {
  CycleCard,
  CycleHeading,
  CycleButtonLink,
  CycleNav,
  ProvidedText,
  InformationLines,
  NamedInformation
} from '@aon/cycle'

export default {
  name: 'CorrespondenceDetails',
  components: {
    CycleCard,
    CycleNav,
    CycleHeading,
    InformationLines,
    NamedInformation,
    ProvidedText,
    CycleButtonLink
  },
  provide() {
    return {
      textContent: {
        correspondence: {
          header: this.header,
          labels: {
            correspondenceLabel: this.correspondenceLabel
          }
        }
      }
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loadingPersonalInfo: 0,
      personalData: {}
    }
  },
  computed: {
    loading() {
      return this.loadingPersonalInfo > 0
    },
    header() {
      return this.fields.header?.value
    },
    correspondenceText() {
      return this.personalData?.correspondence?.value
    },
    correspondenceLabel() {
      return this.fields.correspondenceLabel?.value
    },
    buttonLink() {
      return this.fields.buttonLink?.value
    },
    buttonText() {
      return this.fields.buttonText?.value
    },
    hasEmail() {
      return this.correspondenceText == 'E-mail'
    }
  },
  apollo: {
    personalData: {
      prefetch: false,
      query: PersonalInfo,
      loadingKey: 'loadingPersonalInfo',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }
  }
}
</script>
