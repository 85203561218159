const labelsWithDefaults = {
  statusLabel: 'Status',
  paymentLabel: 'Betaalwijze',
  informationText: '',
  insuranceLabel: 'Verzekering',
  periodLabel: 'Periode',
  numberLabel: 'Factuurnr',
  amountLabel: 'Bedrag',
  dateLabel: 'Factuurdatum',
  downloadLabel: 'Download factuur',
  downloadUrl: '#',
  downloadLinkText: '',
  notAvailableLinkText: '',
  paginationItemText: 'facturen gevonden op',
  paginationText: "pagina's.",
  noResultsText: 'Geen items beschikbaar',
  overViewButtonLabel: 'Terug naar Mijn Overzicht',
  overViewButtonLink: '/mijn-overzicht'
}

export const labels = Object.keys(labelsWithDefaults)

export const labelProps = Object.keys(labelsWithDefaults).reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: {
        type: String,
        required: false,
        default: labelsWithDefaults[label]
      }
    }),
  {}
)
