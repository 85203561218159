export const policyCodeMap = {
  CamperRentalBranch: '02504',
  CamperRentalProduct: '02000',
  TravelBranch: '04600'
}

export const documentCode = '12'

export const policyTermMap = {
  PolicySheet: 'Polisblad',
  AddressChange: 'Adreswijziging'
}
