// The following country codes with their respective IBAN number count are currently not accepted in OHI and therefore
// not valid
// AD: 24, AE: 23, AL: 28, AZ: 28, BA: 20, BH: 22, BR: 29, CR: 21, DO: 28, FO: 18, GE: 22, GI: 23, GL: 18, GT: 28,
// IL: 23, KW: 30, KZ: 20, LB: 28, MD: 24, ME: 22, MK: 19, MR: 27, MU: 30, PK: 24, PS: 29, RS: 22, SA: 24, SM: 27,
// TN: 24, TR: 26, VG: 24,
export const IBANCountriesOHI = {
  AT: 20,
  BE: 16,
  BG: 22,
  CH: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  EE: 20,
  ES: 24,
  FI: 18,
  FR: 27,
  GB: 22,
  GR: 27,
  HR: 21,
  HU: 28,
  IE: 22,
  IS: 26,
  IT: 27,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MT: 31,
  NL: 18,
  NO: 15,
  PL: 28,
  PT: 25,
  RO: 24,
  SE: 24,
  SI: 19,
  SK: 24
}

export const allowedExtensions = ['.doc', '.docx', '.pdf', '.jpeg', '.jpg', '.png']
