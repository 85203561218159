import { logWarning, logInfo } from '@/services/logging'
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-vue'

let silent = true

function getRelativeUrl(url) {
  const matcher = new RegExp(`^${window.location.origin}(/.*)`, 'gi')
  const result = matcher.exec(url)
  if (result) {
    return result[1]
  }
}

function resolveAnchorElement(target, maxDepth = 5) {
  if (target instanceof HTMLAnchorElement) {
    return target
  }

  if (maxDepth === 1) {
    return undefined
  }

  if (target?.parent) {
    return resolveAnchorElement(target.parent, maxDepth - 1)
  }

  return undefined
}

function anchorEventHandlerFactory(Vue, options) {
  return async (e) => {
    if (!options.router) {
      return
    }

    if (isExperienceEditorActive()) {
      return
    }

    const anchorElement = resolveAnchorElement(e.target, options.maxDepth)
    if (!anchorElement) {
      return
    }

    const relativeUrl = getRelativeUrl(anchorElement.href)
    const relativeWindowUrl =
      window.location.pathname + window.location.search + window.location.hash
    if (relativeUrl === relativeWindowUrl || window.location.hash) {
      return
    }
    if (relativeUrl?.length && !anchorElement.target?.length) {
      if (
        Array.isArray(options.ignorePatterns) &&
        options.ignorePatterns.some((pattern) => pattern.test(relativeUrl))
      ) {
        if (!silent) {
          logInfo('navigation interceptor match on ignore pattern for: ', anchorElement.href)
        }

        return
      }

      e.preventDefault()
      if (!silent) {
        logInfo('navigation intercepted for: ', anchorElement.href)
      }
      try {
        await options.router.push(relativeUrl)
      } catch {
        logWarning('route change failed for: ', anchorElement.href)
        window.location.replace(relativeUrl)
      }
    }
  }
}

export default {
  install(Vue, options) {
    if (typeof window === 'undefined') {
      return
    }
    silent = Vue.config.silent
    const eventTypes = ['click']
    const anchorEventHandler = anchorEventHandlerFactory(Vue, options)
    eventTypes.forEach((eventType) =>
      window.addEventListener(eventType, anchorEventHandler, { capture: true })
    )
  }
}
