<script>
import { h } from 'vue'
import BlogTag from '../BlogTag/BlogTag.vue'
export default {
  name: 'BlogTags',
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    return () =>
      h(
        'div',
        { class: 'blogdivider__tags' },
        props.tags.map((tag) =>
          h(BlogTag, {
            props: {
              blogTag: tag,
              shouldAppendComma: props.tags.indexOf(tag) !== props.tags.length - 1
            }
          })
        )
      )
  }
}
</script>
