//
//
//
//
//
//
//
//
//
//
//
//

import { pathOr } from 'ramda'

import {
  CycleFinalQuestionBoolean,
  CycleFinalQuestionCheckBox,
  CycleFinalQuestionInfoText,
  CycleFinalQuestionText
} from './components'

import { CycleFormSet } from '../../CycleFormSet'

export default {
  name: 'CycleFinalQuestions',
  inject: ['formData'],
  components: {
    CycleFinalQuestionBoolean,
    CycleFinalQuestionCheckBox,
    CycleFinalQuestionInfoText,
    CycleFinalQuestionText,
    CycleFormSet
  },
  props: {
    finalQuestions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      questionGroups: ['final', 'agreement'],
      supportedQuestionTypes: ['Boolean', 'CheckBox', 'InfoText', 'Text']
    }
  },
  computed: {
    noQuestions() {
      return Object.keys(this.finalQuestions).length === 0
    },
    questionParentCodes() {
      if (this.noQuestions) {
        return {}
      }
      return this.questionGroups.reduce(
        (acc, questionGroup) =>
          this.finalQuestions[questionGroup].reduce(
            (acc, question) =>
              question.code
                ? question.followUpQuestions.reduce(
                    (acc, fuq) => ({ ...acc, [fuq]: question.code }),
                    acc
                  )
                : acc,
            acc
          ),
        {}
      )
    },
    questionsByCode() {
      if (this.noQuestions) {
        return {}
      }
      return this.questionGroups.reduce(
        (acc, questionGroup) =>
          this.finalQuestions[questionGroup].reduce(
            (acc, question) => ({ ...acc, [question.code]: question }),
            acc
          ),
        {}
      )
    },
    questionIsActiveByCode() {
      if (this.noQuestions) {
        return {}
      }
      return this.questionGroups.reduce(
        (acc, questionGroup) =>
          this.finalQuestions[questionGroup].reduce((acc, question) => {
            const { code } = question
            const parentCode = pathOr(null, ['questionParentCodes', code], this)
            const parentType = pathOr(null, ['questionsByCode', parentCode, 'type'], this)
            const valuePath = ['formData', 'finalQuestions', parentCode]
            if (code && parentCode && parentType) {
              switch (parentType) {
                case 'CheckBox':
                  acc[code] = pathOr(false, valuePath, this)
                  break
                case 'Boolean':
                  acc[code] = pathOr('N', valuePath, this) === 'J'
                  break
              }
            }
            return acc
          }, acc),
        {}
      )
    },
    questions() {
      if (this.noQuestions) {
        return []
      }
      const questionIsActiveByCode = this.questionIsActiveByCode
      return this.questionGroups.reduce(
        (acc, questionGroup) =>
          this.finalQuestions[questionGroup].reduce((acc, q) => {
            if (!this.supportedQuestionTypes.includes(q.type)) {
              // filter out unsupported question types
              return acc
            }
            const isActive = pathOr(true, [q.code], questionIsActiveByCode)
            return [...acc, { ...q, isActive }]
          }, acc),
        []
      )
    }
  }
}
