export const labelsMixin = (labels) => {
  const propTypeLabels = labels.reduce(
    (accumulator, label) =>
      Object.assign(accumulator, {
        [label]: { type: String, required: false }
      }),
    {}
  )
  return {
    props: {
      ...propTypeLabels
    },
    provide: function () {
      return {
        formLabels: this._props
      }
    }
  }
}
