//
//
//
//
//
//
//
//
//
//
//
//

import CycleHtml from '@/components/base/CycleHtml'
import CycleHeading from '@/components/elements/CycleHeading/CycleHeading'

export default {
  name: 'GridComponentAdapter',
  components: { CycleHeading, CycleHtml },
  props: {
    textContent: {
      type: Object,
      default: () => ({})
    }
  }
}
