//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CycleNav',
  props: {
    buttons: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  }
}
