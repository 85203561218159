//
//
//
//

import { CycleCoverage } from '../../functional/CycleCompareCoverage'

/**
 * Sitecore interface for CycleCoverage
 */
export default {
  name: 'CycleCoverageSitecore',
  components: {
    CycleCoverage
  },
  props: {
    /**
     * Coverage list title
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Leading (HTML) text
     */
    bodytext: {
      type: String,
      required: false
    },
    /**
     * Coverage item list.
     */
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Link to perhaps more insurance information
     */
    link: {
      type: Object,
      required: false
    }
  }
}
