<template>
  <nav class="coreui__menus">
    <input
      type="checkbox"
      class="nav-toggle nav-toggle--hamburger"
      :id="navigationToggleName"
      :name="navigationToggleName"
    />
    <label class="nav-trigger nav-trigger--hamburger" :for="navigationToggleName"></label>
    <div class="nav-label" v-if="mobileNavigationLabel">{{ mobileNavigationLabel }}</div>
    <slot />
  </nav>
</template>

<script>
import { CycleUidMixin } from '@aon/cycle/src/mixins/uid'

export default {
  name: 'BaseNavigation',
  mixins: [CycleUidMixin],
  inject: ['dictionaryService'],
  computed: {
    navigationToggleName() {
      return `toggle_${this._cycleUid}`
    },
    mobileNavigationLabel() {
      return this.dictionaryService.phrases['navigation.mobile.label']
    }
  }
}
</script>
