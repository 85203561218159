<template>
  <div class="form__field form__field--survey">
    <CycleHtml v-if="description" :html="description" />
    <CycleInputContainer>
      <CycleTextarea
        graphql-safe
        :name="name"
        required
        @change="handleChange"
        :validation-path="validationPath"
      />
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleInputContainer } from '@/components/functional/forms'
import { CycleTextarea } from '@/components/elements'

import { QuestionMixin } from './QuestionMixin'
import { EmptyAnswer, TextAnswer } from '../models'

export default {
  name: 'QuestionText',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleTextarea
  },
  mixins: [QuestionMixin],
  props: {
    value: {
      type: Object,
      required: true,
      default: () => new EmptyAnswer()
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('input', new TextAnswer(event.target.value))
    }
  }
}
</script>
