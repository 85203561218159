<template>
  <div class="terminated-insurances-CTA">
    <h2>{{ getPhrase('EndedInsurances') }}</h2>
    <CycleButtonLink href="beeindigde-verzekeringen-en-diensten" modifiers="cta, secondary">
      {{ getPhrase('EndedInsurances') }}
    </CycleButtonLink>
  </div>
</template>

<script>
import { CycleButtonLink } from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'
export default {
  name: 'TerminatedInsurancesCTA',
  components: {
    CycleButtonLink
  },
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
