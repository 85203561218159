<template>
  <textarea
    type="textarea"
    rows="10"
    v-bind="Object.assign({}, attributes, $attrs)"
    v-on="Object.assign({}, listeners, $listeners, { input: updateValue })"
    :value="value"
    class="form__input"
  ></textarea>
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleTextarea',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
