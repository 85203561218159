import config from '@/temp/config'

import { ApolloClient, InMemoryCache } from '@apollo/client'
// import sha256 from 'crypto-js/sha256'

/*
  INTROSPECTION DATA
  See https://www.apollographql.com/docs/react/recipes/fragment-matching.html
  This enables the Apollo cache to process fragments on interface types correctly.
  If this file does not exist, you may need to run the `jss graphql:update` script.
*/
import introspectionQueryResultData from '../temp/GraphQLFragmentTypes.json'

/*
  QUERY LINK SELECTION
  A link is transport which GraphQL queries are pushed across.
  You have many choices.
  See the apollo/client documentation for more details.

  NOTE: to use Sitecore Experience Editor it is essential that your
  link passes cookies along with requests (credentials: 'include').
*/

// choose between a basic HTTP link to run queries...
import { createHttpLink } from '@apollo/client/link/http'

// ...or a batched link (multiple queries within 10ms all go in one HTTP request)
// import { BatchHttpLink } from '@apollo/client/link/batch-http'

// ...and an automatic persisted query link, which reduces bandwidth by using query hashes to alias content
// the APQ link is _chained_ behind another link that performs the actual HTTP calls, so you can choose
// APQ + batched, or APQ + http links for example.
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'

export default function (endpoint, ssr, initialCacheState, store) {
  /* HTTP link selection: default to batched + APQ */
  // const link = createPersistedQueryLink({ sha256 }).concat(
  //   new BatchHttpLink({ uri: endpoint, credentials: 'include' })
  // )

  // basic HTTP link
  // const link = createHttpLink({ uri: endpoint, credentials: 'include' });

  const customHeaders = {
    ...(process.env.VUE_APP_POSTMAN_MOCKED === 'true' && { 'x-mock-match-request-body': true }),
    sc_apikey: config.sitecoreApiKey
  }
  const link = createHttpLink({
    uri: () => {
      return store?.getters?.['siteSettings/currentLanguage']
        ? endpoint + `?sc_lang=${store.getters['siteSettings/currentLanguage']}`
        : endpoint
    },
    credentials: 'include',
    headers: customHeaders
  })
  const cache = new InMemoryCache({
    introspectionQueryResultData
  })

  return new ApolloClient({
    ssrMode: ssr,
    ssrForceFetchDelay: 100,
    link,
    cache: cache.restore(initialCacheState)
  })
}
