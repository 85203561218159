<template>
  <CycleCardList :title="title" :modifiers="modifiers">
    <template slot="list-items">
      <CycleListItem v-for="(listItem, index) in list" :key="index">{{ listItem }}</CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import CycleCardList from '../../cycle-card-list/CycleCardList'
import { CycleListItem } from '../../cycle-list/'

export default {
  name: 'CycleListSitecore',
  components: { CycleCardList, CycleListItem },
  props: {
    title: {
      type: String,
      required: false,
      default: () => null
    },
    modifiers: {
      type: [String, Array],
      default: '',
      required: false
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
