<script>
export default {
  name: 'StructuredData',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo() {
    return { script: [{ innerHTML: JSON.stringify(this.fields), type: 'application/ld+json' }] }
  },
  render: () => null
}
</script>
