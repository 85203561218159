//
//
//
//
//
//
//
//
//

import CycleLink from './CycleLink'
import CycleIcon from '../elements/CycleIcon/CycleIcon'

export default {
  name: 'CycleLinkIcon',
  components: { CycleLink, CycleIcon },
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: ''
    },
    modifiers: {
      type: String,
      required: false,
      default: ''
    }
  }
}
