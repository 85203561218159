//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BreadcrumbItem',
  props: {
    path: {
      type: String
    },
    title: {
      type: String
    },
    positionIndex: {
      type: Number
    }
  }
}
