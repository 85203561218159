<template>
  <CycleCard :image-modifiers="['small']">
    <template #header v-if="hasHeading">
      <CycleHeading>
        {{ heading }}
      </CycleHeading>
    </template>
    <template #image v-if="hasImage">
      <img v-bind="imageProperties" />
    </template>

    <template #body>
      <CycleList modifiers="nomarker">
        <CycleListItem v-if="name" data-testid="advisor-name" class="advisor-name">
          {{ name }}
        </CycleListItem>
        <CycleListItem v-if="phone">
          <CycleLink :href="'tel:' + phone" data-testid="advisor-phone">
            <CycleIcon icon="phone" /> {{ phone }}
          </CycleLink>
        </CycleListItem>
        <CycleListItem v-if="contactPage.href">
          <CycleLink
            :href="contactPage.href"
            :target="contactPage.target"
            data-testid="advisor-contact"
          >
            <CycleIcon icon="envelope" /> {{ contactPage.text }}
          </CycleLink>
        </CycleListItem>
      </CycleList>
    </template>
  </CycleCard>
</template>

<script>
import { CycleHeading, CycleList, CycleListItem, CycleIcon, CycleCard, CycleLink } from '@aon/cycle'

export default {
  name: 'AdvisorContactDetails',
  components: {
    CycleHeading,
    CycleList,
    CycleListItem,
    CycleIcon,
    CycleCard,
    CycleLink
  },
  props: {
    fields: Object,
    rendering: Object
  },
  computed: {
    image() {
      return this.fields?.Image
    },
    name() {
      return this.rep?.value
    },
    email() {
      return this.rep?.email
    },
    phone() {
      return this.rep?.phone
    },
    heading() {
      return this.fields?.ContactWithYourAdvisor
    },
    contactPage() {
      return this.fields?.ContactPage?.value || {}
    },
    rep() {
      return this.fields?.additionalData?.data?.legalEntity?.customerOwner?.customerRep
    },
    imageProperties() {
      return this.fields?.Image?.value ?? {}
    },
    hasImage() {
      return this.imageProperties?.src?.length > 0
    },
    hasHeading() {
      return this.heading?.length > 0
    }
  }
}
</script>
