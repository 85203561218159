<template>
  <FunnelLiabilityChange v-bind="funnelData" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'FunnelLiabilityChangeSitecore',
  components: {
    FunnelLiabilityChange: Cycle.FunnelLiabilityChangeSitecore
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    funnelData() {
      return {
        ...this.textContent,
        ...this.funnelWithoutFields
      }
    },
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    funnelWithoutFields() {
      // Fields are somehow duplicated in the rendering prop, we'll remove them here
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
