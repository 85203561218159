//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { QuestionMixin } from './QuestionMixin'

export default {
  name: 'QuestionInfoText',
  components: { CycleHtml },
  mixins: [QuestionMixin]
}
