<template>
  <CycleFormPage
    :next-button-text="textContent.page4NextButton"
    :prev-button-text="textContent.page4PrevButton"
    :on-submit="handleSubmit"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="payment.header" />
      </CycleHeading>
    </template>

    <CycleFormSet v-if="isMultiTrip">
      <template #heading>
        <ProvidedText name="payment.headings.startDate" />
      </template>
      <ProvidedHtml name="payment.content.startDate" />

      <CycleInputContainer :validation-label="textContent.startdateTitle">
        <CycleDateInput
          v-model="formData.startDate"
          :tooltip="textContent.startdateTooltip"
          required
          :min="minStartDate"
          :max="maxStartDate"
        />

        <template #error-min>
          {{ phrase('error-messages-funnels-002') }}
        </template>

        <template #error-max>
          {{ phrase('error-messages-funnels-003') }}
        </template>
      </CycleInputContainer>
    </CycleFormSet>

    <PaymentDetailsForm
      :premium-info="premiumInfo"
      :payment-details="paymentDetails"
      :existing-customer="false"
    />
  </CycleFormPage>
</template>

<script>
import { addDays, format } from 'date-fns'
import { CycleHeading } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'
import { ProductPremium } from '../../models'

import { dictionaryMixin } from '@/mixins'

export default {
  name: 'FunnelTravelStep4',
  inject: ['formData', 'textContent'],
  components: {
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  mixins: [dictionaryMixin],
  props: {
    isMultiTrip: {
      type: Boolean
    },
    premium: {
      type: ProductPremium
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    premiumInfo() {
      return this.isMultiTrip
        ? this.textContent.premiumInfoTextMultiTrip
        : this.textContent.premiumInfoTextSingleTrip
    },
    paymentDetails() {
      const init = {
        paymentTerm: this.isMultiTrip ? PaymentTerm.Monthly : PaymentTerm.Single,
        premium: this.premium && this.premium.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }
        this.paymentDetails = newValue.paymentDetails
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
</script>
