import Vue from 'vue'

const setChildValue = function (name, value, index = 0) {
  this.instances[index][name].value = value
  this.instances[index][name].dispatchEvent(new Event('input'))
}

const setValue = function (value, index = 0) {
  this.instances[index].value = value
  this.instances[index].dispatchEvent(new Event('input'))
}

const getValue = function (index = 0) {
  return this.instances[index].value
}

const registerForTest = (name, elem) => {
  if (!window.$cfpa) {
    window.$cfpa = {
      testElements: {}
    }
  }
  const isDateElem = elem.day && elem.month && elem.year
  const isButton = elem.tagName === 'BUTTON'
  const isCheckoutItem = elem.dataset && elem.dataset.cy === 'checkout-item'
  const hasAnomalies = isDateElem || isButton || isCheckoutItem
  const element = hasAnomalies ? elem : elem[name]

  const testElement = window.$cfpa.testElements[name]
  if (testElement) {
    testElement.instances.push(element)
    // remove non attached instances from array (occurs when SSR fails)
    testElement.instances = testElement.instances.filter((instance) =>
      isAttachedToDom.apply(instance)
    )
    return
  }

  window.$cfpa.testElements[name] = {
    instances: [element],
    getValue,
    setValue,
    setChildValue
  }
}

const unRegisterForTest = (name, elem) => {
  if (!window.$cfpa || !window.$cfpa.testElements || !window.$cfpa.testElements[name]) {
    return
  }

  const testElement = window.$cfpa.testElements[name]
  if (testElement.instances.length > 1) {
    testElement.instances = testElement.instances.filter((instance) => instance === elem)

    return
  }

  delete window.$cfpa.testElements[name]
}

const isAttachedToDom = function () {
  if (!this) {
    return false
  }
  if (typeof this.isConnected === 'boolean') {
    return this.isConnected
  }

  return (
    !this.ownerDocument ||
    !(this.ownerDocument.compareDocumentPosition(this) & this.DOCUMENT_POSITION_DISCONNECTED)
  )
}

export default () => {
  Vue.use({
    install(Vue) {
      if (!Vue.prototype.$cfpa) {
        Vue.prototype.$cfpa = {}
      }

      Vue.prototype.$cfpa.registerForTest = registerForTest
      Vue.prototype.$cfpa.unRegisterForTest = unRegisterForTest

      Vue.directive('testable', {
        inserted(el, { value: name }) {
          registerForTest(name, el)
        },
        unbind(el, { value: name }) {
          unRegisterForTest(name, el)
        }
      })
    }
  })
}
