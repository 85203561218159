<template>
  <footer class="coreui__footer">
    <section class="footergrid">
      <div class="footergrid__info">
        <img
          v-if="logoSrc"
          :src="logoSrc"
          :alt="logoAlt"
          :title="logoTitle"
          class="coreui__custom-logo--footer"
        />
        <span v-else title="Aon" class="coreui__logo coreui__logo--footer"></span>
        <CycleHtml :html="bodyText"></CycleHtml>
        <CycleList v-if="hasTerms" modifiers="nomarker">
          <CycleListItem v-for="term in terms" :key="term.id">
            <CycleLink
              v-if="term.url"
              :href="term.url"
              :target="term.target"
              :class-name="term.className"
            >
              {{ term.title }}
            </CycleLink>
            <span v-else>
              {{ term.title }}
            </span>
          </CycleListItem>
        </CycleList>
      </div>
      <div v-if="hasMenuItems" class="footergrid__nav">
        <div v-for="menu in menus" :key="menu.id" class="footergrid__panel">
          <CycleHeading v-if="menu.title" :weight="6">
            {{ menu.title }}
          </CycleHeading>
          <CycleList v-if="menuHasSubItems(menu)" modifiers="nomarker">
            <CycleListItem v-for="item in menu.items" :key="item.id">
              <CycleLink :href="item.url" :target="item.target">
                {{ item.title }}
              </CycleLink>
            </CycleListItem>
          </CycleList>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import { CycleLink } from '@/components/cycle-link'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import { CycleHtml } from '@/components/base'
import CycleHeading from '@/components/elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleFooter',
  components: {
    CycleHtml,
    CycleLink,
    CycleList,
    CycleListItem,
    CycleHeading
  },
  props: {
    menus: {
      type: Array,
      required: true
    },
    terms: {
      type: Array,
      required: true
    },
    bodyText: {
      type: String,
      required: false,
      default: ''
    },
    logo: {
      type: Object,
      required: false
    }
  },
  computed: {
    hasTerms() {
      return this.terms?.length > 0
    },
    hasMenuItems() {
      return this.menus?.length > 0
    },
    logoSrc() {
      return this.logo?.src
    },
    logoAlt() {
      return this.logo?.alt
    },
    logoTitle() {
      return this.logo?.title || this.logo?.alt || this.logo?.name
    }
  },
  methods: {
    menuHasSubItems(menu) {
      return menu?.items?.length > 0
    }
  }
}
</script>
