//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/** old */
import { CycleCard } from '@/components/card'
import { CycleHeading } from '@/components/elements'
import { CycleButtonLink } from '@/components/cycle-link'

/** new, but relative */
import { Route } from '@/models'
import ProvidedText from '../../Content/ProvidedText'
import { CycleNav } from '@/components/cycle-nav'
import { InformationLines, NamedInformation } from '@/components/atoms'

export default {
  name: 'ContactInformation',
  components: {
    CycleButtonLink,
    CycleCard,
    CycleHeading,
    CycleNav,
    InformationLines,
    NamedInformation,
    ProvidedText
  },
  props: {
    email: {
      type: String,
      required: false
    },
    primaryPhone: {
      type: String,
      required: false
    },
    secondaryPhone: {
      type: String,
      required: false
    },
    showNewsletter: {
      type: Boolean,
      required: false
    },
    newsletter: {
      type: String,
      required: false
    },
    editRoute: {
      type: Route,
      required: true
    }
  }
}
