<template>
  <ECommerceTracker
    :funnel-name="funnelName"
    :year-premium="premium ? premium.totalPremium : undefined"
    :transaction="transaction"
    v-if="ready"
  >
    <template #default="{registerCheckout, registerPurchase}">
      <FormContainer
        :on-submit="handleSubmit"
        store-namespace="funnelHomeContents"
        @submit-success="registerPurchase"
        @page-view="registerCheckout"
      >
        <template #header="{state}">
          <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
          <slot name="collectivity-picker" v-if="state.index === 0" />
        </template>

        <HomeContentsStepSetup
          @submit="handleSetupStep"
          :contents-building-types="contentsBuildingTypes"
          :household-compositions="householdCompositions"
        />
        <HomeContentsStepCoverages
          @coverage-update="updateCoverages"
          :selected-coverages="selectedCoverages"
          :jewelry-options="jewelryOptions"
          :audio-visual-options="audioVisualOptions"
          :special-possessions-options="specialPossessionsOptions"
          :tenants-ownership-options="tenantsOwnershipOptions"
          :premium="premium"
          :display-cyber-coverage="displayCyberCoverage"
        />

        <HomeContentsStepAcceptance @submit="commit(SET_ACCEPTANCE_ANSWERS, $event)" />

        <HomeContentsStepPersonalData
          :address-value="selectedAddress"
          :contact-details-value="contactDetails"
          :person-value="person"
          :is-verified-email-address="isVerifiedEmailAddress"
          @submit="commit(SET_PERSONAL_DATA, $event)"
        >
          <template #postalcode>
            <AddressLookupContainer
              @input="handleAddressLookup"
              :value="selectedAddress"
              :is-postal-code-disabled="true"
            />
          </template>
        </HomeContentsStepPersonalData>

        <HomeContentsStepPayment
          :premium="premium"
          :value="paymentDetails"
          @submit="commit(SET_PAYMENT_DETAILS, $event)"
        />

        <StepFinalQuestions :value="questions" @submit="commit(SET_FUNNEL_QUESTIONS, $event)">
          <FunnelQuestionsContainer
            :product-code="productCode"
            v-model="questions"
            :render-type="questionRenderType.Final"
          />
          <ServiceInformation :service-info="textContent.serviceInfo" />
        </StepFinalQuestions>

        <HomeContentsStepOverview
          :overview-data="overviewData"
          :value="questions"
          @submit="commit(SET_FUNNEL_QUESTIONS, $event)"
          :display-cyber-coverage="displayCyberCoverage"
        >
          <FunnelQuestionsContainer
            :product-code="productCode"
            v-model="questions"
            :render-type="questionRenderType.Agreement"
          />
        </HomeContentsStepOverview>

        <template #confirmation>
          <CycleFunnelConfirmation :result="submitResult" />
        </template>
      </FormContainer>
    </template>
  </ECommerceTracker>
</template>

<script>
import { CycleBusstop } from '@/components/cycle-busstop'
import { CycleFunnelConfirmation } from '@/components/functional/forms'
import { FormContainer, StepFinalQuestions, ECommerceTracker } from '@/components/organisms'

import { funnelMixinFactory } from '@/mixins/funnel'
import { serviceNames, FunnelService } from '@/services/data'
import { uuid4 } from '@/services/UUID'
import {
  FunnelQuestionsContainer,
  QuestionRenderType,
  FunnelQuestions
} from '@/modules/FunnelQuestions'
import { GetContentProvider } from '@/services/FunnelContentProvider'
import { PersonalDataTextSetMap } from '@/constants/textSetLabelMaps'
import { AddressLookupContainer } from '@/modules/AddressLookup'
import { PolicyAction } from '@/constants'

import { FunnelHomeContentsService } from '../services'
import { default as storeModule } from '../store'

import {
  SET_PERSONAL_DATA,
  SET_PAYMENT_DETAILS,
  SET_FUNNEL_QUESTIONS,
  SET_ACCEPTANCE_ANSWERS
} from '../store/mutation-types'

import HomeContentsStepSetup from './steps/HomeContentsStepSetup'
import HomeContentsStepCoverages from './steps/HomeContentsStepCoverages'
import HomeContentsStepPersonalData from './steps/HomeContentsStepPersonalData'
import HomeContentsStepAcceptance from './steps/HomeContentsStepAcceptance'
import HomeContentsStepPayment from './steps/HomeContentsStepPayment'
import HomeContentsStepOverview from './steps/HomeContentsStepOverview'
import { FunnelHomeContents } from '../constants/constants'
import { mapHomeContentsToInputModel } from '@/modules/FunnelHomeContents/services/mappings'
import { AddressLookupService } from '@/modules/AddressLookup/services'

export default {
  name: 'FunnelContentsSitecore',
  components: {
    AddressLookupContainer,
    CycleBusstop,
    CycleFunnelConfirmation,
    FormContainer,
    FunnelQuestionsContainer,
    FunnelQuestions,
    HomeContentsStepSetup,
    HomeContentsStepCoverages,
    HomeContentsStepAcceptance,
    HomeContentsStepPersonalData,
    HomeContentsStepPayment,
    StepFinalQuestions,
    HomeContentsStepOverview,
    ECommerceTracker
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelHomeContents',
      storeModule,
      actionsMapping: [
        'handleSetupStep',
        'fetchPremiumRequestFields',
        'updateCoverages',
        'handleAddressLookup',
        'submitFunnel'
      ],
      gettersMapping: [
        'selectedAddress',
        'person',
        'contactDetails',
        'isCommunicating',
        'contentsBuildingTypes',
        'householdCompositions',
        'selectedCoverages',
        'jewelryOptions',
        'audioVisualOptions',
        'specialPossessionsOptions',
        'tenantsOwnershipOptions',
        'overviewData',
        'submitResult'
      ],
      stateMapping: ['premium', 'isVerifiedEmailAddress'],
      mutationsMapping: {
        SET_PERSONAL_DATA,
        SET_PAYMENT_DETAILS,
        SET_FUNNEL_QUESTIONS,
        SET_ACCEPTANCE_ANSWERS
      },
      services: [
        {
          serviceName: serviceNames.FunnelHomeContentsService,
          serviceDefinition: FunnelHomeContentsService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        },
        {
          serviceName: serviceNames.AddressLookupService,
          serviceDefinition: AddressLookupService
        }
      ]
    })
  ],
  props: {
    textContent: {
      type: Object,
      default: () => ({})
    },
    defaults: {
      type: Object,
      default: () => ({})
    },
    assign: {
      type: Object,
      default: () => ({})
    },
    agreementQuestions: {
      type: Array,
      default: () => []
    },
    finalQuestions: {
      type: Array,
      default: () => []
    },
    displayCyberCoverage: {
      type: Boolean,
      default: false
    },
    transaction: {
      type: String,
      default: () => uuid4()
    },
    funnelName: {
      type: String,
      required: false,
      default: FunnelHomeContents
    }
  },
  data() {
    return {
      ready: false,
      contentProvider: GetContentProvider(this.textContent),
      formData: {
        contentsBuildingType: {},
        insuredObjectAddress: {},
        rental: undefined, // force reactivity on hidden by default input
        ...this.defaults
      },
      questions: {}
    }
  },
  computed: {
    steps() {
      return [
        this.contentProvider.getContentByPath('steps.setup.title'),
        this.contentProvider.getContentByPath('steps.coverages.title'),
        this.contentProvider.getContentByPath('steps.acceptance.title'),
        this.contentProvider.getContentByPath('steps.personalData.title'),
        this.contentProvider.getContentByPath('steps.payment.title'),
        this.contentProvider.getContentByPath('steps.finalQuestions.title'),
        this.contentProvider.getContentByPath('steps.overview.title')
      ]
    },
    questionRenderType() {
      return QuestionRenderType
    },
    productCode() {
      return this.assign?.premiumRequestProductCode || 'M001-816'
    }
  },
  provide() {
    return {
      formData: this.formData, // legacy support?
      textContent: this.textContent,
      textContentMapper: this.textContentMapper,
      contentProvider: this.contentProvider
    }
  },
  async mounted() {
    await this.fetchPremiumRequestFields()
    this.ready = true
  },
  methods: {
    async handleSubmit() {
      await this.submitFunnel(mapHomeContentsToInputModel(PolicyAction.Create))
    },
    textContentMapper(textKey) {
      if (Object.keys(PersonalDataTextSetMap).includes(textKey)) {
        return PersonalDataTextSetMap[textKey]
      }

      return textKey
    }
  }
}
</script>
