//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      open: false,
      selected: this.default || this.options[0] || []
    }
  },
  mounted() {
    this.$emit('input', this.selected)
  },
  watch: {
    default(newValue) {
      this.selected = newValue
    }
  }
}
