<template>
  <div class="documents documents--claims-details">
    <DocumentsHeading>
      <DocumentsProp>{{ fields.status | ScString }}</DocumentsProp>
      <DocumentsProp>{{ fields.damageDate | ScString }}</DocumentsProp>
      <DocumentsProp trivial>{{ fields.number | ScString }}</DocumentsProp>
      <DocumentsProp>{{ fields.product | ScString }}</DocumentsProp>
      <DocumentsProp trivial>{{ fields.type | ScString }}</DocumentsProp>
      <DocumentsProp trivial>{{ fields.moreInfo | ScString }}</DocumentsProp>
      <DocumentsProp></DocumentsProp>
    </DocumentsHeading>
    <!-- Rows -->
    <DocumentsArticle
      v-for="claim in claims"
      :key="claim.number"
      :status="claim.isSettled | asCycleStatus"
      :is-open="isDetailOpen(claim.number)"
      @toggle="toggleClaim(claim.number)"
    >
      <DocumentsProp>
        <CycleDate :date="claim.damageDate" />
      </DocumentsProp>
      <DocumentsProp trivial>{{ claim.number }}</DocumentsProp>
      <DocumentsProp>{{ getClaimProduct(claim) }}</DocumentsProp>
      <DocumentsProp trivial>{{ getClaimType(claim) }}</DocumentsProp>
      <DocumentsProp trivial>
        <template v-if="claim.detailTextExists">
          <a :href="claim.detailsUrl" :target="claim.target" v-if="claim.method !== 'POST'">
            <IconButton
              button-modifiers="link"
              :label="claim.detailsText"
              data-testid="testId"
              icon="chevron-right"
            />
          </a>
          <form
            v-else-if="claim.method === 'POST'"
            :action="claim.detailsUrl"
            method="POST"
            target="_blank"
          >
            <input
              type="hidden"
              v-for="param in claim.params"
              :key="param[0]"
              :name="param[0]"
              :value="param[1]"
            />
            <IconButton
              button-modifiers="link"
              :label="claim.detailsText"
              data-testid="testId"
              icon="chevron-right"
            />
          </form>
        </template>
        <div v-else>{{ claim.detailsText }}</div>
      </DocumentsProp>
      <DocumentsProp>
        <CycleIcon icon="plus" />
      </DocumentsProp>
      <template #details>
        <CycleList class="showonmobile" modifiers="nomarker">
          <CycleListItem>
            <label>{{ fields.number | ScString }}</label>
            <p>{{ claim.number }}</p>
          </CycleListItem>
          <CycleListItem>
            <label>{{ fields.type | ScString }}</label>
            <p>{{ getClaimType(claim) }}</p>
          </CycleListItem>
          <CycleListItem>
            <label>{{ fields.moreInfo | ScString }}</label>
            <template v-if="claim.detailTextExists">
              <a :href="claim.detailsUrl" :target="claim.target" v-if="claim.method !== 'POST'">
                <IconButton
                  button-modifiers="link"
                  :label="claim.detailsText"
                  data-testid="testId"
                  icon="chevron-right"
                />
              </a>
              <form
                v-else-if="claim.method === 'POST'"
                :action="claim.detailsUrl"
                method="POST"
                target="_blank"
              >
                <input
                  type="hidden"
                  v-for="param in claim.params"
                  :key="param[0]"
                  :name="param[0]"
                  :value="param[1]"
                />
                <IconButton
                  button-modifiers="link"
                  :label="claim.detailsText"
                  data-testid="testId"
                  icon="chevron-right"
                />
              </form>
            </template>
            <p v-else>{{ claim.detailsText }}</p>
          </CycleListItem>
        </CycleList>
      </template>
    </DocumentsArticle>
  </div>
</template>

<script>
import {
  DocumentsHeading,
  DocumentsProp,
  DocumentsArticle,
  CycleDate,
  CycleIcon,
  CycleList,
  CycleListItem,
  IconButton
} from '@aon/cycle'
/**
 * Wrapper for claim documents
 *
 * Provides a 6 column layout with a details link.
 * for the 5 column layout (without details button), see DocumentClaims.vue
 */
export default {
  name: 'DocumentClaimsDetails',
  components: {
    DocumentsHeading,
    DocumentsProp,
    DocumentsArticle,
    CycleDate,
    CycleList,
    CycleListItem,
    CycleIcon,
    IconButton
  },
  filters: {
    asCycleStatus(isSettled) {
      return isSettled ? 'confirmed' : 'in-progress'
    }
  },
  props: {
    claims: { type: Array, default: () => [] },
    fields: { type: Object, default: () => {} }
  },
  data() {
    return {
      selectedClaimNumbers: []
    }
  },
  methods: {
    toggleClaim(claimNumber) {
      if (this.selectedClaimNumbers.includes(claimNumber)) {
        this.selectedClaimNumbers.splice(this.selectedClaimNumbers.indexOf(claimNumber), 1)
      } else {
        this.selectedClaimNumbers.push(claimNumber)
      }
    },
    isDetailOpen(claimNumber) {
      return this.selectedClaimNumbers.includes(claimNumber)
    },
    getClaimType(claim) {
      return claim?.type?.value || this.fields?.typeFallback?.value || ''
    },
    getClaimProduct(claim) {
      return claim?.product?.value || this.fields?.product?.value || ''
    }
  }
}
</script>
