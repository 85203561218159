<template>
  <div class="fixed-box" :class="classList">
    <div @click="close" class="fixed-box__close-button"><span class="icon icon--cross"></span></div>
    <ScPlaceholder name="jss-sticky-box" :rendering="rendering" />
  </div>
</template>
<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'StickyBox',
  components: {
    ScPlaceholder: Placeholder
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    classList() {
      return {
        'fixed-box--active': this.isActive
      }
    }
  },
  mounted() {
    this.isActive = true
  },
  methods: {
    close() {
      this.isActive = false
    }
  }
}
</script>
