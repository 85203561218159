<template>
  <ol :class="['busstop', `busstop--length-${steps.length}`]">
    <li
      class="busstop__stop"
      v-for="(step, index) in computedSteps"
      :class="{ 'busstop__stop--visited': index < internalCurrentStep }"
      :key="index"
      :aria-current="step.ariaCurrent"
      @click="index < internalCurrentStep ? selectCurrentStep(index) : null"
    >
      <button class="busstop__button" type="button">
        <div class="busstop__label">
          <div class="busstop__heading">{{ getStepText(index) }}</div>
          {{ step.text }}
        </div>
      </button>
    </li>
  </ol>
</template>

<script>
import { dictionaryMixin } from '@/mixins'
export default {
  name: 'CycleBusstop',
  mixins: [dictionaryMixin],
  props: {
    steps: {
      type: [Array, String],
      required: false,
      default: () => []
    },
    currentStep: {
      type: Number,
      required: false,
      default: () => 0
    },
    stopLabel: {
      type: String,
      required: false,
      default: () => 'Stap'
    }
  },
  data() {
    return {
      internalCurrentStep: 0
    }
  },
  computed: {
    computedSteps() {
      return this.steps.map(
        (step, index) => ({
          text: step,
          ariaCurrent: index === this.internalCurrentStep ? 'step' : null
        }),
        this
      )
    }
  },
  watch: {
    currentStep(step) {
      this.internalCurrentStep = step
    }
  },
  mounted() {
    this.internalCurrentStep = this.currentStep
  },
  methods: {
    selectCurrentStep(index) {
      this.$emit('navigation', index)
      this.internalCurrentStep = index
    },
    getStepText(index) {
      return `${this.phrase('navigation.step', 'Stap')} ${index + 1}`
    }
  }
}
</script>