export const SET_FORM_DEFINITION_ID = 'SET_FORM_DEFINITION_ID'
export const SET_FORM_DEFINITION = 'SET_FORM_DEFINITION'
export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE'
export const SET_FORM_FIELD_VALUES = 'SET_FORM_FIELD_VALUES'
export const SET_ACTIVE_STEP_BY_INDEX = 'SET_ACTIVE_STEP'
export const SET_FORM_STATE = 'SET_FORM_STATE'
export const SET_BUSINESS_DATA = 'SET_BUSINESS_DATA'
export const CLEAR_BUSINESS_DATA_VALUE = 'CLEAR_BUSINESS_DATA_VALUE'
export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const RESET_FORM = 'RESET_FORM'
export const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD'
export const REMOVE_ACTIVE_FIELD = 'REMOVE_ACTIVE_FIELD'
export const SET_VALIDATION_STATUS = 'SET_VALIDATION_STATUS'
export const SET_ERROR_ELEMENTS = 'SET_ERROR_ELEMENTS'
export const SET_INVALID_ELEMENTS = 'SET_INVALID_ELEMENTS'
export const SET_TOUCHED_ELEMENT = 'SET_TOUCHED_ELEMENT'
export const SET_COMMUNICATION_START = 'SET_COMMUNICATION_START'
export const SET_COMMUNICATION_SUCCESS = 'SET_COMMUNICATION_SUCCESS'
export const SET_COMMUNICATION_FAILURE = 'SET_COMMUNICATION_FAILURE'
export const SET_COMMUNICATION_BLOCK_UI_LOADER = 'SET_COMMUNICATION_BLOCK_UI_LOADER'
export const RESET_COMMUNICATION_STATE = 'CLEAR_COMMUNICATION_STATE'
export const SET_FORM_DATA_FORMATTED = 'SET_FORM_DATA_FORMATTED'
export const SET_PREVIOUS_STEP = 'SET_PREVIOUS_STEP'
export const CLEAR_ERRORS_BY_KEY = 'CLEAR_ERRORS_BY_KEY'
export const CLEAR_SUCCESS_BY_KEY = 'CLEAR_SUCCESS_BY_KEY'
export const SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA'
