<template>
  <input
    type="number"
    v-bind="Object.assign({}, attributes, $attrs)"
    v-on="Object.assign({}, listeners, $listeners, { input: updateValue })"
    :value="value"
    :class="formInputClass"
  />
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleNumberInput',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      const maxLength = this.$el.getAttribute('maxlength')
      if (event.target.value.length > maxLength && !!maxLength){
        event.target.value = event.target.value.slice(0, maxLength)
      }
      this.$emit('input', event.target.value)
    }
  }
}
</script>
