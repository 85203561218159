<template>
  <form :action="preflightUrl" method="GET" ref="form">
    <input type="hidden" name="url" :value="redirectUrl" />
  </form>
</template>
<script>
export default {
  name: 'CedPreflight',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    redirectUrl() {
      return JSON.parse(this.fields?.body)?.url
    },
    preflightUrl() {
      return this.fields?.preflightUrl
    }
  },
  mounted() {
    this.submitForm()
  },
  methods: {
    submitForm() {
      if (this.preflightUrl) {
        this.$refs.form.submit()
      }
    }
  }
}
</script>
