<template>
  <ul>
    <li class="navigation__item">
      <input
        type="checkbox"
        class="nav-toggle"
        :id="subgroupNavigationToggleId"
        :name="subgroupNavigationToggleId"
      />
      <label
        class="nav-trigger nav-trigger--next"
        :for="subgroupNavigationToggleId"
        v-if="subgroupHeader"
      >
        <span>{{ subgroupHeader }}</span></label
      >
      <ul class="navigation__sub" v-if="hasSubGroupItems">
        <li class="navigation__item" v-if="subgroupHeader">
          <label class="nav-trigger nav-trigger--back" :for="subgroupNavigationToggleId">
            {{ subgroupHeader }}</label
          >
        </li>

        <NavigationItem
          v-for="item in items"
          :key="item.id"
          :href="getHref(item)"
          :title="getTitle(item)"
          :showAsButton="false"
          :is-active="isActive(item)"
        />
      </ul>
    </li>
  </ul>
</template>

<script>
import { CycleUidMixin } from '@/mixins'
import { NavigationItem } from '@/components/elements/NavigationItem'

export default {
  name: 'NavigationSubGroup',
  mixins: [CycleUidMixin],
  components: {
    NavigationItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    subgroupHeader: {
      type: String
    }
  },
  computed: {
    hasSubGroupItems() {
      return this.items?.length > 0
    },
    subgroupNavigationToggleId() {
      return `toggle_${this._cycleUid}`
    }
  },
  methods: {
    getHref(item) {
      return item?.fields?.Link?.value?.href || ''
    },
    getTitle(item) {
      return item?.fields?.Title?.value || ''
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
</script>
