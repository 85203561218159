//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logError } from '@/services/Logging'
import BusyIndicator from '@/components/molecules/BusyIndicator.vue'

export default {
  name: 'Collapser',
  components: { BusyIndicator },
  props: {
    openTitle: {
      type: String,
      required: false,
      default: 'Close'
    },
    closedTitle: {
      type: String,
      required: false,
      default: 'Open'
    },
    beforeOpen: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      open: false,
      loading: false
    }
  },
  computed: {
    title() {
      return this.open ? this.openTitle : this.closedTitle
    },
    accordionHeaderClassName() {
      const className = 'accordion__header'
      return {
        [`${className}`]: true,
        [`${className}--open`]: this.open
      }
    }
  },
  watch: {
    open() {
      this.$emit('change', this.open)
    }
  },
  methods: {
    async toggleOpenState() {
      return await this.setOpenState(!this.open)
    },
    async setOpenState(state) {
      if (this.beforeOpen && state === true) {
        let openResult = true
        try {
          this.loading = true
          openResult = await this.beforeOpen()
        } catch (e) {
          logError(e)
        } finally {
          this.loading = false
          if (openResult !== false) {
            this.open = state
          }
        }
      } else {
        this.open = state
      }
    }
  }
}
