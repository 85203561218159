import gql from 'graphql-tag'

export const policyItemLiability = gql`
  fragment policyItemLiability on Policy {
    item {
      ... on Liability {
        householdComposition
        liabilityConfiguration {
          mainCoverage {
            liabilityConsumers {
              active
            }
          }
          insuredAmount
        }
      }
    }
  }
`
