//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/stores/profile'
import {
  CycleHeading,
  CycleButton,
  CycleSelect,
  CycleOption,
  CycleRadioInput,
  CycleTextInput,
  CycleDateInput
} from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { uninsurableMessagesMap } from '../../constants/constants'

import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { intlMixin, dictionaryMixin, storeModuleMixinFactory } from '@/mixins'
import format from 'date-fns/format'
import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'

export default {
  name: 'HomeContentsStepSetup',
  inject: ['formData', 'textContent', 'contentProvider'],
  components: {
    CycleTextInput,
    CycleDateInput,
    CycleFormSet,
    CycleInputContainer,
    CycleButton,
    CycleSelect,
    CycleOption,
    CycleRadioInput,
    CycleFormPage,
    CycleHeading,
    ProvidedText,
    ProvidedTooltip
  },
  mixins: [
    intlMixin,
    dictionaryMixin,
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      gettersMapping: ['prefilledBuildingTypeKey']
    })
  ],
  props: {
    contentsBuildingTypes: {
      type: Array,
      required: true
    },
    householdCompositions: {
      type: Array,
      required: true
    }
  },
  computed: {
    isUninsurable() {
      return this.formData.contentsBuildingType?.isInsurable === false
    },
    uninsurableMessage() {
      const selectedKey = this.formData.contentsBuildingType?.key || 'none'
      return uninsurableMessagesMap[selectedKey]()
    },
    minAge() {
      return format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    maxAge() {
      return format(addDays(subYears(new Date(), 110), 1), 'yyyy-MM-dd')
    }
  },
  mounted() {
    const prefilledBuildingType = this.prefilledBuildingTypeKey
      ? this.contentsBuildingTypes.find((cbt) => cbt.key === this.prefilledBuildingTypeKey)
      : null
    if (prefilledBuildingType) {
      this.formData.contentsBuildingType = prefilledBuildingType
    }
  },
  methods: {
    handleSubmit() {
      const {
        contentsBuildingType,
        insuredObjectAddress,
        birthDate,
        householdComposition
      } = this.formData

      this.$emit('submit', {
        contentsBuildingType,
        insuredObjectAddress,
        birthDate,
        householdComposition
      })
    }
  }
}
