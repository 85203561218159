//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCheckboxInput } from '@/components/elements'
import { CycleHtml } from '@/components/base'
import { CycleInputContainer } from '@/components/functional/forms'

import { QuestionMixin } from './QuestionMixin'
import { EmptyAnswer, CheckboxAnswer } from '../models'

export default {
  name: 'QuestionCheckBox',
  components: {
    CycleCheckboxInput,
    CycleHtml,
    CycleInputContainer
  },
  mixins: [QuestionMixin],
  props: {
    labels: {
      type: Object,
      required: true,
      default: () => ({
        checkbox: 'Ik ga akkoord'
      })
    },
    value: {
      type: Object,
      required: true,
      default: () => new EmptyAnswer()
    }
  },
  methods: {
    handleChange(boxesChecked) {
      this.$emit('input', new CheckboxAnswer(boxesChecked.length > 0))
    }
  }
}
