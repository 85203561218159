<template>
  <div>
    <label :class="checkoutItemClass" @click.stop="control">
      <input
        type="checkbox"
        data-cy="checkout-item"
        :name="name"
        :readonly="disabled"
        :disabled="disabled"
        :checked="checked"
        v-testable="$attrs.name"
        @change="updateValue"
      />
      <!-- Check if the prepend icon is set -->
      <template v-if="prependIcon">
        <span :class="`icon icon--${prependIcon}`"></span>
        <strong class="prepend-label">{{ label }}</strong>
      </template>
      <template v-else>
        <strong>{{ label }}</strong>
      </template>
      <!-- tooltip icon for new toggle tooltip -->
      <a
        class="tooltip"
        @click.prevent="isToggled = !isToggled"
        tabindex="1"
        v-if="tooltip && hasToggleTooltip"
      ></a>
      <!-- Modal Tooltip -->
      <ModalTooltip
        v-if="showModalTooltip"
        :tooltip-has-long-text="tooltipHasLongText"
        :tooltip-link-text="tooltipLinkText"
      >
        <template #tooltip-heading>{{ label }}</template>
        <template #tooltip-content>
          <CycleHtml :html="tooltip" />
          <template v-if="tooltipFeedback">
            <TooltipFeedback :subject="label" />
          </template>
        </template>
      </ModalTooltip>

      <!-- Popover Tooltip based on certain conditions-->
      <PopoverTooltip v-if="tooltip && showPopoverTooltip" :tooltip-link-text="tooltipLinkText">
        <template #tooltip-content>
          <slot name="tooltip-content">
            <CycleHtml :html="tooltip" />
            <template v-if="tooltipFeedback">
              <TooltipFeedback :subject="label" />
            </template>
          </slot>
        </template>
      </PopoverTooltip>
    </label>
    <span class="checkout-item__price">{{ price }}</span>
    <div v-if="description" class="checkout-item__description">
      <CycleHtml :html="description" />
    </div>

    <!-- Toggle Tooltip based on -->
    <ToggleTooltip v-if="showToggleTooltip" @close="isToggled = false">
      <template #tooltip-content>
        <slot name="tooltip-content">
          <CycleHtml :html="tooltip" />
          <template v-if="tooltipFeedback">
            <TooltipFeedback :subject="label" />
          </template>
        </slot>
      </template>
    </ToggleTooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CycleCheckout, CycleCheckoutItem, CycleHtml, ModalTooltip } from '@aon/cycle'
import PopoverTooltip from '../molecules/PopoverTooltip'
import TooltipFeedback from '../molecules/TooltipFeedback'

export default {
  name: 'RapidFormCheckoutWrapper',
  components: {
    ModalTooltip,
    CycleCheckout,
    CycleCheckoutItem,
    CycleHtml,
    PopoverTooltip,
    TooltipFeedback
  },
  props: {
    label: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipHeading: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: Boolean,
      required: false
    },
    fieldKey: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    defaultPrice: {
      type: String,
      required: false
    },
    forceValue: {
      type: Boolean,
      required: false
    },
    hasToggleTooltip: {
      type: Boolean,
      required: false
    },
    prependIcon: {
      type: String,
      required: false
    },
    hasPopoverTooltip: {
      type: Boolean,
      required: false
    },
    tooltipHasLongText: {
      type: Boolean,
      required: false
    },
    tooltipLinkText: {
      type: Object,
      required: false,
      default: () => {}
    },
    tooltipFeedback: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isToggled: false
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', ['businessData', 'activeStep']),
    checked() {
      return this.modelValue || this.disabled
    },
    price() {
      return this.forceValue
        ? this.defaultPrice
        : this.businessData?.[this.fieldKey]?.priceFormatted ||
            this.businessData?.[this.fieldKey]?.items[0]?.priceFormatted ||
            this.defaultPrice
    },
    showToggleTooltip() {
      return (this.tooltip && this.hasToggleTooltip && this.isToggled) ?? false
    },
    checkoutItemClass() {
      const mainClassName = 'checkout-item__checkbox'
      return !this.prependIcon ? `${mainClassName}` : `${mainClassName}-icon`
    },
    showPopoverTooltip() {
      return (this.tooltip && this.hasPopoverTooltip) ?? false
    },
    showModalTooltip() {
      return (this.tooltip && !this.hasToggleTooltip && !this.showPopoverTooltip) ?? false
    }
  },
  methods: {
    control(event) {
      const isTooltipOpen = event.target?.closest('.modal, .popover-tooltip__alert') ?? false
      const isAnchorTag = event.target?.tagName === 'A' ?? false
      if (
        /tooltip/gi.test(event.target.className) ||
        event.target.readonly ||
        (isTooltipOpen && !isAnchorTag)
      ) {
        event.preventDefault()
      }
    },
    updateValue(event) {
      this.$emit('blur', event.target.checked)
      this.$emit('change', event.target.checked)
    }
  }
}
</script>
