//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleUidMixin } from '../../mixins/uid'
import { NavigationItem } from '@/components/elements/NavigationItem'
import { SubNavigationItem } from '@/components/molecules'
import menuScript from './scripts/menuScript'

export default {
  name: 'Navigation',
  components: {
    NavigationItem,
    SubNavigationItem
  },
  directives: {
    menuScript: {
      inserted: menuScript
    }
  },
  mixins: [CycleUidMixin],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    logoLink: {
      type: String,
      required: false,
      default: '/'
    },
    logo: {
      type: Object,
      required: false
    }
  },
  computed: {
    navigationToggleId() {
      return `main-navigation-toggle-${this._cycleUid}`
    },
    hasNavigationItems() {
      return !!this.items?.length
    },
    logoSrc() {
      return this.logo?.src
    },
    logoAlt() {
      return this.logo?.alt
    }
  },
  methods: {
    href(item) {
      return item?.fields?.Link?.value?.href
    },
    title(item) {
      return item?.fields?.Title?.value
    },
    showAsButton(item) {
      return item?.name === 'Inloggen'
    },
    getSubItems(item) {
      return item?.fields?.items ?? []
    },
    hasSubItems(item) {
      const subItems = this.getSubItems(item)
      return subItems.length > 0
    },
    isLanguageSwitch(item) {
      return !!item?.fields?.isLanguageSwitch
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
