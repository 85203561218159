<template>
  <section class="premium-calculation-tool">
    <div>
      <section>
        <form :action="actionLink" @submit="submitForm">
          <input type="hidden" name="cn" :value="getCN" />
          <input type="hidden" name="er" :value="eigenRisicoIndex" />
          <input type="hidden" name="av" :value="av" />
          <input type="hidden" name="hv" :value="hv" />
          <input type="hidden" name="tv" :value="tv" />
          <fieldset class="form-head">
            <div>
              <label for="selectage">Mijn <span class="bold">leeftijd</span> is</label>
              <select id="selectage" @change="changeAge">
                <option v-for="(item, index) in age" :key="`selectage-${index}`" :value="index">
                  {{ item }}
                </option>
              </select>
            </div>
            <div>
              <label for="ownrisk">
                en ik kies een <span class="bold">eigen risico</span> van
              </label>
              <button
                type="button"
                @click="showModal('risk')"
                class="modal-link"
                title="Premium Details"
              >
                <span class="info-icon" />
              </button>
              <select id="ownrisk" @change="changeRisk">
                <option
                  v-for="(item, index) in eigenRisico"
                  :key="`ownRisk-${index}`"
                  :value="index"
                >
                  &euro; {{ item[0] }}
                </option>
              </select>
            </div>
          </fieldset>
          <div class="form-body">
            <fieldset>
              <div>
                <label class="basic">
                  Ik wil de volgende <span class="bold">basisverzekering</span>
                  <button
                    type="button"
                    @click="showModal('basic')"
                    class="modal-link"
                    title="Premium Details"
                  >
                    <span class="info-icon" />
                  </button>
                </label>
                <div class="styled-radios">
                  <!-- set class to active on selected item  -->
                  <div
                    v-for="(item, index) in basisPakket"
                    :key="`basisPakket-${index}`"
                    :class="{ active: activeItem === index }"
                  >
                    <input
                      :id="item.key"
                      type="radio"
                      name="basis"
                      :value="index"
                      @change="changeBasis"
                    />
                    <label :for="item.key">
                      {{ item.key }} <span>&euro; {{ formatNumber(item.value[0]) }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-selectbox additional">
                <label for="additional">
                  Ik kies de volgende <span class="bold">aanvullende verzekering</span>
                  <button
                    type="button"
                    @click="showModal('additional')"
                    class="modal-link"
                    title="Premium Details"
                  >
                    <span class="info-icon" />
                  </button>
                </label>
                <Dropdown
                  v-if="aanvullend.length"
                  :options="aanvullend"
                  class="select"
                  :default="selectedAdditional"
                  @input="changeAanvullend"
                />
              </div>
              <div class="form-selectbox dental">
                <label for="dental">
                  Ik kies de volgende <span class="bold">tandartsverzekering</span>
                  <button
                    type="button"
                    @click="showModal('dental')"
                    class="modal-link"
                    title="Premium Details"
                  >
                    <span class="info-icon" />
                  </button>
                </label>
                <Dropdown
                  v-if="tandarts.length"
                  :options="tandarts"
                  class="select"
                  :default="selectedDental"
                  @input="changeDental"
                />
              </div>
            </fieldset>
          </div>
          <div id="stickyPlaceholder" class="sticky"></div>
          <div id="sticky">
            <fieldset class="premium">
              <div class="deduction">
                <span>Korting eigen risico</span>
                <span>- &euro; {{ formatNumber(eigenRisicoKorting) }}</span>
              </div>
              <div class="total">
                Premie totaal
                <div>
                  <span>&euro; {{ premiumComma }}</span>
                  <span class="term visible-xs"> per maand</span>
                </div>
              </div>
            </fieldset>
          </div>
          <button value="Vraag verzekering aan" class="btn btn-submit" type="submit">
            Vraag verzekering aan
          </button>
        </form>
      </section>
    </div>
    <Transition>
      <div class="premium-details-modal" v-if="isModalVisible">
        <div class="content">
          <div class="head">
            <span v-if="modalText == 'risk'">Eigen risico</span>
            <span v-if="modalText == 'basic'">Basisverzekering</span>
            <span v-if="modalText == 'additional'">Aanvullende verzekering</span>
            <span v-if="modalText == 'dental'">Tandartsverzekering</span>
            <span class="close" @click="showModal('close')">&#9587;</span>
          </div>
          <!-- EIGEN RISICO -->
          <div class="body" v-if="modalText == 'risk'">{{ eigenRisicoModalText }}</div>
          <!-- BASIC COVERAGE -->
          <div class="body" v-if="modalText == 'basic'">
            <div class="basic-coverage">
              <div
                v-for="(entry, index) in BasicCoverageModal"
                :key="`BasicCoverageModal-${index}`"
                class="item"
              >
                <span>{{ entry.title }}</span>
                <ul>
                  <li
                    v-for="(listItem, idx) in entry.body"
                    :key="`BasicCoverageModal-listItem-${index}-${idx}`"
                  >
                    {{ listItem }}
                  </li>
                </ul>
                <div class="basic-premium">
                  &euro; {{ formatNumber(entry.premium) }}
                  <span>{{ entry.term }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- AANVULLEND -->
          <div class="body" v-if="modalText == 'additional'">
            <table class="hidden-xs stars">
              <tbody>
                <tr>
                  <th class="headers"></th>
                  <th class="headers">&#9733;</th>
                  <th class="headers">&#9733;&#9733;</th>
                  <th class="headers">&#9733;&#9733;&#9733;</th>
                  <th class="headers">&#9733;&#9733;&#9733;&#9733;</th>
                </tr>
                <tr
                  v-for="(entry, index) in aanvullendeModal.additionalLarge.slice(0, -1)"
                  :key="`additionalLarge${index}`"
                >
                  <td
                    v-for="(item, idx) in entry"
                    :key="`additionalLarge-item-${index}-${idx}`"
                    class="korting"
                    :class="{ labels: idx === 0 }"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr
                  v-for="(modalItem, index) in aanvullendeModal.additionalLarge.slice(-1)[0]"
                  :key="`premiumAanvullendeRow${index}`"
                >
                  <td
                    v-for="(item, idx) in modalItem"
                    :key="`aanvullendeModal-${index}-${idx}`"
                    :class="{ 'clear price text-center': idx !== 0, 'clear leeftijd': idx === 0 }"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </table>
            <section class="table visible-xs">
              <div class="col-md-10 col-md-offset-1">
                <p class="bestGekozenMarker aanvullend-xs">
                  <span>Meest gekozen!</span>
                  <img
                    src="https://www.mbozorgverzekerd.nl/global/img/arrow-bestgekozen.png"
                    alt="arrow"
                  />
                </p>
              </div>
              <article
                v-for="(entry, index) in aanvullendeModal.additionalSmall"
                :key="`additionalSmall${index}`"
              >
                <div class="head">{{ entry.title }}</div>
                <div
                  class="listitem"
                  v-for="(item, idx) in entry.items"
                  :key="`additionalSmall-entries-${index}-${idx}`"
                >
                  <h4>{{ item.header }}</h4>
                  <p>{{ item.content }}</p>
                </div>
              </article>
            </section>
          </div>
          <!-- DENTAL -->
          <div class="body" v-if="modalText == 'dental'">
            <table class="hidden-xs stars">
              <tbody>
                <tr>
                  <!--th class="clear"></th-->
                  <th></th>
                  <th class="fs-normal headers">Tand basis</th>
                  <th class="headers">&#9733;</th>
                  <th class="headers">&#9733;&#9733;</th>
                  <th class="headers">&#9733;&#9733;&#9733;</th>
                  <th class="headers">&#9733;&#9733;&#9733;&#9733;</th>
                </tr>
                <tr v-for="(entry, index) in tandModal.slice(0, -1)" :key="`tandModal${index}`">
                  <td
                    v-for="(item, idx) in entry"
                    :key="`tandModal-item-${index}-${idx}`"
                    class="korting"
                    :class="{ labels: idx === 0 }"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr
                  v-for="(modalItem, index) in tandModal.slice(-1)[0]"
                  :key="`premiumtandRow${index}`"
                >
                  <td
                    v-for="(item, idx) in modalItem"
                    :key="`premiumtandcell-${index}-${idx}`"
                    :class="{ 'clear price text-center': idx !== 0, 'clear leeftijd': idx === 0 }"
                  >
                    {{ item }}<br />
                    <span class="fs-normal">per maand</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END INFO BLOCKS -->
          <div class="foot">
            <button type="button" @click="showModal('close')" class="btn">Close</button>
          </div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
import { InsurancePriceDropdown as Dropdown } from '@aon/cycle'

export default {
  name: 'PremiumCalcSitecore',
  components: {
    Dropdown
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const risico = Number.parseFloat(this.params.basicEigenRisico) || 0
    let vrijwilligRisico = []
    let eigenRisicoDiscountMultiplier = 0
    try {
      vrijwilligRisico = JSON.parse(this.params.vrijwilligEigenRisico).value
      eigenRisicoDiscountMultiplier =
        Number.parseFloat(JSON.parse(this.params.eigenRisicoDiscountMultiplier)) || 0
    } catch (e) {
      vrijwilligRisico = []
    }

    return {
      eigenRisico: vrijwilligRisico.map((item, idx) => [
        item + risico,
        eigenRisicoDiscountMultiplier * idx
      ]),
      eigenRisicoKorting: 0,
      eigenRisicoIndex: 0,
      basisPremium: 0.0,
      activeItem: 10,
      aanvullend: [],
      aanvullendPremium: 0.0,
      tandarts: [],
      tandartsPremium: 0.0,
      premium: 0.0,
      premiumComma: 0.0,
      isModalVisible: false,
      modalText: '',
      selectedAge: 0,
      hv: 1,
      av: 1,
      tv: 1,
      selectedAdditional: null,
      selectedDental: null,
      selectedDentalIndex: 0,
      selectedAdditionalIndex: 0,
      stars: [
        { name: 'Geen', value: null },
        { name: 'BASIS PLUS', value: 0 },
        { name: '&#9733;&#9734;&#9734;&#9734;', value: 1 },
        { name: '&#9733;&#9733;&#9734;&#9734;', value: 2 },
        { name: '&#9733;&#9733;&#9733;&#9734;', value: 3 },
        { name: '&#9733;&#9733;&#9733;&#9733;', value: 4 }
      ]
    }
  },
  computed: {
    actionLink() {
      try {
        const actionLink = JSON.parse(this.params.FormActionLinkParam)
        return `https://secure.zilverenkruis.nl/PremieBerekenen?${actionLink.key}=${actionLink.value}`
      } catch (e) {
        return null
      }
    },
    getCN() {
      try {
        const actionLink = JSON.parse(this.params.FormActionLinkParam)
        return actionLink.value
      } catch (e) {
        return 0
      }
    },
    basisPakket() {
      try {
        return [
          JSON.parse(this.params.basisBudget),
          JSON.parse(this.params.basisZeker),
          JSON.parse(this.params.basisExclusive)
        ]
      } catch (e) {
        return []
      }
    },
    eigenRisicoModalText() {
      try {
        return this.params.eigenRisicoModal
      } catch (e) {
        return ''
      }
    },
    BasicCoverageModal() {
      try {
        return JSON.parse(this.params.basicCoverageModal).data
      } catch (e) {
        return []
      }
    },
    aanvullendeModal() {
      try {
        return JSON.parse(this.params.aanvullendeModal)
      } catch (e) {
        return []
      }
    },
    tandModal() {
      try {
        return JSON.parse(this.params.tandModal).data
      } catch (e) {
        return []
      }
    },
    age() {
      try {
        return JSON.parse(this.params.AanvullendeVerzekeringen).value.map((p) => p.name)
      } catch (e) {
        return ''
      }
    },
    AanvullendeVerzekeringen() {
      try {
        return JSON.parse(this.params.AanvullendeVerzekeringen).value.map((p) => p.value)
      } catch (e) {
        return []
      }
    },
    TandartsVerzekeringen() {
      try {
        return JSON.parse(this.params.Tandartsverzekeringen).value.map((p) => p.value)
      } catch (e) {
        return []
      }
    },
    premiumCommaFormatted() {
      return this.premiumComma.toString().replace('.', ',')
    }
  },
  mounted() {
    this.changeAge({ target: { value: 0 } })
    this.changeBasis({ target: { value: 0 } })
  },
  methods: {
    submitForm(e) {
      e.preventDefault()

      window.dataLayer.push({
        event: 'vraag_verzekering_aan'
      })

      window.location.href =
        this.actionLink + `&er=${this.eigenRisicoIndex}&av=${this.av}&hv=${this.hv}&tv=${this.tv}`
    },
    generateOptionsArray(arr) {
      return arr.map((item, idx) => {
        return {
          index: idx,
          key: this.stars[idx].name,
          selectedValue: this.stars[idx].value,
          value: '&euro;' + this.formatNumber(item),
          originalValue: item
        }
      })
    },
    changeAge(event) {
      this.aanvullend = this.generateOptionsArray(this.AanvullendeVerzekeringen[event.target.value])
      this.tandarts = this.generateOptionsArray(this.TandartsVerzekeringen[event.target.value])

      this.selectedAdditional =
        this.aanvullend[this.selectedAdditionalIndex] || this.selectedAdditional
      this.selectedDental = this.tandarts[this.selectedDentalIndex] || this.selectedDental
      this.changeAanvullend(this.selectedAdditionalIndex)
      this.changeDental(this.selectedDentalIndex)

      this.calculatePremium()
    },
    changeRisk(event) {
      this.eigenRisicoIndex = event.target.value == 0 ? 0 : parseInt(event.target.value) + 1
      this.calculatePremium()
    },
    changeBasis(event) {
      const selectedvalue = Number.parseInt(event.target.value) || 0
      this.hv = selectedvalue + 1

      this.activeItem = selectedvalue

      this.basisPremium = this.basisPakket[selectedvalue].value[0]
      this.calculatePremium()
    },
    changeAanvullend(event) {
      const index = event?.target?.value || event
      this.selectedAdditionalIndex = index
      if (this.aanvullend.length && this.aanvullend[index]) {
        this.aanvullendPremium = this.aanvullend[index].originalValue
        this.av = this.aanvullend[index].selectedValue
        this.selectedAdditional = this.aanvullend[index]
        this.calculatePremium()
      }
    },
    changeDental(event) {
      const index = event?.target?.value || event
      this.selectedDentalIndex = index
      if (this.tandarts.length && this.tandarts[index]) {
        this.tandartsPremium = this.tandarts[index].originalValue
        this.selectedDental = this.tandarts[index]
        this.tv = this.tandarts[index].selectedValue
        this.calculatePremium()
      }
    },
    calculatePremium() {
      if (this.basisPremium != 0) {
        const index = this.eigenRisicoIndex ? this.eigenRisicoIndex - 1 : 0

        this.premium = (
          this.basisPakket[this.activeItem].value[index] +
          this.aanvullendPremium +
          this.tandartsPremium
        ).toFixed(2)
        this.eigenRisicoKorting =
          this.basisPremium - this.basisPakket[this.activeItem].value[index].toFixed(2)
        this.premiumComma = this.premium.toString().replace('.', ',')
      } else {
        this.premiumComma = '0,0'
        this.eigenRisicoKorting = '0,0'
      }
    },
    showModal(which) {
      const body = document.body
      if (this.isModalVisible) {
        this.isModalVisible = false
        body.classList.remove('modalopen')
      } else {
        this.isModalVisible = true
        body.classList.add('modalopen')
      }
      this.modalText = which
    },
    formatNumber(n) {
      if (typeof n === 'number') {
        return n.toFixed(2).toString().replace('.', ',')
      } else if (typeof n === 'string') {
        try {
          return parseFloat(n.replace(',', '.').replace('*', '')).toFixed(2)
        } catch (e) {
          return n
        }
      }
      return n
    }
  }
}
</script>
