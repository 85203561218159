<template>
  <aside class="sidebar">
    <slot />
  </aside>
</template>

<script>
export default {
  name: 'CycleAside'
}
</script>
