//
//
//
//
//
//
//

import { CycleHtml } from '../base'

export default {
  name: 'CycleArticle',
  components: { CycleHtml },
  props: {
    bodytext: {
      type: String,
      required: false
    }
  }
}
