//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CyclePieChart',
  props: {
    segments: {
      type: Array,
      required: true
    }
  },
  computed: {
    piechartLabel() {
      const lastIndex = this.segments.length - 1
      return this.segments[lastIndex] || null
    },
    totalValues() {
      return this.segments.reduce((acc, seg) => acc + seg.value, 0)
    },
    styledSegments() {
      return this.segments.reduce((acc, segment, index) => {
        return [
          ...acc,
          {
            '--bg': segment.color,
            '--value': (segment.value / this.totalValues) * 100,
            '--offset': index > 0 ? this.getOffset(acc, index - 1) : 0,
            '--over50': (segment.value / this.totalValues) * 100 > 50 ? 1 : 0
          }
        ]
      }, [])
    }
  },
  methods: {
    labelClass(label) {
      const _className = 'piechart__label'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: label.isHighlighted
      }
    },
    getOffset(arr, index) {
      return arr[index]['--offset'] + arr[index]['--value']
    }
  }
}
