import { IAuthentication } from './models'

const getters = {
  totalSecondsLeftServer(state: IAuthentication): number {
    return state?.totalSecondsLeftServer ?? 0
  },
  forcedClose(state: IAuthentication): boolean {
    return state?.forcedClose
  }
}
export default getters
