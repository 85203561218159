<template>
  <div class="documents__legend documents-legend | hide-S">
    <span class="documents-legend__status" v-for="(status, name) in PolicyStatusValues" :key="name">
      <StatusIndicator :status="status" />
      {{ statusText(status, name) }}
    </span>
  </div>
</template>

<script>
import { pick, invert } from 'lodash'
import { StatusIndicator } from '@/components/atoms'
import { PolicyStatusValue } from '@/models/PolicyStatus'
import { dictionaryMixin } from '@/mixins'

/**
 * A legend for atoms/StatusIndicator.
 *
 * This legend describes each indicator, eliminating the need of descriptions
 * per implementation. The legend can be filtered to only show the indicators
 * used on the page.
 *
 * @since 3.4.0
 * @displayName Status Legend
 * @requires DictionaryService - injected as dictionaryService
 */
export default {
  name: 'StatusLegend',
  components: { StatusIndicator },
  mixins: [dictionaryMixin],
  props: {
    filter: {
      type: Array,
      default: null
    },
    isForInvoices: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    PolicyStatusValues() {
      if (this.filter == null || this.filter.length === 0) {
        return PolicyStatusValue
      }
      return invert(pick(invert(PolicyStatusValue), this.filter))
    }
  },
  methods: {
    statusText(status, name) {
      return this.isForInvoices
        ? this.phrase(`invoice-status-${status}`, name)
        : this.phrase(`generic-status-${status}`, name)
    }
  }
}
</script>
