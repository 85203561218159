<template>
  <CycleWithQueryParam query-key="insurance">
    <template #default="policyNumber">
      <CyclePolicyDetailsBlock :text-content="textContent" :policy-number="policyNumber" />
    </template>
  </CycleWithQueryParam>
</template>

<script>
import { CycleWithQueryParam } from '../../base'
import { CyclePolicyDetailsBlock } from '../../cycle-policy-details-block'

export default {
  name: 'CyclePolicyDetailsBlockSitecore',
  components: { CycleWithQueryParam, CyclePolicyDetailsBlock },
  props: {
    policyNumberLabel: {
      type: String,
      default: 'Policynumber'
    }
  },
  computed: {
    textContent() {
      return { policyNumber: this.policyNumberLabel }
    }
  }
}
</script>
