<template>
  <CycleButtonIconLink
    :href="href"
    :target="target"
    :icon="icon"
    :title="title"
    :class="{ 'btn--large btn--icon-next': isLarge }"
  >
    {{ linkLabel }}
    <div v-if="isLarge && hasIntroText" class="intro-text">{{ introText }}</div>
  </CycleButtonIconLink>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { InsuranceProduct } from '@/components/Modules/WSM/constants'

export default {
  name: 'IconButtonSitecore',
  components: {
    CycleButtonIconLink: Cycle.CycleButtonIconLink
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    href() {
      return this.fields?.link?.value?.href
    },
    linkLabel() {
      return this.fields?.link?.value?.text
    },
    target() {
      return this.fields?.link?.value?.target
    },
    title() {
      return this.fields?.link?.value?.title
    },
    icon() {
      // for now (October-2020) we only support insurance-products icon
      // as sitecore returns values from a fixed list, we need to map the values to the correct icons
      // therefor we reused this "InsuranceProduct" enum from WSM module
      return InsuranceProduct[this.fields?.icon?.value]
    },
    introText() {
      return this.fields?.introText?.value
    },
    hasIntroText() {
      return this.introText?.length > 0
    },
    isLarge() {
      return this.fields?.large?.value
    }
  }
}
</script>
