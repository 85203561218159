//
//
//
//
//
//
//
//
//

import { CycleIcon, CycleHeading } from '../elements'
import CycleLink from '../cycle-link/CycleLink'

export default {
  name: 'CycleLinkCardItem',
  components: {
    CycleHeading,
    CycleLink,
    CycleIcon
  },
  filters: {
    shorten: (name) => name.replace('verzekering', '')
  },
  props: {
    href: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'shield'
    },
    heading: {
      type: String,
      required: true
    },
    subLine: {
      type: String,
      required: true
    }
  },
  computed: {
    iconModifiers() {
      return this.icon
    }
  }
}
