<template>
  <TopNavigation :items="items" />
</template>

<script>
import { TopNavigation } from '@aon/cycle'

export default {
  name: 'TopNavigationSitecore',
  components: {
    TopNavigation
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    items() {
      return this.fields?.items ?? []
    }
  }
}
</script>
