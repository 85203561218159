
import { Person } from '../../../models'

export default {
  name: 'PersonName',
  functional: true,
  props: {
    person: {
      type: Person,
      required: true
    }
  },
  render(createElement, { props: { person } }) {
    const { initials, tussenvoegsel, lastName } = person

    const fullName = [initials, tussenvoegsel, lastName].filter((s) => !!s).join(' ')

    const element = createElement('template', [fullName])
    return element.children[0]
  }
}
