//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, subDays, format } from 'date-fns'

import { ProductPremium } from '../../models'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '../../../../components/functional'
import { CycleCheckout, CycleCheckoutItem } from '../../../../components/functional/CycleCheckout'
import { WarningMessage } from '@/components/atoms'
import { ProvidedHtml } from '@/components/organisms'
import { dictionaryMixin } from '../../../../mixins'
import {
  CycleRadioInput,
  CycleTextInput,
  CycleCurrencyInput,
  CycleDateInput
} from '../../../../components/elements'
import {
  BaseCoverageOption,
  LuggageOption,
  CancellationOption,
  AccidentsOption,
  ValidationConstants
} from '../../helpers/constants'
import { debounce, get, isEqual } from 'lodash'

export default {
  name: 'FunnelTravelStepCoverages',
  inject: ['formData', 'textContent', 'formErrors'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleCheckout,
    CycleCheckoutItem,
    CContainer: CycleInputContainer,
    CRadio: CycleRadioInput,
    CText: CycleTextInput,
    CCurrency: CycleCurrencyInput,
    CDate: CycleDateInput,
    WarningMessage,
    ProvidedHtml
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      required: false,
      type: ProductPremium
    },
    isMultiTrip: {
      type: Boolean
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    baseCoverageOption() {
      return BaseCoverageOption
    },
    luggageOption() {
      return LuggageOption
    },
    cancellationOption() {
      return CancellationOption
    },
    accidentsOption() {
      return AccidentsOption
    },
    validationConstants() {
      return ValidationConstants
    },
    totalPremium() {
      return get(this, ['premium', 'totalPremium'], 0)
    },
    totalAmountText() {
      return this.isMultiTrip
        ? this.textContent.totalAmountMultiTripText
        : this.textContent.totalAmountSingleTripText
    },
    totalAmountSubtext() {
      return this.isMultiTrip
        ? this.textContent.totalAmountMultiTripSubtext
        : this.textContent.totalAmountSingleTripSubtext
    },
    coverages() {
      return this.formData.coverages
    },
    travelSum: {
      get() {
        return this.formData.travelSum
      },
      set(value) {
        this.$set(this.formData, 'travelSum', value)
        const isValid = !this.formErrors(this.$refs.travelSum.nestedName).$invalid
        if (isValid) {
          this.$set(this.formData.coverages, 'travelSum', this.formData.travelSum)
        } else {
          this.$set(this.formData.coverages, 'travelSum', undefined)
        }
      }
    },
    travelCancellationTooltip() {
      return this.isMultiTrip
        ? this.textContent.travelCancellationMultiTripTooltip
        : this.textContent.travelCancellationSingleTripTooltip
    },
    travelCancellationDescription() {
      return this.isMultiTrip
        ? this.textContent.travelCancellationMultiTripDescription
        : this.textContent.travelCancellationSingleTripDescription
    },
    minBookingDate() {
      if (this.formData.departureDate) {
        return format(subDays(new Date(this.formData.departureDate), 365), 'yyyy-MM-dd')
      }
    },
    maxBookingDate() {
      return format(addDays(new Date(), 0), 'yyyy-MM-dd')
    },
    showCancellationWarning() {
      return (
        !this.isMultiTrip &&
        this.coverages.cancellation &&
        new Date(this.formData.bookingDate) < subDays(new Date(), 8)
      )
    },
    showSpecialSportsWarning() {
      return (
        this.coverages.accidents &&
        this.coverages.accidentsOption === AccidentsOption.SpecialSports &&
        !this.coverages.specialSports
      )
    },
    basicCoverageDescription() {
      return this.isMultiTrip
        ? this.textContent.basicCoverageMultiTripDescription
        : this.textContent.basicCoverageSingleTripDescription
    }
  },
  watch: {
    coverages: {
      handler: debounce(function () {
        this.updateCoverages()
      }, 500),
      deep: true
    },
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }
        if (!isEqual(this.formData.coverages, newValue.coverages)) {
          // Prevent unnecessary (infinite loop) watch trigers
          this.formData.coverages = newValue.coverages
        }
        this.formData.travelSum = newValue.travelSum
        this.formData.bookingDate = newValue.bookingDate
      },
      immediate: true
    }
  },
  methods: {
    pageSubmit() {
      this.$emit('submit', {
        bookingDate: this.formData.bookingDate,
        travelSum: this.formData.travelSum,
        coverages: this.formData.coverages
      })
    },
    updateCoverages() {
      this.$emit('coverage-update', {
        coverages: this.formData.coverages,
        travelSum: this.formData.travelSum
      })
    },
    premiumCoverage(coverage) {
      const basePremium = get(this, ['premium', 'coverages', coverage], undefined)
      return basePremium
    }
  }
}
