<template>
  <div class="form__file">
    <label
      class="btn btn--default btn--file"
      :class="{ 'btn--disabled': disabled, 'btn--tertiary': isSingleFileAdded }"
    >
      {{ buttonLabel || phrase('generic-select-file', 'Upload file') }}
      <input
        type="file"
        class="hide"
        v-bind="{ ...attributes, ...$attrs }"
        @change="onFileAdd"
        :accept="accept"
        :disabled="disabled"
      />
    </label>
    <div class="list list--nomarker" :class="{ 'list--disabled': modelValue.length < 1 }">
      <span v-if="showFileBlock">
        <span class="file__item" v-for="(file, index) in modelValue" :key="index">
          <span v-if="!file.loading">
            {{ file.name }}
            <button type="button" class="icon icon--trash" @click="removeFile(index)"></button>
          </span>
        </span>
        <span v-if="isLoading" class="file__item form__progressmessage">
          {{ phrase('loading-message-file-upload', 'File is uploading.') }}
        </span>
        <span v-else-if="showSuccessMessage" class="file__item form__successmessage">
          {{ phrase('success-message-file-upload', 'Success!') }}
        </span>
        <span v-else-if="showFormatErrorMessage" class="file__item form__errormessage">
          {{ phrase('error-messages-file-upload-invalid-file-format', 'Invalid file format') }}
        </span>
        <span v-else-if="showSizeErrorMessage" class="file__item form__errormessage">
          {{ phrase('error-messages-file-upload-invalid-file-size', 'File size limit exceeded') }}
        </span>
      </span>
      <span v-else>
        {{
          filePlaceholder ||
          phrase('error-messages-file-upload-no-file-selected', 'This file upload is empty')
        }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseInput from '../BaseInput'
import { File } from '../../../../models'
import { dictionaryMixin } from '../../../../mixins'

export default {
  name: 'MultiFileInput',
  extends: BaseInput,
  mixins: [dictionaryMixin],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    addFileLabel: {
      type: String,
      required: false
    },
    changeFileLabel: {
      type: String,
      required: false
    },
    addAnotherFileLabel: {
      type: String,
      required: false
    },
    filePlaceholder: {
      type: String,
      required: false
    },
    numberOfFiles: {
      type: Number,
      required: false,
      default: 1
    },
    maxFileSize: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      modelValue: [],
      showSuccessMessage: false,
      showSizeErrorMessage: false,
      showFormatErrorMessage: false
    }
  },
  computed: {
    isLoading() {
      return this.modelValue.some((file) => file.loading)
    },
    // Disabled when loading a file or reached the max number of files (except single file case)
    disabled() {
      return (
        this.$attrs.disabled ||
        this.isLoading ||
        this.modelValue.length > Math.max(this.numberOfFiles - 1, 1)
      )
    },
    showFileBlock() {
      return (
        this.isLoading ||
        this.showSizeErrorMessage ||
        this.showFormatErrorMessage ||
        this.modelValue.length > 0
      )
    },
    // Only single file is allowed and already added one.
    isSingleFileAdded() {
      return this.modelValue.length === 1 && this.numberOfFiles === 1
    },
    buttonLabel() {
      if (this.isSingleFileAdded) {
        return this.changeFileLabel
      } else if (this.modelValue.length > 0 && this.modelValue.length < this.numberOfFiles) {
        return this.addAnotherFileLabel
      } else {
        return this.addFileLabel
      }
    }
  },
  mounted() {
    if (Array.isArray(this.value)) {
      this.modelValue = this.value
    }
  },
  methods: {
    isAllowedSize(file) {
      return file.size < this.maxFileSize * 1024 * 1024
    },
    isAllowedExtension(file) {
      const fileExtension = file.name
        .toLowerCase()
        .split(/(?=\.)/)
        .pop()
      return fileExtension && this.accept.split(',').includes(fileExtension)
    },
    onSuccess() {
      this.showSuccessMessage = this.modelValue.length > 0
      this.showSizeErrorMessage = false
      this.showFormatErrorMessage = false
    },
    onFileAdd(e) {
      // Get initial data from target
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      // Initialize the file object
      let file = new File(files[files.length - 1].name, files, true, files[files.length - 1].size)

      // Fill source data
      const fileReader = new FileReader()
      fileReader.onload = function (e) {
        const [meta, source] = e.target.result.split(',')
        const [mimetype, encoding] = meta.substring(5).split(';') // trim 'data:'
        file.source = source
        file.mimetype = mimetype
        file.encoding = encoding
        file.loading = false
      }
      fileReader.readAsDataURL(file.raw[0])

      // Don't add invalid files
      if (!this.isAllowedExtension(file)) {
        this.showSuccessMessage = false
        this.showSizeErrorMessage = false
        this.showFormatErrorMessage = true
        return
      } else if (!this.isAllowedSize(file)) {
        this.showSuccessMessage = false
        this.showFormatErrorMessage = false
        this.showSizeErrorMessage = true
        return
      }

      if (this.isSingleFileAdded) {
        // Change the existing file if only single file is allowed
        this.modelValue = [file]
      } else {
        // Add file object to the file list
        this.modelValue.push(file)
      }

      this.onSuccess()
      this.$emit('change', this.modelValue)
    },
    removeFile(index) {
      this.modelValue.splice(index, 1)
      this.onSuccess()
      this.$emit('change', this.modelValue)
    }
  }
}
</script>
