import { helpers } from 'vuelidate/lib/validators'
import { formatLicenseplate } from './formatters'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import { allowedExtensions, IBANCountriesOHI } from './defaults'
import { logWarning } from '@/services/logging'

// Check whether a given validation param is a function (should be reactive)
const paramReferenceCheck = (param) => {
  return typeof param === 'function' ? param.call() : param
}
const dateStringCheck = (value) => {
  return value instanceof Date ? value : parseISO(value)
}
const arrayCheck = (value) => {
  return Array.isArray(value) ? value : []
}
const getExtension = (file) =>
  file.name
    .toLowerCase()
    .split(/(?=\.)/)
    .pop()

const postcode = helpers.regex('postcode', /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/)
const euro = helpers.regex('euro', /^\d+(,\d{2})?$/)
const phone = helpers.regex('phone', /(^[0-9]{10}$)/)
const phoneDutch = phone
const phoneInternational = helpers.regex('phoneInternational', /^(00|\+)([0-9]{7,15}$)/)
const phoneDutchOrInternational = helpers.regex(
  `phoneDutchOrInternational`,
  /^(([0-9]{10})|((00|\+)([0-9]{7,15})))$/
)

const ibanDutch = helpers.withParams(
  { type: 'iban' },
  (value) => !helpers.req(value) || isValidDutchIBAN(value)
)
const ibanOhi = helpers.withParams(
  { type: 'iban' },
  (value) => !helpers.req(value) || isValidOHIIBAN(value)
)
const bic = helpers.regex(
  'bic',
  /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z])([0-9a-np-zA-NP-Z]))((([0-9a-wy-zA-WY-Z])([0-9a-zA-Z]{2}))|([xX]{3})|)/
)
const bsn = helpers.withParams({ type: 'bsn' }, (value) => !helpers.req(value) || isValidBSN(value))
const licenseplate = helpers.withParams(
  { type: 'licenseplate' },
  (value) => !helpers.req(value) || !!formatLicenseplate(value)
)

const date = helpers.withParams(
  { type: 'date' },
  (value) => helpers.req(value) && isValid(parseISO(value))
)

const min = (minValue) =>
  helpers.withParams(
    { type: 'date' },
    (value) =>
      !helpers.req(value) || dateStringCheck(paramReferenceCheck(minValue)) <= parseISO(value)
  )

const max = (maxValue) =>
  helpers.withParams(
    { type: 'date' },
    (value) =>
      !helpers.req(value) || dateStringCheck(paramReferenceCheck(maxValue)) >= parseISO(value)
  )
const exactly = (expected) => helpers.withParams({ type: 'string' }, (value) => value === expected)

const graphqlSafe = helpers.regex('graphqlSafe', /^[A-Za-z0-9`’\-|()[\],.?!;: (\r|\n|\v)]+$/)
const basicTextWithNumbersAndSpecialCharacters = helpers.regex(
  'basicTextWithNumbersAndSpecialCharacters',
  /^[A-Za-z0-9`’\-|()[\],.?!;:@ (\r|\n|\v)]+$/
)

const alphaExt = helpers.regex('alphaExt', /^[A-Za-z0-9`'’\-|()[\],.?!;: (\r|\n|\v)]+$/)
const validName = helpers.withParams(
  { type: 'string' },
  (value) => !helpers.req(value) || isValidName(value)
)
const checked = (value) => {
  logWarning('This validator does not do what you want it to do, use mustBeChecked')
  return value === true
}

// Took 5 developers 4 hours to fix this shit, do not touch -- Maarten Bicknese 2020-06-05
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mustBeChecked = (name) => (value) => {
  if (value?.answer != null) {
    return value.answer === true
  }

  return value === true
}

const maxFileSize = (files) => !arrayCheck(files).some((file) => file.size > 2 * 1024 * 1024)

const allowedFileExtension = (files) =>
  !arrayCheck(files).some((file) => !allowedExtensions.includes(getExtension(file)))

const isCaseInSensitiveEqual = (equalTo) =>
  helpers.withParams({ type: 'sameAs', eq: equalTo }, function (value, parentVm) {
    return value?.toLowerCase() === helpers.ref(equalTo, this, parentVm)?.toLowerCase()
  })

const emailRegex =
  // eslint-disable-next-line no-control-regex
  /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

const email = helpers.regex('email', emailRegex)

export default {
  postcode,
  euro,
  phone,
  phoneDutch,
  phoneInternational,
  phoneDutchOrInternational,
  licenseplate,
  date,
  checked,
  mustBeChecked,
  ibanOhi,
  ibanDutch,
  bic,
  min,
  max,
  graphqlSafe,
  basicTextWithNumbersAndSpecialCharacters,
  exactly,
  alphaExt,
  bsn,
  maxFileSize,
  allowedFileExtension,
  validName,
  isCaseInSensitiveEqual,
  email
}

export const isValidDutchIBAN = (ibanDutch) => {
  const formatted = ibanDutch.toUpperCase().replace(/[^A-Z0-9]/g, '')
  // match and capture (1) the country code, (2) the check digits, and (3) the rest
  const code = formatted.match(/^(NL)(\d{2})([A-Z\d]{4}\d{7}([A-Z\d]?){3})$/)
  // check syntax
  if (!code) return false
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  return mod97(digits) === 1
}

export const isValidOHIIBAN = (ibanOhi) => {
  let formatted = ibanOhi.toUpperCase().replace(/[^A-Z0-9]/g, '')
  if (!formatted.match(/^[\dA-Z]+$/)) {
    return false
  }
  let len = formatted.length
  /* This OHI IBAN validator only accepts IBAN's from a specific list of countries. The not accepted IBAN counrties are
  stated in 'IBANCountriesOHI'; the country code and number of digits must match. */
  if (len !== IBANCountriesOHI[formatted.substr(0, 2)]) {
    return false
  }
  const code = formatted.substr(4) + formatted.substr(0, 4)
  let digits = ''
  for (let i = 0; i < len; i++) {
    digits += parseInt(code.charAt(i), 36)
  }
  return mod97(digits) === 1
}

const mod97 = (ibanDigits) => {
  let checksum = ibanDigits.slice(0, 2),
    fragment
  for (let offset = 2; offset < ibanDigits.length; offset += 7) {
    fragment = String(checksum) + ibanDigits.substring(offset, offset + 7)
    checksum = parseInt(fragment) % 97
  }
  return checksum
}

/* Based on: http://www.calaris.nl/index.php/tips/algemeen/37-bsn-11-proef-valideren*/
export const isValidBSN = (bsn) => {
  let formatted = bsn.toString().split('').map(Number)
  let charCount = formatted.length
  if (charCount > 9 || charCount < 8) {
    return false
  }
  if (charCount === 8) {
    formatted.unshift(0)
  }
  let sum = formatted.reduce((accumulator, currentValue, currentIndex) => {
    if (currentIndex === 8) {
      accumulator -= currentValue * (10 - (currentIndex + 1))
    } else {
      accumulator += currentValue * (10 - (currentIndex + 1))
    }
    return accumulator
  }, 0)
  return sum % 11 === 0
}

export const isValidName = (name) => {
  if (!name.match(/^[a-zA-Z||`’\-|\s]*$/)) {
    return false
  }
  return true
}
