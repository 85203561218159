import { render, staticRenderFns } from "./RapidFormFieldSet.vue?vue&type=template&id=f2b27be8"
import script from "./RapidFormFieldSet.vue?vue&type=script&lang=js"
export * from "./RapidFormFieldSet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports