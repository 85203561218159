<template>
  <CycleCardList :title="title" modifiers="nomarker" data-testid="subCollectivitiesCard">
    <template #list-items>
      <CycleListItem
        v-for="(subCollectivity, index) in subCollectivities"
        :key="`suCollectivity-${index}`"
      >
        <CycleLinkIcon
          modifiers="chevron-right"
          :href="subCollectivity.link.href"
          :target="subCollectivity.link.target"
          >{{ subCollectivity.name }}</CycleLinkIcon
        >
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { CycleListItem, CycleCardList, CycleLinkIcon } from '@aon/cycle'

export default {
  name: 'SubCollectivitiesList',
  components: {
    CycleCardList,
    CycleListItem,
    CycleLinkIcon
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    subCollectivities() {
      return this.fields?.subCollectivities?.value || []
    },
    title() {
      return this.fields?.title?.value
    }
  }
}
</script>
