<template>
  <div class="coreui"></div>
</template>

<script>
export default {
  name: 'Redirecting',
  metaInfo() {
    return {
      title: 'Redirecting'
    }
  },
  props: {
    context: {
      type: Object
    }
  }
}
</script>
