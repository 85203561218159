import gql from 'graphql-tag'

export const policyItemLegalAid = gql`
  fragment policyItemLegalAid on Policy {
    item {
      ... on LegalAid {
        legalAidConfiguration {
          mainCoverage {
            consumerAndLiving {
              active
            }
            traffic {
              active
            }
          }
          additionalCoverages {
            consumerAndLivingAdditional {
              active
            }
            trafficAdditional {
              active
            }
            income {
              active
            }
            fiscalAndAssets {
              active
            }
            divorceMediation {
              active
            }
          }
        }
      }
    }
  }
`
