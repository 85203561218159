<template>
  <CycleSubGrid>
    <CycleCard v-for="(product, index) in products" :key="index">
      <template #image>
        <CycleLink
          v-if="isLinkValid(product.productPageLink)"
          v-bind="getLinkBinding(product.productPageLink)"
          :title="getLinkTitle(product.productPageLink)"
        >
          <img v-bind="getProductImageBinding(product)" />
        </CycleLink>
        <img v-bind="getProductImageBinding(product)" v-else />
      </template>
      <template #header>
        <CycleHeading>{{ getProductDisplayName(product) }}</CycleHeading>
      </template>
      <template #body>
        <CycleHtml :html="getProductDescription(product)" />
        <CycleLink
          v-if="isLinkValid(product.productPageLink)"
          v-bind="getLinkBinding(product.productPageLink)"
          :title="getLinkTitle(product.productPageLink)"
        >
          {{ productPageLink }}
        </CycleLink>
      </template>
      <template #footer>
        <CycleLink
          v-if="isLinkValid(product.productSalesPageLink)"
          v-bind="getLinkBinding(product.productSalesPageLink)"
          :title="getLinkTitle(product.productSalesPageLink)"
        >
          {{ getLinkTitle(product.productSalesPageLink) }}
        </CycleLink>
      </template>
    </CycleCard>
  </CycleSubGrid>
</template>

<script>
export default {
  name: 'InsuranceDisplayCards',
  components: {},
  inject: ['dictionaryService'],
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    productPageLink() {
      return this.dictionaryService?.phrases['generic-read-more']
    }
  },
  methods: {
    getLinkBinding(link) {
      return link?.value
    },
    isLinkValid(link) {
      return link?.value?.href
    },
    getLinkTitle(link) {
      return (
        link?.value?.title ||
        link?.value?.text ||
        this.dictionaryService?.phrases['generic-read-more']
      )
    },
    getProductImageBinding(product) {
      return {
        src: product?.productImage?.value?.src,
        alt: product?.productImage?.value?.alt
      }
    },
    getProductDescription(product) {
      return product?.productDescription
    },
    getProductDisplayName(product) {
      return product?.productTypeDisplay
    },
    getLinkToBindForImage(product) {
      return { ...product?.productPageLink?.value, class: {} }
    }
  }
}
</script>
