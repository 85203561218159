import { sourceSystemMap } from './maps/sourceSystemMap'
import { policyStatusMap } from './maps/policyStatusMap'

export const getters = {
  documents: (state) => state?.documents || [],
  clientIdentifier: (state) => state?.clientIdentifier || null,
  getPortfolioInfo:
    (state) =>
    ({ dataSourceId, locale, clientIdentifier }) => {
      return state.portfolioInfo.find((item) => {
        return (
          item.dataSourceId === dataSourceId &&
          item.locale === locale &&
          item.clientIdentifier === clientIdentifier
        )
      })
    },
  getPolicyInfo: (state) => (policyNumber) => {
    return state.policies.find((policy) => policy.numberEncrypted === policyNumber)
  },
  getDirectLinks: (state) => (policyNumber) => {
    const directLinks = state.directLinks.find((item) => item.numberEncrypted === policyNumber)

    return directLinks?.detailPageLinks
  },
  verifySourceSystem:
    (_state, getters) =>
    ({ policyNumber, client }) => {
      const policy = getters.getPolicyInfo(policyNumber)

      return policy?.sourceSystem?.system?.code === sourceSystemMap[client]
    },
  verifyPolicyStatus:
    (_state, getters) =>
    ({ policyNumber, policyStatus }) => {
      const policy = getters.getPolicyInfo(policyNumber)
      return policy?.status === policyStatusMap[policyStatus]
    }
}
