<template>
  <div v-if="hasDescription || areShareLinksVisible" class="share">
    <div v-if="hasDescription" class="share__heading">
      <CycleHeading :weight="3">{{ description }}</CycleHeading>
    </div>
    <div v-if="areShareLinksVisible" class="share__icons">
      <a
        v-for="item in mappedShareItemsWithLinks"
        :key="item.id"
        :href="item.link.href"
        class="share__link"
        target="_blank"
        :data-track-share="item.icon"
      >
        <CycleIcon v-if="item.icon" :modifiers="[item.icon]" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaShare',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    description() {
      return this.fields?.description?.value
    },
    hasDescription() {
      return this.description?.length > 0
    },
    shareItemsWithLinks() {
      return this.fields?.items?.filter((a) => a?.fields?.link?.value?.href?.length > 0) || []
    },
    mappedShareItemsWithLinks() {
      if (this.shareItemsWithLinks?.length === 0) {
        return []
      }

      return this.shareItemsWithLinks.map((item) => ({
        id: item.id,
        icon: item?.fields?.icon?.value,
        link: { href: item.fields.link.value.href + window.location.href }
      }))
    },
    areShareLinksVisible() {
      return this.mappedShareItemsWithLinks.length > 0
    }
  }
}
</script>
