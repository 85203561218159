//
//
//
//
//
//

import { CycleHtml } from '../../../../../base'
import { mixin } from './mixin'

export default {
  name: 'CycleFinalQuestionInfoText',
  components: { CycleHtml },
  mixins: [mixin]
}
