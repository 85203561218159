<template>
  <ServicesDisplayCards :services="services" placeholder="jss-products" />
</template>

<script>
import ServicesDisplayCards from './ServicesDisplayCards'

export default {
  name: 'MyServicesSitecore',
  components: {
    ServicesDisplayCards
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    services() {
      return this.fields?.services
    }
  }
}
</script>
