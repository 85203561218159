import { mapValidators } from '../services/Validators'
import { noneDefaultSlots, stickyDefaultSlot } from '../constants/slotModifiers'

export const getters = {
  formDefinition(state) {
    return state.formDefinition
  },
  formDefinitionId(state) {
    return state.formDefinitionId
  },
  name(state) {
    return state.name
  },
  steps(state) {
    return state.steps
  },
  fields(state) {
    return state.fields
  },
  activeStepIndex(state) {
    return state.steps.findIndex((step) => step.key === state.formState['step'])
  },
  prevStepIndex(state) {
    return state.prevStepIndex
  },
  highestStepIndex(state) {
    const highestStep = state.businessData?.['highest-step'] ?? state.formState?.step
    return state.steps.findIndex((step) => step.key === highestStep)
  },
  activeFieldSets(state, getters) {
    const steps = state.steps.map((s) => s.key)
    return state.fieldSets.filter((f) => steps.indexOf(f.step) === getters.activeStepIndex)
  },
  activeFieldSetsInSlot(state, getters) {
    return (slotModifier) => {
      return getters.activeFieldSets.filter((fieldSet) => {
        if (slotModifier) {
          // get a list of fieldsets with the given slotModifier as location/slot to render
          return fieldSet.modifiers?.includes(slotModifier)
        }
        // get a list of fieldsets, excluding the fieldsets which has a slotModifier.
        // exclusion is done by checking the values in fieldset.modifiers does not exist in noneDefaultSlots object
        return !noneDefaultSlots.some((noneDefaultSlot) =>
          fieldSet.modifiers?.includes(noneDefaultSlot)
        )
      })
    }
  },
  activeFieldSetInModal(state, getters) {
    return () => {
      return getters.activeFieldSets.filter((fieldSet) => {
        return fieldSet.modal?.modalName === state.businessData?.openModal?.modalName
      })
    }
  },
  openModalName(state) {
    return state.businessData?.openModal?.modalName
  },
  disallow(state) {
    return state.businessData?.openModal?.dontAllowBackGroundClick
  },
  activeFieldSetInStepAsSticky(state, getters) {
    return getters.activeFieldSets.filter((fieldSet) => {
      return fieldSet.modifiers?.includes(stickyDefaultSlot)
    })
  },
  isFieldSetSticky(state, getters) {
    const fieldSet = getters.activeFieldSetInStepAsSticky
    return (fieldSet && fieldSet?.length > 0) ?? false
  },
  productTotalFormat(state) {
    return state.businessData?.product?.pricing?.totalFormatted ?? ''
  },
  productTotal(state) {
    return state.businessData?.product?.pricing?.total ?? null
  },
  productPricePaymentTerm(state) {
    return state.businessData?.product?.pricePaymentTerm ?? ''
  },
  productPaymentTerm(state) {
    return state.businessData?.product?.pricing?.paymentTerm ?? ''
  },
  getProductSuggestedOffer(state) {
    return state.businessData?.product?.suggestedOffer ?? null
  },
  isProductLoadedAtInitialStep(state, getters) {
    return getters.activeStepIndex === 0 && state.businessData?.productsLoadedAtInitialStep
  },
  getFields(state) {
    return (fieldSet) => state.fields.filter((field) => field.fieldSet === fieldSet)
  },
  getGroupFields(state) {
    return (fieldGroup) => state.fields.filter((field) => field.fieldGroup === fieldGroup)
  },
  getFieldsWithoutGroup(state) {
    return (fieldSet) =>
      state.fields.filter((field) => field.fieldSet === fieldSet && !field.fieldGroup)
  },
  getFieldSetGroup(state) {
    return (fieldGroupKey) => state.fieldGroups.filter((group) => group.fieldSet === fieldGroupKey)
  },
  getFieldsByKey(state) {
    return (key) => state.fields.find((field) => field.fieldKey === key)
  },
  getFieldValue(state) {
    return (fieldKey) => state.formData[fieldKey]
  },
  getFieldGroup(state) {
    return (fieldGroupKey) => state.fieldGroups.find((group) => group.key === fieldGroupKey)
  },
  formState(state) {
    return state.formState
  },
  activeStep(state, getters) {
    return state.steps[getters.activeStepIndex]
  },
  activeStepErrors(state, getters) {
    return state.errors[getters.activeStep?.key]
  },
  activeStepNavigation(state, getters) {
    return getters.activeStep?.navigation || 0
  },
  formData(state) {
    return state.formData
  },
  formDataFormatted(state) {
    return state.formDataFormatted
  },
  businessData(state) {
    return state.businessData
  },
  hasDefinition(state) {
    return (
      Array.isArray(state.steps) && Array.isArray(state.fieldSets) && Array.isArray(state.fields)
    )
  },
  activeValidators(state) {
    return mapValidators(
      state.formState.activeFields.map((fieldKey) =>
        state.fields.find((field) => field.key === fieldKey)
      )
    )
  },
  validationProperties(state) {
    return (fieldKey, validator) => {
      const validation = state.fields.find((field) => field.key === fieldKey)?.validations[
        validator
      ]

      if (!validation) {
        return {}
      }

      return {
        message: validation.message,
        displayOn: validation.displayOn
      }
    }
  },
  errorElements(state) {
    return state.formState.errorElements
  },
  invalidElements(state) {
    return state.formState.invalidElements
  },
  isTouchedElement(state) {
    return (fieldKey) => state.formState.touchedElements.includes(fieldKey)
  },
  errors(state) {
    return state.errors
  },
  isCommunicating(state) {
    return Object.values(state.communication).some((com) => com.isPending)
  },
  communicationStatusByKey(state) {
    return ({ fieldKey, fieldGroup }) => {
      return state.communication[fieldKey] || state.communication[fieldGroup]
    }
  },
  blockInteraction(state) {
    const hasBlockingInteraction = Object.values(state.communication).some(
      (com) => com.blockUI && com.isPending
    )
    const formSubmitIsPending = state.communication[state.formState.step]?.isPending

    return hasBlockingInteraction || formSubmitIsPending
  },
  getBlockUiLoader(state, getters) {
    const loaderUrl =
      state.communication['block-ui-loader']?.url || getters.activeStep?.loader?.url || null
    const loaderText =
      state.communication['block-ui-loader']?.text || getters.activeStep?.loader?.text || null
    return loaderUrl && loaderText
      ? { src: loaderUrl, text: loaderText }
      : loaderUrl
      ? { src: loaderUrl }
      : null
  },
  getFormDataFormattedValue(state) {
    return (fieldKey) => state.formDataFormatted[fieldKey]
  },
  fieldsInGroup(state) {
    return (fieldGroupKey = '') => {
      return state.fields.filter((item) => item.fieldGroup === fieldGroupKey)
    }
  },
  hasFieldInErrors(state, getters) {
    return ({ fieldKey, fieldGroup }) => {
      return !!getters.errors[fieldKey] || !!getters.errors[fieldGroup]
    }
  }
}
