<template>
  <div>
    <header v-if="hasTitle" class="grid__header">
      <CycleHeading :weight="2">{{ title }}</CycleHeading>
    </header>
    <CycleContentBlock v-if="hasImage || hasScore || hasDescription" class="panel--review">
      <template #image v-if="hasImage">
        <img v-bind="imageProps" />
      </template>
      <template #body v-if="hasScore || hasDescription">
        <span v-if="hasScore" class="review__score">{{ score }}</span>
        <CycleHtml v-if="hasDescription" :html="description" />
      </template>
    </CycleContentBlock>

    <SsrCarousel v-if="hasSlides" v-bind="options">
      <div v-for="slide in slides" :key="slide.id">
        <CycleContentBlock class="panel--review-stacked">
          <div v-for="review in slide.fields.reviews" :key="review.id" class="review-stacked">
            <div
              v-if="hasReviewTitle(review) || isReviewDateVisible(review) || hasRate(review)"
              class="review-stacked__header"
            >
              <span v-if="hasReviewTitle(review)">
                <strong class="review-stacked__title">{{ getReviewTitle(review) }}</strong>
              </span>
              <div
                v-if="isReviewDateVisible(review) || hasRate(review)"
                class="review-stacked__details"
              >
                <span v-if="isReviewDateVisible(review)">{{ getFormattedReviewDate(review) }}</span>
                <div
                  v-if="hasRate(review)"
                  class="rating"
                  :style="{
                    [`--rating`]: getRate(review)
                  }"
                />
              </div>
            </div>
            <div v-if="hasReviewDescription(review)" class="review-stacked__body">
              <CycleHtml :html="getReviewDescription(review)" />
            </div>
          </div>
        </CycleContentBlock>
      </div>
    </SsrCarousel>
  </div>
</template>
<script setup>
import carouselTypes from './carouselTypes.json'
import { CycleContentBlock, CycleHeading, CycleHtml } from '@aon/cycle'
import { formatDate } from '@/services/formatting'

import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import Vue from 'vue'
Vue.use(ssrCarouselCss)
const MinDateValueFromSitecoresDateField = '0001-01-01T00:00:00'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  },
  rendering: {
    type: Object,
    default: () => ({})
  }
})
carouselTypes
const options = carouselTypes['stackedReviews']?.options
const title = props.fields?.title?.value
const hasTitle = title?.length > 0
const description = props.fields?.description?.value
const hasDescription = description?.length > 0
const score = props.fields?.score?.value
const hasScore = score?.length > 0
const image = props.fields?.image?.value
const hasImage = image?.src?.length > 0
const alt = props.fields?.imageAltText?.value
const imageProps = {
  alt,
  title,
  src: image?.src,
  width: image?.width,
  height: image?.height
}
const slides = props.fields?.slides?.filter((slide) => slide?.fields?.reviews?.length > 0) ?? []
const hasSlides = slides?.length > 0
const getRate = (review) => {
  const ratingValue = Number.parseFloat(review?.fields?.rate?.value) || 0
  return ratingValue > 5 ? 5 : ratingValue < 0 ? 0 : ratingValue
}

const hasRate = (review) => review?.fields?.rate?.value?.length > 0
const getReviewDate = (review) => review?.fields?.date?.value
const isReviewDateVisible = (review) => getReviewDate(review) != MinDateValueFromSitecoresDateField
const getFormattedReviewDate = (review) => formatDate({ date: getReviewDate(review) })
const getReviewDescription = (review) => review?.fields?.description?.value
const hasReviewDescription = (review) => getReviewDescription(review)?.length > 0
const getReviewTitle = (review) => review?.fields?.title?.value
const hasReviewTitle = (review) => getReviewTitle(review)?.length > 0
</script>
