<template>
  <BreadcrumbItemList :breadcrumbs="breadcrumbs" v-if="hasBreadcrumbs" />
</template>

<script>
import { BreadcrumbItemList } from '@aon/cycle'
export default {
  name: 'Breadcrumb',
  components: {
    BreadcrumbItemList
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    breadcrumbs() {
      return this.fields?.breadcrumbs ?? []
    },
    hasBreadcrumbs() {
      return this.fields?.breadcrumbs?.length > 0
    }
  }
}
</script>
