export const serviceNames = {
  FunnelService: 'FunnelService',
  FunnelHomeContentsService: 'FunnelHomeContentsService',
  FunnelWeddingService: 'FunnelWeddingService',
  FunnelHomeOwnerService: 'FunnelHomeOwnerService',
  FunnelLiabilityService: 'FunnelLiabilityService',
  FunnelTravelService: 'FunnelTravelService',
  CountryListService: 'CountryListService',
  FaqService: 'FaqService',
  ProductPremiumService: 'ProductPremiumService',
  VehicleDataService: 'VehicleDataService',
  FunnelQuestionService: 'FunnelQuestionService',
  CarPremiumService: 'CarPremiumService',
  AddressLookupService: 'AddressLookupService',
  CheckAcceptanceCarService: 'CheckAcceptanceCarService',
  ValidateLicensePlateAndSignCodeService: 'ValidateLicensePlateAndSignCodeService',
  PolicyService: 'PolicyService',
  PolicyDetailsService: 'PolicyDetailsService',
  WeddingPremiumService: 'WeddingPremiumService'
}
