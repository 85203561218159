<template>
  <FunnelCar v-bind="funnelData">
    <template #collectivity-picker>
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </template>
  </FunnelCar>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CycleFunnelCarSitecore',
  components: {
    FunnelCar: Cycle.CycleFunnelCarSitecore,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    antiForgeryToken() {
      return this.rendering.antiForgeryToken
    },
    dataSource() {
      return this.rendering.dataSource
    },
    funnelData() {
      return {
        ...this.textContent,
        antiForgeryToken: this.antiForgeryToken,
        dataSource: this.dataSource
      }
    }
  }
}
</script>
