<template>
  <!-- eslint-disable vue/attribute-hyphenation -- need camelcased $props in funnelMixin -->
  <FunnelLegalAid
    v-bind="textContent"
    :antiForgeryToken="antiForgeryToken"
    :dataSource="dataSource"
  >
    <template #collectivity-picker>
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </template>
  </FunnelLegalAid>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'CycleFunnelLegalAidSitecore',
  components: {
    FunnelLegalAid: Cycle.CycleFunnelLegalAidSitecore,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    antiForgeryToken() {
      return this.rendering.antiForgeryToken
    },
    dataSource() {
      return this.rendering.dataSource
    }
  }
}
</script>
