<template>
  <div>
    <EmptyTablePlaceholder
      v-if="!isLoading && documents.length === 0"
      :no-results-text="textContent.noResultsText"
      hide-button
    />
    <DocumentInvoices v-if="documents.length > 0">
      <StatusLegend :filter="['confirmed', 'error']" is-for-invoices />
      <DocumentsHeading>
        <template #status>
          <div>
            {{ textContent.statusLabel }}
          </div>
        </template>
        <div>{{ textContent.dateLabel }}</div>
        <div class="hide-S">{{ textContent.numberLabel }}</div>
        <div class="hide-S">{{ textContent.insuranceLabel }}</div>
        <div>{{ textContent.amountLabel }}</div>
        <div class="hide-S">{{ textContent.downloadLabel }}</div>
        <div></div>
      </DocumentsHeading>
      <DocumentsItem
        v-for="document in documents"
        :key="document.invoicenumber"
        :is-open="openDetails(document.invoicenumber) === 'open'"
        @open="handleDetailClick(document.invoicenumber)"
      >
        <template #status>
          <StatusIndicator :status="showDotType(document.status)"></StatusIndicator>
        </template>
        <template #items>
          <div>{{ document.invoicedate | CycleDate({ locale }) }}</div>
          <div class="hide-S">{{ document.invoicenumber }}</div>
          <div class="hide-S">{{ document.insurance }}</div>
          <div>{{ document.amount | CycleCurrency({ locale }) }}</div>
          <div class="hide-S">
            <CycleLinkIcon
              v-if="hasNkcLink"
              modifiers="download"
              target="_blank"
              :href="`${textContent.downloadUrl}${document.invoicedate}/${document.invoiceId}`"
              @click.native.stop
            >
              {{ textContent.downloadLinkText }}
            </CycleLinkIcon>
            <CycleLinkIcon
              v-else-if="document.documentId"
              modifiers="download"
              target="_blank"
              :href="`${textContent.downloadUrl}${document.documentId}`"
              @click.native.stop
            >
              {{ textContent.downloadLinkText }}
            </CycleLinkIcon>
            <p v-else>{{ textContent.notAvailableLinkText }}</p>
          </div>
        </template>
        <template #details>
          <CycleList modifiers="nomarker">
            <CycleListItem>
              <label>{{ textContent.statusLabel }}</label>
              <p>{{ document.status }}</p>
            </CycleListItem>
            <!-- Payment method will be available later -->
            <CycleListItem></CycleListItem>
            <CycleListItem class="showonmobile">
              <label>{{ textContent.numberLabel }}</label>
              <p>{{ document.invoicenumber }}</p>
            </CycleListItem>
            <!-- Period will be available later -->
            <CycleListItem></CycleListItem>
            <CycleListItem class="showonmobile">
              <label>{{ textContent.insuranceLabel }}</label>
              <p>{{ document.insurance }}</p>
            </CycleListItem>
            <!-- Download -->
            <CycleListItem>
              <label>{{ textContent.downloadLabel }}</label>
              <CycleLinkIcon
                v-if="hasNkcLink"
                modifiers="download"
                target="_blank"
                :href="`${textContent.downloadUrl}${document.invoicedate}/${document.invoiceId}`"
                @click.native.stop
              >
                {{ textContent.downloadLinkText }}
              </CycleLinkIcon>
              <CycleLinkIcon
                v-else-if="document.documentId"
                modifiers="download"
                target="_blank"
                :href="`${textContent.downloadUrl}${document.documentId}`"
                @click.native.stop
              >
                {{ textContent.downloadLinkText }}
              </CycleLinkIcon>
              <p v-else>{{ textContent.notAvailableLinkText }}</p>
            </CycleListItem>
          </CycleList>
        </template>
      </DocumentsItem>
    </DocumentInvoices>
    <i v-if="documents.length > 0"> {{ textContent.informationText }} </i>
    <CyclePagination
      v-if="hasPagination"
      :total-number-of-items="totalNumberOfLines"
      :number-of-items-on-page="numberOfLinesOnPage"
      :current-page="pageNumber"
      :pagination-item-text="textContent.paginationItemText"
      :pagination-text="textContent.paginationText"
      @page-change="setPageNumber"
    />
  </div>
</template>

<script>
import { InvoiceStatus } from '@/models/my-overview'
import { StatusIndicator } from '@/components/atoms'
import { DocumentsHeading, DocumentInvoices } from '@/components/atoms/Documents'
import { CyclePagination } from '@/components/cycle-pagination'
import { DocumentsItem, EmptyTablePlaceholder, StatusLegend } from '@/components/molecules'
import { CycleCurrency, CycleDate } from '@/filters'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import { intlMixin, dictionaryMixin } from '@/mixins'
import { CycleLinkIcon } from '@/components/cycle-link'

export default {
  name: 'InvoicesTable',
  components: {
    DocumentInvoices,
    DocumentsHeading,
    DocumentsItem,
    StatusIndicator,
    CyclePagination,
    CycleList,
    CycleListItem,
    EmptyTablePlaceholder,
    CycleLinkIcon
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    },
    numberOfLinesOnPage: {
      type: Number,
      required: false
    },
    pageNumber: {
      type: Number,
      required: true
    },
    totalNumberOfLines: {
      type: Number,
      required: false,
      default: 0
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    hasNkcLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    hasPagination() {
      if (
        this.numberOfLinesOnPage === 0 ||
        this.numberOfLinesOnPage == null ||
        this.numberOfLinesOnPage == undefined
      ) {
        return false
      }

      return this.totalNumberOfLines / this.numberOfLinesOnPage > 1
    }
  },
  methods: {
    // Show corresponding dot type based on incoming status
    showDotType(status) {
      if (status === InvoiceStatus.Success || status === InvoiceStatus.SuccessEnglish) {
        return 'confirmed'
      }
      return 'error'
    },
    setPageNumber(e) {
      this.$emit('page-changed', parseInt(e))
    },
    handleDetailClick(index) {
      if (this.selected.includes(index)) {
        this.selected.splice(this.selected.indexOf(index), 1)
      } else {
        this.selected.push(index)
      }
    },
    openDetails(index) {
      if (this.selected.includes(index)) {
        return 'open'
      }

      return ''
    }
  }
}
</script>
