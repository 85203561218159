import { rootStore } from '@/stores'
import { merge } from 'lodash'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const namespaced = true

export const store = merge({}, rootStore, { actions, getters, mutations, state, namespaced })

export default store
