<template>
  <CycleSubGrid>
    <CycleCard v-for="(category, index) in categories" :key="index">
      <template #image>
        <img v-bind="getCategoryImageBinding(category)" />
      </template>
      <template #header>
        <CycleHeading>{{ getCategoryTitle(category) }}</CycleHeading>
      </template>
      <template #body>
        <p class="productLink" v-for="product in category.products" :key="product.id">
          <CycleIcon
            :key="getIconKey(product)"
            :modifiers="getProductIcon(product)"
            v-if="product.icon"
          />
          <CycleLink
            :key="getLinkKey(product)"
            v-bind="getLinkBinding(product.productPageLink)"
            :title="getLinkTitle(product.productPageLink)"
            >{{ getLinkTitle(product.productPageLink) }}
          </CycleLink>
        </p>
      </template>
    </CycleCard>
  </CycleSubGrid>
</template>

<script>
import { InsuranceProduct } from '@/components/Modules/WSM/constants'

export default {
  name: 'CategorizedInsurancesCard',
  components: {},
  inject: ['dictionaryService'],
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getLinkBinding(link) {
      return link?.value
    },
    isLinkValid(link) {
      return link?.value?.href
    },
    getLinkTitle(link) {
      return (
        link?.value?.title ||
        link?.value?.text ||
        this.dictionaryService?.phrases['generic-read-more']
      )
    },
    getCategoryImageBinding(category) {
      return {
        src: category?.image?.src,
        alt: category?.image?.alt
      }
    },
    getCategoryTitle(category) {
      return category?.title
    },
    getProductIcon(product) {
      return InsuranceProduct[product?.icon]
    },
    getIconKey(product) {
      return `${product?.id}-iconKey`
    },
    getLinkKey(product) {
      return `${product?.id}-linkKey`
    }
  }
}
</script>
