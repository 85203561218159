import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function general({
  textContent,
  formData,
  optionalCoverageInformation,
  optionalCoveragesByValue,
  textContentKeyMap
}) {
  return new OverviewGroupModel({
    title: textContent.generalTitle,
    entries: [
      {
        label: textContent[textContentKeyMap[formData.coverage]],
        value: optionalCoverageInformation.priceBase,
        type: OverviewEntryTypes.CURRENCY
      },
      ...(formData.coverages.includes('legalAid')
        ? [
            {
              label: textContent[textContentKeyMap['legalAid']],
              value: optionalCoveragesByValue['legalAid'].price,
              type: OverviewEntryTypes.CURRENCY
            }
          ]
        : []),
      ...(formData.coverages.includes('passengerDamage')
        ? [
            {
              label: textContent[textContentKeyMap['passengerDamage']],
              value: optionalCoveragesByValue['passengerDamage'].price,
              type: OverviewEntryTypes.CURRENCY
            }
          ]
        : []),
      ...(formData.accessories
        ? [
            {
              label: textContent['accessories'],
              value: formData.accessories,
              type: OverviewEntryTypes.CURRENCY
            }
          ]
        : []),
      {
        label: textContent.paymenttermMonth,
        value: optionalCoverageInformation.priceCurrent,
        type: OverviewEntryTypes.CURRENCY
      }
    ]
  })
}
