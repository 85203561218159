<template>
  <div :class="classList" v-if="isVisible">
    <slot
      :handle-slide="handleSlide"
      :is-collapsed="isCollapsed"
      :is-sticky-scrolling-content="isStickyScrollingContent"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, onUnmounted, computed } from 'vue'
import { stickyDefaultSlot } from '@/components/Modules/RapidFormPlayer/constants/slotModifiers'

const props = defineProps({
  isStickyForDesktop: {
    type: Boolean,
    default: false
  }
})
const baseClassName = ref('form__set')
const isVisible = ref(true)
const isCollapsed = ref(true)
const isStickyScrollingContent = ref(false)
const classList = computed(() => {
  const mainClassName = 'form__sticky-wrapper'
  return {
    [`${mainClassName}`]: true,
    [`${mainClassName}--desktop`]: props?.isStickyForDesktop
  }
})
const activeOnScroll = () => {
  const targetElement = document?.querySelector(`.${baseClassName.value}--${stickyDefaultSlot}`)
  const elementTop = targetElement?.getBoundingClientRect()?.top ?? 0
  const viewPortHeight = window?.innerHeight
  isVisible.value = elementTop <= viewPortHeight ? false : true
}
const handleSlide = (event) => {
  event.preventDefault()
  isCollapsed.value = !isCollapsed.value
}
const stickContentScroll = async () => {
  const targetStickContentElement = await document?.querySelector('.stick-content')
  isStickyScrollingContent.value =
    targetStickContentElement?.scrollHeight > targetStickContentElement?.clientHeight
}
onMounted(() => {
  window?.addEventListener('scroll', activeOnScroll)
})
onUpdated(() => {
  stickContentScroll()
})
onUnmounted(() => {
  window?.removeEventListener('scroll', activeOnScroll)
})
</script>
