<template>
  <CycleBusstop
    v-if="showBusStop && !hasBusstopStepper"
    :steps="mappedSteps"
    :current-step="activeStepIndex"
    @navigation="updateStep"
  />
  <StepperProgressBar
    v-else-if="hasBusstopStepper"
    :steps="mappedSteps"
    :current-step="activeStepIndex"
    :highest-step-index="highestStepIndex"
    @navigation="updateStep"
    @navigateStepToNext="navigateStepToNext"
  />
</template>

<script>
import { CycleBusstop, StepperProgressBar } from '@aon/cycle'
import { mapActions, mapGetters } from 'vuex'
import { submitContext } from '@/components/Modules/RapidFormPlayer/constants/api'

export default {
  name: 'RapidFormStepIndication',
  components: {
    CycleBusstop,
    StepperProgressBar
  },
  props: {
    hasBusstopStepper: {
      type: Boolean,
      default: false
    },
    highestStepIndex: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      'steps',
      'activeStepIndex',
      'activeStep',
      'activeStepNavigation'
    ]),
    mappedSteps() {
      const stepsLength = this.steps.length
      return this.steps
        .filter((step, index) => index !== stepsLength - 1)
        .filter((step) => step.navigation?.showBack || step.navigation?.showNext)
        .map((item) => item.navigation?.busStopTitle || item.name)
    },
    showBusStop() {
      return this.activeStepNavigation?.showBusStop ?? false
    }
  },
  watch: {
    activeStepIndex(newValue, oldValue) {
      if (this.activeStepNavigation?.pageScrollTop && window) {
        window.scrollTo(0, 0, { behavior: 'smooth' })
      } else if (newValue !== oldValue && this.$el && this.showBusStop) {
        this.$el.scrollIntoView?.({ behavior: 'smooth', block: 'end', inline: 'nearest' })
      }
    }
  },
  methods: {
    ...mapActions('rapidFormPlayer', ['updateStep', 'submitStep']),
    navigateStepToNext() {
      this.submitStep({ elementKey: this.activeStep.key, submitContext: submitContext.step })
    }
  }
}
</script>
