//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleButton } from '../elements'

export default {
  name: 'CycleHeroPanel',
  components: { CycleButton }
}
