<template>
  <CycleBankAccount :bank-account="bankAccount" :loading-bank-account="loadingBankAccount" />
</template>

<script>
import bankAccount from './gql/bankAccount.gql'
import { BankAccount } from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleBankAccountNumberSitecore',
  components: {
    CycleBankAccount: BankAccount
  },
  provide() {
    return {
      textContent: {
        bankAccount: {
          header: this.header,
          labels: {
            paymentMethodLabel: this.paymentMethodLabel,
            accountNumberLabel: this.accountNumberLabel
          },
          text: {
            fallbackText: this.fallbackText,
            paymentMethodText: this.paymentMethodText
          }
        }
      },
      button: {
        buttonLink: this.buttonLink,
        buttonText: this.buttonText
      }
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loadingBankAccount: 0,
      bankAccount: {}
    }
  },
  computed: {
    header() {
      return this.fields.header?.value
    },
    fallbackText() {
      return this.fields.fallbackText?.value
    },
    paymentMethodText() {
      return this.fields.paymentMethodText?.value
    },
    paymentMethodLabel() {
      return this.fields.paymentMethodLabel?.value
    },
    accountNumberLabel() {
      return this.fields.accountNumberLabel?.value
    },
    buttonLink() {
      return this.fields.buttonLink?.value
    },
    buttonText() {
      return this.fields.buttonText?.value
    },
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    }
  },
  apollo: {
    bankAccount: {
      prefetch: false,
      query: bankAccount,
      loadingKey: 'loadingBankAccount',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }
  }
}
</script>
