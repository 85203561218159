<template>
  <div class="toggle-tooltip--alert" v-if="hasContent">
    <div class="btn-close" @click.prevent="close">
      <CycleIcon modifiers="cross" />
    </div>
    <template>
      <slot name="tooltip-content" />  
    </template>
  </div>
</template>

<script>
import { computed } from 'vue'
import { CycleIcon } from '@/components/elements'
export default {
  name: 'ToggleTooltip',
  components: {
    CycleIcon
  },
  setup(props, { slots, emit }) {
    const hasContent = computed(()=> {
      return slots['tooltip-content'] && slots['tooltip-content']().length  > 0
    })
    const close = () => {
      emit('close')
    }
    
    return {
      hasContent: hasContent,
      close: close
    }
  }
}
</script>
