<template>
    <p>
        {{ getAccountNumber }} 
        <span @click="toggleCensor" class="privacy-toggle icon" :class="getCensorToggleClass"></span>
    </p>
</template>

<script>
export default {
  name: 'IbanPrivacy',
  props: ['accountNumber', 'censored'],
  computed: {
      getAccountNumber() {
      if (this.accountNumber == undefined || this.accountNumber.length < 5) {
        return ''
      }

      let numlen = this.accountNumber.length - 4
      let accountNumberCensored = ('*'.repeat(numlen) + this.accountNumber.substring(numlen))
      return this.censored ? accountNumberCensored : this.accountNumber
    },
    getCensorToggleClass() {
      return this.censored ? "icon--eye" : "icon--eye-slash"
    }
  },
  methods: {
    toggleCensor() {
      this.$emit("toggleCensor")
    }
  }
}
</script>
