<template>
  <div class="widget" data-testid="myInsurancesItem">
    <header class="widget__header" data-testid="myInsurancesItemHeader">
      <HeadingIcon :icon="icon" />
      <CycleHeading class="heading--dashboard" :data-testid="getDataTestIdForHeading">{{ heading }}</CycleHeading>
      <p v-if="textContent.policyNumberLabel && subheading" :data-testid="getDataTestIdForPolicyNumber">
        {{ textContent.policyNumberLabel }}: <strong>{{ subheading }}</strong>
      </p>
    </header>
    <div class="widget__body" data-testid="myInsurancesItemBody">
      <span v-if="licenseplate" data-testid="myInsurancesItemLicensePlate">
        <template>{{ textContent.licensePlateLabel }}: </template>
        <!-- Kenteken -->
        <strong>{{ licenseplate }}</strong>
      </span>
      <p v-if="hasOverviewContent" data-testid="myInsurancesItemOverviewContent">
        {{ policy.overviewContent.text }} 
        <br /><br />
        <a :href="policy.overviewContent.url" target="_blank">
          {{ policy.overviewContent.urlText }}
        </a>
      </p>
    </div>
    <footer class="widget__footer" data-testid="myInsurancesItemFooter">
      <PolicyActions
        :links="links"
        :text-content="textContent"
        :claim-fallback="claimFallback"
        :status="status"
        :policy-test-id="getDataTestIdForHeading"
        :has-overview-content="hasOverviewContent"
      />
    </footer>
  </div>
</template>

<script>
import { PolicyStatus } from '../../../models'
import { CycleHeading } from '../../elements'
import { CycleButtonLink } from '../../cycle-link'
import { SourceMatrixSwitch } from '../../organisms'
import { LinkToLegacy, PolicyActions, HeadingIcon } from '@/components/molecules'

export default {
  name: 'CycleMyInsurancesItem',
  components: {
    PolicyActions,
    LinkToLegacy,
    CycleHeading,
    CycleButtonLink,
    HeadingIcon,
    SourceMatrixSwitch
  },
  props: {
    textContent: Object,
    heading: String,
    subheading: String,
    licenseplate: String,
    description: String,
    location: String,
    status: PolicyStatus,
    icon: String,
    locationlabel: {
      type: String,
      default: ''
    },
    descriptionlabel: {
      type: String,
      default: 'Omschrijving'
    },
    legacyText: {
      type: String,
      default: 'Wilt u uw verzekering inzien, wijzigen of schade melden?'
    },
    legacyLinkText: {
      type: String,
      default: 'Log dan in op uw oude omgeving.'
    },
    claimFallback: {
      type: String
    },
    policy: Object,
    links: Array
  },
  computed: {
    getDataTestIdForHeading() {
      return this.heading?.replace(' ', '-')?.toLowerCase() || 'policy'
    },
    getDataTestIdForPolicyNumber() {
      return this.getDataTestIdForHeading + "-policy-number"
    },
    hasOverviewContent() {
      return this.policy?.overviewContent ? true : false
    }
  }
}
</script>
