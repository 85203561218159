<template>
  <transition name="pageload">
    <NotFound v-if="notFound" :context="appState.sitecoreContext" />
    <Redirecting v-if="redirecting" :context="appState.sitecoreContext" />
    <RouteLoading v-else-if="loading" />
    <Layout v-else :route="appState.routeData" />
  </transition>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { dataApi } from '@sitecore-jss/sitecore-jss-vue'
import { dataFetcher } from '@/dataFetcher'
import { ignoreRoutechange, isAnchorUpdatedOnSamePath } from './ignoreRoutechange'
import config from '../temp/config'
import Layout from '../Layout'
import NotFound from '../NotFound'
import Redirecting from '../Redirecting'
import RouteLoading from './RouteLoading'
import LoginVariables from '../constants/LoginVariables'

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

export default {
  name: 'RouteHandler',
  components: {
    Layout,
    NotFound,
    Redirecting,
    RouteLoading
  },
  props: {
    route: {
      type: Object
    }
  },
  data() {
    const state = {
      notFound: false,
      defaultLanguage: config.defaultLanguage,
      loading: true,
      redirecting: false
    }

    // To take advantage of Vue's reactive data for tracking app state changes, we need
    // to reference the same `state` object that the $jss store references in order for mutations to be observed.
    // $jss is attached to the Vue instance via `SitecoreJssPlugin`.
    const appState = this.$jss.store.state

    // if the app state has routeData, we don't need to load it and don't need a loading screen
    if (appState.routeData) {
      state.loading = false
    }

    // route path from vue router - if route was resolved, it's not a 404
    if (this.route !== null) {
      state.notFound = false
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    //
    // -- This is not valid when running without SSR context -- RH 2020-05-14
    //
    // if (!appState.routeData) {
    //   state.notFound = true;
    // }

    // if we have initial context data, and that context data has a language defined, set the default language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (appState?.sitecoreContext?.language) {
      state.defaultLanguage = appState.sitecoreContext.language
    }

    return { ...state, appState }
  },
  computed: {
    ...mapGetters(['clientIdentifier'])
  },
  watch: {
    // watch for a change in the 'route' prop
    route(newRoute, oldRoute) {
      this.reloadForNewConfig(newRoute, oldRoute)

      // if the route contains a hash value, assume the URL is a named anchor/bookmark link, e.g. /page#anchorId.
      // in that scenario, we don't want to fetch new route data but instead allow default browser behavior.
      if (isAnchorUpdatedOnSamePath(newRoute, oldRoute)) {
        return
      }

      // if the route change should be ignored (e.g. funnel page changes ?page=x)
      if (ignoreRoutechange(newRoute, oldRoute)) {
        return
      }

      this.updateRouteData()
    }
  },
  created() {
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!this.appState.routeData) {
      this.updateRouteData()
    }
  },
  methods: {
    ...mapMutations(['RESET_STATE', 'SET_CLIENT_IDENTIFIER']),
    /**
     * Loads route data from Sitecore Layout Service into appState.routeData
     */
    reloadForNewConfig(newRoute, oldRoute) {
      //reload page if new config is needed for alternate config path
      if (window.__ENV__?.alternateConfigPath) {
        const altConfigPath = window.__ENV__?.alternateConfigPath
        if (
          newRoute?.fullPath.includes(altConfigPath) !== oldRoute?.fullPath.includes(altConfigPath)
        ) {
          window.location.reload()
        }
      }
    },
    updateRouteData() {
      let sitecoreRoutePath = this.route.params.sitecoreRoute || '/'
      if (!sitecoreRoutePath.startsWith('/')) {
        sitecoreRoutePath = `/${sitecoreRoutePath}`
      }

      const language =
        this.route.params.lang || this.appState.sitecoreContext.language || this.defaultLanguage
      this.loading = true
      const query = this.route.query

      // get the route data for the new route
      getRouteData(sitecoreRoutePath, language, query).then((routeData) => {
        if (routeData?.sitecore?.context?.redirect?.location?.length > 0) {
          window.location.replace(routeData?.sitecore?.context?.redirect?.location)
        }

        if (routeData !== null && routeData?.sitecore?.route) {
          if (routeData.sitecore.context.siteSettings) {
            this.$store.dispatch('siteSettings/updateSiteSettings', {
              currentLanguage: routeData.sitecore.context.language,
              availableLanguages: routeData.sitecore.context.siteSettings.activeLanguages,
              siteName: routeData.sitecore.context?.site?.name,
              logoutUrl: routeData.sitecore.context?.siteSettings.logoutUrl,
              dictionary: routeData.sitecore.context?.dictionary
            })
          }
          const newClientIdentifier = routeData.sitecore.context?.securityInfo?.clientIdentifier

          if (newClientIdentifier && newClientIdentifier !== this.clientIdentifier) {
            this.$store.commit('RESET_STATE')

            this.$store.commit('SET_CLIENT_IDENTIFIER', {
              clientIdentifier: newClientIdentifier
            })
          }
          // Update the JSS store instance with the fetched data.
          // This will signal the RouteHandler to update/re-render, as well as any components
          // that are referencing the JSS store instance in their `data` object.
          this.$jss.store.setSitecoreData(routeData)
          this.notFound = false
          this.redirecting = false
        } else {
          // only show error page when getRouteData returns null
          this.notFound = routeData === null ? true : false
          this.redirecting = routeData === null ? false : true
        }
        this.loading = false
      })
    }
  }
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 * @param {object} query Object containing all search query params
 */
function getRouteData(route, language, query) {
  const additionalParams = Object.keys(query)
    .filter((key) => /^sc_/.test(key))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: query[key]
      }),
      {}
    )

  const querystringParams = {
    ...additionalParams,
    sc_lang: language,
    query: JSON.stringify(query)
  }

  let site = ''

  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: querystringParams,
    fetcher: dataFetcher
  }

  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response?.headers?.['x-cfp-redirectlocation']) {
      window.location.replace(error.response.headers['x-cfp-redirectlocation'])

      return
    }
    if (error?.response?.status === 401) {
      const loginDomain = window.__ENV__?.sitecoreUrl || config.sitecoreApiHost
      let returnUrl = window.location.pathname + window.location.search

      // FE removes the state when url has jlo=1; so returnUrl should never contain jlo=1
      if (returnUrl.includes('jlo=1')) {
        returnUrl = returnUrl.replace('jlo=1', 'jli=1')
      }
      if (!returnUrl.includes('jli=1')) {
        returnUrl = returnUrl.includes('?') ? returnUrl + '&jli=1' : returnUrl + '?jli=1'
      }
      const loginUrl =
        loginDomain +
        LoginVariables.LoginUrl.replace(/##returnUrl##/g, encodeURIComponent(returnUrl))
          .replace(/##origin##/g, encodeURIComponent(window.location.origin))
          .replace(/##site##/g, encodeURIComponent(site))

      window.location.replace(loginUrl)
      return
    }
    if (
      (error?.response?.status === 404 || error?.response?.status === 403) &&
      error?.response?.data
    ) {
      return error.response.data
    }

    if (error?.response?.status === 500) {
      return dataApi.fetchRouteData('/500/', fetchOptions).catch((error) => {
        console.error('Failed to get error page', error)
        return null
      })
    }

    console.error('Route data fetch error', error, error.response)

    return null
  })
}
</script>

<style>
.pageload-enter-active,
.pageload-leave-active {
  transition: opacity 0.15s;
}

.pageload-enter,
.pageload-leave-to {
  opacity: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pageload-enter,
  .pageload-leave-to {
    opacity: 1;
  }
}
</style>
