//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCurrency } from '../../../../filters/cycle-currency/cycle-currency'
import { intlMixin } from '../../../../mixins/intl'

export default {
  name: 'CycleCheckoutLabel',
  filters: {
    CycleCurrency
  },
  mixins: [intlMixin],
  props: {
    amount: {
      type: [String, Number]
    },
    amountDescription: {
      type: String
    },
    amountSubtext: {
      type: String
    }
  }
}
