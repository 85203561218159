export const testableMixin = {
  mounted() {
    if (this.$cfpa && this.$cfpa.registerForTest) {
      this.$cfpa.registerForTest(this.name, this.$refs)
    }
  },
  destroyed() {
    if (this.$cfpa && this.$cfpa.unRegisterForTest) {
      this.$cfpa.unRegisterForTest(this.name, this.$refs)
    }
  }
}
