export const mixin = {
  inject: ['formData'],
  props: {
    question: {
      type: Object,
      required: false,
      default: () => ({ isActive: false })
    }
  },
  computed: {
    active() {
      return this.question.isActive
    },
    description() {
      return this.question.description || ''
    },
    code() {
      return this.question.code || ''
    },
    name() {
      return this.code ? `formData.finalQuestions.${this.code}` : ''
    }
  }
}
