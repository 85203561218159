//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { WeddingCoverageTypes } from '../../models'

export default {
  name: 'WeddingStepOverview',
  components: {
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    overviewData: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      coverageTypes: WeddingCoverageTypes
    }
  },
  computed: {
    totalPremium() {
      return this.overviewData?.premium?.totalPremium
    }
  },
  methods: {
    premiumCoverage(coverage) {
      return this.overviewData?.premium?.coverages[coverage]
    }
  }
}
