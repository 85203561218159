<template>
  <PersonalDetails
    :heading="heading"
    :name-label="nameLabel"
    :empty-name="emptyName"
    :address-label="addressLabel"
    :postal-address-label="postalAddressLabel"
    :empty-address-line1="emptyAddressLine1"
    :empty-address-line2="emptyAddressLine2"
    :birthday-label="birthdayLabel"
    :empty-birthday="emptyBirthday"
    :link="link"
    :mypersonalinfo="mypersonalinfo"
  />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { default as PersonalInfo } from './gql/mypersonalinfo.gql'

export default {
  name: 'CyclePersonalDetailsSitecore',
  components: {
    PersonalDetails: Cycle.CyclePersonalDetailsSitecore
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      error: undefined,
      personalData: {}
    }
  },
  computed: {
    heading() {
      return this.fields.heading?.value
    },
    nameLabel() {
      return this.fields.nameLabel?.value
    },
    emptyName() {
      return this.fields.emptyName?.value
    },
    addressLabel() {
      return this.fields.addressLabel?.value
    },
    postalAddressLabel() {
      return this.fields.postalAddressLabel?.value
    },
    emptyAddressLine1() {
      return this.fields.emptyAddressLine1?.value
    },
    emptyAddressLine2() {
      return this.fields.emptyAddressLine2?.value
    },
    birthdayLabel() {
      return this.fields.birthdayLabel?.value
    },
    emptyBirthday() {
      return this.fields.emptyBirthday?.value
    },
    link() {
      return this.fields.link?.value || {}
    },
    mypersonalinfo() {
      return { personalData: this.personalData }
    }
  },
  async created() {
    try {
      this.personalData = (await this.$jss.gql.query(PersonalInfo)).data.personalData
    } catch (error) {
      this.error = error
    }
  }
}
</script>
