//
//
//
//
//
//
//
//
//

import { CycleLinkIcon } from '@/components/cycle-link/'
import { CycleListItem } from '@/components/cycle-list/'
import CycleCardList from '@/components/cycle-card-list/CycleCardList'
import EmptyTablePlaceholder from './EmptyTablePlaceholder'

export default {
  name: 'PolicySchedule',
  inject: ['textContent'],
  components: {
    CycleLinkIcon,
    CycleListItem,
    CycleCardList,
    EmptyTablePlaceholder
  },
  props: {
    policySchedule: {
      type: Object,
      default: () => {}
    }
  }
}
