<template>
  <CycleCardList v-if="hasList" :title="title" :modifiers="modifiers">
    <template slot="list-items">
      <CycleListItem v-for="(listItem, index) in list" :key="index">
        {{ listItem }}
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script setup>
import { CycleCardList, CycleListItem } from '@aon/cycle'
import { defineProps } from 'vue'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  },
  params: {
    type: Object,
    default: () => ({})
  }
})

const title = props.fields?.title?.value
const list = props.fields?.list?.value || []
const hasList = list?.length > 0
const modifiers = props.params?.modifiers
</script>
