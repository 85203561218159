<template>
  <CycleAside>
    <ScPlaceholder name="jss-content" :rendering="rendering" />
  </CycleAside>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import { CycleAside } from '@aon/cycle'

export default {
  name: 'AsideSitecore',
  components: {
    CycleAside,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
