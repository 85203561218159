<template>
  <!-- eslint-disable vue/attribute-hyphenation -- need camelcased $props in funnelMixin -->
  <FunnelWedding v-bind="fields" :antiForgeryToken="antiForgeryToken" :dataSource="dataSource">
    <template #collectivity-picker>
      <ScPlaceholder name="jss-content" :rendering="rendering" />
    </template>
  </FunnelWedding>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'FunnelWeddingSitecore',
  components: {
    FunnelWedding: Cycle.FunnelWeddingSitecore,
    ScPlaceholder: Placeholder
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    antiForgeryToken() {
      return this.rendering.antiForgeryToken
    },
    dataSource() {
      return this.rendering.dataSource
    }
  },
  errorCaptured(error) {
    console.error(error)
    return false
  }
}
</script>
