<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :next-button-text="contentProvider.getContentByPath('steps.payment.nextButton')"
    :prev-button-text="contentProvider.getContentByPath('steps.payment.backButton')"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.payment.title" />
      </CycleHeading>
    </template>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="paymentDetails.startDate.heading" />
      </template>
      <ProvidedHtml name="paymentDetails.startDate.content" />

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="paymentDetails.startDate.label" />
        </template>

        <CycleDateInput
          v-model="formData.startDate"
          required
          :min="minStartDate"
          :max="maxStartDate"
        />

        <template #error-min>
          {{ phrase('error-messages-funnels-002') }}
        </template>

        <template #error-max>
          {{ phrase('error-messages-funnels-003') }}
        </template>
      </CycleInputContainer>
    </CycleFormSet>

    <PaymentDetailsForm :payment-details="paymentDetails" existing-customer />
  </CycleFormPage>
</template>

<script>
import { addDays } from 'date-fns'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'

import { dictionaryMixin } from '@/mixins'
import { ProductPremium } from '../../models'

import { currentYYYYMMDD, toYYYYMMDD } from '@/services/DateTime'

export default {
  name: 'HomeContentsStepPayment',
  inject: ['formData', 'contentProvider'],
  components: {
    CycleButton,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText,
    CycleDateInput
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      type: ProductPremium,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    minStartDate() {
      return currentYYYYMMDD()
    },
    maxStartDate() {
      return toYYYYMMDD(addDays(new Date(), 365))
    },
    paymentDetails() {
      const init = {
        paymentTerm: PaymentTerm.Monthly,
        premium: this.premium?.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }
        this.paymentDetails = newValue.paymentDetails
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
</script>
