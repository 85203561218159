//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pathOr } from 'ramda'
import { camelCase } from 'lodash'

import { CycleRadioInput, CycleButton, CycleHeading, CycleTag } from '@/components/elements'
import ContentToggle from '@/components/molecules/ContentToggle'
import { CycleHtml } from '@/components/base'
import { CycleButtonLink, CycleLink } from '@/components/cycle-link'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import ModalTooltip from '@/components/molecules/ModalTooltip'
import ValueTransition from '@/components/molecules/ValueTransition'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
export default {
  name: 'CycleCoverage',
  filters: {
    CycleCurrency
  },
  components: {
    CycleHeading,
    CycleList,
    CycleLink,
    CycleListItem,
    CycleButtonLink,
    CycleButton,
    ModalTooltip,
    CycleHtml,
    CycleRadioInput,
    CycleTag,
    ContentToggle,
    ValueTransition
  },
  mixins: [intlMixin],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    recommendedText: {
      type: String,
      required: false
    },
    isHighlighted: {
      type: Boolean,
      required: false
    },
    showToggle: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    bodytext: {
      type: String,
      required: false
    },
    price: {
      type: [Number, String],
      required: false
    },
    paymentTerm: {
      type: String,
      required: false,
      default: 'p/m'
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    alternativeOffers: {
      type: Array,
      required: false
    },
    link: {
      type: [String, Object],
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    modifiers: {
      type: [String, Array],
      required: false
    },
    value: {
      type: [String, Object],
      required: false
    },
    modelValue: {
      type: [String, Object],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    highlightedCoverageToggleOpenText: {
      type: String,
      required: false,
      default: 'Toon dekkingen'
    },
    highlightedCoverageToggleCloseText: {
      type: String,
      required: false,
      default: 'Verberg dekkingen'
    },
    mostPopularText: {
      type: String,
      required: false
    },
    selectedText: {
      type: String,
      required: false
    },
    disabledText: {
      type: String,
      required: false
    },
    transitionParams: {
      type: Object,
      default: () => ({})
    },
    preventNotInsurableTextTooltip: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    testableName() {
      if (!this.name) {
        return 'coverageButton'
      }

      return `coverageButton-${camelCase(this.title.replace(/[^\w]+/g, ''))}`
    },
    comparisonItemClassList() {
      const mainClassName = 'comparison__item'
      return {
        [mainClassName]: true,
        [`${mainClassName}--disabled`]: this.disabled,
        [`${mainClassName}--highlighted`]: this.isHighlighted,
        [`${mainClassName}--selected`]: this.isSelected,
        [`${mainClassName}--bot-cards`]: this.isBotEnabled,
        [`${mainClassName}--card-checked`]: this.isCheckMarkEnabled
      }
    },
    showBodyText() {
      return (this.bodytext && !this.disabled) || (this.bodytext && this.preventNotInsurableTextTooltip)
    },
    showBodyTextTooltip() {
      return this.bodytext && !this.preventNotInsurableTextTooltip
    },
    isSelected() {
      return this.modelValue === this.value && this.modelValue != null
    },
    linkUrl() {
      return pathOr(false, ['link', 'href'], this)
    },
    linkText() {
      return pathOr(this.link, ['link', 'text'], this)
    },
    hasLink() {
      return this.linkText && this.linkUrl
    },
    hasList() {
      return this.list?.length > 0
    },
    isListInToggle() {
      return this.hasList && this.showToggle
    },
    isRecommendedTextVisible() {
      return !!this.recommendedText && this.isHighlighted
    },
    isPopular() {
      return !!this.mostPopularText
    },
    isBestOfferTool() {
      return this.isBotEnabled || this.isCheckMarkEnabled
    },
    isBotEnabled() {
      return this.modifiers?.includes('BestOfferTool')
    },
    isCheckMarkEnabled() {
      return this.modifiers?.[0]?.includes('BestOfferToolCheckMark')
    },
    hasSingleCoverBlock() {
      return this.modifiers?.[0]?.includes('BestOfferToolSingleCoverblock')
    },
    pricePosition() {
      return this.isBestOfferTool ? 'top' : 'bottom'
    },
    hasTransitionParams() {
      return !!this.transitionParams?.intervalTime
    },
    singleCoverblockSectionClassName() {
      return this.hasSingleCoverBlock ? 'comparison__list--single-coverblock-section' : ''
    },
    description() {
      return (this.list?.filter((item) => !item?.icon?.name))[0]?.text
    },
    hasDescription() {
      return this.description?.length > 0
    },
    hasTooltip() {
      return this.tooltip?.length > 0
    }
  },
  methods: {
    emitter(event) {
      this.$emit('click', this.value ?? event)
    },
    updateValue() {
      this.$emit('change', this.value)
    },
    extractOffer(offer) {
      return offer?.linkToSalesFunnel ?? {}
    },
    getUspIcon(usp) {
      return usp?.icon?.name ?? ''
    },
    hasUspIcon(usp) {
      return usp?.icon?.name
    }
  }
}
