<template>
  <a class="tooltip" @click="toggleTooltip" @blur="hide" tabindex="1" v-if="hasContent">
    {{ linktext }}
    <CycleCard tooltip :class="{ 'card--hiding': isHidden }" :id="_cycleUid">
      <template #header>
        <CycleHeading :weight="3">
          <slot name="tooltip-heading">Tooltip title</slot>
        </CycleHeading>
        <CycleLink href="#" target="_self" @click.prevent="hide">
          <CycleIcon modifiers="cross" />
        </CycleLink>
      </template>

      <template #body>
        <slot>
          <slot name="tooltip-content">
            A tooltip is often used to specify extra information about something when the user moves
            the mouse pointer over an element.
          </slot>
        </slot>
      </template>
    </CycleCard>
  </a>
</template>

<script>
import CycleCard from '../card/CycleCard'
import { CycleHeading, CycleIcon } from '../elements'
import { CycleLink } from '../cycle-link'
import { CycleUidMixin } from '../../mixins'

export default {
  name: 'CycleTooltip',
  components: {
    CycleCard,
    CycleHeading,
    CycleLink,
    CycleIcon
  },
  mixins: [CycleUidMixin],
  props: {
    linktext: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isHidden: true,
      card: undefined,
      cardSelector: '.card'
    }
  },
  computed: {
    hasContent() {
      return (
        this.$slots['tooltip-content']?.length > 0 ||
        this.$slots['tooltip-heading']?.length > 0 ||
        this.linktext?.length > 0
      )
    }
  },
  mounted() {
    if (this.$el.querySelector == null) {
      return
    }
    this.card = this.$el.removeChild(this.$el.querySelector(this.cardSelector))
    document.body.appendChild(this.card)
  },
  watch: {
    isHidden(val) {
      if (!val) {
        this.$nextTick(() => {
          this.setPosition(this.card)
        })
      }
    }
  },
  methods: {
    getFit(p) {
      return [
        p.trigger.top > p.card.height, // fitsAbove
        p.doc.width - p.trigger.right > p.card.width, // fitsRight
        p.doc.height - p.trigger.bottom > p.card.height, // fitsBelow
        p.trigger.left > p.card.width // fitsLeft
      ]
    },
    setPosition(c) {
      const p = this.getPositions()
      const [, fitsRight, fitsBelow, fitsLeft] = this.getFit(p)

      let left = fitsRight ? p.trigger.left + p.trigger.width : p.trigger.left - p.card.width
      if (!fitsLeft && !fitsRight) {
        left =
          Math.ceil((p.doc.width - p.card.width) / 2) ||
          parseInt(getComputedStyle(c)['font-size']) / 2
      }
      const top = fitsBelow
        ? p.trigger.top + p.trigger.height + p.scroll
        : p.trigger.top - p.card.height + p.scroll

      c.style.cssText = `top: ${top}px; left: ${left}px; right: ${left}px;`
    },
    getPositions() {
      const scroll = document.documentElement.scrollTop
      const doc = document.documentElement.getBoundingClientRect()
      const trigger = this.$el.getBoundingClientRect()
      const card = this.card.getBoundingClientRect()
      return { scroll, trigger, card, doc }
    },
    hide() {
      this.isHidden = true
    },
    toggleTooltip() {
      this.isHidden = !this.isHidden
    }
  }
}
</script>
