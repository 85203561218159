<template>
  <Navigation :items="navigationItems" :logo-link="logoLink" :logo="logo" />
</template>
<script>
import { Navigation } from '@aon/cycle'
export default {
  name: 'CycleNavigationSitecore',
  components: { Navigation },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    navigationItems() {
      let items = this.fields?.items || []
      if (!items.length > 0) {
        return []
      }

      if (this.params?.hideLanguageSwitch) {
        return items
      }
      const indexForLanguageSwitch = items.length - 1
      return [
        ...items.slice(0, indexForLanguageSwitch),
        { fields: { isLanguageSwitch: true } },
        ...items.slice(indexForLanguageSwitch)
      ]
    },
    logoLink() {
      return this.fields?.LogoLink?.value.href
    },
    logo() {
      return this.fields?.Logo?.value
    }
  }
}
</script>
