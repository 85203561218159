export enum InsuranceProduct {
  Accidents = 'ongevallen',
  Bike = 'bike',
  Boat = 'vaartuig',
  Camper = 'camper',
  CamperRental = 'camper-rental',
  Cancellation = 'cancellation',
  Car = 'auto',
  Caravan = 'caravan',
  Contents = 'inboedel',
  Damage = 'damage',
  Death = 'death',
  DeliveryVan = 'delivery-van',
  Disability = 'arbeidsongeschiktheid',
  Diving = 'diving',
  Dog = 'dog',
  EBike = 'e-bike',
  Entrepreneurship = 'Entrepreneurship',
  Expat = 'expat',
  Globetrotter = 'globetrotter',
  Golf = 'golf',
  Health = 'zorg',
  HearingDevice = 'gehoorapparaat',
  Home = 'woonhuis',
  HuntingEquipment = 'hunting-equipment',
  HuntingLiability = 'hunting-liability',
  Income = 'income',
  LegalAid = 'rechtsbijstand',
  Liability = 'aansprakelijkheid',
  Microcar = 'microcar',
  Moped = 'bromfiets',
  Mortgages = 'mortgages',
  MotorCycle = 'motor',
  MyMoney = 'my-money',
  Oldtimer = 'oldtimer',
  Other = 'andere-producten',
  Pension = 'pension',
  Recreationalhome = 'recreationalhome',
  Recreationgoods = 'recreatiegoederen',
  RelationAndChildren = 'relation-and-children',
  Speedpedelec = 'speedpedelec',
  Students = 'students',
  Trailer = 'trailer',
  Travel = 'reis',
  Vip = 'vip',
  VitaalVoordeel = 'vitaal-voordeel',
  Wedding = 'bruiloft'
}
