//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pathOr, isEmpty } from 'ramda'
import { InsuranceProduct } from '@/constants'
import CycleMyInsurancesItem from './my-insuranceitem/CycleMyInsurancesItem.vue'
import { PolicyStatus } from '../../models'
import { CycleHeading } from '@/components/elements'

function mapPolis(packageItem) {
  return {
    ...packageItem,
    heading: pathOr('Verzekering', ['product', 'name'], packageItem),
    policyNumber: packageItem.number,
    omschrijving: pathOr('Onbekend', ['product', 'name'], packageItem),
    locatie: pathOr('', ['reference'], packageItem),
    status: PolicyStatus.fromObject(packageItem.status),
    kenteken: pathOr('', ['licensePlate'], packageItem),
    sourceSystem: pathOr('', ['sourceSystem'], packageItem)
  }
}

export default {
  name: 'MyInsurancesList',
  components: {
    CycleMyInsurancesItem,
    CycleHeading
  },
  inject: ['textContent'],
  props: {
    portfolioInfo: Object,
    textContent: Object
  },
  computed: {
    constructData() {
      const portfolioInfo = pathOr(this.portfolioInfo || [], ['portfolioInfo'], this.portfolioInfo)
      let packages = pathOr([], ['packages'], portfolioInfo)
      const policies = pathOr([], ['policies'], portfolioInfo)

      packages = [{ policies }, ...packages]

      return packages
        .map((packageItem) => ({
          name: pathOr('', ['product', 'name'], packageItem),
          policies: pathOr([], ['policies'], packageItem)
            .filter(({ product }) => product != null)
            .map(mapPolis)
        }))
        .filter(({ policies }) => !isEmpty(policies))
    }
  },
  methods: {
    gridDividerClass(index) {
      const baseClass = 'grid__divider'
      if (index + 1 < this.constructData?.length) {
        return baseClass
      }

      return `${baseClass} ${baseClass}--last`
    },
    policyIcon(icon) {
      return InsuranceProduct[icon] || 'shield'
    }
  }
}
