<template>
  <div v-if="banner" class="banner__wrapper" data-testid="banner-wrapper">
    <img v-if="hasBannerImage" v-bind="bannerSrc" class="banner__img" data-testid="banner-image" />
    <div>
      <h3 v-if="hasBannerTitle" class="banner__title" data-testid="banner-title">{{ title }}</h3>
      <CycleHtml
        v-if="hasBannerContent"
        :html="content"
        class="banner__content"
        data-testid="banner-content"
      />
    </div>
  </div>
</template>
<script>
import { CycleHtml } from '@aon/cycle'
export default {
  name: 'RapidFormBannerContentField',
  components: { CycleHtml },
  props: {
    banner: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    hasBannerTitle() {
      return !!this.banner?.title
    },
    hasBannerContent() {
      return !!this.banner?.content
    },
    hasBannerImage() {
      return !!this.banner?.image
    },
    bannerSrc() {
      return {
        src: this.banner?.image?.url,
        alt: this.banner?.image?.altText
      }
    },
    title() {
      return this.banner?.title
    },
    content() {
      return this.banner?.content
    }
  }
}
</script>
