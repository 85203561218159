<template>
  <NotificationBar :notification-type="modifiers" :nomargin="isNomargin">
    <ScRichText tag="span" :field="fields.bodytext" />
  </NotificationBar>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue'
import { pathOr } from 'ramda'

export default {
  name: 'CycleNotificationSitecore',
  components: {
    ScRichText: RichText
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    modifiers() {
      return pathOr([], ['params', 'modifiers'], this)
    },
    isNomargin() {
      return this.params['nomargin'] ?? false
    }
  }
}
</script>
