import {
  SET_SITESETTINGS_CURRENTLANGUAGE,
  SET_SITESETTINGS_AVAILABLELANGUAGES,
  SET_SITESETTINGS_SITENAME,
  SET_SITESETTINGS_LOGOUTURL,
  SET_DICTIONARY
} from './mutation-types'

import { SiteSettingsState } from './state'
import { localizedPaths } from './models'

export default {
  [SET_SITESETTINGS_CURRENTLANGUAGE]: (state: SiteSettingsState, currentLanguage: string) => {
    state.currentLanguage = currentLanguage
  },
  [SET_SITESETTINGS_AVAILABLELANGUAGES]: (
    state: SiteSettingsState,
    availableLanguages: localizedPaths
  ) => {
    state.availableLanguages = availableLanguages
  },
  [SET_SITESETTINGS_SITENAME]: (state: SiteSettingsState, siteName: string | undefined) => {
    state.siteName = siteName
  },
  [SET_SITESETTINGS_LOGOUTURL]: (state: SiteSettingsState, logoutUrl: string | undefined) => {
    state.logoutUrl = logoutUrl
  },
  [SET_DICTIONARY]: (state: SiteSettingsState, dictionary: Object) => {
    state.dictionary = dictionary
  }
}
