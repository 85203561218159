<template>
  <CycleCardList
    v-if="showCoveragesCard"
    :title="textContent.coverageTitle"
    :modifiers="coverageModifier"
    data-testid="coveragesCard"
  >
    <template #list-items v-if="showCoverages">
      <CycleListItem
        v-for="coverage in activeCoverages"
        :key="coverage"
        data-testid="coveragesCardActive"
      >
        {{ textContent[`${coverage}Label`] }}
      </CycleListItem>
    </template>
    <template #list-items v-else>
      <CycleListItem data-testid="coveragesCardFallback">
        <CycleHtml :html="textContent.fallbackCoverage" />
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleListItem, CycleCardList, CycleHtml } from '@aon/cycle'

export default {
  name: 'CoveragesCard',
  components: {
    CycleCardList,
    CycleListItem,
    CycleHtml
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    coverageModifier() {
      return this.showCoverages ? 'pros' : 'nomarker'
    },
    hasActiveCoverages() {
      return this.activeCoverages.length > 0
    },
    showCoveragesCard() {
      return this.hasActiveCoverages || this.textContent?.fallbackCoverage
    },
    areCoverageLabelsPresent() {
      return this.activeCoverages.every((coverage) => this.textContent[`${coverage}Label`])
    },
    showCoverages() {
      return this.hasActiveCoverages && this.areCoverageLabelsPresent
    },
    homeCoverages() {
      const homeCoverages = {
        ...(this.policyInfo?.policy?.item?.homeConfiguration?.coverages ||
          this.policyInfo?.policy?.item?.contentsConfiguration?.coverages)
      }

      if (!homeCoverages) {
        return {}
      }
      return Object.entries(homeCoverages).reduce((acc, [, value]) => {
        if (value?.name) {
          acc = { ...acc, [value?.name]: value?.active }
        }
        return acc
      }, {})
    },
    coverages() {
      const policyInfoItem = this.policyInfo?.policy?.item

      if (!policyInfoItem) {
        return null
      }

      const configurations =
        policyInfoItem.carConfigurations ||
        policyInfoItem.legalAidConfiguration ||
        policyInfoItem.liabilityConfiguration ||
        policyInfoItem.travelConfiguration ||
        policyInfoItem.weddingConfiguration ||
        policyInfoItem.homeConfiguration ||
        policyInfoItem.motorcycleConfigurations ||
        policyInfoItem.contentsConfiguration

      const subCoverages = {
        ...configurations?.mainCoverage,
        ...configurations?.additionalCoverages,
        ...this.homeCoverages,
        ...configurations?.options
      }

      return {
        ...Object.entries(subCoverages).reduce((acc, [key, value]) => {
          let coverageValue = true
          if (key === 'item' && value?.type === 'Limited') {
            key = 'limitedCasco'
          } else if (key === 'item' && value?.type === 'Full') {
            key = 'fullCasco'
          } else if (key === '__typename') {
            coverageValue = false
          } else if (value?.hideCoverage !== undefined && value?.hideCoverage) {
            coverageValue = false
          } else if (value?.active !== undefined && !value?.active) {
            coverageValue = false
          } else if (value?.active === undefined && !value) {
            coverageValue = false
          }
          return {
            ...acc,
            [key]: coverageValue
          }
        }, {})
      }
    },
    activeCoverages() {
      if (this.coverages == null) {
        return []
      }
      return Object.keys(this.coverages).filter((key) => this.coverages[key])
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
