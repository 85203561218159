<template>
  <ChangeAddress v-bind="componentData" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleChangeAddressSitecore',
  components: {
    ChangeAddress: Cycle.CycleChangeAddressSitecore
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    componentData() {
      return {
        ...mapValues(this.fields, (item) => item.value ?? item),
        ...this.rendering
      }
    }
  }
}
</script>
