/* eslint-disable no-console */
/**
 * Encapsulate the logging system for easy abstraction.
 */
const functionalLogger = (level) => {
  return (...args) => {
    if (console && console[level]) {
      console[level](...args)
    }
  }
}

const noop = () => {}
export const logInfo = process.env.VUE_APP_CONFIG_SILENT ? noop : functionalLogger('info')
export const logWarning = process.env.VUE_APP_CONFIG_SILENT ? noop : functionalLogger('warn')
export const logError = functionalLogger('error')
