//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { CycleRadioInput } from '@/components/elements'
import { dictionaryMixin } from '@/mixins'

export default {
  name: 'HomeContentsStepAcceptance',
  components: { CycleInputContainer, CycleFormPage, CycleFormSet, CycleRadioInput },
  inject: ['formData', 'contentProvider'],
  mixins: [dictionaryMixin],
  computed: {
    isNotInsurable() {
      return (
        !!this.formData.rental ||
        !!this.formData.woodenOrThatchedRoof ||
        !!this.formData.recreationalHome
      )
    }
  },
  watch: {
    'formData.ownHome'() {
      this.formData.rental = undefined
    }
  },
  methods: {
    handleSubmit() {
      const { ownHome, rental, woodenOrThatchedRoof, recreationalHome } = this.formData

      this.$emit('submit', {
        ownHome: { answer: ownHome },
        rental: { answer: rental },
        woodenOrThatchedRoof: { answer: woodenOrThatchedRoof },
        recreationalHome: { answer: recreationalHome }
      })
    }
  }
}
