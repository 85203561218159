<template>
  <div class="grid pakket-area">
    <div class="title">{{ getText('PakketText') }}</div>
    <div class="content" v-for="(pack, index) in packages" :key="pack.name">
      <div class="listings">
        <div class="listing">
          <div>{{ getText('PakketNameText') }}</div>
          <div class="data">{{ pack.name }}</div>
        </div>
        <div class="listing">
          <div>{{ getText('InsurerText') }}</div>
          <div class="data">{{ pack.insurer }}</div>
        </div>
        <div class="listing">
          <div>{{ pack.premiumTerm }}</div>
          <div class="data">{{ pack.premium }}</div>
        </div>
      </div>
      <InsuranceEntryZakelijk
        :name="getText('InThisPakketText')"
        :value="pack"
        :is-folded="index !== 0"
      >
      </InsuranceEntryZakelijk>
    </div>
    <p>{{ getPhrase('Zakelijk.MoreInfo') }}</p>
  </div>
</template>
<script>
import InsuranceEntryZakelijk from './InsuranceEntryZakelijk.vue'
import { dictionaryMixin } from '@/mixins/Dictionary'
export default {
  name: 'BusinessPackages',
  components: {
    InsuranceEntryZakelijk
  },
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    let insuranceKeys = Object.keys(this.fields?.insurances) || []
    let packages = []
    try {
      packages = insuranceKeys
        .map((key) => {
          return this.fields.insurances[key].packages
        })
        .filter((p) => p.length > 0)
        .flat()
    } catch (e) {
      console.error('error', e)
    }
    return { packages }
  },
  methods: {
    getText(text) {
      return this.getPhrase(text) || text
    }
  }
}
</script>
