<template>
  <CycleLinkCard :tag="tag" :href="href" :target="target" :text="text" />
</template>

<script>
import { CycleLinkCard } from '@aon/cycle'
export default {
  name: 'CycleLinkCardSitecore',
  components: {
    CycleLinkCard
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    link() {
      return this.fields.link?.value
    },
    href() {
      return this.link?.href || ''
    },
    target() {
      return this.link?.target || '_self'
    },
    text() {
      return this.link?.text || '_self'
    },
    tag() {
      const count = this.fields.Counter || this.fields.counter
      if (!count) return {}
      const type = this.fields.type || 'success'
      return { count, type }
    }
  }
}
</script>
