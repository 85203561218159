<template>
    <h5 v-if="price != null" class="comparison__price">
        <template>
            <CycleHtml tag="span" :html="price | CycleCurrency({ locale: 'nl-NL', split: true })" />
            <span class="comparison__payment-term">{{ paymentTerm }}</span>
        </template>
    </h5>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleCurrency } from '@/filters'
export default {
    name: 'CoveragePremiumPrice',
    components: {
        CycleHtml
    },
    filters: {
        CycleCurrency
    },
    props: {  
        price: {
            type: [Number, String],
            required: false
        },
        paymentTerm: {
            type: String,
            required: false,
            default: 'p/m'
        }
    }
}
</script>