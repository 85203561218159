//
//
//
//
//
//
//

export default {
  name: 'ResponsivePicture',
  props: {
    sources: { type: Object, required: true },
    defaultKey: { type: String, required: true },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
    fetchPriority: { type: String }
  },
  computed: {
    defaultSrc() {
      return this.sources[this.defaultKey]
    }
  }
}
