//
//
//
//
//

export default {
  name: 'FixedBox',
  props: {
    isInPageEditMode: {
      type: Boolean
    },
    showOnDesktop: {
      type: Boolean
    },
    stickToElement: {
      type: String
    },
    outOfViewElement: {
      type: String
    }
  },
  data() {
    return {
      isActive: false,
      attached: false,
      bodyFixedBoxClassName: 'has-fixed-box-bottom'
    }
  },
  computed: {
    classList() {
      const mainClassName = 'fixed-box-bottom'
      return {
        [`${mainClassName}`]: !this.isInPageEditMode,
        [`${mainClassName}--active`]: this.isActive && !this.isInPageEditMode,
        [`${mainClassName}--desktop`]: this.showOnDesktop,
        [`${mainClassName}--attached`]: this.isActive && this.attached
      }
    }
  },
  mounted() {
    document?.body.setAttribute('class', this.bodyFixedBoxClassName)
    window?.addEventListener('scroll', this.setActiveness)
    if (this.stickToElement) {
      window?.addEventListener('scroll', this.setStickToElement)
    }
  },
  destroyed() {
    document?.body.classList.remove(this.bodyFixedBoxClassName)
    window?.removeEventListener('scroll', this.setActiveness)
    if (this.stickToElement) {
      window?.removeEventListener('scroll', this.setStickToElement)
    }
  },
  methods: {
    attachToElement(thisElement, targetElement) {
      const bodyRect = document?.body.getBoundingClientRect()
      const elemRect = targetElement.getBoundingClientRect()
      const offset = elemRect.top - bodyRect.top
      thisElement.style['margin-top'] = 0 - thisElement.clientHeight + 'px'
      thisElement.style.top = offset + 'px'
      thisElement.style.height = thisElement.clientHeight + 'px'
      this.attached = true
    },
    detachFromElement(thisElement) {
      thisElement.style.top = ''
      thisElement.style.height = ''
      this.attached = false
    },
    isElementInViewport(element) {
      const rect = element.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      const isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return isVisible
    },
    setStickToElement() {
      const targetElement = document?.querySelector(this.stickToElement)
      const fixedBoxElement = this.$refs.fixedBox
      if (this.isElementInViewport(targetElement)) {
        this.attachToElement(fixedBoxElement, targetElement)
      } else {
        this.detachFromElement(fixedBoxElement)
      }
    },
    setActiveness() {
      //panel--hero hardcoded for fallback if sitecore settings aren't set
      this.isActive = !this.isElementInViewport(
        (this.outOfViewElement && document?.querySelector(this.outOfViewElement)) ||
          document?.querySelector('.panel--hero')
      )
    }
  }
}
