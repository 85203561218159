//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '../../../elements/CycleHeading'

export default {
  name: 'CycleFormSet',
  components: { CycleHeading },
  props: {
    heading: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classList() {
      const baseClassName = 'form__set'
      return [baseClassName, ...this.modifiers.map((modifier) => `${baseClassName}--${modifier}`)]
    }
  }
}
