<template>
  <CycleStoryteqContainer
    :placeholder="placeholder"
    :video-key="videoKey"
    :param-name="paramName"
  />
</template>

<script>
import * as cycle from '@aon/cycle'

export default {
  name: 'StoryteqContainer',
  components: {
    CycleStoryteqContainer: cycle.StoryteqContainer
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    placeholder() {
      return this.fields.placeholder.value
    },
    videoKey() {
      return this.fields.videoKey.value
    },
    paramName() {
      return this.fields.paramName.value
    }
  }
}
</script>
