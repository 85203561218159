<template>
  <CycleFormSet>
    <component
      v-for="question in showableQuestions"
      :key="question.code"
      :is="`Question${question.questionType}`"
      :labels="labels"
      :question="question"
      v-model="value[question.code]"
      :name="`funnelQuestions.${nature}.${question.code}`"
      :validation-path="`formData.funnelQuestions.${nature}.${question.code}`"
      :validate-on-input-value="validateOnInputValue"
      @input="handleInput"
    />
  </CycleFormSet>
</template>

<script>
import { CycleFormSet } from '@/components/functional/forms'

import QuestionBoolean from '../QuestionBoolean'
import QuestionCheckBox from '../QuestionCheckBox'
import QuestionInfoText from '../QuestionInfoText'
import QuestionText from '../QuestionText'

export default {
  name: 'FunnelQuestions',
  components: {
    QuestionBoolean,
    QuestionCheckBox,
    QuestionInfoText,
    QuestionText,
    CycleFormSet
  },
  inject: ['formData'],
  props: {
    questions: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    labelBooleanYes: {
      type: String,
      required: false,
      default: 'Ja'
    },
    labelBooleanNo: {
      type: String,
      required: false,
      default: 'Nee'
    },
    labelCheckbox: {
      type: String,
      required: false,
      default: 'Ik ga akkoord'
    },
    nature: {
      type: String,
      required: true
    },
    validateOnInputValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    showableQuestions() {
      return this.questions.filter((question) =>
        this.parentsHavePositiveAnswer(question.followUpParentCode)
      )
    },
    labels() {
      return {
        booleanYes: this.labelBooleanYes,
        booleanNo: this.labelBooleanNo,
        checkbox: this.labelCheckbox
      }
    }
  },
  created() {
    this.setReactive(this, `formData.funnelQuestions.${this.nature}`.split('.'), this.value || {})
  },
  methods: {
    setReactive(object, pathArr, newVal) {
      if (pathArr.length == 1) {
        this.$set(object, pathArr[0], newVal)
      } else {
        if (!object[pathArr[0]]) this.$set(object, pathArr[0], {})
        this.setReactive(object[pathArr[0]], pathArr.splice(1), newVal)
      }
    },
    parentsHavePositiveAnswer(parentCodes) {
      if (parentCodes.length === 0) {
        return true
      }

      return parentCodes.some((code) => this.value[code] != null && this.value[code].isPositive)
    },
    handleInput() {
      Object.entries(this.value).forEach(([key, value]) => {
        this.$set(this.formData.funnelQuestions[this.nature], key, value)
      })
      this.$emit('input', this.value)
    }
  }
}
</script>
