<script>
import { Fragment } from 'vue-fragment'
import { CycleHtml, ModalTooltip } from '@aon/cycle'
export default {
  name: 'RapidFormContentField',
  components: { Fragment, CycleHtml, ModalTooltip },
  props: {
    content: {
      type: String,
      default: ''
    },
    tooltip: {
      type: [String, Boolean],
      default: false
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classList() {
      if (!Array.isArray(this.modifiers)) {
        return ''
      }

      return this.modifiers.join(' ')
    },
    isInline() {
      if ((Array.isArray(this.modifiers) && this.modifiers.includes('inline')) || this.tooltip) {
        return true
      }

      return null
    }
  },
  methods: {
    composeFieldTooltip(createElement) {
      if (this.tooltip) {
        return createElement(ModalTooltip, [
          createElement(CycleHtml, { props: { html: this.tooltip } }),
          createElement('template', { slot: 'tooltip-heading' }, '')
        ])
      }
    }
  },
  render(createElement) {
    return createElement('div', {}, [
      createElement(CycleHtml, {
        props: { html: this.content, class: this.classList, inline: this.isInline }
      }),
      this.composeFieldTooltip(createElement)
    ])
  }
}
</script>
