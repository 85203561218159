//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty, pathOr } from 'ramda'
import CycleForm from '../../forms/CycleForm/CycleForm'
import CycleFormPage from '../../forms/CycleFormPage/CycleFormPage'
import CycleFormSet from '../../forms/CycleFormSet/CycleFormSet'
import CycleFormConfirmation from '../../forms/CycleFormConfirmation/CycleFormConfirmation'
import { CycleInputContainer } from '../../forms/CycleInputContainer'
import { CycleButton } from '../../../elements/CycleButton'
import { CycleHeading } from '../../../elements/CycleHeading'
import CycleBusstop from '../../../cycle-busstop/CycleBusstop'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'

import { CycleCheckout, CycleCheckoutItem } from '../../CycleCheckout'
import { CycleButtonLink } from '../../../cycle-link'
import { CycleCompareCoverage } from '../../CycleCompareCoverage'
import { CycleCoverage } from '../../CycleCompareCoverage'
import { CycleHtml } from '../../../base'
import { CyclePersonalData, CycleFinalQuestions, CycleFunnelOverview } from '../shared/'
import LicenseOwner from './step4/LicenseOwner'
import RegularDriver from './step4/RegularDriver'

import {
  CycleLicensePlateInput,
  CycleDateInput,
  CycleTextInput,
  CycleRadioInput,
  CycleSelect,
  CycleOption,
  CycleCheckboxInput,
  CycleHiddenInput
} from '../../../elements'

import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import { debounce } from 'lodash'

import { CycleCurrency } from '../../../../filters'
import { intlMixin, dictionaryMixin } from '../../../../mixins'
import { overview } from './helpers'

import { NotificationBar } from '@/components/atoms'

export default {
  name: 'CycleFunnelCar',
  filters: {
    CycleCurrency
  },
  components: {
    CycleFormConfirmation,
    CycleForm,
    CycleFormPage,
    CycleFormSet,
    CContainer: CycleInputContainer,
    CLicenseplate: CycleLicensePlateInput,
    CText: CycleTextInput,
    CDate: CycleDateInput,
    CSelect: CycleSelect,
    COption: CycleOption,
    CRadio: CycleRadioInput,
    CCompare: CycleCompareCoverage,
    CCoverage: CycleCoverage,
    CCheckout: CycleCheckout,
    CCheckoutItem: CycleCheckoutItem,
    CCheckbox: CycleCheckboxInput,
    CLink: CycleButtonLink,
    CycleBusstop,
    CycleHtml,
    CycleTooltip,
    CycleButton,
    CycleHeading,
    CyclePersonalData,
    CycleFinalQuestions,
    RegularDriver,
    LicenseOwner,
    CycleFunnelOverview,
    CHidden: CycleHiddenInput,
    NotificationBar
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    checkSignCodeAndLicensePlate: {
      type: Function,
      required: false,
      default: () => ''
    },
    getPremiumsAction: { type: Function, required: false, default: () => {} },
    checkAcceptance: { type: Function, required: false, default: () => {} },
    getPremiumsForConfigurationAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    getPremiumsForConfigurationSelectionAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    formSubmitAction: { type: Function, required: false, default: () => {} },
    chooseInsurerAction: { type: Function, required: false, default: () => {} },
    lookupAction: { type: Function, required: false, default: () => {} },
    fieldInformation: { type: Object, required: false, default: () => ({}) },
    premiumInformation: { type: Array, required: false, default: () => [] },
    vehicleInformation: { type: Object, required: false, default: () => ({}) },
    finalQuestions: { type: Object, required: false, default: () => ({}) },
    optionalCoverageInformation: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isAcceptedLicensePlate: { type: Boolean, default: true },
    declinedLicensePlateMessage: { type: String, required: false }
  },
  data() {
    return {
      claimFreeYears: Array.from({ length: 34 }, (v, k) => k - 3).map((key) => {
        let value = key
        if (value === 30) value = '30+'
        if (value === -3) value = '-3 of meer'
        return { key, value }
      }),
      expectedResponse: 'YES',
      isAcceptedObject: true,
      declinedObjectMessage: undefined,
      retrievingSignCode: undefined
    }
  },
  computed: {
    funnelOverview() {
      const {
        textContent,
        formData,
        optionalCoverageInformation,
        vehicleInformation,
        fieldInformation,
        currentSecurityValue,
        optionalCoveragesByValue
      } = this
      const textContentKeyMap = {
        'PremiumRequestConfig-Aon-Car-WA': 'coverageWa',
        'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco': 'coverageWaPlus',
        'PremiumRequestConfig-Aon-Car-WA-Casco': 'coverageWaExtended',
        legalAid: 'legalaid',
        passengerDamage: 'passengers'
      }

      return overview({
        textContent,
        formData,
        optionalCoverageInformation,
        vehicleInformation,
        fieldInformation,
        currentSecurityValue,
        optionalCoveragesByValue,
        textContentKeyMap
      })
    },
    licensePlateLengthOk() {
      return !!(this.formData.licenseplate && this.formData.licenseplate.length >= 6)
    },
    pricePerMonth() {
      return this.optionalCoverageInformationSet ? this.optionalCoverageInformation.priceCurrent : 0
    },
    updatedSignCode() {
      return this.formData.signCode
    },
    updatedLicensePlate() {
      return this.formData.licenseplate
    },
    isSignCodeValid() {
      return (
        this.updatedSignCode?.length === 4 &&
        this.formData.checkSignCodeResponse === this.expectedResponse
      )
    },
    steps() {
      return [
        this.textContent.page1title,
        this.textContent.page2title,
        this.textContent.page3title,
        this.textContent.page4title,
        this.textContent.page5title,
        this.textContent.page6title,
        this.textContent.page7title
      ]
    },
    startAdditionalStep4Form() {
      return (
        this.formData.regulardriver !== undefined &&
        this.formData.licenseholder !== undefined &&
        this.formData.onlyprivate !== undefined
      )
    },
    chosenCoverage() {
      return this.formData.coverage
    },
    chosenCoverages() {
      return this.formData.coverages
    },
    chosenAccessories() {
      return this.formData.accessories
    },
    isNotInsurable() {
      return !this.formData.onlyprivate || !this.startAdditionalStep4Form
    },
    isRegularDriver() {
      return !!this.formData.regulardriver
    },
    isLicenseHolder() {
      return !!this.formData.licenseholder
    },
    isNotPrivate() {
      return !this.formData.onlyprivate && this.formData.onlyprivate !== undefined
    },
    hasWaCoverage() {
      return this.formData.coverage != 'coverageWa'
    },
    minAge() {
      return format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    maxAge() {
      return format(addDays(subYears(new Date(), 80), 1), 'yyyy-MM-dd')
    },
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    carAge() {
      const currentYear = new Date().getFullYear()
      return (
        currentYear - parseInt(this.vehicleInformation && this.vehicleInformation.constructionYear)
      )
    },
    hasVehicleInformation() {
      return (
        this.vehicleInformation !== undefined &&
        !!Object.keys(this.vehicleInformation).length &&
        this.vehicleInformation.constructor === Object
      )
    },
    agreementQuestions() {
      if (!this.finalQuestions.getAgreementQuestions) {
        return []
      }

      return this.finalQuestions.getAgreementQuestions.reduce((acc, elem) => {
        if (elem.type === 'CheckBox') {
          return [...acc, elem.description]
        }
        return acc
      }, [])
    },
    distancePerYear() {
      return (this.fieldInformation && this.fieldInformation.CarMilage) || []
    },
    optionalCoverageInformationSet() {
      return !isEmpty(this.optionalCoverageInformation)
    },
    optionalCoveragesByValue() {
      const coverages = pathOr([], ['optionalCoverages'], this.optionalCoverageInformation)
      return coverages.reduce((acc, c) => ({ ...acc, [c.value]: c }), {})
    },
    carSecurityOptions() {
      return (this.fieldInformation && this.fieldInformation.SecurityClass) || []
    },
    currentSecurityValue() {
      const { carsecurity = '' } = this.formData
      return pathOr(
        '',
        ['value'],
        this.carSecurityOptions.find((o) => o.key === carsecurity)
      )
    },
    isValidVehicle() {
      return this.isAcceptedLicensePlate && this.isAcceptedObject
    },
    isValidVehicleMessage() {
      return this.declinedLicensePlateMessage || this.declinedObjectMessage
    },
    showSignCodeError() {
      return !this.isSignCodeValid && this.retrievingSignCode === false
    },
    showSignCodeSuccess() {
      return this.isSignCodeValid && this.retrievingSignCode === false
    }
  },
  watch: {
    chosenCoverage(newCoverage) {
      this.$nextTick(() => {
        // give this.formData a chance to update
        this.getPremiumsForConfigurationAction(newCoverage).then(() => {
          this.$refs.form.isSubmitting = false
          this.$refs.form.next()
        })
      })
    },
    chosenCoverages(newCoverages, oldCoverages) {
      if (this.$refs.form.formState.index === 2 && newCoverages.length !== oldCoverages.length) {
        this.fetchNewPremiumForSelection()
      }
    },
    chosenAccessories() {
      if (this.$refs.form.formState.index === 2) {
        this.fetchNewPremiumForSelection()
      }
    },
    optionalCoverageInformation(newValue) {
      this.$set(this.formData, 'accessories', pathOr(0, ['accessoryValue'], newValue))
    },
    hasWaCoverage(showacc) {
      if (!showacc) {
        const accessoriesIndex = this.formData.coverages.indexOf('accessories')
        if (accessoriesIndex > -1) this.formData.coverages.splice(accessoriesIndex, 1)
      }
    },
    vehicleInformation(newVal) {
      this.$set(this.formData, 'vehicleInformation', newVal)
    },
    updatedSignCode(newVal) {
      if (newVal.length !== 4) {
        this.formData.checkSignCodeResponse = undefined
      } else {
        this.checkSignCodeCombination()
      }
    },
    updatedLicensePlate(newVal) {
      if (newVal) {
        this.formData.checkSignCodeResponse = undefined
        this.formData.signCode = ''
      }
    }
  },
  methods: {
    stripHtml(string) {
      return string.replace(/<\/?[^>]+(>|$)/g, '')
    },
    setCoverage(coverage) {
      this.$refs.form.isSubmitting = true
      this.$set(this.formData, 'coverage', coverage)
      // give the update a chance to propagate
      this.$nextTick(() => this.$emit('coverageChange'))
    },
    checkSignCodeCombination: debounce(
      function () {
        this.retrievingSignCode = true
        return this.checkSignCodeAndLicensePlate()
          .then((result) => {
            this.$set(this.formData, 'checkSignCodeResponse', result)
          })
          .finally(() => {
            this.retrievingSignCode = false
          })
      },
      500,
      { leading: true, trailing: false }
    ),
    async handleFirstStep() {
      const response = await this.checkAcceptance()
      await this.handleObjectAcceptation(response)
      await this.getPremiumsAction()
      this.setDefaultDriver()
    },
    setDefaultDriver() {
      const { zipcode, birthdate } = this.formData
      const driver = {
        zipcode,
        birthdate
      }
      this.$set(this.formData, 'driver', driver)
    },
    handleObjectAcceptation(res) {
      const acceptancePoints = pathOr(0, ['premie', 'points'], res)
      if (Number(acceptancePoints) < 5000) {
        this.isAcceptedObject = true
        return Promise.resolve()
      }
      this.isAcceptedObject = false
      this.declinedObjectMessage = pathOr(0, ['premie', 'displayMessage'], res)
      window.scroll({
        top: () => this.$el.getBoundingClientRect().top,
        behavior: 'smooth'
      })
      return Promise.reject(new Error('rejection'))
    },
    fetchNewPremiumForSelection() {
      this.$refs.form.isSubmitting = true
      this.$nextTick(() => {
        // give this.formData a chance to update
        this.getPremiumsForConfigurationSelectionAction().then(
          () => (this.$refs.form.isSubmitting = false)
        )
      })
    }
  }
}
