<template>
  <div class="tile">
    <input
      v-if="hasDefaultSlot"
      type="radio"
      class="tile__toggle"
      :name="`${tilesId}-toggle`"
      ref="toggle"
      tabindex="-1"
      :id="tileId"
      :value="tileId"
      :checked="selected"
    />
    <label
      class="tile__button"
      :for="tileId"
      @click="showContent"
      tabindex="0"
      ref="label"
      :style="labelStyle"
    >
      <CycleIcon v-if="!isVertical && !isImaged" :modifiers="icon" />
      <CycleHeading v-if="title">{{ title }}</CycleHeading>
      <p v-if="summary">{{ summary }}</p>
    </label>
    <article v-if="hasDefaultSlot" class="tile__content" tabindex="-1">
      <div v-if="title" class="tile__header">
        <CycleHeading :weight="2">{{ title }}</CycleHeading>
      </div>
      <div class="tile__body">
        <slot></slot>
      </div>
    </article>
  </div>
</template>

<script>
import { CycleHeading, CycleIcon } from '../../../elements'
import { CycleUidMixin } from '../../../../mixins/uid'

export default {
  name: 'CycleTile',
  components: { CycleHeading, CycleIcon },
  mixins: [CycleUidMixin],
  inject: {
    tilesProvider: {
      default: {
        tilesId: null
      }
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    summary: {
      type: String,
      required: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    isImaged: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    tilesId() {
      return this.tilesProvider.tilesId || `tiles-undefined-${this._cycleUid}-`
    },
    tileId() {
      return `${this.tilesId}-tile-${this._cycleUid}`
    },
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    labelStyle() {
      return {
        [`background-image`]: this.tileBackgroundImage
      }
    },
    tileBackgroundImage() {
      return this.isImaged && this.image?.src?.length > 0 ? `url(${this.image.src})` : null
    }
  },
  methods: {
    showContent() {
      if (!this.hasDefaultSlot) return
      const toggle = this.$refs.toggle
      const label = this.$refs.label
      const checked = toggle.checked

      requestAnimationFrame(() => {
        toggle.checked = !checked

        !this.isVertical &&
          toggle.checked &&
          label.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }
  }
}
</script>
