<template>
  <div
    v-if="!isNotificationBarClosed"
    class="notification-bar"
    :class="`notification-bar--${alertType}`"
  >
    <div v-if="hasTitle || hasBodyText" class="notification-bar__content">
      <div v-if="hasTitle" class="notification-bar__content--header">
        <p>{{ title }}</p>
      </div>
      <ContentToggle
        v-if="hasBodyText"
        is-plain
        :open-action-text="toggleOpenText"
        :close-action-text="toggleClosedText"
      >
        <CycleHtml :html="bodyText" />
      </ContentToggle>
      <button
        v-if="isNotificationBarCloseable"
        @click="closeNotificationBar"
        class="icon icon--cross"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ContentToggle } from '@aon/cycle'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})
const title = props.fields?.title
const hasTitle = title?.length > 0
const bodyText = props.fields?.bodyText
const hasBodyText = bodyText?.length > 0
const toggleOpenText = props.fields?.toggleOpenText
const toggleClosedText = props.fields?.toggleClosedText
const alertType = props.fields?.alertType
const isNotificationBarCloseable = props.fields?.isNotificationBarCloseable
const isNotificationBarClosed = ref(localStorage.getItem('isNotificationBarClosed') === 'false')

const closeNotificationBar = () => {
  isNotificationBarClosed.value = true
  saveStorage()
}
const saveStorage = () => {
  localStorage.setItem('isNotificationBarClosed', isNotificationBarClosed.value.toString())
}

onMounted(() => {
  if (!isNotificationBarCloseable) {
    return
  }
  isNotificationBarClosed.value = localStorage.getItem('isNotificationBarClosed') === 'true'
})
</script>
