<template>
  <div v-if="active">
    <CycleHtml v-if="description" :html="description" />
    <CycleInputContainer>
      <CycleCheckboxInput
        v-model="formData.finalQuestions[code]"
        :name="name"
        :value="true"
        :label="label"
        required
      />
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleCheckboxInput } from '../../../../../elements'
import { CycleHtml } from '../../../../../base'
import { CycleInputContainer } from '../../../CycleInputContainer'
import { mixin } from './mixin'

export default {
  name: 'CycleFinalQuestionCheckBox',
  components: {
    CycleCheckboxInput,
    CycleHtml,
    CycleInputContainer
  },
  mixins: [mixin],
  props: {
    label: {
      type: String,
      required: false,
      default: 'Ik ga akkoord'
    }
  }
}
</script>
