<template>
  <CycleContentBlock centered>
    <CycleHtml :html="introText" />
  </CycleContentBlock>
</template>

<script>
export default {
  name: 'CollectivityIntroSitecore',
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    introText() {
      return this.fields.bodyText
    }
  }
}
</script>
