<template>
  <div>
    <CycleHtml v-for="{ text, id } in textValues" :html="text" :key="id" />
  </div>
</template>

<script>
import { CycleHtml } from '@/components/base'

export default {
  name: 'ServiceInformation',
  components: {
    CycleHtml
  },
  props: {
    serviceInfo: {
      required: false,
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    textValues() {
      if (Array.isArray(this.serviceInfo)) {
        return this.serviceInfo.map((e) => ({ text: e.fields.richText.value, id: e.id }))
      }

      return Object.values(this.serviceInfo).map((e, i) => ({
        text: e.text,
        id: Object.keys(this.serviceInfo)[i]
      }))
    }
  }
}
</script>
