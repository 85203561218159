<template>
  <div v-if="hasContent" :class="popoverTooltipClassList">
    <a :class="tooltipClassList" @click.prevent="isVisible = true" tabindex="1">{{
      tooltipLabelText
    }}</a>
    <div v-show="isVisible" ref="tooltipElement" class="popover-tooltip__alert" :style="styleVars">
      <div class="btn-close" @click.prevent="close">
        <CycleIcon modifiers="cross" />
      </div>
      <div class="popover-tooltip__alert--content">
        <slot name="tooltip-content" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, nextTick, onMounted, onUnmounted } from 'vue'
import { CycleIcon } from '@aon/cycle'
export default {
  name: 'PopoverTooltip',
  components: {
    CycleIcon
  },
  props: {
    tooltipLinkText: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup(props, { slots }) {
    const isVisible = ref(false)
    const tooltipElement = ref(null)
    const hasContent = computed(() => {
      return slots['tooltip-content'] && slots['tooltip-content']().length > 0
    })
    const styleVars = computed(() => {
      let newTooltipPos = 0
      const { top, left, right, width } = tooltipElement?.value?.getBoundingClientRect() || {}
      const clientWidth = window.innerWidth || document.documentElement.clientWidth
      const isInViewport = top >= 0 && left >= 0 && right <= clientWidth
      const tipGutter = 5
      if (!isInViewport) {
        const gutterValue = 20
        const gap = clientWidth - left
        newTooltipPos = width - gap + gutterValue
      }
      return {
        left: `${-Math.abs(newTooltipPos)}px`,
        '--popover-tip-position': `${newTooltipPos + tipGutter}px`
      }
    })
    const close = () => {
      isVisible.value = false
    }
    const tooltipLabelText = props?.tooltipLinkText?.text
    const hasTooltipLabelText = tooltipLabelText?.length > 0
    const popoverTooltipClassList = computed(() => {
      const mainClassName = 'popover-tooltip'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--tooltip-link-text`]: hasTooltipLabelText
      }
    })
    const tooltipClassList = computed(() => {
      return {
        tooltip: !hasTooltipLabelText,
        [`tooltip--tooltip-link-text icon icon--${props?.tooltipLinkText?.icon}`]:
          hasTooltipLabelText,
        'tooltip--tooltip-link-text-active': hasTooltipLabelText && isVisible.value
      }
    })
    onMounted(() => {
      nextTick(() => {
        window.addEventListener('resize', close)
      })
    })
    onUnmounted(() => {
      window.removeEventListener('resize')
    })
    return {
      hasContent,
      close,
      isVisible,
      tooltipElement,
      styleVars,
      tooltipLabelText,
      popoverTooltipClassList,
      hasTooltipLabelText,
      tooltipClassList
    }
  }
}
</script>
