export const props = {
  fields: {
    type: Object,
    default: () => ({})
  },
  rendering: {
    type: Object,
    default: () => ({})
  }
}
