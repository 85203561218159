<template>
  <nav
    :class="{
      nav: true,
      'nav--stroke': stroke,
      'nav--icon': icon,
      'nav--buttons': buttons
    }"
  >
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'CycleNav',
  props: {
    buttons: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  }
}
</script>
