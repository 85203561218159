export const ignoreRoutechange = (newRoute, oldRoute) => {
  if (newRoute.path !== oldRoute.path) {
    return false
  }

  return ignorePatterns.some(
    (pattern) =>
      pattern.path.test(newRoute.path) &&
      Object.keys(newRoute.query ?? oldRoute.query ?? {}).includes(pattern.query)
  )
}

const ignorePatterns = [
  { path: /\/afsluiten/, query: 'page' },
  { path: /\/wijzigen-polis/, query: 'page' }
]

export const isAnchorUpdatedOnSamePath = (newRoute, oldRoute) => {
  return newRoute.path === oldRoute.path && newRoute.hash !== ''
}
