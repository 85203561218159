export const state = () => ({
  formDefinitionId: undefined,
  name: undefined,
  steps: undefined,
  prevStepIndex: null,
  fieldSets: undefined,
  fieldGroups: undefined,
  fields: undefined,
  dictionary: undefined,
  formState: {
    step: null,
    token: null,
    submitted: false,
    validationStatus: {},
    validationVisible: false,
    errorElements: [],
    invalidElements: [],
    activeFields: [],
    touchedElements: []
  },
  formData: {},
  formDataFormatted: {},
  businessData: {},
  analyticsData: {},
  errors: {},
  communication: {}
})
