<template>
  <CycleWithQueryParam query-key="insurance">
    <template #default="insurance">
      <CycleClaimForm
        :text-content="{ ...$props }"
        :form-submit-action="formSubmit"
        :policy-number="insuranceNumber(insurance)"
        :insurance-name="insuranceName(insurance)"
        :insurance-hash="insurance"
      />
    </template>
  </CycleWithQueryParam>
</template>
<script>
import CycleClaimForm from '../../functional/forms/CycleClaimForm/CycleClaimForm'
import { CycleWithQueryParam } from '../../base'
import { jssMixin } from '../../../mixins'
import { reducePortfolio } from '../../../services/Insurance'

import portfolioInfo from './queries.gql'

export default {
  name: 'CycleClaimFormSitecore',
  components: { CycleClaimForm, CycleWithQueryParam },
  mixins: [jssMixin],
  props: {
    currentInsuranceNameLabel: {
      type: String
    },
    policyNumberLabel: {
      type: String
    },
    claimDateLabel: {
      type: String
    },
    claimExplanationLabel: {
      type: String
    },
    estimatedClaimCostLabel: {
      type: String
    },
    sendFormLabel: {
      type: String
    },
    postSucceededMessageText: {
      type: String
    },
    postFailedMessageText: {
      type: String
    }
  },
  data() {
    return {
      portfolioInfo: { policies: [], packages: [] }
    }
  },
  computed: {
    policies() {
      return reducePortfolio(this.portfolioInfo)
    }
  },
  beforeMount() {
    this.preload()
  },
  methods: {
    formSubmit(formValues) {
      return this.gql.mutationByName('createClaim', this.claimFormParams(formValues))
    },
    async preload() {
      try {
        const res = await this.gql.query(portfolioInfo)
        this.portfolioInfo = res.data.portfolioInfo
      } catch {
        // if only we had error handling
      }
    },
    claimFormParams(formValues) {
      const portfolioReduction = reducePortfolio(this.portfolioInfo)
      const myPolicy =
        portfolioReduction[formValues?.policyNumber] || portfolioReduction[this.insurance]

      return {
        formValues: {
          ...formValues,
          policyNumber: this.insuranceNumber(formValues.policyNumber),
          sourceSystem: myPolicy.sourceSystem.system.code,
          business: myPolicy.product.name,
          policyUniqueIdentifier: myPolicy.policyUniqueIdentifier
        }
      }
    },
    insuranceName(policyNumberEncrypted) {
      return this.policies[policyNumberEncrypted]?.product?.name || '-'
    },
    insuranceNumber(policyNumberEncrypted) {
      return this.policies[policyNumberEncrypted]?.number || null
    }
  }
}
</script>
