
export default {
  name: 'ClickSimulator',
  props: {
    linkAttribute: {
      type: String
    },
    buttonToClick: {
      type: String
    },
    iframeName: {
      type: String,
      required: false
    }
  },
  mounted() {
    this.handleListeners(true)
  },
  methods: {
    handleListeners(add) {
      const chatButton = document?.querySelectorAll(`[data-script="${this.linkAttribute}"]`)
      if (chatButton) {
        chatButton.forEach((el) => {
          if (add) {
            el.addEventListener('click', this.clickButton)
          } else {
            el.removeEventListener('click', this.clickButton)
          }
        })
      }
    },
    clickButton() {
      if (this.iframeName) {
        const chatFrameObject = document.getElementsByName(this.iframeName)
        if (document.getElementsByName(this.iframeName).length > 0) {
          chatFrameObject[0]?.contentWindow?.document?.getElementById(this.buttonToClick)?.click()
        } else {
          document
            .querySelector('.' + this.iframeName)
            .contentWindow?.document?.querySelector('div > button')
            ?.click()
        }
      } else {
        const buttonElementToClick = document?.getElementById(this.buttonToClick)
          ? document?.getElementById(this.buttonToClick)
          : document?.querySelector('.' + this.buttonToClick)
        buttonElementToClick.click()
      }
    }
  },
  beforeUnmount() {
    this.handleListeners(false)
  },
  render: () => null
}
