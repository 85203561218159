<template>
  <CycleContentBlock v-if="isDirectLinksCardVisible" contrast-color data-testid="directLinksCard">
    <template #header>
      <CycleHeading data-testid="directLinksCardHeader">
        {{ textContent.directButtonsTitle }}
      </CycleHeading>
    </template>
    <template #body>
      <PolicyActions
        :links="directLinks"
        :text-content="textContent"
        data-testid="directLinksCardBody"
      />
    </template>
  </CycleContentBlock>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleContentBlock, CycleHeading, CycleHtml, PolicyActions } from '@aon/cycle'

export default {
  name: 'DirectLinksCard',
  components: {
    CycleContentBlock,
    CycleHeading,
    CycleHtml,
    PolicyActions
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getDirectLinks']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    directLinks() {
      return this.getDirectLinks(this.policyNumber)
    },
    isDirectLinksCardVisible() {
      return this.directLinks?.length > 0
    }
  },
  created() {
    this.fetchDirectLinks({ detailsPage: true })
  },
  methods: {
    ...mapActions(['fetchDirectLinks'])
  }
}
</script>
