//
//
//
//
//
//

function constructArray(mode) {
  if (Array.isArray(mode)) {
    return mode
  } else {
    return [mode]
  }
}

export default {
  name: 'CycleSubGrid',
  props: {
    modifiers: {
      type: [String, Array],
      required: false,
      validator(val) {
        return constructArray(val)
          .map((value) => ['buttons', 'cards'].includes(value))
          .reduce((accumulator, currentValue) => accumulator && currentValue, true)
      }
    },
    subgridstyle: {
      type: String,
      required: false
    }
  },
  computed: {
    constructClass() {
      const classArray = ['subgrid']
      if (this.subgridstyle) {
        classArray.push(this.subgridstyle)
      }
      return constructArray(this.modifiers)
        .filter((value) => value !== undefined)
        .filter((value) => value !== '')
        .reduce(
          (accumulator, currentValue) => [...accumulator, `subgrid--${currentValue}`],
          classArray
        )
    }
  }
}
