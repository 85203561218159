<template>
  <CycleContentBlock v-bind="modifierList" :anchor-id="anchorId">
    <template #image v-if="image && imageSrc && !hasEmptyImage">
      <a :href="imageLink" v-if="imageLink">
        <img :src="imageSrc" :title="imageTitle" :alt="imageAlt" loading="lazy" />
      </a>
      <img :src="imageSrc" :title="imageTitle" :alt="imageAlt" loading="lazy" v-else />
    </template>
    <template #header v-if="hasHeaderSlot">
      <CycleIcon v-if="icon" :modifiers="[icon]" hexagon />
      <CycleHeading :weight="headingWeight">{{ title }}</CycleHeading>
    </template>
    <ScRichText tag="div" :field="fields.bodytext" />
    <slot />
    <template #footer v-if="hasFooterSlot">
      <CycleNav buttons v-if="navButtons">
        <component
          v-for="(item, index) in links"
          :key="index"
          :is="getLinkComponent(item)"
          :href="href(item)"
          :target="target(item)"
          modifiers="cta, secondary"
        >
          {{ item.title }}
        </component>
      </CycleNav>
      <template v-else>
        <component
          v-for="(item, index) in links"
          :key="index"
          :is="getLinkComponent(item)"
          :href="href(item)"
          :target="target(item)"
          modifiers="cta, secondary"
        >
          {{ item.title }}
        </component>
      </template>
    </template>
  </CycleContentBlock>
</template>
<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue'
import { defaultHeadingWeight } from '@/constants/Defaults'

export default {
  name: 'CycleContentBlockSitecore',
  components: {
    ScRichText: RichText
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasFooterSlot() {
      return this.links?.length > 0
    },
    hasHeaderSlot() {
      return !!(this.icon || this.title)
    },
    navButtons() {
      return this.params['nav-buttons'] ?? false
    },
    links() {
      return this.fields?.links?.value ?? []
    },
    modifiers() {
      return this.params?.modifiers
    },
    transparent() {
      return this.params?.transparent ?? 0
    },
    modifierList() {
      const modifiers = {}
      if (this.modifiers && this.modifiers.length) {
        modifiers[this.modifiers] = true
      }
      if (this.transparent === 0 || this.transparent === '0') {
        modifiers['contrastColor'] = true
      }
      return modifiers
    },
    footerComponent() {
      return this.navButtons ? 'nav' : 'fragment'
    },
    image() {
      return this.fields?.image?.value ?? {}
    },
    imageLink() {
      return this.fields?.imageLink?.value?.href
    },
    imageSrc() {
      return this.image?.src
    },
    imageAlt() {
      return this.image?.alt
    },
    imageTitle() {
      return this.image?.title || this.image?.name || this.image?.alt || ''
    },
    icon() {
      return this.fields?.icon?.value
    },
    title() {
      return this.fields?.heading?.value
    },
    anchorId() {
      return this.fields?.anchorId?.value
    },
    hasEmptyImage() {
      return this.image?.class?.includes('scEmptyImage')
    },
    headingWeight() {
      return Number.parseInt(this.fields?.weight?.value) || defaultHeadingWeight
    }
  },
  methods: {
    href(item) {
      return (item && item.href) || ''
    },
    target(item) {
      return (item && item.target) || ''
    },
    getLinkComponent(item) {
      return item?.className == 'txt-btn' ? 'CycleLink' : 'CycleButtonLink'
    }
  }
}
</script>
