//
//
//
//
//
//
//
//
//

export default {
  name: 'CycleWebsite',
  props: {
    urlWebsite: {
      type: String,
      required: false,
      default: ''
    }
  }
}
