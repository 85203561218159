//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleInputContainer from './../../../forms/CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleTooltip from '../../../../cycle-tooltip/CycleTooltip'
import { CycleHtml } from '../../../../base'
import { debounce } from 'lodash'

export default {
  name: 'CyclePostcodeCheckDriver',
  components: {
    CycleHtml,
    CycleTooltip,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData'],
  props: {
    postcodeFound: {
      type: Boolean,
      required: false
    },
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    zipcode() {
      return this.formData.driver.zipcode || ''
    },
    housenumber() {
      return this.formData.driver.housenumber || ''
    },
    zipcode_housenumber() {
      return this.zipcode.length + this.housenumber.length
    },
    manualEntry() {
      return !this.postcodeFound
    }
  },
  watch: {
    zipcode_housenumber() {
      if (this.zipcode.length >= 6 && this.housenumber.length > 0) {
        this.fetchAddress(this.formData.driver)
      }
    }
  },
  methods: {
    fetchAddress: debounce(function (e) {
      this.$emit('input', e)
    }, 1000)
  }
}
