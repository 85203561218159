<template>
  <input
    type="text"
    v-bind="Object.assign({}, attributes, $attrs)"
    v-on="Object.assign({}, listeners, $listeners, { input: updateValue })"
    :value="value"
    :class="formInputClass"
  />
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleTextInput',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value.trim())
    }
  }
}
</script>
