//
//
//
//
//
//

import CycleLink from './CycleLink'
import CycleBaseComponent from '../base/CycleBaseComponent'

export default {
  name: 'CycleButtonIconLink',
  components: { CycleLink },
  extends: CycleBaseComponent,
  props: {
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      bem: {
        defaultModifiers: 'icon',
        allowedModifiers: ['icon', 'icon-next'],
        block: 'btn'
      }
    }
  },
  computed: {
    iconClass() {
      return {
        [`icon--${this.icon}`]: !!this.icon
      }
    }
  }
}
