<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText :name="stepTitle" />
      </CycleHeading>
    </template>

    <slot />

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'" v-show="!isInvalid">
        <ProvidedText name="steps.finalQuestions.nextButton" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.finalQuestions.backButton" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHeading } from '@/components/elements'
import { CycleFormPage } from '@/components/functional/forms'
import { ProvidedText } from '../Content'

export default {
  name: 'StepFinalQuestions',
  components: {
    CycleFormPage,
    CycleHeading,
    ProvidedText
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    stepTitle: {
      type: String,
      default: 'steps.finalQuestions.title'
    },
    isInvalid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
</script>
