<template>
  <CycleRadioBarInput
    :value="value"
    :id="keyName + value"
    :model-value="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    v-show="isVisible"
    v-if="isShown"
    :has-input-error="hasInputError"
  >
    <slot />
  </CycleRadioBarInput>
</template>

<script>
import { CycleRadioBarInput } from '@aon/cycle'
import RapidFormRadioWrapper from './RapidFormRadioWrapper'

export default {
  name: 'RapidFormRadioBarWrapper',
  components: {
    CycleRadioBarInput
  },
  extends: RapidFormRadioWrapper,
  props: {
    keyName: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
