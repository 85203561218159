<template>
  <div v-if="hasCarouselTypeStackedReview">
    <header v-if="hasTitle" class="grid__header">
      <CycleHeading :weight="2">{{ title }}</CycleHeading>
    </header>
    <CycleContentBlock v-if="hasImage || hasScore || hasDescription" class="panel--review">
      <template #image v-if="hasImage">
        <img v-bind="imageProps" />
      </template>
      <template #body v-if="hasScore || hasDescription">
        <span v-if="hasScore" class="review__score">{{ score }}</span>
        <CycleHtml v-if="hasDescription" :html="description" />
      </template>
    </CycleContentBlock>

    <SsrCarousel
      v-if="hasCarouselTypeStackedReview && hasChunkedSlides"
      v-bind="options"
      :class="className"
    >
      <div class="slide" v-for="(slide, index) in chunkedSlides" :key="index">
        <CycleContentBlock class="panel--review-stacked">
          <div v-for="(review, reviewIndex) in slide" :key="reviewIndex" class="review-stacked">
            <div
              v-if="hasReviewTitle(review) || isReviewDateVisible(review) || hasRating(review)"
              class="review-stacked__header"
            >
              <span v-if="hasReviewTitle(review)">
                <strong class="review-stacked__title">{{ getReviewTitle(review) }}</strong>
              </span>
              <div
                v-if="isReviewDateVisible(review) || hasRating(review)"
                class="review-stacked__details"
              >
                <span v-if="isReviewDateVisible(review)">{{ getFormattedReviewDate(review) }}</span>
                <div
                  v-if="hasRating(review)"
                  class="rating"
                  :style="{
                    [`--rating`]: getRating(review)
                  }"
                />
              </div>
            </div>
            <div v-if="hasReviewDescription(review)" class="review-stacked__body">
              <CycleHtml :html="getReviewDescription(review)" />
            </div>
          </div>
        </CycleContentBlock>
      </div>
    </SsrCarousel>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import { CycleContentBlock, CycleHeading, CycleHtml } from '@aon/cycle'
import { formatDate } from '@/services/formatting'
import Vue from 'vue'
Vue.use(ssrCarouselCss)
const MinDateValueFromSitecoresDateField = '0001-01-01T00:00:00'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  },
  carouselType: {
    type: String
  },
  className: {
    type: String
  },
  options: {
    type: Object,
    default: () => ({})
  },
  slides: {
    type: Array,
    default: () => []
  }
})

const title = props.fields?.Title?.value
const description = props.fields?.Description?.value
const score = props.fields?.Score?.value
const hasTitle = props.fields.Title?.value?.length > 0
const hasDescription = description?.length > 0
const hasScore = score?.length > 0
const hasCarouselTypeStackedReview = props.carouselType === 'stackedReviews'

const imageProps = {
  alt: props.fields?.altText?.value,
  title: props.fields?.Image?.title?.value,
  src: props.fields?.Image?.value?.src,
  height: props.fields?.Image?.value?.height,
  width: props.fields?.Image?.value?.width
}
const hasImage = imageProps?.src?.length > 0

const chunkedSlides = computed(() => {
  const maxSlides = 3
  const chunkedSlides = []
  for (let i = 0; i < props.slides.length; i += maxSlides) {
    chunkedSlides.push(props.slides.slice(i, i + maxSlides))
  }
  return chunkedSlides
})

const hasChunkedSlides = chunkedSlides.value?.length > 0

const getRating = (review) => {
  const ratingValue = Number.parseFloat(review?.props?.fields?.rating?.value) || 0
  return ratingValue > 5 ? 5 : ratingValue < 0 ? 0 : ratingValue
}

const hasRating = (review) => review?.props?.fields?.rating?.value.length > 0
const getReviewDate = (review) => review?.props?.fields?.date?.value
const isReviewDateVisible = (review) => getReviewDate(review) != MinDateValueFromSitecoresDateField
const getFormattedReviewDate = (review) => formatDate({ date: getReviewDate(review) })
const getReviewDescription = (review) => review.props?.fields?.description?.value
const hasReviewDescription = (review) => review.props?.fields?.description?.value?.length > 0
const getReviewTitle = (review) => review.props?.fields?.title?.value
const hasReviewTitle = (review) => review.props?.fields?.title?.value?.length > 0
</script>
