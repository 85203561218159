<template>
  <FunnelTravelChange v-bind="funnelData" />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'CycleFunnelTravelChangeSitecore',
  components: {
    FunnelTravelChange: Cycle.CycleFunnelTravelChangeSitecore
  },
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  computed: {
    funnelData() {
      return {
        ...this.textContent,
        ...this.renderingWithoutFields
      }
    },
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    renderingWithoutFields() {
      // Fields are somehow duplicated in the rendering prop, we'll remove them here
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
