//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CyclePieChart from '../cycle-pie-chart/CyclePieChart'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
export default {
  name: 'CycleInfoGraph',
  components: {
    CyclePieChart
  },
  filters: { CycleCurrency },
  mixins: [intlMixin],
  props: {
    segments: {
      type: Array
    },
    title: {
      type: Object
    }
  }
}
