<template>
  <MyInsurancesList :portfolio-info="portfolioInfo" :text-content="textContent" />
</template>

<script>
import { portfolioInfoQuery } from '@/gql/queries'
import { MyInsurancesList } from '@aon/cycle'
import { normalizePortfolioInfo } from '@/services/portfolio'

export default {
  name: 'PortfolioList',
  components: {
    MyInsurancesList
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    error: undefined,
    portfolioInfo: {},
    loadingPortfolioInfo: 0
  }),
  computed: {
    textContent() {
      return Object.entries(this.fields).reduce(
        (acc, [key, { value }]) => ({ ...acc, [key]: value }),
        {}
      )
    }
  },
  apollo: {
    portfolioInfo: {
      prefetch: false, // Not prefetching because we don't have authentication
      query: portfolioInfoQuery,
      variables() {
        return {
          inputModel: {}
        }
      },
      loadingKey: 'loadingPortfolioInfo',
      notifyOnNetworkStatusChange: true, // required for loading state to work, @see https://github.com/vuejs/vue-apollo/issues/263
      fetchPolicy: 'cache-and-network',
      update: normalizePortfolioInfo
    }
  }
}
</script>
