<template>
  <ContactDetails
    :heading="heading"
    :email-label="emailLabel"
    :phone-label="phoneLabel"
    :phone-label2="phoneLabel2"
    :newsletter-label="newsletterLabel"
    :empty-phone="emptyPhone"
    :link="link"
    :mypersonalinfo="myPersonalInfo"
  />
</template>

<script>
import * as Cycle from '@aon/cycle'
import { default as PersonalInfo } from './gql/mypersonalinfo.gql'

export default {
  name: 'CycleContactDetailsSitecore',
  components: {
    ContactDetails: Cycle.CycleContactDetailsSitecore
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    heading() {
      return this.fields.heading?.value
    },
    emailLabel() {
      return this.fields.emailLabel?.value
    },
    emptyEmail() {
      return this.fields.emptyEmail?.value
    },
    phoneLabel() {
      return this.fields.phoneLabel?.value
    },
    phoneLabel2() {
      return this.fields.phoneLabel2?.value
    },
    newsletterLabel() {
      return this.fields.emailOptInLabel?.value
    },
    emptyPhone() {
      return this.fields.emptyPhone?.value
    },
    link() {
      return this.fields.link?.value || {}
    }
  },
  apollo: {
    myPersonalInfo: {
      prefetch: false,
      query: PersonalInfo,
      loadingKey: 'loadingPersonalInfo',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      update: (personalData) => ({ ...personalData })
    }
  }
}
</script>
