<template>
  <div class="insurance-entry" v-if="value.policies.length > 0">
    <div class="entry-title">
      <a @click.prevent="collapse" class="entry-text">
        <span class="folding-name">{{ name }} ({{ value.policies.length }})</span>
        <CycleIcon :icon="icon" />
      </a>
    </div>
    <div class="entry-content" v-show="!folded">
      <InsuranceTable :documents="value.policies" />
    </div>
  </div>
</template>

<script>
import { CycleIcon } from '@aon/cycle'
import InsuranceTable from './MyInsuranceTable.vue'

export default {
  name: 'InsuranceEntryZakelijk',
  components: { CycleIcon, InsuranceTable },
  props: {
    name: String,
    value: Object,
    isFolded: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      icon: 'chevron-down',
      folded: this.isFolded
    }
  },
  methods: {
    collapse() {
      this.folded = !this.folded
      this.icon = this.folded ? 'chevron-down' : 'chevron-up'
    }
  }
}
</script>
