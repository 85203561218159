import { pathOr } from 'ramda'

export function premiumResultConfigurationPrice(configuration) {
  return parseFloat(
    ['breakdownAssistance', 'carAssistance', 'casco', 'liability']
      .reduce(
        (premiumSum, coverage) =>
          premiumSum +
          parseFloat(
            pathOr(
              0,
              ['item', 'mainCoverage', coverage, 'coveragePremium', 'termPremium'],
              configuration
            )
          ),
        0
      )
      .toFixed(2)
  )
}

export function premiumResultConfigurationProductCode(configuration) {
  return pathOr(null, ['item', 'premiumRequestProductCode'], configuration)
}

export function premiumResultConfigurationId(configuration) {
  return pathOr('', ['configurationId'], configuration)
}

export function premiumResultConfigurationOrder(configuration) {
  return [
    'PremiumRequestConfig-Aon-Car-WA',
    'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco',
    'PremiumRequestConfig-Aon-Car-WA-Casco'
  ].indexOf(premiumResultConfigurationId(configuration))
}

export function premiumResultConfigurationIdStripped(configuration) {
  return pathOr('', ['configurationId'], configuration).replace('PremiumRequestConfig-', '')
}

const insurerCfg = {
  'Aon-Car-WA': {
    code: 'coverageWa',
    textKeys: {
      title: 'coverageWa',
      tooltip: 'coverageWaTooltip'
    }
  },
  'Aon-Car-WA-Beperkt-Casco': {
    code: 'coverageWaPlus',
    textKeys: {
      title: 'coverageWaPlus',
      tooltip: 'coverageWaPlusTooltip'
    }
  },
  'Aon-Car-WA-Casco': {
    code: 'coverageWaExtended',
    textKeys: {
      title: 'coverageWaExtended',
      tooltip: 'coverageWaExtendedTooltip'
    }
  }
}

const additionalCoverageCfg = {
  legalAid: {
    textKeys: {
      label: 'legalaid',
      tooltip: 'legalaidTooltip'
    }
  },
  passengerDamage: {
    textKeys: {
      label: 'passengers',
      tooltip: 'passengersTooltip'
    }
  }
}

export function premiumResultConfigurationConfig(configuration) {
  return insurerCfg[premiumResultConfigurationIdStripped(configuration)]
}

class additionalCoveragesModel {
  constructor(configuration) {
    this.configuration = configuration
  }
  getAdditionalCoverage(key) {
    const coverage = pathOr(null, ['item', 'additionalCoverages', key], this.configuration)
    if (!coverage) {
      return null
    }

    return {
      termPremium: pathOr(0, ['coveragePremium', 'termPremium'], coverage),
      textKeys: pathOr({}, [key, 'textKeys'], additionalCoverageCfg)
    }
  }
  get legalAid() {
    return this.getAdditionalCoverage('legalAid')
  }
  get passengerDamage() {
    return this.getAdditionalCoverage('passengerDamage')
  }
}

export class premiumResultConfigurationModel {
  constructor(configuration) {
    this.configuration = configuration
    this.additionalCoverages = new additionalCoveragesModel(this.configuration)
  }
  get price() {
    return premiumResultConfigurationPrice(this.configuration)
  }
  get productCode() {
    return premiumResultConfigurationProductCode(this.configuration)
  }
  get configurationId() {
    return premiumResultConfigurationId(this.configuration)
  }
  get order() {
    return [
      'PremiumRequestConfig-Aon-Car-WA',
      'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco',
      'PremiumRequestConfig-Aon-Car-WA-Casco'
    ].indexOf(this.configurationId)
  }
  get textKeys() {
    return pathOr('', [this.insurer, 'textKeys'], insurerCfg)
  }
  get insurer() {
    return premiumResultConfigurationIdStripped(this.configuration)
  }
  get coverageCode() {
    return insurerCfg[this.insurer].code
  }
  get termPremium() {
    return pathOr(null, ['item', 'productPremium', 'termPremium'], this.configuration)
  }
}
