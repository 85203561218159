export const services = {
  youtube: {
    url: 'https://www.youtube-nocookie.com/embed/[videoCode]',
    defaultTags: {
      title: 'YouTube video player',
      frameborder: '0',
      allow:
        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
      allowfullscreen: true
    }
  },
  brightcove: {
    url: 'https://players.brightcove.net/[accountid]/[playerid]/index.html?videoId=[videoCode]',
    defaultTags: {
      title: 'Brightcove video player',
      frameborder: '0',
      allow:
        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
      allowfullscreen: true
    }
  }
}
