//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleList, CycleListItem } from '../cycle-list'
import { CycleCollapser } from '../cycle-collapser'
import { CycleBaseComponent } from '../base'
import CycleImage from '../cycle-image/CycleImage'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { CycleLink } from '../cycle-link'
import CycleButton from '../elements/CycleButton/CycleButton'

import { CycleCurrency } from '../../filters/cycle-currency/cycle-currency'
import { intlMixin } from '../../mixins/intl'

export default {
  name: 'CycleAdviceBlock',
  filters: {
    CycleCurrency
  },
  components: {
    CycleCollapser,
    CycleList,
    CycleListItem,
    CycleImage,
    CycleHeading,
    CycleLink,
    CycleButton
  },
  extends: CycleBaseComponent,
  mixins: [intlMixin],
  props: {
    usps: {
      type: Array,
      required: false,
      default: () => []
    },
    image: {
      type: Object,
      required: false
    },
    details: {
      type: Array,
      required: false,
      default: () => []
    },
    warnings: {
      type: String,
      required: false,
      default: ''
    },
    premium: {
      type: Number,
      required: false
    },
    link: {
      type: Object,
      required: false,
      default: () => ({})
    },
    subtext: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      open: false,
      bem: {
        allowedModifiers: ['promoted'],
        block: 'advice'
      }
    }
  },
  computed: {
    openTitle() {
      return !this.open ? 'Toon details' : 'Sluit details'
    },
    hasPremium() {
      return !!this.premium
    }
  },
  methods: {
    onParentCollapserChange(data) {
      this.open = data
    }
  }
}
