//
//
//
//
//
//

export default {
  name: 'TopNavigationItem',
  props: {
    href: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    className() {
      const mainClassName = 'navigation__switcher'
      return {
        [mainClassName]: true,
        [`${mainClassName}--active`]: this.active
      }
    }
  }
}
