//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, format } from 'date-fns'

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { PaymentDetailsForm, ProvidedText, ProvidedHtml } from '@/components/organisms'

import { dictionaryMixin } from '@/mixins'

export default {
  name: 'LiabilityStepPayment',
  components: {
    CycleButton,
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  inject: ['formData'],
  mixins: [dictionaryMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    paymentDetails: undefined,
    startDate: undefined
  }),
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }

        this.paymentDetails = newValue.paymentDetails
        this.startDate = newValue.startDate
      },
      immediate: true
    },
    ['formData.startDate'](newValue) {
      this.startDate = newValue
    }
  },
  created() {
    this.$set(this.formData, 'startDate', this.startDate) // Needed for validations
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { paymentDetails: this.paymentDetails, startDate: this.startDate })
    }
  }
}
