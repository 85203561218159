<template>
  <div>
    <CycleFormSet :heading="textContent.personaldataTitle">
      <CContainer>
        <template slot="label">
          {{ textContent.salutationLabel }}
          <cycle-tooltip v-if="textContent.salutationTooltip">
            <template slot="tooltip-heading">
              {{ textContent.salutationLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.salutationTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CRadio
          v-model="formData.salutation"
          value="M"
          label="De heer"
          required
          :disabled="disabled.includes('salutation')"
        />
        <CRadio
          v-model="formData.salutation"
          value="V"
          label="Mevrouw"
          required
          :disabled="disabled.includes('salutation')"
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.initialsLabel }}
          <cycle-tooltip v-if="textContent.initialsTooltip">
            <template slot="tooltip-heading">
              {{ textContent.initialsLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.initialsTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.initials"
          required
          :disabled="disabled.includes('initials')"
          :max-length="25"
          alpha-ext
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.prepositionLabel }}
          <cycle-tooltip v-if="textContent.prepositionTooltip">
            <template slot="tooltip-heading">
              {{ textContent.prepositionLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.prepositionTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.preposition"
          placeholder="Optioneel"
          :disabled="disabled.includes('preposition')"
          :max-length="50"
          alpha-ext
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.lastnameLabel }}
          <cycle-tooltip v-if="textContent.lastnameTooltip">
            <template slot="tooltip-heading">
              {{ textContent.lastnameLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.lastnameTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.lastname"
          required
          :disabled="disabled.includes('lastname')"
          :max-length="50"
          alpha-ext
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.birthdateLabel }}
          <cycle-tooltip v-if="textContent.birthdateTooltip">
            <template slot="tooltip-heading">
              {{ textContent.birthdateLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.birthdateTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CDate
          v-model="formData.birthdate"
          :disabled="disabled.includes('birthdate')"
          required
          date
          :min="min"
          :max="max"
        />
        <template #error-max>
          {{ phrase('error-messages-funnels-005') }}
        </template>
        <template #error-min>
          {{
            formData.car
              ? phrase('error-messages-funnels-007')
              : phrase('error-messages-funnels-006')
          }}
        </template>
      </CContainer>
    </CycleFormSet>
    <CycleFormSet :heading="textContent.contactTitle">
      <CPostcodeCheck
        @input="checkPostcode"
        :postcode-found="postcodeFound"
        :text-content="textContent"
        :disabled="disabled"
      />
      <CContainer>
        <template slot="label">
          {{ textContent.phonenumberLabel }}
          <cycle-tooltip v-if="textContent.phonenumberTooltip">
            <template slot="tooltip-heading">
              {{ textContent.phonenumberLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.phonenumberTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.phonenumber"
          required
          :disabled="disabled.includes('phonenumber')"
          phone
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.emailaddressLabel }}
          <cycle-tooltip v-if="textContent.emailaddressTooltip">
            <template slot="tooltip-heading">
              {{ textContent.emailaddressLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.emailaddressTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.emailaddress"
          required
          email
          :disabled="disabled.includes('emailaddress')"
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.emailaddressRepeatLabel }}
          <cycle-tooltip v-if="textContent.emailaddressRepeatTooltip">
            <template slot="tooltip-heading">
              {{ textContent.emailaddressRepeatLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.emailaddressRepeatTooltip" />
            </template>
          </cycle-tooltip>
        </template>
        <CText
          v-model="formData.emailaddress2"
          required
          @paste.prevent
          :same-as="() => formData.emailaddress"
          email
          :disabled="disabled.includes('emailaddress2')"
          autocomplete="new-password"
        />
        <template #subtext>{{ textContent.emailaddressSubtext }}</template>
      </CContainer>
    </CycleFormSet>
  </div>
</template>

<script>
import { pathOr } from 'ramda'
import { CycleInputContainer } from '../../../forms/CycleInputContainer'
import { CycleFormSet } from '../../../forms/CycleFormSet'
import { CyclePostcodeCheck } from '../CyclePostcodeCheck'
import { CycleTextInput, CycleRadioInput } from '../../../../elements'
import { CycleDateInput } from '../../../../elements/forms'
import { dictionaryMixin } from '../../../../../mixins'
import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import { CycleHtml } from '../../../../base'

export default {
  name: 'CyclePersonalData',
  components: {
    CycleHtml,
    CContainer: CycleInputContainer,
    CycleFormSet: CycleFormSet,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CDate: CycleDateInput,
    CPostcodeCheck: CyclePostcodeCheck
  },
  mixins: [dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    },
    max: {
      type: [String, Date],
      default: format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    min: {
      type: [String, Date],
      default: format(addDays(subYears(new Date(), 111), 1), 'yyyy-MM-dd')
    }
  },
  data() {
    return {
      postcodeFound: undefined
    }
  },
  methods: {
    checkPostcode() {
      if (this.$jss) {
        this.$jss.gql
          .queryByName('addressFromPostalCodeAndHouseNumber', {
            inputModel: {
              postalCode: this.formData.zipcode,
              houseNumber: this.formData.housenumber
            }
          })
          .then((res) => {
            const foundStreet = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'street'],
              res
            )
            const foundCity = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'town'],
              res
            )
            if (foundStreet && foundCity) {
              this.postcodeFound = true
              this.formData.street = foundStreet
              this.formData.city = foundCity
            } else {
              this.postcodeFound = false
            }
          })
          .catch(() => {
            this.postcodeFound = false
          })
      } else {
        this.postcodeFound = false
      }
    }
  }
}
</script>
