<template>
  <div class="fireworks-container">
    <canvas :id="canvasId">Canvas is not supported in your browser.</canvas>
  </div>
</template>

<script>
import Firework from '../libs/fireworks.js'
export default {
  name: 'FireWorks',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    canvasId: {
      type: String,
      default: 'canvas'
    }
  },
  data() {
    return {
      fireObj: null
    }
  },
  mounted() {
    this.initCanvas()
  },
  destroyed() {
    this.fireObj.destroy()
  },
  methods: {
    initCanvas() {
      this.fireObj = new Firework(this.canvasId, this.options)
      this.fireObj.start()
    }
  }
}
</script>