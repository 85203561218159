<template>
  <div class="faq" v-if="hasCategories">
    <template v-for="category in categories">
      <FaqCategory
        :key="category.id"
        :category="category"
        :is-active="isCategoryActive(category)"
        @categorySelected="handleFaqCategorySelection(category)"
      >
        <ul v-if="hasCategorySubCategories(category)" class="faq__subcategories list list--nostyle">
          <template v-for="subCategory in getSubCategoriesWithFaqItems(category)">
            <li :key="subCategory.uniqueId">
              <FaqSubCategory
                :title="subCategory.title"
                :is-open="isSubCategorySelected(subCategory)"
                @subCategorySelected="handleFaqSubCategorySelection(category, subCategory)"
              />
            </li>
          </template>
        </ul>
      </FaqCategory>
      <div :key="category.uniqueId" v-if="isCategoryActive(category)" :class="faqContentClassName">
        <CycleFaqList v-if="areFaqItemsVisible" :faq-data="visibleFaqItems" />
      </div>
    </template>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'

import { FaqCategory, FaqSubCategory } from '../subComponents'
import { CycleFaqList } from '@aon/cycle'

export default {
  name: 'CategorizedFaqList',
  components: {
    FaqCategory,
    FaqSubCategory,
    CycleFaqList
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const openSubCategoryUniqueId = ref('')
    const activeFaqCategoryId = ref('')
    const activeCategory = computed(() => props.categories?.find(isCategoryActive))
    const hasCategories = computed(() => props.categories?.length > 0)
    const areFaqItemsVisible = computed(() => visibleFaqItems.value?.length > 0)
    const selectedSubCategory = computed(() =>
      activeCategory.value.subCategories?.find(isSubCategorySelected)
    )
    const visibleFaqItems = computed(() => {
      return activeCategory.value.faqItems ?? selectedSubCategory.value?.faqItems
    })

    onMounted(() => setInitialActiveCategory())
    const setInitialActiveCategory = () => {
      const firstCategory = props.categories[0]
      if (!firstCategory) {
        return
      }
      activateCategory(firstCategory)
    }

    const handleFaqCategorySelection = (category) => {
      if (!hasCategoryFaqItems(category)) {
        return
      }
      activateCategory(category)
    }

    const hasCategoryFaqItems = (category) => {
      return category?.faqItems?.length > 0
    }

    const activateCategory = (category) => {
      if (isCategoryActive(category)) {
        return
      }
      activeFaqCategoryId.value = category.uniqueId
    }

    const isCategoryActive = (category) => {
      return activeFaqCategoryId.value === category?.uniqueId
    }

    const getSubCategoriesWithFaqItems = (category) => {
      return category?.subCategories?.filter(hasSubCategoryFaqItems)
    }

    const hasSubCategoryFaqItems = (subCategory) => {
      return subCategory?.faqItems?.length > 0
    }

    const hasCategorySubCategories = (category) => {
      return category?.subCategories?.length > 0
    }

    const handleFaqSubCategorySelection = (category, subCategory) => {
      activateCategory(category)
      setSelectedSubCategory(subCategory)
    }

    const setSelectedSubCategory = (subCategory) => {
      if (isSubCategorySelected(subCategory)) {
        return
      }
      openSubCategoryUniqueId.value = subCategory.uniqueId
    }

    const isSubCategorySelected = (subCategory) => {
      return openSubCategoryUniqueId.value === subCategory?.uniqueId
    }

    const faqContentClassName = computed(() => {
      const baseClassForFaqContent = 'faq__content'
      return {
        [`${baseClassForFaqContent}`]: true,
        [`${baseClassForFaqContent}--open`]: true
      }
    })

    return {
      hasCategories,
      isCategoryActive,
      visibleFaqItems,
      areFaqItemsVisible,
      hasSubCategoryFaqItems,
      isSubCategorySelected,
      handleFaqCategorySelection,
      handleFaqSubCategorySelection,
      hasCategorySubCategories,
      getSubCategoriesWithFaqItems,
      faqContentClassName
    }
  }
}
</script>
