<template>
  <select
    v-bind="Object.assign({}, attributes, $attrs)"
    v-on="Object.assign({}, listeners, $listeners, { change: updateValue })"
    v-model="localValue"
    :class="formInputClass"
  >
    <slot name="option" />
  </select>
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleSelect',
  extends: BaseInput,
  provide() {
    return {
      modelValue: this.value
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      localValue: this.value
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.localValue)
    }
  }
}
</script>
