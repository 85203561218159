//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { funnelMixinFactory } from '@/mixins/funnel'
import { serviceNames } from '@/services/data'

import { storeModuleNamespace } from '../constants'
import { default as storeModule } from '../store'
import { FinalQuestions, AgreementQuestions } from './FunnelQuestions'
import { FunnelQuestionService } from '../services'
import { QuestionRenderType } from '../models/QuestionRenderType'

export default {
  name: 'FunnelQuestionsContainer',
  components: { FinalQuestions, AgreementQuestions },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: storeModuleNamespace,
      storeModule,
      gettersMapping: [
        'questionsByCode',
        'finalQuestionsForProduct',
        'agreementQuestionsForProduct'
      ],
      actionsMapping: ['getQuestions'],
      services: [
        {
          serviceName: serviceNames.FunnelQuestionService,
          serviceDefinition: FunnelQuestionService
        }
      ]
    })
  ],
  inject: ['formData'],
  props: {
    productCode: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Object,
      required: true
    },
    renderType: {
      type: Number,
      required: false,
      default: QuestionRenderType.Both
    }
  },
  data() {
    return {
      questionValues: {
        final: {},
        agreement: {}
      }
    }
  },
  computed: {
    finalQuestions() {
      return this.finalQuestionsForProduct(this.productCode)
    },
    agreementQuestions() {
      return this.agreementQuestionsForProduct(this.productCode)
    },
    shouldRenderAgreementQuestions() {
      return (
        this.renderType === QuestionRenderType.Agreement ||
        this.renderType === QuestionRenderType.Both
      )
    },
    shouldRenderFinalQuestions() {
      return (
        this.renderType === QuestionRenderType.Final || this.renderType === QuestionRenderType.Both
      )
    }
  },
  watch: {
    productCode: {
      immediate: true,
      handler(code) {
        if (code && (this.questionsByCode == null || this.questionsByCode[code] == null)) {
          this.getQuestions({ code })
        }
      }
    }
  },
  methods: {
    handleQuestionAnswered() {
      this.$emit('input', this.questionValues)
    }
  }
}
