//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { intlMixin, dictionaryMixin } from '@/mixins'
import { Address } from '@/models'
import { CycleFormPage, CycleInputContainer, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import {
  CycleSelect,
  CycleOption,
  CycleRadioInput,
  CycleTextInput,
  CycleNumberInput
} from '@/components/elements/forms'
import { CycleHeading } from '@/components/elements'
import { uninsurableMessagesMap } from '../../constants'

export default {
  name: 'HomeOwnerStepSetup',
  components: {
    CycleFormPage,
    CycleInputContainer,
    CycleOption,
    CycleSelect,
    ProvidedText,
    ProvidedTooltip,
    CycleHeading,
    CycleFormSet,
    CycleRadioInput,
    CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData', 'textContent', 'contentProvider'],
  mixins: [intlMixin, dictionaryMixin],
  props: {
    homeTypeValues: {
      type: Array,
      required: true,
      default: () => []
    },
    submitStepSetup: {
      type: Function,
      default: () => ({})
    },
    checkAcceptanceMessage: {
      type: String,
      default: ''
    },
    selectedAddress: {
      type: Address
    },
    showHouseVolumeEntry: {
      type: Boolean,
      default: false
    },
    showLocationCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showUninsurableMessage() {
      return this.formData.selectedHomeType?.isInsurable === false
    },
    uninsurableMessage() {
      const selectedKey = this.formData.selectedHomeType?.id || 'none'
      return uninsurableMessagesMap[selectedKey]()
    },
    canSubmit() {
      return (
        this.formData.selectedHomeType?.isInsurable === true &&
        (!this.showLocationCheck || this.formData.homelocation === true)
      )
    },
    isNotHomelocation() {
      return this.formData.homelocation === false
    }
  },
  watch: {
    selectedAddress: {
      handler(newAddress) {
        this.$emit('address-changed', newAddress)
      },
      immediate: true
    },
    showHouseVolumeEntry(newVal) {
      if (newVal) {
        this.formData.houseVolume = null
      }
    },
    ['formData.selectedHomeType'](selectedHomeType) {
      this.$emit('home-type-changed', selectedHomeType)
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.submitStepSetup()
        this.$emit('setup-finished')
      } catch (err) {
        window.scroll({
          top: this.$el.getBoundingClientRect().top,
          behavior: 'smooth'
        })
        throw err
      }
    },
    setHouseVolume(event) {
      this.$emit('house-volume-changed', parseInt(event.target.value))
    }
  }
}
