import gql from 'graphql-tag'

export const policyItemWedding = gql`
  fragment policyItemWedding on Policy {
    item {
      ... on Wedding {
        weddingConfiguration {
          mainCoverage {
            expenses {
              active
            }
          }
          additionalCoverages {
            liability {
              active
            }
            accidents {
              active
            }
            liabilityAndAccidents {
              active
            }
            cancellation {
              active
            }
            honeymoonCancellation {
              active
            }
            badWeather {
              active
            }
          }
        }
      }
    }
  }
`
