<template>
  <CycleFormPage
    :heading="contentProvider.getContentByPath('steps.coverages.title')"
    :prev-button-text="contentProvider.getContentByPath('steps.coverages.backButton')"
    :next-button-text="contentProvider.getContentByPath('steps.coverages.nextButton')"
  >
    <CycleFormSet>
      <CycleCheckout
        :amount="totalPremium"
        :amount-description="
          contentProvider.getContentByPath('paymentDetails.totalAmountMonthly.label')
        "
        :amount-subtext="
          contentProvider.getContentByPath('paymentDetails.totalAmountMonthly.subtext')
        "
        :heading="contentProvider.getContentByPath('steps.coverages.headers.baseCoverage')"
      >
        <template #checkout-item>
          <CycleCheckoutItem
            :price="
              premiumCoverage(
                displayCyberCoverage ? coverageTypes.contents : coverageTypes.contentsIncludingCyber
              )
            "
            :label="contentProvider.getContentByPath('steps.coverages.baseCoverage.label')"
            :tooltip="
              contentProvider.getContentByPath('steps.coverages.baseCoverage.tooltip', false)
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.baseCoverage.description')
            "
            selected
          />

          <CycleCheckoutItem
            v-if="displayCyberCoverage"
            :price="premiumCoverage(coverageTypes.cyber)"
            :label="contentProvider.getContentByPath('steps.coverages.cyberCoverage.label')"
            :tooltip="
              contentProvider.getContentByPath('steps.coverages.cyberCoverage.tooltip', false)
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.cyberCoverage.description')
            "
            selected
          />

          <CycleCheckoutItem
            v-model="localCoverages.glass"
            :label="contentProvider.getContentByPath('steps.coverages.glass.label')"
            :tooltip="contentProvider.getContentByPath('steps.coverages.glass.tooltip', false)"
            :description="contentProvider.getContentByPath('steps.coverages.glass.description')"
            :price="premiumCoverage(coverageTypes.glass)"
            @change="updateSelectedCoverages"
          />

          <CycleCheckoutItem
            v-model="localCoverages.jewelry"
            :label="contentProvider.getContentByPath('steps.coverages.jewelry.label')"
            :tooltip="contentProvider.getContentByPath('steps.coverages.jewelry.tooltip')"
            :description="contentProvider.getContentByPath('steps.coverages.jewelry.description')"
            :price="premiumCoverage(coverageTypes.jewelry)"
            selected
          >
            <template slot="checkout-item-input">
              <CycleInputContainer render-list>
                <CycleRadioInput
                  v-for="option in jewelryOptions"
                  :key="`optionalCoverageInformation-${option.key}-option-${option.key}`"
                  v-model="localCoverages.jewelryOption"
                  :value="option"
                  @change="updateSelectedCoverages"
                >
                  {{ option.value }}
                </CycleRadioInput>
              </CycleInputContainer>
            </template>
          </CycleCheckoutItem>

          <CycleCheckoutItem
            v-model="localCoverages.audioVisual"
            :label="contentProvider.getContentByPath('steps.coverages.audioVisual.label')"
            :tooltip="contentProvider.getContentByPath('steps.coverages.audioVisual.tooltip')"
            :description="
              contentProvider.getContentByPath('steps.coverages.audioVisual.description')
            "
            :price="premiumCoverage(coverageTypes.audioVisual)"
            selected
          >
            <template slot="checkout-item-input">
              <CycleInputContainer render-list>
                <CycleRadioInput
                  v-for="option in audioVisualOptions"
                  :key="`optionalCoverageInformation-${option.key}-option-${option.key}`"
                  v-model="localCoverages.audioVisualOption"
                  :value="option"
                  @change="updateSelectedCoverages"
                >
                  {{ option.value }}
                </CycleRadioInput>
              </CycleInputContainer>
            </template>
          </CycleCheckoutItem>

          <CycleCheckoutItem
            v-model="localCoverages.specialPossessions"
            :label="contentProvider.getContentByPath('steps.coverages.specialPossessions.label')"
            :tooltip="
              contentProvider.getContentByPath('steps.coverages.specialPossessions.tooltip')
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.specialPossessions.description')
            "
            :price="premiumCoverage(coverageTypes.specialPossessions)"
            selected
          >
            <template slot="checkout-item-input">
              <CycleInputContainer render-list>
                <CycleRadioInput
                  v-for="option in specialPossessionsOptions"
                  :key="`optionalCoverageInformation-${option.key}-option-${option.key}`"
                  v-model="localCoverages.specialPossessionsOption"
                  :value="option"
                  @change="updateSelectedCoverages"
                >
                  {{ option.value }}
                </CycleRadioInput>
              </CycleInputContainer>
            </template>
          </CycleCheckoutItem>

          <CycleCheckoutItem
            v-model="localCoverages.tenantsOwnership"
            :label="contentProvider.getContentByPath('steps.coverages.tenantsOwnership.label')"
            :tooltip="contentProvider.getContentByPath('steps.coverages.tenantsOwnership.tooltip')"
            :description="
              contentProvider.getContentByPath('steps.coverages.tenantsOwnership.description')
            "
            :price="premiumCoverage(coverageTypes.tenantsOwnership)"
            selected
          >
            <template slot="checkout-item-input">
              <CycleInputContainer render-list>
                <CycleRadioInput
                  v-for="option in tenantsOwnershipOptions"
                  :key="`optionalCoverageInformation-${option.key}-option-${option.key}`"
                  v-model="localCoverages.tenantsOwnershipOption"
                  :value="option"
                  @change="updateSelectedCoverages"
                >
                  {{ option.value }}
                </CycleRadioInput>
              </CycleInputContainer>
            </template>
          </CycleCheckoutItem>
        </template>
      </CycleCheckout>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import { CycleButton, CycleHeading, CycleRadioInput } from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { ProvidedText } from '@/components/organisms'
import { HomeContentsCoverageTypes, ProductPremium, Coverages } from '../../models'

import { debounce } from 'lodash'

export default {
  name: 'HomeContentsStepCoverages',
  inject: ['contentProvider', 'textContent', 'formData'],
  components: {
    CycleButton,
    CycleInputContainer,
    CycleRadioInput,
    CycleFormPage,
    CycleHeading,
    ProvidedText,
    CycleFormSet,
    CycleCheckout,
    CycleCheckoutItem
  },
  props: {
    selectedCoverages: {
      type: Coverages,
      default: () => {}
    },
    premium: {
      type: ProductPremium,
      required: false
    },
    jewelryOptions: {
      type: Array,
      required: false
    },
    audioVisualOptions: {
      type: Array,
      required: false
    },
    tenantsOwnershipOptions: {
      type: Array,
      required: false
    },
    specialPossessionsOptions: {
      type: Array,
      required: false
    },
    displayCyberCoverage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      coverageTypes: HomeContentsCoverageTypes,
      localCoverages: {}
    }
  },
  computed: {
    totalPremium() {
      return this.premium?.totalPremium
    }
  },
  watch: {
    selectedCoverages: {
      immediate: true,
      handler(selectedCoverages) {
        this.localCoverages = new Coverages(selectedCoverages)
      }
    }
  },
  methods: {
    updateSelectedCoverages: debounce(function () {
      this.$emit('coverage-update', this.localCoverages)
    }, 500),
    handleSubmit() {},
    premiumCoverage(coverage) {
      return this.premium?.coverages[coverage]
    }
  }
}
</script>
