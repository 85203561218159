<template>
  <section :class="className" :id="id">
    <header class="grid__header" v-if="hasHeaderSlot">
      <slot name="header" />
    </header>
    <slot />
  </section>
</template>

<script>
function testSlot(name = 'default') {
  return !!this.$scopedSlots[name] || !!this.$slots[name]
}

export default {
  name: 'CycleSection',
  props: {
    sectionStyle: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    className() {
      const mainClassName = 'grid'
      return {
        [mainClassName]: true,
        [this.sectionStyle]: !!this.sectionStyle
      }
    },
    hasHeaderSlot() {
      return testSlot.call(this, 'header')
    }
  }
}
</script>
