//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleLinkIcon } from '@/components/cycle-link'
import { CycleHtml } from '@/components/base'
import CycleNav from '../cycle-nav/CycleNav'
import { isJSON } from './helpers'
import IconButton from './IconButton'

export default {
  name: 'PolicyActions',
  components: { CycleLinkIcon, CycleNav, IconButton, CycleHtml },
  props: {
    textContent: Object,
    links: Array,
    status: Object,
    claimFallback: String,
    policyTestId: String,
    hasOverviewContent: Boolean
  },
  methods: {
    linkModifier(icon) {
      return icon || 'chevron-right'
    },
    getPolicyTestId() {
      return this.policyTestId ?? 'policy'
    },
    getDataTestIdForLink(link) {
      return `${this.getPolicyTestId()}-${link.name.toLowerCase().replace(' ', '-')}`
    },
    currentUTCTime() {
      return new Date().toISOString().slice(0, -5) + 'Z'
    },
    // MYZ-516: Need to update the timestamp as validation doesn't allow the http request
    handleSubmit() {
      this.$nextTick(() => {
        if (this.$refs.timestamp) {
          this.$refs.timestamp[0].setAttribute('value', this.currentUTCTime())
        }
      })
    }
  },
  computed: {
    hasCED() {
      return this.CEDObject != null
    },
    CEDObject() {
      return this.links?.find(({ url }) => isJSON(url))
    },
    CEDCtaIcon() {
      return this.CEDObject?.icon ?? null
    },
    CEDCtaButtonText() {
      return this.CEDObject?.title ?? ''
    },
    baseUrl() {
      if (!this.CEDObject?.url) return {}
      return JSON.parse(this.CEDObject.url)
    },
    cedUrl() {
      return `${this.baseUrl.CEDUrl}`
    },
    params() {
      return Object.entries(this.baseUrl).reduce((acc, [key, value]) => {
        if (key === 'CEDUrl') {
          return acc
        }

        return [...acc, [key.toLowerCase(), value]]
      }, [])
    },
    dynamicLinks() {
      return this.links.filter(({ url }) => !isJSON(url))
    },
    getDataTestIdForCEDUrl() {
      return `${this.getPolicyTestId()}-button-ced`
    }
  }
}
