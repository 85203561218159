<template>
  <li class="navigation__group">
    <a v-if="hasGroupLink" class="navigation__item navigation__item--title" :href="groupLinkHref">{{
      groupLinkTitle
    }}</a>
    <template v-if="hasSubGroups">
      <NavigationSubGroup
        v-for="item in items"
        :key="item.id"
        :items="item.fields.items"
        :subgroup-header="getSubGroupHeader(item)"
      />
    </template>
  </li>
</template>

<script>
import { CycleUidMixin } from '@/mixins'
import NavigationSubGroup from './NavigationSubGroup'
export default {
  name: 'NavigationGroup',
  components: {
    NavigationSubGroup
  },
  mixins: [CycleUidMixin],

  props: {
    items: {
      type: Array,
      default: () => []
    },
    groupLink: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasSubGroups() {
      return this.items.length > 0
    },
    hasGroupLink() {
      return !!this.groupLink?.value?.href
    },
    groupLinkHref() {
      return this.groupLink?.value?.href
    },
    groupLinkTitle() {
      return this.groupLink?.value?.title ?? this.groupLink?.text
    }
  },
  methods: {
    getSubGroupHeader(item) {
      return item?.fields?.SubgroupHeader?.value || ''
    }
  }
}
</script>