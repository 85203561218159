import { defaultApiEndpoint, submitContext } from '../constants/api'
import { dataFetcherWithCancel } from '@/dataFetcher'
import { logWarning } from '@/services/logging'

export function submitForm(
  formId,
  path,
  action,
  regenerateDefinition,
  key,
  formState,
  formData,
  businessData
) {
  let queryString = ''
  if (typeof window !== 'undefined') {
    const queryStore = []
    const urlParams = new URLSearchParams(window.location.search)
    for (const [key, value] of urlParams) {
      queryStore.push(`${key}=${encodeURIComponent(value)}`)
    }
    queryString = queryStore?.length > 0 ? `?${queryStore.join('&')}` : ''
  }
  const submitUrl = `${defaultApiEndpoint}/${formId}/${path}/${action}/${key}${queryString}`
  const submitData = {
    regenerateDefinition,
    state: formState,
    formData,
    businessData
  }

  const { cancelFunction, response: responsePromise } = dataFetcherWithCancel(submitUrl, submitData)

  /* * *
   * I personally award beer or stroopwafels to the first one who makes this in async/await
   * -- RH 2021-04-28
   * * */

  const response = new Promise((resolve, reject) => {
    responsePromise
      .then((responseData) => {
        resolve(formatResponse(responseData))
      })
      .catch(reject)
  })

  return { cancelFunction, response }
}

export function formatResponse(response = {}) {
  try {
    const {
      data: { businessData, errors, state, formData, step, definition, formDataFormatted }
    } = response
    return {
      definition,
      errors,
      formState: {
        step,
        ...state
      },
      businessData,
      formData,
      formDataFormatted
    }
  } catch (ex) {
    logWarning('FormService.formatResponse: failed to map formData ... ', ex.message || ex)

    return {}
  }
}

export function getSubmitContext(fieldObject) {
  if (fieldObject[submitContext.fieldGroup.path]) {
    return submitContext.fieldGroup
  }

  return submitContext.field
}
