<template>
  <CycleSubGrid>
    <CycleCard v-for="(service, index) in services" :key="index">
      <template #image>
        <img v-bind="getProductServiceBinding(service)" />
      </template>
      <template #header>
        <CycleHeading>{{ getServiceDisplayName(service) }}</CycleHeading>
      </template>
      <template #body>
        <CycleHtml :html="getServiceDescription(service)" />
      </template>
      <template #footer>
        <CycleLink v-bind="getLinkToBind(service)" :title="getlinkTitle(service)">{{
          getLinkText(service)
        }}</CycleLink>
      </template>
    </CycleCard>
  </CycleSubGrid>
</template>

<script>
export default {
  name: 'ServicesDisplayCards',
  components: {},
  inject: ['dictionaryService'],
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getLinkText(service) {
      return (
        service?.serviceLink?.value?.text || this.dictionaryService?.phrases['generic-read-more']
      )
    },
    getlinkTitle(service) {
      return service?.serviceLink?.value?.title || service?.serviceName
    },
    getProductServiceBinding(service) {
      return {
        src: service?.serviceImage?.value?.src,
        alt: service?.serviceImage?.value?.alt
      }
    },
    getLinkToBind(service) {
      return service?.serviceLink?.value
    },
    getServiceDescription(service) {
      return service?.serviceDescription
    },
    getServiceDisplayName(service) {
      return service?.serviceDisplayName
    }
  }
}
</script>
