<template>
  <div v-if="isBlogDividerVisible" class="blogdivider">
    <p v-if="hasAuthor" class="blogdivider__author">{{ author }}</p>
    <div v-if="hasTags || hasDatePublished" class="blogdivider__content">
      <p v-if="hasDatePublished" class="blogdivider__date">
        {{ datePublished }}
      </p>
      <BlogTags v-if="hasTags" :tags="tags" />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
import BlogTags from '../BlogTags/BlogTags.vue'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})

const tags = props.fields?.tags || []
const hasTags = tags?.length > 0
const datePublished = props.fields?.datePublished
const hasDatePublished = datePublished?.length > 0
const author = props.fields?.author
const hasAuthor = author?.length > 0
const isBlogDividerVisible = hasAuthor || hasDatePublished || hasTags
</script>
