export default {
  install(Vue) {
    Vue.filter('ScBoolean', (field) => {
      if (typeof field?.value !== 'string') {
        return field?.value
      }
      switch (field?.value?.toLowerCase()) {
        case 'true':
          return true
        case 'false':
          return false
        default:
          return null
      }
    })
    Vue.filter('ScNumber', (field) => Number(field?.value))
    Vue.filter('ScString', (field) => {
      return !field?.value ? String(field) : String(field?.value) || ''
    })
    Vue.filter('ScLink', (field) => (field?.value?.href ? field.value : {}))
  }
}
