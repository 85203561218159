export default class File {
  /**
   * @param {String} name  - File name
   * @param {String} source  - File source
   * @param {String} encoding  - File encoding
   * @param {String} mimetype  - File mimetype
   * @param {Number} size  - File size
   * @param {Boolean} loading  - Status indicator
   * @param {FileList} raw  - Raw input file source
   */
  constructor(name, raw, loading, size, source, encoding, mimetype) {
    this.name = name
    this.raw = raw
    this.loading = loading
    this.size = size
    this.source = source
    this.encoding = encoding
    this.mimetype = mimetype
  }

  get healthAcceptTypes() {
    return '.pdf,.png,.gif,.jpg,.jpeg'
  }
}
