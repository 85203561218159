<template>
  <span v-if="transitionValue !== null">
    <slot :transitionValue="calculatedTransitionValue"></slot>
  </span>
</template>

<script>
export default {
  name: 'ValueTransition',
  props: {
    value: {
      required: true,
      validator(value) {
        return !isNaN(value)
      }
    },
    transitionParams: {
      required: true
    }
  },
  data() {
    return {
      transitionValue: 0,
      interval: null,
      isIncremented: true
    }
  },
  computed: {
    calculatedTransitionValue() {
      return this.transitionValue.toFixed(2)
    }
  },
  watch: {
    transitionValue(newValue) {
      if (this.isValueTransformed(newValue)) {
        this.transitionValue = this.value
        this.stopTransition()
      }
    },
    value(newValue, oldValue) {
      this.transitionValue = oldValue
      const incrementValue = Math.abs((newValue - oldValue)/this.transitionParams.incrementValue)
      this.stopTransition()
      if (newValue > oldValue) {
        this.positiveTransition(incrementValue)
      } else {
        this.negativeTransition(incrementValue)
      }
    }
  },
  created() {
    this.transitionValue = this.value
  },
  unmounted() {
    this.stopTransition()
  },
  methods: {
    positiveTransition(incrValue) {
      this.isIncremented = true
      this.interval = setInterval(() => {
        this.transitionValue += incrValue
      }, this.transitionParams?.intervalTime)
    },
    negativeTransition(incrValue) {
      this.isIncremented = false
      this.interval = setInterval(() => {
        this.transitionValue -= incrValue
      }, this.transitionParams?.intervalTime)
    },
    isValueTransformed(newValue){
      return this.isIncremented ? newValue >= this.value : newValue <= this.value
    },
    stopTransition() {
      if (this.interval) {
        clearInterval(this.interval)
      } 
    }
  }
}
</script>
