//
//
//
//
//
//
//
//
//
//
//

import { computed } from 'vue'
import { CycleIcon } from '@/components/elements'
export default {
  name: 'ToggleTooltip',
  components: {
    CycleIcon
  },
  setup(props, { slots, emit }) {
    const hasContent = computed(()=> {
      return slots['tooltip-content'] && slots['tooltip-content']().length  > 0
    })
    const close = () => {
      emit('close')
    }
    
    return {
      hasContent: hasContent,
      close: close
    }
  }
}
