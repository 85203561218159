import gql from 'graphql-tag'

export const policyItemMotorcycle = gql`
  fragment policyItemMotorCycle on Policy {
    item {
      ... on Motorcycle {
        licensePlate
        claimFreeYears
        noClaimDiscount
        step
        winterBreak
        membership
        vehicleData {
          brand
          model
          vehicleType
          constructionYear
        }
        motorcycleConfigurations {
          mainCoverage {
            mainCoverageLegalLiability {
              active
            }
            item {
              type
            }
          }
          additionalCoverages {
            helmetClothingBagage {
              active
            }
            passengerAccidents {
              active
            }
            passengerDamage {
              active
            }
            passengerDamageExtended {
              active
            }
            redressalAssistance {
              active
            }
            replacementTransport {
              active
            }
            roadsideAssistance {
              active
              hideCoverage
            }
            accessoires {
              active
            }
            legalAid {
              active
            }
            legalAidExtended {
              active
            }
          }
        }
      }
    }
  }
`
