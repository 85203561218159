//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleIcon } from '@/components/elements'

export default {
  name: 'InputRadioTile',
  components: { CycleHeading, CycleIcon },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: () => ''
    }
  },
  computed: {
    checked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
