<template>
  <CycleCurrencyInput v-bind="$attrs">
    <CycleTextInput
      v-model="totalAmountInteger"
      :inline="true"
      @input="handleInput"
      @blur="handleBlur"
    />
    <span>,</span>
    <CycleTextInput v-model="totalAmountDecimal" :inline="true" @blur="handleBlur" />
  </CycleCurrencyInput>
</template>

<script>
import { CycleTextInput, CycleCurrencyInput } from '@aon/cycle'

export default {
  name: 'RapidFormCurrencyWrapper',
  components: {
    CycleTextInput,
    CycleCurrencyInput
  },
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      totalAmountDecimal: undefined,
      totalAmountInteger: undefined
    }
  },
  computed: {
    parsedTotalAmount() {
      if (!this.totalAmountInteger && !this.totalAmountDecimal) {
        return undefined
      }

      return parseFloat(
        `${this.totalAmountInteger ?? '0'}.${this.totalAmountDecimal ?? '0'}`
      ).toFixed(2)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (isNaN(newValue)) {
          return
        }

        this.updateAmount(newValue)
      }
    }
  },
  methods: {
    handleInput() {
      const currencyInput = this.parsedTotalAmount
      this.$emit('change', currencyInput)
    },
    handleBlur(event) {
      this.handleInput()
      this.$emit('blur', event)
    },
    updateAmount(newValue) {
      this.totalAmountInteger = parseInt(newValue)
      this.totalAmountDecimal = ((newValue % 1).toFixed(2) * 100).toString().padStart(2, '0')
    }
  }
}
</script>
