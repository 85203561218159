import * as Vuelidators from 'vuelidate/lib/validators'
import Validators from '../constants/validators'
import { logWarning } from '@/services/logging'

export const mapValidators = (fieldDefs) => {
  return fieldDefs
    .filter((field) => field && field.validations && !field.showAsLabel)
    .reduce((acc, field) => {
      return {
        ...acc,
        [field.originalKey || field.key]: mapValidatorField(field.validations)
      }
    }, {})
}

function mapValidatorField(validators) {
  return Object.entries(validators).reduce((acc, [key, validator]) => {
    if (!validationMap[key]) {
      logWarning('Unmapped validation: ', key, validator)

      return acc
    }

    const targetValidator = validationMap[key]

    /** REMARK
     *  Validators which require arguments are factories like minValue (which resolve a new validator function)
     *  others (without params) can be used directly like required
     */
    const validatorValue = validator.value
      ? validator.value
      : !Array.isArray(validator.parameters)
      ? Object.values(validator.parameters || {})[0] || false
      : false

    return {
      ...acc,
      [key]: validatorValue ? targetValidator(validatorValue) : targetValidator
    }
  }, {})
}

const validationMap = {
  ...Vuelidators,
  ...Validators
}
