<template>
  <CycleCardList :title="textContent.premiumTitle" modifiers="nomarker" data-testid="premiumCard">
    <template #list-items>
      <CycleListItem v-if="termPremium" data-testid="premiumCardTerm">
        <label>{{ premiumLabel }}</label>
        <p>{{ termPremium }}</p>
      </CycleListItem>
      <CycleListItem v-if="hasOwnRisk" data-testid="premiumCardOwnRisk">
        <label>{{ textContent.ownRiskLabel }}</label>
        <p>{{ textContent.ownRiskText }}</p>
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleListItem, CycleCardList } from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'
import { formatCurrency } from '@/services/formatting'

export default {
  name: 'PremiumCard',
  components: {
    CycleCardList,
    CycleListItem
  },
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo', 'verifySourceSystem']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    premiumLabel() {
      return (
        this.getPhrase(`payment-term-${this.policyInfo?.paymentDetails?.paymentTerm}`) ||
        this.getPhrase('premium-term')
      )
    },
    termPremium() {
      return formatCurrency({
        amount: this.policyInfo?.termPremium,
        locale: this.$store.getters.currentLanguage
      })
    },
    hasOwnRisk() {
      // Rental insurances come from a different system which does not have policyInfo.policy.item
      // therefore to display ownRisk it is necessary a less restrictive condition
      return this.policyInfo?.policy
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
