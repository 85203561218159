<template>
  <div class="coreui">
    <component v-for="(comp, index) in jssTop" :is="comp" :key="`compTop${index}`" />
    <BaseNavigation>
      <component v-for="(comp, index) in jssNav" :is="comp" :key="`compNav${index}`" />
    </BaseNavigation>
    <component v-for="(comp, index) in jssHeader" :is="comp" :key="`compHeader${index}`" />
    <main class="coreui__main">
      <component v-for="(comp, index) in jssMain" :is="comp" :key="`compMain${index}`" />
    </main>
    <component
      v-for="(comp, index) in jssFooter"
      :is="comp"
      :key="`compFooter${index}`"
      class="coreui__footer"
    />
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import BaseNavigation from '@/components/Cycle/BaseNavigation'
export default {
  name: 'CoreUI',
  components: {
    Placeholder,
    BaseNavigation
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  withPlaceholder: {
    placeholders: [
      {
        placeholder: 'jss-top',
        computedPropName: 'jssTop'
      },
      {
        placeholder: 'jss-navigation',
        computedPropName: 'jssNav'
      },
      {
        placeholder: 'jss-header',
        computedPropName: 'jssHeader'
      },
      {
        placeholder: 'jss-main',
        computedPropName: 'jssMain'
      },
      {
        placeholder: 'jss-footer',
        computedPropName: 'jssFooter'
      }
    ]
  }
}
</script>
