//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, format } from 'date-fns'
import { CycleHeading } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'
import { ProductPremium } from '../../models'

import { dictionaryMixin } from '@/mixins'

export default {
  name: 'FunnelTravelStep4',
  inject: ['formData', 'textContent'],
  components: {
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  mixins: [dictionaryMixin],
  props: {
    isMultiTrip: {
      type: Boolean
    },
    premium: {
      type: ProductPremium
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    premiumInfo() {
      return this.isMultiTrip
        ? this.textContent.premiumInfoTextMultiTrip
        : this.textContent.premiumInfoTextSingleTrip
    },
    paymentDetails() {
      const init = {
        paymentTerm: this.isMultiTrip ? PaymentTerm.Monthly : PaymentTerm.Single,
        premium: this.premium && this.premium.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }
        this.paymentDetails = newValue.paymentDetails
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
