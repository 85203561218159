//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isValid, parseISO } from 'date-fns'
import BaseInput from '../BaseInput'

const isValidDate = (date) => isValid(new Date(date))
const isValidDateValue = (dateString) => !isNaN(parseISO(dateString).getTime())

export default {
  name: 'DateSingleInput',
  extends: BaseInput,
  // Override value prop from BaseInput
  props: {
    value: {
      // Can be bound to Date and String values
      type: [String, Date, Object],
      required: false,
      default: ''
    }
  },
  data() {
    return {
      // Will be checked on creation
      isDate: undefined,
      // Pattern will only be used when browser fallback for type=date is type=text
      pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
      // Allowed Event code here 
      allowedEventCodes: [
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Tab'
      ]
    }
  },
  computed: {
    dateStringValue() {
      // Should we render Date or String
      if (this.isDate) {
        // When rendering a date, create a valid string representation
        if (this.value instanceof Date && !isNaN(this.value)) {
          const propDate = this.value.toISOString().split('T')[0]
          return this.setFormatDate(propDate)
        } else {
          return this.setFormatDate(this.$el?.value)
        }
      } 
      else {
        return this.setFormatDate(this.value)
      }
      return ''
    },
    isDateFilled() {
      return this.$refs.date?.value !== ''
    }
  },
  created() {
    // Simple check whether we're dealing with Date object or String
    this.isDate = typeof this.value === 'object'
  },
  methods: {
    getFormatDate(dateString = null) {
      const [day, month, year] = dateString ? dateString.split('-') : ''

      return (
        dateString &&
        [year?.padStart(4, '0'), month?.padStart(2, '0') , day?.padStart(2, '0')].join('-')
      )
    },
    setFormatDate(dateString = null) {
      if(!dateString) {
        return this.$refs.date?.value
      }
      const [year, month, day] = dateString.split('-')
      
      return (
        dateString &&
        [day?.padStart(2, '0'), month?.padStart(2, '0') , year?.padStart(4, '0')].join('-')
      )
    },
    handleDateKeydown(event) {
      // Always Permit for Backspace and Delete
      if(this.allowedEventCodes.includes(event.code)  || event.which === 8) {
        return
      }
      // Restrict Typing of all kind of chars and special symbol
      const testIfNumeric = /^\d*$/
      if (!testIfNumeric.test(event.key)) {
        event.preventDefault()
      }
      // Arrange the input number into {2}-{2}-{4} format
      const dateElement = this.$refs.date.value
      if(dateElement) {
        const formatDateString = dateElement.replace(/^(\d{2})$/g, '$1-').replace(/^(\d{2}\-\d{2})$/g, '$1-')
        this.$refs.date.value = formatDateString
      }
    },
    ariaInvalid(isInvalid) {
      if (isInvalid && !this.isDateFilled) {
        return
      }
      this.$nextTick(() => {
        this.$refs.date.setAttribute('aria-invalid', isInvalid.toString())
      })
    },
    handleSectionChange(event) {
      const targetDate = event.target.value
      const date = this.getFormatDate(targetDate)
      const isValid = isValidDateValue(date)
      if (isValid) {
        this.$emit('input', date)
      } else {
        this.$emit('input', null)
      }
      this.ariaInvalid(!isValid)
    },
    handleChange(event) {
      this.handleSectionChange(event)
      this.$emit('change', event)
    }
  }
}
