<template>
  <div class="content-toggle" :class="classList">
    <div class="content-toggle__body" :aria-hidden="isHidden" v-show="open">
      <slot name="hidden-content" v-if="open"></slot>
      <slot />
    </div>
    <div class="content-toggle__button-container">
      <span
        class="content-toggle__button"
        @click="toggleContent"
        @keydown.enter="toggleContent"
        aria-role="button"
        tabindex="0"
        :aria-expanded="isOpen"
        :aria-controls="collapseId"
      >
        {{ !open ? openActionText : closeActionText }}
      </span>
    </div>
  </div>
</template>

<script>
import { CycleUidMixin } from '@/mixins'

export default {
  name: 'ContentToggle',
  mixins: [CycleUidMixin],
  props: {
    openActionText: {
      type: String,
      default: 'Toon details'
    },
    closeActionText: {
      type: String,
      default: 'Verberg details'
    },
    isPlain: {
      type: Boolean,
      default: false
    },
    isDefaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: this.isDefaultOpen,
      collapseId: `collapse_${this._cycleUid}`
    }
  },
  computed: {
    isHidden() {
      return (!this.open).toString()
    },
    isOpen() {
      return this.open.toString()
    },
    classList() {
      return {
        'content-toggle--open': this.open,
        'content-toggle--plain': this.isPlain
      }
    }
  },
  methods: {
    toggleContent() {
      this.open = !this.open
    }
  }
}
</script>
