//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FullHouseNumber } from '@/components/atoms'
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import { Gender } from '@/constants'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { Person, ContactDetails, Address } from '@/models'

export default {
  name: 'DetailDataOverview',
  components: {
    FullHouseNumber,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    contactDetails: {
      required: true,
      type: ContactDetails
    },
    person: {
      required: true,
      type: Person
    },
    address: {
      required: true,
      type: Address
    },
    textContent: {
      type: Object,
      required: false
    }
  },
  computed: {
    setGender() {
      if (this.person.gender === Gender.Male) {
        return this.textContent.personalData.labels.salutationOption.M
      } else {
        return this.textContent.personalData.labels.salutationOption.V
      }
    },
    fullName() {
      return this.setGender + ' ' + this.person.initials + ' ' + this.person.lastName
    },
    fullAddress() {
      return (
        this.address.street +
        ' ' +
        this.address.houseNumber +
        ' ' +
        this.address.houseNumberAddition
      )
    },
    fullCityName() {
      return this.address.postalCode + ' ' + this.address.city
    }
  }
}
