<template>
  <CycleFormSet>
    <template #heading>
      <ProvidedText name="personalData.header" />
    </template>

    <!-- gender -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="personalData.labels.salutation" />

        <ProvidedTooltip
          heading-name="personalData.headings.salutation"
          body-name="personalData.tooltips.salutation"
        />
      </template>

      <CycleRadioInput
        v-for="gender in genders"
        :key="gender"
        v-model="formData.person.gender"
        :value="gender"
        required
      >
        <ProvidedText :name="`personalData.labels.salutationOption.${gender}`" />
      </CycleRadioInput>
    </CycleInputContainer>

    <!-- intials -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="personalData.labels.initials" />

        <ProvidedTooltip
          heading-name="personalData.headings.initials"
          body-name="personalData.tooltips.initials"
        />
      </template>

      <CycleTextInput v-model="formData.person.initials" required :max-length="25" alpha-ext />
    </CycleInputContainer>

    <!-- tussenvoegsel -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="personalData.labels.preposition" />

        <ProvidedTooltip
          heading-name="personalData.headings.preposition"
          body-name="personalData.tooltips.preposition"
        />
      </template>

      <CycleTextInput
        v-model="formData.person.tussenvoegsel"
        placeholder="Optioneel"
        :max-length="50"
        alpha-ext
      />
    </CycleInputContainer>

    <!-- last name -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="personalData.labels.lastName" />

        <ProvidedTooltip
          heading-name="personalData.headings.lastName"
          body-name="personalData.tooltips.lastName"
        />
      </template>

      <CycleTextInput v-model="formData.person.lastName" required :max-length="50" alpha-ext />
    </CycleInputContainer>

    <!-- date of birth -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="personalData.labels.dateOfBirth" />

        <ProvidedTooltip
          heading-name="personalData.headings.dateOfBirth"
          body-name="personalData.tooltips.dateOfBirth"
        />
      </template>
      <CycleDateInput
        v-model="formData.person.dateOfBirth"
        required
        date
        :max="LegalAge"
        :min="MaxNewPolicyAge"
        :is-disabled="disableDateOfBirth"
      />
      <template #error-min>
        {{ phrase('error-messages-funnels-006') }}
      </template>

      <template #error-max>
        {{ phrase('error-messages-funnels-005') }}
      </template>
    </CycleInputContainer>
  </CycleFormSet>
</template>

<script>
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput, CycleRadioInput, CycleTextInput } from '@/components/elements/forms'
import { CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { Person } from '@/models'
import { Gender, LegalAge, MaxNewPolicyAge } from '@/constants'
import { dictionaryMixin } from '../../../mixins/dictionary'

import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import ProvidedTooltip from '../Content/ProvidedTooltip'

export default {
  name: 'PersonalDataForm',
  components: {
    CycleButton,
    CycleDateInput,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleTooltip,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  mixins: [dictionaryMixin],
  inject: ['formData'], // needed for validations
  props: {
    value: {
      required: true,
      type: Person
    },
    disableDateOfBirth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    genders() {
      return Gender
    },
    LegalAge() {
      return LegalAge
    },
    MaxNewPolicyAge() {
      return MaxNewPolicyAge
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$set(this.formData, 'person', value)
      }
    }
  }
}
</script>
