<template>
  <div :class="[{ 'static-spinner': isStatic }, 'spinner-outer']" :data-position="position">
    <div v-if="source" :class="[{ 'step-loader-block': hasLoaderText }, 'steploader']">
      <CycleHeading v-if="hasLoaderText" :weight="4" data-testid="step-loader-text">{{ loaderText }}</CycleHeading>
      <CycleImage :image="source" />
    </div>
    <div v-else ref="spinner" class="spinner">
      <CycleIcon modifiers="spinner" />
      <p>
        <slot>{{ phrase('loading-indicator-text') }}</slot>
      </p>
    </div>
  </div>
</template>

<script>
import CycleIcon from '../elements/CycleIcon/CycleIcon'
import IndicatorPosition from './IndicatorPosition'
import CycleImage from '../cycle-image/CycleImage'
import { logWarning } from '@/services/Logging'
import { dictionaryMixin } from '@/mixins/dictionary'
import { CycleHeading } from '../elements/CycleHeading'

export default {
  name: 'BusyIndicator',
  components: { CycleIcon, CycleHeading, CycleImage },
  inject: {
    indicatorPosition: { default: IndicatorPosition.Default },
    scrollIntoView: { default: false }
  },
  mixins: [dictionaryMixin],
  props: {
    source: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isStatic: false
    }
  },
  computed: {
    position() {
      if (!Object.values(IndicatorPosition).includes(this.indicatorPosition)) {
        logWarning(`"${this.indicatorPosition}" is not a valid position`)
        return IndicatorPosition.Default
      }
      return this.indicatorPosition
    },
    hasLoaderText() {
      return !!this.source?.text
    },
    loaderText() {
      return this.source?.text
    }
  },
  async mounted() {
    if (this.scrollIntoView) {
      if (this.isIframe()) {
        this.isStatic = true
        await this.$nextTick()
        this.$refs?.spinner?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
      }
    }
  },
  methods: {
    isIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return false
      }
    }
  }
}
</script>
