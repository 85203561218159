<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <span v-html="selected.key"></span>
      <span v-html="selected.value"></span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option
          open = false
          $emit('input', i)
        "
      >
        <span v-html="option.key"></span>
        <span v-html="option.value"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      open: false,
      selected: this.default || this.options[0] || []
    }
  },
  mounted() {
    this.$emit('input', this.selected)
  },
  watch: {
    default(newValue) {
      this.selected = newValue
    }
  }
}
</script>
