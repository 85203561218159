import { logWarning } from '../services/Logging'

export const jssMixin = {
  computed: {
    formDefinition() {
      const formDefinition = { formDefinitionId: this.$attrs.dataSource }
      const antiForgeryToken = this.$attrs?.antiForgeryToken?.value
      if (antiForgeryToken) {
        formDefinition.antiForgeryToken = antiForgeryToken
      }
      return formDefinition
    },
    /** @deprecated */
    jssInterfacePresent() {
      logWarning('jssInterfacePresent() is Deprecated. Please just trust the system.')
      return !!(this.$jss || (global !== undefined && global.$jss))
    },
    gql() {
      return {
        mutate: (mutation, variables, options) => {
          const mutationPromise = this._runInJss('mutate')(
            mutation,
            this._enrichVariables(variables),
            options
          )
          return mutationPromise
        },
        mutationByName: (mutationName, variables, options) => {
          const mutationPromise = this._runInJss('mutationByName')(
            mutationName,
            this._enrichVariables(variables),
            options
          )
          return mutationPromise
        },
        query: (query, variables, options) => this._runInJss('query')(query, variables, options),
        queryByName: (queryName, variables, options) =>
          this._runInJss('queryByName')(queryName, variables, options)
      }
    }
  },
  methods: {
    _runInJss(functionName) {
      return (...params) => {
        if (this.$jss) {
          return this.$jss.gql[functionName](...params)
        }
        if (global !== undefined) {
          return global.$jss.gql[functionName](...params)
        }
        throw new Error('$jss not found')
      }
    },
    _enrichVariables(variables) {
      return {
        ...variables,
        formDefinition: this.formDefinition
      }
    },
    /** @deprecated */
    mutate(mutation, variables) {
      logWarning('Deprecated: Please use this.gql.mutate')
      return this.gql.mutate(mutation, variables)
    },
    /** @deprecated */
    mutationByName(mutationName, variables) {
      logWarning('Deprecated: Please use this.gql.mutationByName')
      return this.gql.mutationByName(mutationName, variables)
    },
    /** @deprecated */
    query(query, variables) {
      logWarning('Deprecated: Please use this.gql.query')
      return this.gql.query(query, variables)
    },
    /** @deprecated */
    queryByName(queryName, variables) {
      logWarning('Deprecated: Please use this.gql.queryByName')
      return this.gql.queryByName(queryName, variables)
    }
  }
}
