import { Commit } from 'vuex'
import { localizedPaths } from './models'

import {
  SET_SITESETTINGS_CURRENTLANGUAGE,
  SET_SITESETTINGS_AVAILABLELANGUAGES,
  SET_SITESETTINGS_SITENAME,
  SET_SITESETTINGS_LOGOUTURL,
  SET_DICTIONARY
} from './mutation-types'

export default {
  updateSiteSettings(
    { commit }: { commit: Commit },
    {
      currentLanguage,
      availableLanguages,
      siteName,
      logoutUrl,
      dictionary
    }: {
      currentLanguage: string
      availableLanguages: localizedPaths
      siteName: string
      logoutUrl: string
      dictionary: Object
    }
  ) {
    commit(SET_SITESETTINGS_CURRENTLANGUAGE, currentLanguage)
    commit(SET_SITESETTINGS_AVAILABLELANGUAGES, availableLanguages)
    commit(SET_SITESETTINGS_SITENAME, siteName)
    commit(SET_SITESETTINGS_LOGOUTURL, logoutUrl)
    commit(SET_DICTIONARY, dictionary)
  }
}
