<template>
  <input
    type="date"
    v-bind="Object.assign({}, attributes, $attrs)"
    v-on="Object.assign({}, listeners, $listeners, { input: updateValue })"
    :value="dateStringValue"
    :pattern="pattern"
    class="form__input form__input--inline"
    @keyup.enter="updateValue"
  />
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleDateInput',
  extends: BaseInput,
  // Override value prop from BaseInput
  props: {
    value: {
      // Can be bound to Date and String values
      type: [String, Date, Object],
      required: false,
      default: ''
    }
  },
  data() {
    return {
      // Will be checked on creation
      isDate: undefined,
      // Pattern will only be used when browser fallback for type=date is type=text
      pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
      // This will be checked on mount
      supportedByBrowser: true
    }
  },
  computed: {
    dateStringValue() {
      // Should we render Date or String
      if (this.isDate) {
        // When rendering a date, create a valid string representation
        if (this.value instanceof Date && !isNaN(this.value))
          return this.value.toISOString().split('T')[0]
        else return this.$el && this.$el.value
      } else return this.value
    }
  },
  created() {
    // Simple check whether we're dealing with Date object or String
    this.isDate = typeof this.value === 'object'
  },
  mounted() {
    // Try to create a input type=date. Based on that set browser support
    let test = document.createElement('input')
    try {
      test.type = 'date'
    } catch (e) {
      //
    }
    this.supportedByBrowser = test.type === 'date'
  },
  methods: {
    updateValue(event) {
      // Update model with String or Date
      if (this.isDate) {
        if (!this.supportedByBrowser) {
          // For browsers with no type=date support, parse value as Date
          this.$emit('input', new Date(event.target.value))
        } else {
          this.$emit('input', event.target.valueAsDate)
        }
      } else {
        this.$emit('input', event.target.value)
      }
    }
  }
}
</script>
