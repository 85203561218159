<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    hide-back-button
    :hide-next-button="!canSubmit"
    :next-button-text="contentProvider.getContentByPath('steps.setup.nextButton')"
  >
    <template #header>
      <CycleHeading :weight="3"><ProvidedText name="steps.setup.heading" /></CycleHeading>
    </template>

    <template slot="technicalError">
      <CycleFormConfirmation status="rejected" v-if="!!checkAcceptanceMessage">
        <CycleHtml :html="checkAcceptanceMessage" />
      </CycleFormConfirmation>
    </template>

    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.labels.homebuildingtype" />
          <ProvidedTooltip
            heading-name="steps.setup.labels.homebuildingtype"
            body-name="steps.setup.tooltips.homebuildingtype"
          />
        </template>
        <CycleSelect required v-model="formData.selectedHomeType">
          <template #option>
            <CycleOption
              slot="option"
              v-for="item in homeTypeValues"
              :key="item.id"
              :value="item"
              v-text="item.value"
            />
          </template>
        </CycleSelect>
        <template #subtext v-if="showUninsurableMessage">
          <p class="form__errormessage">
            {{ phrase(uninsurableMessage) }}
          </p>
        </template>
      </CycleInputContainer>

      <slot name="postalcode" />
      <CycleInputContainer v-if="showLocationCheck">
        <template #label>
          <ProvidedText name="steps.setup.labels.homelocation" />
        </template>
        <CycleRadioInput
          v-model="formData.homelocation"
          :value="true"
          :label="phrase('general-yes', 'Ja')"
          required
        />
        <CycleRadioInput
          v-model="formData.homelocation"
          :value="false"
          :label="phrase('general-no', 'Nee')"
          required
        />
        <template #subtext v-if="isNotHomelocation">
          <p class="form__errormessage">
            {{ phrase('home-funnel-error-message-HO004') }}
          </p>
        </template>
      </CycleInputContainer>
      <CycleInputContainer v-if="showHouseVolumeEntry">
        <template #label>
          <ProvidedText name="steps.setup.labels.houseVolume" />
          <ProvidedTooltip
            heading-name="steps.setup.labels.houseVolume"
            body-name="steps.setup.tooltips.houseVolume"
          />
        </template>
        <CNumber v-model="formData.houseVolume" required numeric inline @blur="setHouseVolume" />
      </CycleInputContainer>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import { intlMixin, dictionaryMixin } from '@/mixins'
import { Address } from '@/models'
import { CycleFormPage, CycleInputContainer, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import {
  CycleSelect,
  CycleOption,
  CycleRadioInput,
  CycleTextInput,
  CycleNumberInput
} from '@/components/elements/forms'
import { CycleHeading } from '@/components/elements'
import { uninsurableMessagesMap } from '../../constants'

export default {
  name: 'HomeOwnerStepSetup',
  components: {
    CycleFormPage,
    CycleInputContainer,
    CycleOption,
    CycleSelect,
    ProvidedText,
    ProvidedTooltip,
    CycleHeading,
    CycleFormSet,
    CycleRadioInput,
    CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData', 'textContent', 'contentProvider'],
  mixins: [intlMixin, dictionaryMixin],
  props: {
    homeTypeValues: {
      type: Array,
      required: true,
      default: () => []
    },
    submitStepSetup: {
      type: Function,
      default: () => ({})
    },
    checkAcceptanceMessage: {
      type: String,
      default: ''
    },
    selectedAddress: {
      type: Address
    },
    showHouseVolumeEntry: {
      type: Boolean,
      default: false
    },
    showLocationCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showUninsurableMessage() {
      return this.formData.selectedHomeType?.isInsurable === false
    },
    uninsurableMessage() {
      const selectedKey = this.formData.selectedHomeType?.id || 'none'
      return uninsurableMessagesMap[selectedKey]()
    },
    canSubmit() {
      return (
        this.formData.selectedHomeType?.isInsurable === true &&
        (!this.showLocationCheck || this.formData.homelocation === true)
      )
    },
    isNotHomelocation() {
      return this.formData.homelocation === false
    }
  },
  watch: {
    selectedAddress: {
      handler(newAddress) {
        this.$emit('address-changed', newAddress)
      },
      immediate: true
    },
    showHouseVolumeEntry(newVal) {
      if (newVal) {
        this.formData.houseVolume = null
      }
    },
    ['formData.selectedHomeType'](selectedHomeType) {
      this.$emit('home-type-changed', selectedHomeType)
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.submitStepSetup()
        this.$emit('setup-finished')
      } catch (err) {
        window.scroll({
          top: this.$el.getBoundingClientRect().top,
          behavior: 'smooth'
        })
        throw err
      }
    },
    setHouseVolume(event) {
      this.$emit('house-volume-changed', parseInt(event.target.value))
    }
  }
}
</script>
