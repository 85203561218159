//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, addYears, format } from 'date-fns'
import {
  CycleDateInput,
  CycleHeading,
  CycleOption,
  CycleRadioInput,
  CycleSelect,
  CycleTextInput
} from '@/components/elements'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { CycleFormSet } from '@/components/functional'

export default {
  name: 'WeddingStepSetup',
  inject: ['formData', 'textContent', 'validationConstants', 'countries'],
  components: {
    CycleSelect,
    CycleOption,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedTooltip,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleDateInput
  },
  mixins: [intlMixin, dictionaryMixin],
  computed: {
    minStartDate() {
      return format(
        addDays(new Date(), this.validationConstants.minimalWeddingDateInDays),
        'yyyy-MM-dd'
      )
    },
    maxStartDate() {
      return format(
        addYears(new Date(), this.validationConstants.maximalWeddingDateInYears),
        'yyyy-MM-dd'
      )
    }
  },
  created() {
    if (typeof this.formData.weddingCountry != Object) {
      this.formData.weddingCountry = this.countries.find(
        (e) => e.key == this.formData.weddingCountry
      )
    }
    if (typeof this.formData.weddingPartyCountry != Object) {
      this.formData.weddingPartyCountry = this.countries.find(
        (e) => e.key == this.formData.weddingPartyCountry
      )
    }
  },
  methods: {
    handleSubmit() {
      const { insuredAmount, weddingDate, weddingCountry, weddingPartyCountry } = this.formData
      this.$emit('submit', {
        insuredAmount,
        weddingDate,
        weddingCountry,
        weddingPartyCountry
      })
    }
  }
}
