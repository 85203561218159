<template>
  <Topbar>
    <template #leftContent v-if="advisorContent">{{ advisorContent }}</template>
    <template #centerContent v-if="link">
      <span>
        <CycleLink :href="link.href" :target="link.target">
          <CycleIcon modifiers="exit" />
          <span>{{ link.text }}</span>
        </CycleLink>
        {{ collectivityName }}
      </span>
    </template>
    <template #rightContent v-if="clientContent">{{ clientContent }}</template>
  </Topbar>
</template>

<script>
import * as Cycle from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'

export default {
  name: 'TopbarSitecore',
  components: {
    Topbar: Cycle.Topbar,
    CycleIcon: Cycle.CycleIcon,
    CycleLink: Cycle.CycleLink
  },
  mixins: [dictionaryMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    advisorContent() {
      return (
        this.fields?.advisorID && this.getPhrase('generic-advisor') + ': ' + this.fields.advisorID
      )
    },
    clientContent() {
      return (
        (this.fields?.advisorID || this.fields?.clientName) &&
        this.getPhrase('generic-client') + ': ' + (this.fields?.clientName ?? '')
      )
    },
    collectivityName() {
      return this.fields?.collectivityName
    },
    link() {
      return this.fields?.link?.value
    }
  }
}
</script>
