<template>
  <CycleSimpleForm
    :heading="formLabels.heading"
    :next-button-text="formLabels.nextButtonLabel"
    :has-next-button="postcodeFound"
    :form-submit-action="onSubmit"
  >
    <p>{{ formLabels.intro }}</p>
    <CyclePostcodeCheck
      :text-content="textContent"
      :postcode-found="postcodeFound"
      :has-manual-entry="false"
      @input="fetchAddress"
    />
    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          {{ formLabels.moveDateLabel }}
          <CycleTooltip v-if="formLabels.moveDateTooltip">
            <template slot="tooltip-heading">{{ formLabels.moveDateLabel }}</template>
            <template slot="tooltip-content">
              <CycleHtml :html="formLabels.moveDateTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CycleDateInput
          v-model="formData.moveDate"
          required
          :min="today"
          :max="yesterdayNextYear"
        />
      </CycleInputContainer>
    </CycleFormSet>
    <template slot="thank-you">
      <slot name="thank-you"></slot>
    </template>
  </CycleSimpleForm>
</template>

<script>
import { pick } from 'lodash'
import format from 'date-fns/format'
import addYears from 'date-fns/addYears'
import subDays from 'date-fns/subDays'

import { CycleInputContainer } from '../CycleInputContainer'
import { CycleDateInput } from '../../../elements/forms'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import { CyclePostcodeCheck } from '../shared/CyclePostcodeCheck'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'
import CycleHtml from '../../../base/CycleHtml'
import { CycleFormSet } from '../CycleFormSet'

export default {
  name: 'CycleChangeAddress',
  components: {
    CycleSimpleForm,
    CyclePostcodeCheck,
    CycleDateInput,
    CycleInputContainer,
    CycleTooltip,
    CycleHtml,
    CycleFormSet
  },
  inject: ['formLabels', 'formData'],
  props: {
    postcodeFound: {
      type: Boolean,
      required: false
    },
    onSubmit: {
      type: Function,
      required: true
    },
    fetchAddress: {
      type: Function,
      required: true
    }
  },
  computed: {
    textContent() {
      return pick(this.formLabels, [
        'zipcodeLabel',
        'zipcodeTooltip',
        'zipcodeCheckFailureText',
        'housenumberLabel',
        'housenumberTooltip',
        'streetLabel',
        'streetTooltip',
        'cityLabel',
        'cityTooltip'
      ])
    },
    today() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    /**
     * The back office accepts up to one year, our validation accepts all days in the next year.
     * Therefore we offset by one day (CFPA-1794)
     */
    yesterdayNextYear() {
      return format(subDays(addYears(new Date(), 1), 1), 'yyyy-MM-dd')
    }
  }
}
</script>
