
import { policySwitch } from '../../services/SourceMatrix'

export default {
  name: 'SourceMatrixSwitch',
  props: {
    policy: Object
  },
  render() {
    return policySwitch(
      this.policy,
      this.$slots.caseAonDirect70,
      this.$slots.caseAonLegalAid,
      this.$slots.caseAonLiability,
      this.$slots.caseAonCar,
      this.$slots.caseAonTravel,
      this.$slots.caseAonShortTravel,
      this.$slots.caseAonEffects,
      this.$slots.caseAonProperty,
      this.$slots.caseAonWedding,
      this.$slots.caseMeeus,
      this.$slots.caseIAK,
      this.$slots.caseMijnWerk,
      this.$slots.caseMijnWerkLiability,
      this.$slots.caseMijnWerkTravel,
      this.$slots.caseMijnWerkCar,
      this.$slots.caseMijnWerkLegal,
      this.$slots.caseMijnWerkHomeOwner,
      this.$slots.caseMijnWerkHomeContents,
      this.$slots.caseAonVerzekeringen,
      this.$slots.caseAonDirect10,
      this.$slots.caseOHIZorg,
      this.$slots.caseNKC,
      this.$slots.caseKNMV,
      this.$slots.caseVGA,
      this.$slots.caseDefault || (this.$slots.default && this.$slots.default[0])
    )
  }
}
