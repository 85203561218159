<script>
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { dynamicExpressionFactory } from '../../../mixins/DynamicExpression'
import { componentTypeMap } from './mappings/ComponentTypeMap'
import {
  fieldDynamicExpressionProps,
  dynamicExpressionPropsGetters
} from './RapidFormField.constants'
import {
  CycleOption,
  CycleCheckboxInput,
  CycleSelect,
  CycleHtml,
  ToggleCheckbox,
  CycleFormConfirmation,
  LoginContainer,
  CycleHeading,
  CycleLicensePlateInput,
  ModalTooltip,
  MultiFileInput,
  CycleButton,
  TotalPremium,
  CycleIcon
} from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'
import RapidFormContentField from './fields/RapidFormContentField'
import RapidFormBannerContentField from './fields/RapidFormBannerContentField'
import RapidFormInputContainer from './RapidFormInputContainer'
import RapidFormCheckoutWrapper from './wrappings/RapidFormCheckoutWrapper.vue'
import RapidFormProductWrapper from './wrappings/RapidFormProductWrapper.vue'
import RapidFormRadioWrapper from './wrappings/RapidFormRadioWrapper.vue'
import RapidFormDateWrapper from './wrappings/RapidFormDateWrapper.vue'
import RapidFormRadioBarWrapper from './wrappings/RapidFormRadioBarWrapper'
import RapidFormInputRadioTileWrapper from './wrappings/RapidFormInputRadioTileWrapper.vue'
import RapidFormSuggestionWidget from './fields/RapidFormSuggestionWidget'
export default {
  name: 'RapidFormField',
  components: {
    RapidFormInputContainer,
    CycleOption,
    CycleCheckboxInput,
    CycleHtml,
    ToggleCheckbox,
    LoginContainer,
    CycleHeading,
    CycleFormConfirmation,
    TotalPremium,
    CycleIcon
  },
  mixins: [
    dynamicExpressionFactory('isShown', 'showIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('isGroupShown', 'groupShowIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('isVisible', 'visibleIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('isEnabled', 'enableIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory('currentValue', 'getValue', fieldDynamicExpressionProps, ''),
    dynamicExpressionFactory('parsedLabel', 'dynamicLabel', fieldDynamicExpressionProps, ''),
    dynamicExpressionFactory('parsedContent', 'dynamicContent', fieldDynamicExpressionProps, ''),
    dynamicExpressionFactory(
      'currentOptions',
      'dynamicOptions',
      fieldDynamicExpressionProps,
      false
    ),
    dictionaryMixin
  ],
  props: {
    fieldDefinition: {
      required: true,
      type: Object
    },
    fieldSet: {
      required: true,
      type: [Object, String]
    }
  },
  data() {
    return {
      debouncing: null,
      isNotifyClosed: false
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      ...dynamicExpressionPropsGetters,
      'getFieldValue',
      'getFieldGroup',
      'communicationStatusByKey',
      'getFormDataFormattedValue',
      'isTouchedElement',
      'fieldsInGroup',
      'hasFieldInErrors',
      'isCommunicating',
      'getProductSuggestedOffer',
      'productPricePaymentTerm'
    ]),
    classes() {
      const currentClasses = []
      if (this.fieldDefinition.showAsLabel) {
        currentClasses.push('fieldset__column')
      }
      if (!this.isVisible || this.fieldDefinition.type === 'Hidden') {
        currentClasses.push('hidden')
      }

      return `${this.joinModifiers(this.fieldDefinition.modifiers)} ${currentClasses.join(' ')}`
    },
    fieldValue: {
      get() {
        if (this.fieldDefinition.showAsLabel || this.isLabel) {
          const readableData = this.getReadableData()
          this.updateField({
            value: readableData,
            fieldKey: this.fieldDefinition.key
          })
          return `${readableData}`
        }
        if (this.componentType === RapidFormContentField) {
          return this.parsedContent
        }

        if (this.componentType === TotalPremium) {
          return this.getReadableData()
        }

        return this.getFieldValue(this.fieldDefinition.key)
      },
      set(value) {
        this.updateField({ value, fieldKey: this.fieldDefinition.key })
        if (this.fieldDefinition.cloneValueTo) {
          this.updateField({
            value,
            fieldKey: this.fieldDefinition.cloneValueTo
          })
        }
        this.fieldDefinition.deleteValueFrom?.split(',').forEach((fieldKey) => {
          const formFieldValue = this.getFieldValue(fieldKey.trim())
          const resetValue =
            formFieldValue === undefined || typeof formFieldValue === 'string' ? '' : false
          this.updateField({
            value: resetValue,
            fieldKey: fieldKey.trim()
          })
          this.clearErrorsByKey({ key: fieldKey.trim() })
        })
        this.fieldDefinition.deleteValueFromBusinessData?.split(',').forEach((fieldKey) => {
          this.clearBusinessDataValue({
            fieldKey: fieldKey.trim()
          })
        })
      }
    },
    field() {
      return this.fieldDefinition
    },
    fieldGroup() {
      if (!this.fieldDefinition.fieldGroup) {
        return undefined
      }

      return this.getFieldGroup(this.fieldDefinition.fieldGroup)
    },
    activeErrors() {
      const errorElement = this.errorElements.find((element) => {
        /**
         * only find elements where the fieldKey or fieldGroup does not exist in this.errors
         * (this.errors contains server errors mapped by fieldKey or fieldGroup)
         * this prevents both FE error message and BE error message to show at the same time
         * scenario example: invalid voucher code (BE check) and code modified/not activated validation (FE check)
         */
        return (
          element.key === this.fieldDefinition.key && !this.hasFieldInErrors(this.fieldDefinition)
        )
      })

      if (!errorElement) {
        return []
      }

      if (!errorElement.error && errorElement.invalid) {
        const immediateErrors = errorElement.errors.filter((item) => item.displayOn === 'immediate')
        const blurredErrors = errorElement.errors.filter((item) => item.displayOn === 'blur')

        if (!this.isTouchedElement(this.fieldDefinition.key)) {
          return immediateErrors
        }

        return [...immediateErrors, ...blurredErrors]
      } else if (!errorElement.error) {
        return []
      }

      if (errorElement?.errors.length > 1) {
        return [errorElement.errors.find((item) => item.validator !== 'required')]
      }

      return errorElement?.errors || []
    },
    inputAttr() {
      let baseAttr = {
        name: this.fieldDefinition.key,
        'aria-invalid': this.activeErrors.length,
        autocomplete: this.fieldDefinition.autoComplete ?? 'off'
      }
      if (
        !this.showFieldSpinner &&
        (this.activeErrors.length ||
          this.errors[this.fieldDefinition.fieldKey] ||
          this.hasErrorInGroup())
      ) {
        // activate customErrorSlot in case of errors related to the current field and spinner is not showing
        baseAttr['showErrorMessage'] = true
      }
      return baseAttr
    },
    componentType() {
      return componentTypeMap(this.fieldDefinition.type, this.fieldDefinition.modifiers)
    },
    componentProperties() {
      switch (this.componentType) {
        case CycleHtml:
          return {
            html: this.fieldDefinition.intro
          }
        case RapidFormContentField:
          return {
            content: this.fieldValue,
            tooltip:
              this.fieldDefinition.tooltip && !this.fieldDefinition.showLabel
                ? this.fieldDefinition.tooltip
                : false
          }
        case RapidFormBannerContentField:
          return {
            banner: this.fieldDefinition?.banner ?? {}
          }
        case RapidFormSuggestionWidget:
          return {
            banner: this.fieldDefinition?.banner ?? {},
            title: this.fieldDefinition?.title,
            label: this.fieldDefinition?.label,
            tooltip: this.fieldDefinition.tooltip,
            tooltipHasLongText: this.fieldDefinition.tooltipHasLongText,
            modifiers: this.joinModifiers(this.fieldDefinition.modifiers),
            content: this.fieldDefinition.content,
            suggestedOffer: this.getProductSuggestedOffer,
            btnCollapseText: this.getPhrase('suggestion-collapse-text')
          }
        case LoginContainer:
          return {
            linkIntroText: this.fieldDefinition.linkIntroText,
            link: this.fieldDefinition.link,
            showNewCustomerLink: this.fieldDefinition.showNewCustomerLink,
            customerLinkIntroText: this.fieldDefinition.customerLinkIntroText,
            customerLinkText: this.fieldDefinition.customerLinkText,
            customerLinkToggleText: this.fieldDefinition.customerLinkToggleText,
            customerDetailsFieldValue: this.fieldValue
          }
        case TotalPremium:
          return {
            premiumInfo: this.fieldDefinition.premiumInfo,
            premiumLabel: this.fieldDefinition.premiumLabel,
            premium: this.fieldValue,
            transitionParams: this.fieldDefinition.numberTransition,
            displayTotalPremiumWithProvision: this.fieldDefinition.displayTotalPremiumWithProvisie,
            paymentTerm: this.productPricePaymentTerm,
            tooltip: this.fieldDefinition.tooltip,
            label: this.fieldDefinition?.label
          }
        case ToggleCheckbox:
          return {
            openActionText: this.fieldDefinition.toggleOnText,
            closeActionText: this.fieldDefinition.toggleOffText,
            value: this.fieldDefinition.checkedValue,
            modelValue: this.fieldValue,
            name: this.fieldDefinition.key,
            disabled: !this.isEnabled
          }
        case CycleLicensePlateInput:
          return {
            value: this.fieldValue,
            name: this.fieldDefinition.key,
            heading: this.fieldDefinition.heading,
            placeholder: this.fieldDefinition.placeholder,
            disabled: !this.isEnabled,
            modifiers: this.joinModifiers(this.fieldDefinition.modifiers),
            autocomplete: 'on',
            restrictAlphaNum: true,
            readonly: this.showFieldSpinner
          }
        case RapidFormRadioWrapper:
        case RapidFormRadioBarWrapper:
        case RapidFormDateWrapper:
          return {
            value: this.fieldValue,
            name: this.fieldDefinition.key,
            heading: this.fieldDefinition.heading,
            placeholder: this.fieldDefinition.placeholder || 'dd-mm-jjjj',
            disabled: !this.isEnabled,
            modifiers: this.joinModifiers(this.fieldDefinition.modifiers),
            hasInputError: this.activeErrors.length ? true : false
          }
        case CycleSelect:
          return {
            value: this.fieldOptions.some((e) => e.value === this.fieldValue)
              ? this.fieldValue
              : '',
            name: this.fieldDefinition.key,
            heading: this.fieldDefinition.heading,
            placeholder: this.fieldDefinition.placeholder,
            disabled: !this.isEnabled,
            modifiers: this.joinModifiers(this.fieldDefinition.modifiers),
            'aria-invalid': this.activeErrors.length ? true : false,
            autocomplete: this.fieldDefinition.autoComplete ?? 'off'
          }
        case MultiFileInput:
          return {
            name: this.fieldDefinition.key,
            value: this.fieldValue,
            addFileLabel: this.fieldDefinition.addFileLabel,
            changeFileLabel: this.fieldDefinition.changeFileLabel,
            addAnotherFileLabel: this.fieldDefinition.addAnotherFileLabel,
            filePlaceholder: this.fieldDefinition.placeholder,
            accept: this.fieldDefinition.allowedFileExtension,
            maxFileSize: this.fieldDefinition.fileSize,
            numberOfFiles: this.fieldDefinition.numberOfFiles,
            hasInputError: this.activeErrors.length ? true : false
          }
        default:
          return {
            value: this.fieldValue,
            name: this.fieldDefinition.key,
            heading: this.fieldDefinition.heading,
            placeholder: this.fieldDefinition.placeholder,
            disabled: !this.isEnabled,
            modifiers: this.joinModifiers(this.fieldDefinition.modifiers),
            'aria-invalid': this.activeErrors.length ? true : false,
            autocomplete: this.fieldDefinition.autoComplete ?? 'off',
            maxLength: this.fieldDefinition.maxLength ?? null
          }
      }
    },
    showValueText() {
      return this.componentType === CycleSelect || this.componentType === RapidFormRadioWrapper
    },
    fieldOptions() {
      return this.currentOptions || this.fieldDefinition.options
    },
    isLabel() {
      return typeof this.componentType === 'string' && this.componentType.toLowerCase() === 'label'
    },
    showFieldSpinner() {
      return (
        !this.fieldDefinition.interaction?.blocking &&
        this.fieldDefinition.interaction?.loading?.show &&
        this.communicationStatusByKey(this.fieldDefinition)?.isPending
      )
    },
    showFieldSuccessMessage() {
      return (
        this.fieldDefinition.interaction?.success?.show &&
        this.communicationStatusByKey(this.fieldDefinition)?.isFulfilled &&
        !this.errors[this.fieldDefinition?.key] &&
        this.activeErrors.length === 0
      )
    },
    checkedOptions() {
      if (this.fieldDefinition.getValue) {
        return [this.currentValue]
      }
      const fieldKey = this.fieldDefinition.originalKey || this.fieldDefinition.key
      const checkedOptions = this.getFieldValue(fieldKey)
      let optionValues = []
      if (checkedOptions) {
        for (let i = 0; i < checkedOptions.length; i++) {
          optionValues.push(this.getTextByValue(this.fieldDefinition.options, checkedOptions[i]))
        }
      }

      return optionValues
    },
    showWhenEmpty() {
      return !(
        this.fieldDefinition?.hideOnOverviewIfEmpty === true &&
        this.fieldDefinition?.showAsLabel &&
        !this.fieldValue
      )
    },
    attachEvents() {
      const triggerOn = this.fieldDefinition?.triggerOn ?? ['blur', 'keydown', 'keyup', 'change']
      // retrieve/format events in key/value pairs eg. {'blur': fn}
      const events = Object.assign(
        {},
        ...triggerOn.map((event) => ({ [event]: this.handleEventsWrapper }))
      )
      return events
    },
    hasPromoCode() {
      return this.fieldDefinition?.modifiers?.includes('promotionalcode')
    },
    isClearButtonEnable() {
      return this.fieldDefinition?.enableClearButton ?? false
    },
    showinputClearButton() {
      return this.isClearButtonEnable && this.showFieldSuccessMessage
    }
  },
  watch: {
    isShown: {
      immediate: true,
      handler(shown) {
        if (shown) {
          this.setActiveField(this.fieldDefinition.key)
        } else {
          this.removeActiveField(this.fieldDefinition.key)
        }
      }
    },
    fieldValue: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.clearErrorsByKey(this.fieldDefinition)
        }
      }
    },
    'fieldDefinition.options': {
      handler(newVal, oldVal) {
        if (
          this.fieldDefinition.type === 'Select' &&
          Array.isArray(oldVal) &&
          oldVal.length > 1 &&
          JSON.stringify(newVal) !== JSON.stringify(oldVal)
        ) {
          this.fieldValue = newVal[0]?.value
        }
      }
    }
  },
  beforeDestroy() {
    this.removeActiveField(this.fieldDefinition.key)
  },
  methods: {
    ...mapActions('rapidFormPlayer', [
      'updateField',
      'setActiveField',
      'removeActiveField',
      'handleFieldTouch',
      'clearErrorsByKey',
      'clearSuccessByKey',
      'clearBusinessDataValue'
    ]),
    componentSlots(createElement) {
      const label =
        !!this.fieldDefinition.label &&
        this.fieldDefinition.showLabel &&
        createElement(CycleHtml, {
          slot: 'label',
          props: { tag: 'span', html: this.parsedLabel }
        })
      const tooltipHeading = createElement(CycleHtml, {
        slot: 'tooltip-heading',
        props: { tag: 'span', html: this.parsedLabel }
      })
      const subtext = createElement(CycleHtml, {
        slot: 'subtext',
        props: {
          tag: 'span',
          html: this.fieldDefinition.subtext
        }
      })
      const loading = createElement('span', { slot: 'loading' }, [
        this.fieldDefinition.interaction?.loading?.label || this.getPhrase('loading-indicator-text')
      ])
      const successMessage = createElement(CycleHtml, {
        slot: 'successMessage',
        props: {
          tag: 'span',
          html:
            this.fieldDefinition.interaction?.success?.label ||
            this.getPhrase('funnel-confirmation-ok')
        }
      })

      // FE validation errors
      let errors = createElement(
        'span',
        { slot: 'errorMessage' },
        this.activeErrors.map((error) => {
          return createElement(
            'span',
            { attrs: { name: `error-${error.validator}`, key: error.validator } },
            [error.message]
          )
        })
      )

      // BE service errors
      const serviceErrors =
        Object.keys(this.errors).length === 0
          ? undefined
          : createElement('span', { slot: 'errorMessage' }, [
              createElement('span', this.getServiceErrorMessage())
            ])

      const prepend =
        !!this.fieldDefinition?.prepend?.value &&
        this.getInputGroupSlots(createElement, 'prepend', this.fieldDefinition?.prepend)

      const append =
        !!this.fieldDefinition?.append?.value &&
        this.getInputGroupSlots(createElement, 'append', this.fieldDefinition?.append)

      return [
        label,
        tooltipHeading,
        subtext,
        loading,
        successMessage,
        errors,
        serviceErrors,
        prepend,
        append
      ]
    },
    /**
     * apply tooltips on field-level outside a RapidFormInputContainer
     * @param createElement
     */
    composeFieldTooltip(createElement) {
      if (this.fieldDefinition.tooltip) {
        return createElement(ModalTooltip, [
          createElement(CycleHtml, {
            slot: 'tooltip-content',
            props: { html: this.fieldDefinition.tooltip }
          }),
          createElement(CycleHtml, {
            slot: 'tooltip-heading',
            props: { tag: 'span', html: this.parsedLabel }
          })
        ])
      }
    },
    composeSubtextTooltip(createElement) {
      if (this.fieldDefinition.subtextTooltip) {
        return createElement(ModalTooltip, [
          createElement(CycleHtml, {
            slot: 'tooltip-content',
            props: { html: this.fieldDefinition.subtextTooltip }
          }),
          createElement(CycleHtml, {
            slot: 'tooltip-heading',
            props: { tag: 'span', html: this.fieldDefinition.subtext }
          })
        ])
      }
    },
    composeSendOnClear(createElement) {
      if (this.showinputClearButton) {
        return createElement('button', {
          attrs: {
            name: `clear_input_${this.fieldDefinition.key}`,
            type: 'button',
            disabled: this.isCommunicating
          },
          class: {
            'btn--cancel-voucher': this.hasPromoCode,
            'icon icon--cross': true
          },
          on: {
            click: () => {
              this.fieldValue = ''
              this.clearSuccessByKey(this.fieldDefinition)
              this.clearErrorsByKey(this.fieldDefinition)
              this.clearBusinessDataValue(this.fieldDefinition)
              this.handleFieldTouchWrapper()
            }
          }
        })
      }
    },
    composeSendOnClick(createElement) {
      if (this.fieldDefinition.sendOnClick) {
        return createElement(
          'button',
          {
            attrs: {
              name: this.fieldDefinition.key,
              type: 'button',
              disabled: this.isCommunicating || this.showinputClearButton
            },
            class: {
              btn: true,
              'btn--link': !this.isClearButtonEnable,
              'btn btn--primary': this.isClearButtonEnable
            },
            on: {
              click: this.sendOnClickHandler
            }
          },
          this.fieldDefinition.sendOnClickLabel
        )
      }
    },
    composeComponent(createElement) {
      if (this.fieldDefinition.showAsLabel && this.componentType === CycleCheckboxInput) {
        return createElement('dl', {}, [
          createElement('dt', {}, [this.parsedLabel]),
          ...this.checkedOptions?.map((option) => {
            return createElement('dd', {}, [option])
          })
        ])
      }
      if (this.fieldDefinition.showAsLabel) {
        return createElement('dl', {}, [
          createElement('dt', {}, [
            createElement(CycleHtml, { props: { tag: 'span', html: this.parsedLabel } }),
            this.composeFieldTooltip(createElement)
          ]),
          createElement('dd', {}, [createElement(CycleHtml, { props: { html: this.fieldValue } })])
        ])
      }
      if (this.fieldDefinition.subtextTooltip) {
        return createElement('div', {}, [
          ...this.componentRenderFn(createElement),
          createElement('p', { class: 'form__text', slot: 'subtext' }, [
            createElement(CycleHtml, {
              props: { tag: 'span', html: this.fieldDefinition.subtext }
            }),
            this.composeSubtextTooltip(createElement)
          ])
        ])
      }
      if (
        this.componentType === RapidFormCheckoutWrapper ||
        this.componentType === RapidFormProductWrapper
      ) {
        return [
          ...this.componentRenderFn(createElement),
          createElement('a', { attrs: { name: this.fieldDefinition.key } })
        ]
      }
      if (this.componentType === CycleButton) {
        return [...this.componentRenderFn(createElement)]
      }
      return createElement(
        RapidFormInputContainer,
        {
          attrs: {
            ...this.inputAttr,
            tooltip: this.fieldDefinition.tooltip,
            isLoading: this.showFieldSpinner,
            showSuccessMessage: this.showFieldSuccessMessage,
            renderList: this.fieldDefinition?.modifiers?.includes('vertical'),
            renderHorizontal: this.fieldDefinition?.modifiers?.includes('horizontal'),
            renderMinWidth: this.fieldDefinition?.modifiers?.includes('min-width'),
            renderHalfWidth: this.fieldDefinition?.modifiers?.includes('half-width-desktop'),
            renderHighlightedLabel: this.fieldDefinition?.modifiers?.includes('highlightedlabel'),
            renderAddMarginTop: this.fieldDefinition?.modifiers?.includes('add-margin-top'),
            hasToggleTooltip: this.fieldDefinition?.modifiers?.includes('toggle-tooltip'),
            hasPopoverTooltip: this.fieldDefinition?.modifiers?.includes('popover-tooltip'),
            renderRemoveMarginsBottom:
              this.fieldDefinition?.modifiers?.includes('remove-margins-bottom'),
            inputsBelowLabel: this.fieldDefinition?.modifiers?.includes('inputs-below-label'),
            fieldLoader: this.fieldDefinition?.loader?.url,
            renderCompact: this.fieldDefinition?.modifiers?.includes('compact'),
            prependImage: this.fieldDefinition?.prependMediaImage,
            tooltipHasLongText: this.fieldDefinition?.tooltipHasLongText,
            tooltipLinkText: this.fieldDefinition?.suggestion,
            tooltipFeedback: this.fieldDefinition?.enableTooltipFeedback
          }
        },
        [
          ...this.componentSlots(createElement),
          ...this.componentRenderFn(createElement),
          createElement('a', { attrs: { name: this.fieldDefinition.key } }),
          this.composeSendOnClear(createElement),
          this.composeSendOnClick(createElement)
        ]
      )
    },
    componentRenderFn(createElement) {
      switch (this.componentType) {
        case RapidFormRadioWrapper:
          return [
            ...(!this.fieldOptions
              ? []
              : this.fieldOptions.map((option) => {
                  return createElement(
                    this.componentType,
                    {
                      attrs: {
                        ...this.componentProperties,
                        key: option.value,
                        value: option.value,
                        modelValue: this.fieldValue,
                        showIf: option.showIf,
                        enableIf: option.enableIf,
                        visibleIf: option.visibleIf
                      },
                      on: {
                        change: (value) => {
                          this.fieldValue = value
                          this.handleFieldTouchWrapper()
                        },
                        keydown: this.handleKeydown
                      }
                    },
                    [option.text]
                  )
                }))
          ]
        case RapidFormRadioBarWrapper:
          return [
            createElement(
              'div',
              { attrs: { class: 'radio-bar' } },
              !this.fieldOptions
                ? []
                : this.fieldOptions.map((option) => {
                    return createElement(
                      this.componentType,
                      {
                        attrs: {
                          ...this.componentProperties,
                          key: option.value,
                          keyName: this.field.key,
                          value: option.value,
                          modelValue: this.fieldValue,
                          showIf: option.showIf,
                          enableIf: option.enableIf,
                          visibleIf: option.visibleIf
                        },
                        on: {
                          change: (value) => {
                            this.fieldValue = value
                            this.handleFieldTouchWrapper()
                          },
                          keydown: this.handleKeydown
                        }
                      },
                      [option.text]
                    )
                  })
            )
          ]
        case RapidFormInputRadioTileWrapper:
          return [
            createElement(
              'div',
              { attrs: { class: 'tiles tiles--radio-box' } },
              !this.fieldOptions
                ? []
                : this.fieldOptions.map((option) => {
                    return createElement(
                      this.componentType,
                      {
                        attrs: {
                          ...this.componentProperties,
                          key: option.value,
                          keyName: this.field.key,
                          value: option.value,
                          modelValue: this.fieldValue,
                          showIf: option.showIf,
                          enableIf: option.enableIf,
                          visibleIf: option.visibleIf,
                          text: option.text
                        },
                        on: {
                          change: (value) => {
                            this.fieldValue = value
                            this.handleFieldTouchWrapper()
                          },
                          keydown: this.handleKeydown
                        }
                      },
                      [option.text]
                    )
                  })
            )
          ]
        case CycleCheckboxInput:
          return [
            ...(!this.fieldOptions
              ? []
              : this.fieldOptions.map((option) => {
                  return createElement(
                    this.componentType,
                    {
                      attrs: {
                        ...this.componentProperties,
                        key: option.value,
                        value: option.value,
                        modelValue: this.fieldValue
                      },
                      on: {
                        change: (value) => {
                          this.fieldValue = value
                          this.handleFieldTouchWrapper()
                        },
                        keydown: this.handleKeydown
                      }
                    },
                    [option.text]
                  )
                }))
          ]
        case CycleSelect:
          return [
            createElement(
              this.componentType,
              {
                attrs: {
                  ...this.componentProperties
                },
                on: {
                  change: (value) => {
                    this.fieldValue = value
                    this.handleFieldTouchWrapper()
                  }
                }
              },
              this.fieldOptions.map((option) => {
                return createElement(
                  CycleOption,
                  {
                    props: {
                      value: option.value,
                      key: option.value,
                      disabled: option.disabled
                    },
                    slot: 'option'
                  },
                  [option.text]
                )
              })
            )
          ]
        case CycleFormConfirmation:
          return [
            createElement(
              CycleFormConfirmation,
              {
                props: {
                  status: this.fieldDefinition.options[0]?.value
                }
              },
              [
                createElement(CycleHtml, {
                  props: {
                    html: this.fieldDefinition.htmlContent?.find((c) => c.value === 'Closing')?.text
                  }
                })
              ]
            )
          ]
        case 'Label':
          return [createElement('p', {}, [this.fieldValue])]
        case CycleButton:
          return [
            createElement(
              this.componentType,
              {
                on: {
                  click: (event) => {
                    event.preventDefault()
                    this.sendOnClickHandler()
                  }
                },
                attrs: {
                  ...this.componentProperties
                }
              },
              [createElement('span', { slot: 'default' }, [this.fieldDefinition.placeholder])]
            )
          ]
        case RapidFormCheckoutWrapper:
          return [
            createElement(
              this.componentType,
              {
                on: {
                  change: (value) => {
                    this.fieldValue = value
                  },
                  blur: this.handleFieldTouchWrapper
                },
                props: {
                  ...this.componentProperties,
                  label: this.parsedLabel,
                  modelValue: this.fieldValue,
                  tooltip: this.fieldDefinition.tooltip,
                  fieldKey: this.fieldDefinition.key,
                  isLoading: this.showFieldSpinner,
                  disabled: !this.fieldDefinition.canSelect,
                  defaultPrice: this.fieldDefinition.getValue,
                  forceValue: this.fieldDefinition.forceGetValue,
                  hasToggleTooltip: this.fieldDefinition?.modifiers?.includes('toggle-tooltip'),
                  prependIcon: this.fieldDefinition?.prepend?.value,
                  hasPopoverTooltip: this.fieldDefinition?.modifiers?.includes('popover-tooltip'),
                  tooltipHasLongText: this.fieldDefinition?.tooltipHasLongText,
                  tooltipLinkText: this.fieldDefinition?.suggestion,
                  tooltipFeedback: this.fieldDefinition?.enableTooltipFeedback
                }
              },
              [...this.componentSlots(createElement)]
            )
          ]
        case RapidFormProductWrapper:
          return [
            createElement(
              this.componentType,
              {
                attrs: {
                  ...this.componentProperties
                },
                on: {
                  input: (value) => {
                    this.fieldValue = value
                  },
                  change: (value) => {
                    this.fieldValue = value
                  },
                  blur: this.handleFieldTouchWrapper,
                  paste: this.pasteHandler
                },
                props: {
                  isLoading: this.showFieldSpinner
                }
              },
              [...this.componentSlots(createElement)]
            )
          ]
      }
      return [
        createElement(this.componentType, {
          attrs: {
            ...this.componentProperties
          },
          on: {
            input: (value) => {
              this.fieldValue = value
            },
            paste: this.pasteHandler,
            ...this.attachEvents
          }
        })
      ]
    },
    getTextByValue(options, value) {
      const option = options.find((item) => item.value === value)
      if (option && option.text) {
        return option.text
      }
    },
    getReadableData() {
      if (this.fieldDefinition.getValue) {
        return this.currentValue
      }
      const fieldKey = this.fieldDefinition.originalKey || this.fieldDefinition.key

      if (this.showValueText) {
        return this.getTextByValue(this.fieldDefinition.options, this.getFieldValue(fieldKey))
      }

      if (this.getFormDataFormattedValue(fieldKey)) {
        return this.getFormDataFormattedValue(fieldKey)
      }
      return this.getFieldValue(fieldKey)
    },
    handleEventsWrapper(event) {
      // Only four events blur, keyup, keydown, change are responsible for service call for fields
      switch (event?.type) {
        case 'blur':
          this.handleFieldTouchWrapper()
          break
        case 'keydown':
          this.handleKeydown(event)
          break
        case 'keyup':
          this.handleKeyUp()
          break
        case 'change':
          // Call function only if "change" event comes from Sitecore end
          if (this.fieldDefinition?.triggerOn) {
            this.handleFieldTouchWrapper()
          }
          break
        default:
          // If event is Boolean/String/Number but not an object then set as fieldValue
          this.fieldValue = event
      }
    },
    handleFieldTouchWrapper() {
      if (
        (this.fieldValue !== '' || !this.fieldDefinition.dontSendWhenEmpty) &&
        !this.fieldDefinition.sendOnClick
      ) {
        this.debouncing?.cancel()
        this.handleFieldTouch({
          fieldObject: this.fieldDefinition
        })
      }
    },
    handleKeydown(event) {
      if (event.keyCode === 13) {
        if (this.fieldDefinition.sendOnClick) {
          event.stopPropagation()
          this.sendOnClickHandler()
        } else {
          this.handleFieldTouchWrapper()
        }
      }
    },
    handleKeyUp() {
      if (this.fieldDefinition.sendWhenValid || this.fieldGroup?.sendWhenValid) {
        this.debouncing = this.debounceFieldTouch()
      }
    },
    pasteHandler(event) {
      if (this.fieldDefinition.preventPaste) {
        event.preventDefault()
      }
    },
    sendOnClickHandler() {
      this.handleFieldTouch({
        fieldObject: this.fieldDefinition
      })
    },
    joinModifiers(modifiersList = []) {
      return [modifiersList.join(' ')]
    },
    hasErrorInGroup() {
      const groupFields = this.fieldsInGroup(this.fieldDefinition.fieldGroup)
      return (
        this.errors[this.fieldDefinition.fieldGroup] &&
        groupFields[groupFields.length - 1]?.fieldKey === this.fieldDefinition.fieldKey
      )
    },
    getInputGroupSlots(createElement, slotName, inputParams) {
      switch (inputParams?.type) {
        case 'Text':
          return createElement('span', { slot: slotName }, inputParams.value)
        case 'Icon':
          return createElement(CycleIcon, { slot: slotName, props: { icon: inputParams.value } })
        default:
          return null
      }
    },
    getServiceErrorMessage() {
      const itemKey = this.hasErrorInGroup()
        ? this.fieldDefinition.fieldGroup
        : this.fieldDefinition.fieldKey

      return this.errors[itemKey]?.[0].friendlyMessage
    },
    composeNotifyIcons(createElement, inputParams = {}) {
      const hasNotify = this.fieldDefinition?.modifiers?.includes('notify')
      if (hasNotify) {
        const attachCloseEvent = inputParams.closable
          ? {
              on: {
                click: () => {
                  this.isNotifyClosed = true
                }
              }
            }
          : {}
        return createElement(CycleIcon, {
          props: { icon: inputParams?.icon },
          class: inputParams?.class,
          ...attachCloseEvent
        })
      }
    },
    debounceFieldTouch: debounce(function () {
      this.handleFieldTouchWrapper()
    }, 500)
  },
  render(createElement) {
    if (!this.isShown || !this.showWhenEmpty || !this.isGroupShown || this.isNotifyClosed) {
      return createElement()
    }

    return createElement(
      'div',
      {
        attrs: {
          class: this.classes,
          'data-test': this.fieldDefinition.key,
          'data-testid': this.fieldDefinition.id
        }
      },
      [
        this.fieldDefinition.title &&
          createElement(
            CycleHeading,
            {
              props: {
                weight: this.fieldDefinition.type === 'Confirmation' ? 2 : 4
              }
            },
            [this.fieldDefinition.title]
          ),
        this.fieldDefinition?.notify?.icon &&
          this.composeNotifyIcons(createElement, {
            icon: this.fieldDefinition?.notify?.icon,
            class: 'notify--icon',
            closable: false
          }),
        createElement(CycleHtml, {
          props: { html: this.fieldDefinition.intro }
        }),
        this.fieldDefinition?.notify?.closable &&
          this.composeNotifyIcons(createElement, {
            icon: 'cross',
            class: 'notify--close',
            closable: true
          }),
        this.composeComponent(createElement)
      ]
    )
  }
}
</script>
