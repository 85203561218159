import { lazyLoadDataLayer } from '../globals/gtm'
import { uuid4 } from './UUID'

const category = 'Direct Individual'

export const registerPurchase = (product, price = 0, id = uuid4()) => {
  lazyLoadDataLayer().push({
    event: 'ecommerce:purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id,
          affiliation: category,
          revenue: price
        },
        products: [
          {
            id,
            name: product,
            category,
            price,
            quantity: 1
          }
        ]
      }
    }
  })
}

export const registerCheckout = (product, step, price = 0, id) => {
  lazyLoadDataLayer().push({
    event: 'ecommerce:checkout',
    ecommerce: {
      checkout: {
        actionField: { step },
        products: [
          {
            name: product,
            id,
            price,
            category,
            quantity: 1
          }
        ]
      }
    }
  })
}
