//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CycleTextInput',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value.trim())
    }
  }
}
