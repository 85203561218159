<template>
  <div class="coreui">
    <slot name="jss-navigation" />
    <slot name="jss-header" />
    <main class="coreui__main">
      <slot name="jss-main" />
    </main>
    <footer class="coreui__footer">
      <slot name="jss-footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'CycleLayout'
}
</script>
