<template>
  <CycleCard>
    <template #header>
      <CycleHeading>
        <ProvidedText name="loginCredentials.header" />
      </CycleHeading>
    </template>
    <template #body>
      <CycleList modifiers="nomarker">
        <CycleListItem>
          <label>
            <ProvidedText name="loginCredentials.labels.username" />
          </label>
          <p>
            <ProvidedText name="loginCredentials.placeholders.username" />
          </p>
        </CycleListItem>
        <CycleListItem>
          <label>
            <ProvidedText name="loginCredentials.labels.password" />
          </label>
          <p>
            <ProvidedText name="loginCredentials.placeholders.password" />
          </p>
        </CycleListItem>
      </CycleList>
    </template>
  </CycleCard>
</template>

<script>
import { CycleCard } from '../../../card'
import { CycleHeading } from '../../../elements'
import { CycleList, CycleListItem } from '../../../cycle-list'
import ProvidedText from '../../Content/ProvidedText'

export default {
  name: 'LoginCredentials',
  components: {
    CycleCard,
    CycleHeading,
    CycleList,
    CycleListItem,
    ProvidedText
  }
}
</script>
