import window from './window'

const crypto =
  window.crypto ||
  window.msCrypto ||
  (() => {
    const nodeCrypto = require('crypto')
    nodeCrypto.getRandomValues = nodeCrypto.randomFillSync // Close enough
    return nodeCrypto
  })()
export default crypto
