import { InsuranceProduct } from '@/components/Modules/WSM/constants'
import { isEmpty } from 'ramda'

/**
 * Reduces a portfolio object to a { number => policy } object
 *
 * @param {Object} portfolioInfo
 * @param {String} [key] - The key on which to identify policies by
 * @returns {Object} - List of policies keyed by [key]
 */
export const portfolioReducer =
  (key = 'numberEncrypted') =>
  (portfolioInfo) => {
    if (!portfolioInfo) return {}
    if (portfolioInfo.portfolioInfo) return portfolioReducer(key)(portfolioInfo.portfolioInfo)

    const packages =
      portfolioInfo.packages?.reduce?.(
        (accumulator, currentValue) => [...accumulator, ...currentValue?.policies],
        []
      ) || []
    const policies = portfolioInfo.policies?.filter?.(({ product }) => product != null) || []

    return [...policies, ...packages].reduce(
      (acc, policy) => ({
        ...acc,
        [policy[key]]: policy
      }),
      {}
    )
  }

/**
 * Maps icon value in policy object to a css classname for applicable icon.
 * If icon value is not listed yet; maps to the default value: "shield".
 *
 * @param {Object} policy
 * @returns {Object} - mapped policy
 */
const mapIconName = (policies) =>
  policies?.map((policy) => ({ ...policy, icon: InsuranceProduct[policy.icon] || 'shield' })) || []

/**
 * Normalizes portfolioInfo; maps icon values in policies
 *
 * @param {Object} portfolioInfo
 * @returns {Object} - Normalized portfolioInfo
 */
export const normalizePortfolioInfo = (portfolioInfo) => {
  if (!portfolioInfo || isEmpty(portfolioInfo)) return {}
  if (portfolioInfo.portfolioInfo) return normalizePortfolioInfo(portfolioInfo.portfolioInfo)

  const mappedPolicies = mapIconName(portfolioInfo?.policies)
  const mappedPackages =
    portfolioInfo?.packages?.map((pack) => ({
      ...pack,
      policies: mapIconName(pack?.policies)
    })) || []
  return { portfolioInfo: { packages: mappedPackages, policies: mappedPolicies } }
}
