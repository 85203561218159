//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleCurrency } from '@/filters'
export default {
    name: 'CoveragePremiumPrice',
    components: {
        CycleHtml
    },
    filters: {
        CycleCurrency
    },
    props: {  
        price: {
            type: [Number, String],
            required: false
        },
        paymentTerm: {
            type: String,
            required: false,
            default: 'p/m'
        }
    }
}
