<template>
  <CycleCard centered>
    <template #header v-if="isReviewTitleVisible">
      <b class="review__title">{{ hyphenatedTitle }} </b>
    </template>
    <template #body>
      <p v-if="hasReviewDate">{{ reviewDate }}</p>
      <div class="rating" :style="{ [`--rating`]: ratingValue }">
        <span class="rating__score">{{ ratingScore }}</span>
      </div>
      <CycleHtml v-if="hasDescription" :html="description" />
    </template>
  </CycleCard>
</template>

<script>
import { CycleCard } from '@/components/card'
import { CycleHtml } from '@/components/base'
import { getHyphenatedContent } from '@/services/Hyphenator'
export default {
  name: 'Review',
  components: { CycleCard, CycleHtml },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    reviewDate: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    ratingValue() {
      return this.rating > 5 ? 5 : this.rating < 0 ? 0 : this.rating
    },
    ratingScore() {
      return `${2 * this.ratingValue}/10`
    },
    hasTitle() {
      return this.title.length > 0
    },
    hasReviewDate() {
      return this.reviewDate.length > 0
    },
    hasDescription() {
      return this.description.length > 0
    },
    hyphenatedTitle() {
      return this.hasTitle ? getHyphenatedContent(this.title) : ''
    },
    isReviewTitleVisible() {
      return this.hyphenatedTitle?.length > 0
    }
  }
}
</script>
