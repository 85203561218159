//
//
//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CyclePostalCodeInput',
  extends: BaseInput,
  data() {
    return {
      inputModeType: 'numeric'
    }
  },
  mounted() {
    this.setInputModeType(this.value)
  },
  methods: {
    setInputModeType(postal = null) {
      if (!postal || (postal?.length >= 0 && postal?.length <= 3)) {
        this.inputModeType = 'numeric'
      } else {
        this.inputModeType = 'text'
      }
    },
    updateValue(event) {
      const postalCode = event.target.value.trim()
      this.setInputModeType(postalCode)
      this.$emit('input', postalCode)
    }
  }
}
