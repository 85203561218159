import { pathOr } from 'ramda'

export const linkMixin = {
  props: {
    link: {
      type: Object,
      required: false
    }
  },
  computed: {
    href() {
      return pathOr('', ['link', 'href'], this)
    },
    /** @deprecated */
    title() {
      return pathOr('', ['link', 'title'], this)
    },
    text() {
      return pathOr('', ['link', 'text'], this)
    },
    target() {
      return pathOr('_self', ['link', 'target'], this)
    }
  }
}
