<script>
import { debounce } from 'lodash'
import { CrossWindowMessages } from '@/services/CrossWindowMessages'

export default {
  name: 'CycleIFrameSizeEmitterSitecore',
  props: {
    offset: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      observer: null
    }
  },
  created() {
    this.scrollParentToTop()
  },
  mounted() {
    this.attachEventHandlers()
    this.emitCurrentSize()
  },
  destroyed() {
    this.scrollParentToTop()
    this.detachEventHandlers()
  },
  methods: {
    attachEventHandlers() {
      window.addEventListener('resize', this.emitCurrentSize)
      document.addEventListener('transitionend', this.emitCurrentSize)
      this.observer = new MutationObserver(this.emitCurrentSize)
      this.observer.observe(this.$root.$el, {
        subtree: true,
        attributes: true,
        childList: true,
        characterData: true
      })
    },
    detachEventHandlers() {
      window.removeEventListener('resize', this.emitCurrentSize)
      document.removeEventListener('transitionend', this.emitCurrentSize)
      this.observer.disconnect()
    },
    emitCurrentSize() {
      this.emitCurrentSizeDebounced(this)
    },
    emitCurrentSizeDebounced: debounce(
      (vm) => {
        const bodyHeight = document.body.getBoundingClientRect().height
        const totalHeight = Math.floor(bodyHeight + vm.offset)
        CrossWindowMessages.emitHeight(totalHeight)
      },
      100,
      { maxWait: 200 }
    ),
    scrollParentToTop() {
      CrossWindowMessages.scrollTo({ top: 0 })
    }
  },
  render() {
    return this.$slots.default
  }
}
</script>
