//
//
//
//
//
//

import { CycleTextInput } from '@/components/elements'
import { CycleInputContainer } from '@/components/functional'
import { Address } from '../../models'
import data from './data/address'

export default {
  name: 'CycleAddress',
  components: { CycleInputContainer, CycleTextInput },
  props: {
    address: {
      type: Address,
      required: true
    }
  },
  data() {
    return {
      postalCodeLabel: data.textContent.postalCodeLabel
    }
  },
  created() {
    this.address.postalCode = data.content.address.postalCode
    this.address.houseNumber = data.content.address.houseNumber
    this.address.street = data.content.address.street
    this.address.city = data.content.address.town
  }
}
