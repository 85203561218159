//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { NavigationLink } from '@/models'
import { CycleContentBlock } from '../cycle-content-block'
import HeadingIcon from './HeadingIcon'
import { CycleHtml } from '../base'
import { CycleButtonLink } from '../cycle-link'

export default {
  name: 'EmptyTablePlaceholder',
  components: {
    CycleContentBlock,
    HeadingIcon,
    CycleHtml,
    CycleButtonLink
  },
  props: {
    noResultsText: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'folder-search'
    },
    overviewLink: {
      type: NavigationLink,
      required: false
    },
    hideButton: {
      type: Boolean,
      required: false
    }
  }
}
