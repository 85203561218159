<template>
  <CycleArticle :bodytext="bodytext">
    <ScPlaceholder name="jss-content" :rendering="rendering" />
  </CycleArticle>
</template>

<script>
import { CycleArticle } from '@aon/cycle'
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'

export default {
  name: 'ArticleSitecore',
  components: {
    ScPlaceholder: Placeholder,
    CycleArticle
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    bodytext() {
      return this.fields.bodytext?.value
    }
  }
}
</script>
