import { general } from './general'
import { cardetails } from './cardetails'
import { licenseholder } from './licenseholder'
import { payment } from './payment'
import { requester } from './requester'
import { driver } from './driver'

export function overview(params) {
  return [
    general(params),
    cardetails(params),
    driver(params),
    licenseholder(params),
    requester(params),
    payment(params)
  ]
}
