<template>
  <button :class="[bemClass, ctaIconClass]" v-on="$listeners" v-bind="$attrs">
    <slot />
  </button>
</template>

<script>
import { CycleBaseComponent } from '../../base'

export default {
  name: 'CycleButton',
  extends: CycleBaseComponent,
  props: {
    ctaIcon: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      bem: {
        defaultModifiers: 'primary',
        allowedModifiers: [
          'primary',
          'secondary',
          'tertiary',
          'login',
          'link',
          'cta',
          'approve',
          'paging',
          'ellipsis',
          'ellipsis-middle',
          'back',
          'next',
          'toggle',
          'pre-funnel',
          'modal-submit'
        ],
        block: 'btn'
      }
    }
  },
  computed: {
    ctaIconClass() {
      return this.ctaIcon
        ? {
            [`btn--cta-icon-${this.ctaIcon}`]: true
          }
        : ''
    }
  }
}
</script>
