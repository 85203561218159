<template>
  <ClickSimulator v-bind="componentData" />
</template>

<script>
import { ClickSimulator } from '@aon/cycle'
import { mapValues } from 'lodash'

export default {
  name: 'ClickSimulatorSitecore',
  components: {
    ClickSimulator
  },
  props: {
    fields: {
      type: Object,
      required: false
    }
  },
  computed: {
    componentData() {
      return {
        ...mapValues(this.fields, (item) => item.value ?? item)
      }
    }
  }
}
</script>
