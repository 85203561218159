export const prefillFields = [
  {
    value: 'profile/licensePlate',
    fieldKey: 'your-situation.setup.licenseplate'
  },
  {
    value: 'profile/prefilledBuildingTypeKey',
    fieldKey: 'your-situation.setup.contentsbuildingtype'
  }
]
