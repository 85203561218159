
import { Person } from '../../../models'
import { CycleDate } from '../../../filters'
import { intlMixin } from '../../../mixins'

export default {
  name: 'PersonDateOfBirth',
  mixins: [intlMixin],
  props: {
    person: {
      type: Person,
      required: true
    }
  },
  render(createElement) {
    return createElement('template', [CycleDate(this.person.dateOfBirth, { locale: this.locale })])
      .children[0]
  }
}
