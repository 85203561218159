<template>
  <CollectivityPicker
    :is-loading="loadingCollectivities > 0"
    :get-collectivities="fetchCollectivities"
    :collectivities="collectivities"
    :stay-on-page="stayOnPage"
  />
</template>

<script>
import { CycleCollectivityPicker } from '@aon/cycle'
import { mapValues } from 'lodash'
import { collectivitiesQuery } from '@/gql/queries'

export default {
  name: 'CycleCollectivityPickerSitecore',
  components: {
    CollectivityPicker: CycleCollectivityPicker
  },
  provide() {
    return { textContent: this.textContent }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    urlRegExp: new RegExp(`url="([^"]*)`, 'i'),
    collectivities: [],
    loadingCollectivities: 0,
    searchTerm: '',
    pageNumber: 1,
    skipQuery: true
  }),
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    areCollectivitiesIncluded() {
      return this.params?.includeCollectivities === '1'
    },
    areHealthPartnersIncluded() {
      return this.params?.includeHealthPartners === '1'
    },
    stayOnPage() {
      return this.params?.stayOnPage === '1'
    }
  },
  apollo: {
    collectivities: {
      prefetch: false,
      query: collectivitiesQuery,
      loadingKey: 'loadingCollectivities',
      variables() {
        return {
          inputModel: {
            collectivityName: this.areHealthPartnersIncluded
              ? this.searchTerm.replace(/\s+/g, '')
              : this.searchTerm,
            pageNumber: '1',
            numberOfLinesOnPage: '10',
            filterEmptyCollectivityTypes: this.filterEmptyCollectivityTypes,
            includeCollectivities: this.areCollectivitiesIncluded,
            includeHealthPartners: this.areHealthPartnersIncluded
          }
        }
      },
      skip() {
        return this.skipQuery
      },
      fetchPolicy: 'cache-and-network',
      update({ collectivities }) {
        const collectivityItems = collectivities?.collectivities ?? []
        return collectivityItems.map(this.mapCollectivityItem)
      }
    }
  },
  methods: {
    getPropertyRegExp(prop) {
      return new RegExp(`${prop}="([^"]*)`, 'i')
    },
    extractUrl(linkTag) {
      if (!this.urlRegExp.test(linkTag)) {
        return null
      }

      return this.urlRegExp.exec(linkTag)[1]
    },
    mapCollectivityItem(item) {
      if (item) {
        const { name, url, text: description, link } = item.collectivityType
        return {
          collectivityGuid: item.collectivityGuid,
          collectivityName: item.collectivityName,
          collectivityType: {
            name,
            description,
            url: this.extractUrl(url),
            link: link
          },
          deepLink: item.deepLink
        }
      }
      return false
    },
    fetchCollectivities(newSearchTerm) {
      if (!newSearchTerm || newSearchTerm.length < 3 || typeof newSearchTerm !== 'string') {
        this.skipQuery = true
        return
      }

      this.searchTerm = newSearchTerm
      this.skipQuery = false
    }
  }
}
</script>
