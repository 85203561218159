import {
  CycleTextInput,
  CycleNumberInput,
  CycleCheckboxInput,
  CycleSelect,
  MultiFileInput,
  CycleHiddenInput,
  CycleHtml,
  ToggleCheckbox,
  LoginContainer,
  CycleFormConfirmation,
  CycleLicensePlateInput,
  CycleTextarea,
  CyclePostalCodeInput,
  CycleButton,
  TotalPremium
} from '@aon/cycle'

import RapidFormContentField from '../fields/RapidFormContentField'
import RapidFormBannerContentField from '../fields/RapidFormBannerContentField'
import RapidFormSuggestionWidget from '../fields/RapidFormSuggestionWidget'

import RapidFormProductWrapper from '../wrappings/RapidFormProductWrapper'
import RapidFormCheckoutWrapper from '../wrappings/RapidFormCheckoutWrapper'
import RapidFormDateWrapper from '../wrappings/RapidFormDateWrapper'
import RapidFormCurrencyWrapper from '../wrappings/RapidFormCurrencyWrapper'
import RapidFormPortalWrapper from '../wrappings/RapidFormPortalWrapper'
import RapidFormRadioWrapper from '../wrappings/RapidFormRadioWrapper'
import RapidFormRadioBarWrapper from '../wrappings/RapidFormRadioBarWrapper'
import RapidFormInputRadioTileWrapper from '../wrappings/RapidFormInputRadioTileWrapper'

export function componentTypeMap(type, modifiers) {
  switch (type) {
    case 'Text':
      if (modifiers?.includes('licenseplate')) {
        return CycleLicensePlateInput
      } else if (modifiers?.includes('postalcode')) {
        return CyclePostalCodeInput
      } else {
        return CycleTextInput
      }
    case 'Number':
      return CycleNumberInput
    case 'Radio':
      return RapidFormRadioWrapper
    case 'RadioBar':
      return RapidFormRadioBarWrapper
    case 'RadioBox':
      return RapidFormInputRadioTileWrapper
    case 'Checkbox':
      return modifiers?.includes('checkout') ? RapidFormCheckoutWrapper : CycleCheckboxInput
    case 'Date':
      return RapidFormDateWrapper
    case 'Select':
      return CycleSelect
    case 'Hidden':
      return CycleHiddenInput
    case 'Html':
      return CycleHtml
    case 'Content':
      return modifiers?.includes('notify--banner')
        ? RapidFormBannerContentField
        : RapidFormContentField
    case 'BaseProducts':
      return RapidFormProductWrapper
    case 'CustomToggleCheckbox':
      return ToggleCheckbox
    case 'Login':
      return LoginContainer
    case 'Confirmation':
      return CycleFormConfirmation
    case 'Currency':
      return RapidFormCurrencyWrapper
    case 'MultiLineText':
      return CycleTextarea
    case 'PortalTarget':
      return RapidFormPortalWrapper
    case 'Upload':
      return MultiFileInput
    case 'Button':
      return CycleButton
    case 'TotalPremium':
      return TotalPremium
    case 'Suggestion':
      return RapidFormSuggestionWidget
    default:
      return type
  }
}
