<template>
  <div class="pagination" v-if="totalPages > 1">
    <p class="pagination__header">
      {{ totalNumberOfItems }} {{ paginationItemText }} {{ totalPages }}
      {{ paginationText }}
    </p>
    <nav class="pagination__nav">
      <button class="pagination__button" v-if="isPrevious" @click="previousClick()">
        <span class="icon icon--chevron-left"></span>
      </button>

      <button
        class="pagination__button"
        v-for="pageNumber in pagesToShow"
        :key="pageNumber"
        @click="pageClick(pageNumber)"
        :aria-current="isAriaCurrent(pageNumber)"
      >
        <span>{{ pageNumber }}</span>
      </button>

      <div v-if="isTotalPagesHigherThanFive" :class="elipsisClass" aria-hidden="true">
        <span>...</span>
      </div>

      <button class="pagination__button" v-if="isNext" @click="nextClick()">
        <span class="icon icon--chevron-right"></span>
      </button>
    </nav>
  </div>
</template>

<script>
import CycleButton from '../elements/CycleButton/CycleButton'
export default {
  name: 'CyclePagination',
  components: { CycleButton },
  props: {
    totalNumberOfItems: {
      type: Number,
      required: false,
      default: 0
    },
    numberOfItemsOnPage: {
      type: Number,
      required: false,
      default: 10
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    },
    paginationItemText: {
      type: String,
      required: false,
      default: 'items gevonden op'
    },
    paginationText: {
      type: String,
      required: false,
      default: "pagina's."
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalNumberOfItems / this.numberOfItemsOnPage)
    },
    isPrevious() {
      return this.isTotalPagesHigherThanFive && this.currentPage > 1
    },
    isNext() {
      return this.isTotalPagesHigherThanFive && this.currentPage < this.totalPages - 1
    },
    isTotalPagesHigherThanFive() {
      return this.totalPages > 5
    },
    moreThanFivePages() {
      if (this.currentPage === 1) {
        return this.possibleOptions[0]
      }
      if (this.currentPage <= this.totalPages - 2) {
        return this.possibleOptions[1]
      }
      return this.possibleOptions[2]
    },
    lessThanFivePages() {
      return [1, 2, 3, 4, 5].splice(0, this.totalPages)
    },
    possibleOptions() {
      return [
        [1, 2, this.totalPages],
        [1, this.currentPage],
        [1, this.totalPages - 1, this.totalPages]
      ]
    },
    pagesToShow() {
      return this.isTotalPagesHigherThanFive ? this.moreThanFivePages : this.lessThanFivePages
    },
    elipsisClass() {
      const className = 'pagination__button pagination__button--ellipsis'
      const middleClass = 'pagination__button--ellipsis-middle'
      return this.isElipsisMiddleClassEnabled ? `${className} ${middleClass}` : className
    },
    isElipsisMiddleClassEnabled() {
      return [1, this.totalPages - 1, this.totalPages].some((val) => val === this.currentPage)
    }
  },
  methods: {
    previousClick() {
      this.$emit('page-change', this.currentPage - 1)
    },
    nextClick() {
      this.$emit('page-change', this.currentPage + 1)
    },
    pageClick(pageNumber) {
      this.$emit('page-change', pageNumber)
    },
    isAriaCurrent(pageNumber) {
      return pageNumber === this.currentPage
    }
  }
}
</script>
