//
//
//
//

import { CycleHeading } from '../elements/CycleHeading'

export default {
  name: 'CyclePolicyDetailsBlock',
  components: { CycleHeading },
  props: {
    textContent: {
      type: Object,
      required: true
    },
    policyNumber: {
      type: String,
      required: true
    }
  }
}
