<template>
  <li
    class="breadcrumb__item"
    itemprop="itemListElement"
    itemscope
    itemtype="https://schema.org/ListItem"
    v-if="title"
  >
    <a itemprop="item" :href="path" :title="title" v-if="path">
      <span itemprop="name">{{ title }}</span>
    </a>
    <span itemprop="name" v-else>{{ title }}</span>
    <meta itemprop="position" :content="positionIndex + 1" />
  </li>
</template>

<script>
export default {
  name: 'BreadcrumbItem',
  props: {
    path: {
      type: String
    },
    title: {
      type: String
    },
    positionIndex: {
      type: Number
    }
  }
}
</script>
