<template>
  <picture :class="['picture', pictureModifier]">
    <PictureImg :source="image" />
  </picture>
</template>

<script>
export default {
  name: 'CycleImage',
  components: {
    PictureImg: {
      name: 'Img',
      props: {
        source: Object
      },
      render(createImage) {
        return (
          this.source.src &&
          createImage('img', {
            attrs: {
              src: new URL(this.source.src).href,
              width: +this.source.width || null,
              height: +this.source.height || null,
              title: this.source.title || null,
              alt: `${escape(this.source.alt)}`
            }
          })
        )
      }
    }
  },
  props: {
    image: {
      type: Object,
      required: false,
      default: () => ({})
    },
    modifier: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    pictureModifier() {
      return {
        [`picture--${this.modifier}`]: !!this.modifier
      }
    }
  }
}
</script>
