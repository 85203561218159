<script>
import FunnelQuestions from './FunnelQuestions'

export default {
  name: 'AgreementQuestions',
  functional: true,
  render(createElement, context) {
    return createElement(FunnelQuestions, {
      ...context.data,
      key: 'agreement',
      props: { ...context.props, nature: 'agreement' }
    })
  }
}
</script>
