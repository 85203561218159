<template>
  <FormContainer :on-submit="handleSubmit" store-namespace="funnelLiability">
    <template #header="{state}">
      <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
    </template>

    <LiabilityStepSetupChange :value="stepSetupData" @submit="handleSetup" />

    <LiabilityStepOffers :premium="availablePremium" />

    <LiabilityStepFinalQuestions :value="questions" @submit="commit(SET_FUNNEL_QUESTIONS, $event)">
      <FunnelQuestionsContainer
        :product-code="selectedProductCode"
        v-model="questions"
        :render-type="questionRenderType.Final"
      />
      <ServiceInformation :service-info="serviceInfo" />
    </LiabilityStepFinalQuestions>

    <LiabilityStepOverviewChange v-bind="fullState">
      <FunnelQuestionsContainer
        :product-code="selectedProductCode"
        v-model="questions"
        :render-type="questionRenderType.Agreement"
      />
    </LiabilityStepOverviewChange>

    <template #confirmation>
      <CycleFunnelConfirmation :result="submitResult" />
    </template>
  </FormContainer>
</template>

<script>
import { merge } from 'lodash'
import { CycleAddress } from '@/components/cycle-address'
import { CycleBusstop } from '@/components/cycle-busstop'
import { CycleFunnelConfirmation } from '@/components/functional/forms'
import { FormContainer, ServiceInformation } from '@/components/organisms'

import { funnelMixinFactory } from '@/mixins/funnel'
import { PolicyAction } from '@/constants'
import { Address } from '@/models'
import { serviceNames, FunnelService } from '@/services/data'
import { FunnelQuestionsContainer } from '@/modules/FunnelQuestions'
import { QuestionRenderType } from '@/modules/FunnelQuestions/models'

import { ProductPremiumService, writePolicyMapper, PolicyDetailsService } from '../services'
import { default as storeModule } from '../store'
import { SET_FUNNEL_QUESTIONS, SET_POLICY_NUMBER } from '../store/mutation-types'

import LiabilityStepSetupChange from './Steps/LiabilityStepSetupChange'
import LiabilityStepOffers from './Steps/LiabilityStepOffers'
import LiabilityStepFinalQuestions from './Steps/LiabilityStepFinalQuestions'
import LiabilityStepOverviewChange from './Steps/LiabilityStepOverviewChange'
import { textProps, textMap } from './textContent'

export default {
  name: 'FunnelLiabilityChangeSitecore',
  components: {
    CycleAddress,
    CycleBusstop,
    FormContainer,
    CycleFunnelConfirmation,
    FunnelQuestionsContainer,
    LiabilityStepOffers,
    LiabilityStepSetupChange,
    LiabilityStepFinalQuestions,
    LiabilityStepOverviewChange,
    ServiceInformation
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelLiability',
      storeModule,
      actionsMapping: ['handleSetup', 'submitFunnel', 'fetchPolicyDetails'],
      gettersMapping: [
        'availablePremium',
        'fullState',
        'isPremiumAvailable',
        'selectedProductCode',
        'stepSetupData',
        'submitResult'
      ],
      mutationsMapping: { SET_FUNNEL_QUESTIONS, SET_POLICY_NUMBER },
      services: [
        {
          serviceName: serviceNames.ProductPremiumService,
          serviceDefinition: ProductPremiumService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        },
        {
          serviceName: serviceNames.PolicyDetailsService,
          serviceDefinition: PolicyDetailsService
        }
      ]
    })
  ],
  props: {
    ...textProps,
    serviceInfo: {
      type: Array,
      required: false
    },
    textContent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      address: new Address(),
      formData: {},
      questions: {}
    }
  },
  computed: {
    steps() {
      return [this.page1title, this.page2title, this.page5title, this.page6title]
    },
    questionRenderType() {
      return QuestionRenderType
    }
  },
  mounted() {
    this.commit(SET_POLICY_NUMBER, this.$route.query.insurance)
    this.fetchPolicyDetails()
  },
  provide() {
    return {
      formData: this.formData, // legacy support?
      textContent: merge({}, textMap(this), this.textContent)
    }
  },
  methods: {
    async handleSubmit() {
      await this.submitFunnel(writePolicyMapper(PolicyAction.Change))
    }
  }
}
</script>
