/**
 * All available icons in alphabetical order
 */
export const icons = [
  'aansprakelijkheid',
  'andere-producten',
  'apartment',
  'arbeidsongeschiktheid',
  'at',
  'auto',
  'balance',
  'bandages',
  'bell',
  'bell-notification',
  'bike',
  'box',
  'bromfiets',
  'bruiloft',
  'bubble-dots',
  'bubbles',
  'calendar',
  'calendar-check',
  'camper',
  'camper-rental',
  'cancellation',
  'caravan',
  'caret-down',
  'check',
  'check-circle',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'city',
  'claims',
  'clock3',
  'cloud-hailstones',
  'cog',
  'coin-euro',
  'cross',
  'damage',
  'death',
  'delivery-van',
  'diving',
  'document',
  'dog',
  'download',
  'e-bike',
  'earth',
  'earth-europe',
  'enter',
  'entrepreneurship',
  'envelope',
  'envelope-open',
  'envelope-questionmark',
  'error-validation',
  'error-notification',
  'exclamation-triangle',
  'exit',
  'exit-right',
  'eye',
  'eye-slash',
  'expat',
  'facebook',
  'file',
  'file-image',
  'file-stats',
  'file-pdf',
  'file-zip',
  'first-aid',
  'flame',
  'folder-search',
  'gehoorapparaat',
  'gift',
  'globetrotter',
  'golf',
  'graduation',
  'graph',
  'headset',
  'heart',
  'hexagon',
  'hourglass',
  'hunting-equipment',
  'hunting-liability',
  'inboedel',
  'inbox',
  'info-circle',
  'income',
  'information-validation',
  'laptop-phone',
  'launch',
  'license2',
  'lifebuoy',
  'link21',
  'linkedin',
  'lock',
  'map',
  'medal',
  'menu',
  'microcar',
  'microscope',
  'minus',
  'mortgages',
  'motor',
  'my-money',
  'netherlands',
  'new-insurance',
  'oldtimer',
  'ongevallen',
  'outbox',
  'overview',
  'paperclip',
  'payments',
  'pen',
  'pencil',
  'pencil-line',
  'pension',
  'phone',
  'phone-wave',
  'pills',
  'plug',
  'plus',
  'positive-validation',
  'profile',
  'pulse',
  'question',
  'receipt-euro',
  'rechtsbijstand',
  'recreatiegoederen',
  'recreationalhome',
  'reis',
  'relation-and-children',
  'reply',
  'rocket',
  'save',
  'schade',
  'search',
  'shield',
  'shield-check',
  'snow2',
  'speed-slow',
  'speedpedelec',
  'spinner',
  'stairs',
  'status-confirmed',
  'status-error',
  'status-in-progress',
  'status-on-hold',
  'status-pending',
  'students',
  'syringe',
  'tab',
  'tag',
  'thermometer',
  'thumbs-up',
  'thumbs-up-solid',
  'times',
  'trailer',
  'trash',
  'twitter',
  'unlock',
  'user',
  'users',
  'users-plus',
  'users2',
  'user-notification',
  'vaartuig',
  'vip',
  'vitaal-voordeel',
  'warning',
  'warning-notification',
  'whatsapp',
  'woonhuis',
  'x-twitter',
  'zorg'
]

export default icons
