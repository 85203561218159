<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :prev-button-text="textContent.liability.steps.finalQuestions.labels.prev"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.finalQuestions.header" />
      </CycleHeading>
    </template>

    <slot />
    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="liability.steps.finalQuestions.labels.next" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHeading } from '@/components/elements'
import { CycleFormPage } from '@/components/functional/forms'
import { ProvidedText } from '@/components/organisms'

export default {
  inject: ['textContent'],
  components: {
    CycleFormPage,
    CycleHeading,
    ProvidedText
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
</script>
