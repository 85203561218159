<template>
  <div id="root">
    <ContextView :visible="contextViewVisible" />
    <router-view v-if="!languageIsChanging" key="$route.fullPath" />
  </div>
</template>

<script>
import ContextView from './ContextView'
import { showLogicalError } from '@/plugins/ErrorHandling'
import { phrases } from '@/constants/Dictionary'

export default {
  name: 'AppRoot',
  components: {
    ContextView
  },
  inject: ['dictionaryService'],
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      toggleContextView: this.toggleContextView
    }
  },
  data() {
    return {
      languageIsChanging: false,
      contextViewVisible: false
    }
  },
  errorCaptured(err, vm, info) {
    console.warn('Error rendering component: ', vm, err, 'additional info', info)

    if (!this.$isServer) {
      return true
    }

    const friendlyMessage =
      this?.dictionaryService?.phrases?.[phrases.errorMessagesComponentsFriendlyMessage.key]

    showLogicalError(err, vm, vm, friendlyMessage)
    return false
  },
  methods: {
    toggleContextView() {
      this.contextViewVisible = !this.contextViewVisible
    }
  }
}
</script>
