<template>
  <CycleForm :on-submit="formSubmitAction" ref="form" v-on="$listeners">
    <template #header="{ state }">
      <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
      <slot name="collectivity-picker" v-if="state.index === 0" />
    </template>

    <CycleFormPage :heading="steps[0]" :has-prev-button="false" :has-next-button="false">
      <CycleCard>
        <CycleHtml :html="textContent.introText" />
      </CycleCard>

      <CycleFormSet>
        <CycleAdviceBlock
          @chosen="chooseInsurerAction"
          :premium="premiumInformation['Aon-LegalAid'].premium"
        >
          <template slot="advice-description">
            {{ textContent.premiumTaxesExText }}
            <CycleTooltip>
              <template slot="tooltip-heading">
                {{ textContent.premiumTaxesHeader }}
              </template>
              <template slot="tooltip-content">
                <CycleHtml :html="textContent.premiumTaxesTooltip" />
              </template>
            </CycleTooltip>
          </template>
          <template slot="advice-usps">
            <CycleHtml :html="premiumInformation['Aon-LegalAid'].productUSP" />
          </template>
          <template slot="advice-button">
            {{ textContent.page1NextButton }}
          </template>
        </CycleAdviceBlock>
      </CycleFormSet>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[1]"
      :next-button-text="textContent.page2NextButton"
      :prev-button-text="textContent.page2PrevButton"
      modifier="checkout"
    >
      <CycleCheckout :heading="textContent.chosenCoverages" hide-checkout-label>
        <CycleCheckoutItem
          slot="checkout-item"
          :label="textContent.defaultCoverage"
          :tooltip="textContent.defaultCoverageTooltip"
          :price="premiumInformation['Aon-LegalAid'].premium"
          selected
        />
      </CycleCheckout>
      <CycleCheckout
        :heading="textContent.optionalCoverages"
        :amount="premiumInformation['Aon-LegalAid'].totalAmount"
        :amount-description="textContent.paymenttermMonth"
        :amount-subtext="textContent.premiumTaxesText"
      >
        <template #checkout-item>
          <CycleCheckoutItem
            v-model="formData.coverages"
            value="traffic"
            :label="textContent.traffic"
            :tooltip="textContent.trafficTooltip"
            :price="premiumInformation['Aon-LegalAid'].coverages.traffic"
          />
          <CycleCheckoutItem
            v-model="formData.coverages"
            value="income"
            :label="textContent.income"
            :tooltip="textContent.incomeTooltip"
            :price="premiumInformation['Aon-LegalAid'].coverages.income"
          />
          <CycleCheckoutItem
            v-model="formData.coverages"
            value="finance"
            :label="textContent.finance"
            :tooltip="textContent.financeTooltip"
            :price="premiumInformation['Aon-LegalAid'].coverages.taxAndCapital"
          />
          <CycleCheckoutItem
            v-model="formData.coverages"
            value="mediation"
            :label="textContent.mediation"
            :tooltip="textContent.mediationTooltip"
            :price="premiumInformation['Aon-LegalAid'].coverages.divorceMediation"
          />
        </template>
      </CycleCheckout>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[2]"
      :next-button-text="textContent.page3NextButton"
      :prev-button-text="textContent.page3PrevButton"
    >
      <CyclePersonalData :text-content="textContent" />
      <CContainer>
        <template #label>{{ textContent.mailinglistLabel }}</template>
        <CCheckbox v-model="formData.mailinglist" value="y" :label="textContent.mailinglistText" />
      </CContainer>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[3]"
      :next-button-text="textContent.page4NextButton"
      :prev-button-text="textContent.page4PrevButton"
    >
      <CycleFormSet :heading="textContent.startdateTitle">
        <CycleHtml :html="textContent.startdateText" />
        <CContainer :validation-label="textContent.startdateTitle">
          <CDate v-model="formData.startdate" required :min="minStartDate" :max="maxStartDate" />
          <template #error-min>
            {{ phrase('error-messages-funnels-002') }}
          </template>
          <template #error-max>
            {{ phrase('error-messages-funnels-004') }}
          </template>
        </CContainer>
      </CycleFormSet>
      <CycleFormSet :heading="textContent.cancelTitle">
        <CycleHtml :html="textContent.cancelText" />
      </CycleFormSet>
      <CycleFormSet :heading="textContent.paymenttypeTitle">
        <CycleHtml :html="textContent.paymenttypeText" />
        <CContainer>
          <template slot="label">
            {{ textContent.ibanLabel }}
            <CycleTooltip v-if="textContent.ibanTooltip">
              <template slot="tooltip-heading">
                {{ textContent.ibanLabel }}
              </template>
              <template slot="tooltip-content">
                <CycleHtml :html="textContent.ibanTooltip" />
              </template>
            </CycleTooltip>
          </template>
          <CText
            v-model="formData.iban"
            required
            iban-dutch
            :placeholder="textContent.ibanPlaceholder"
            autocomplete="off"
          />
        </CContainer>
        <CContainer>
          <template slot="label">{{ paymentTerm }}</template>
          {{ premiumInformation['Aon-LegalAid'].totalAmount | CycleCurrency({ locale }) }}
        </CContainer>
        <CycleHtml :html="textContent.existingCustomerText" />
        <CycleHtml :html="textContent.premiumInfoText" />
        <CycleTooltip>
          <template slot="tooltip-heading">
            {{ textContent.premiumTaxesHeader }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.premiumTaxesTooltip" />
          </template>
        </CycleTooltip>
      </CycleFormSet>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[4]"
      :next-button-text="textContent.page5NextButton"
      :prev-button-text="textContent.page5PrevButton"
    >
      <CycleFinalQuestions :final-questions="finalQuestions" />
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[5]"
      :next-button-text="textContent.page6NextButton"
      :prev-button-text="textContent.page6PrevButton"
    >
      <CycleHtml :html="textContent.overviewText" />
      <CycleFormSet :heading="textContent.generalTitle">
        <dl>
          <dt>{{ textContent.defaultCoverage }}</dt>
          <dd>
            {{ premiumInformation['Aon-LegalAid'].premium | CycleCurrency({ locale }) }}
          </dd>
          <template v-if="formData.coverages.includes('traffic')">
            <dt>{{ textContent.traffic }}</dt>
            <dd>
              {{ premiumInformation['Aon-LegalAid'].coverages.traffic | CycleCurrency({ locale }) }}
            </dd>
          </template>
          <template v-if="formData.coverages.includes('income')">
            <dt>{{ textContent.income }}</dt>
            <dd>
              {{ premiumInformation['Aon-LegalAid'].coverages.income | CycleCurrency({ locale }) }}
            </dd>
          </template>
          <template v-if="formData.coverages.includes('finance')">
            <dt>{{ textContent.finance }}</dt>
            <dd>
              {{
                premiumInformation['Aon-LegalAid'].coverages.taxAndCapital
                  | CycleCurrency({ locale })
              }}
            </dd>
          </template>
          <template v-if="formData.coverages.includes('mediation')">
            <dt>{{ textContent.mediation }}</dt>
            <dd>
              {{
                premiumInformation['Aon-LegalAid'].coverages.divorceMediation
                  | CycleCurrency({ locale })
              }}
            </dd>
          </template>
          <dt>{{ textContent.paymenttermMonth }}</dt>
          <dd>
            {{ premiumInformation['Aon-LegalAid'].totalAmount | CycleCurrency({ locale }) }}
          </dd>
        </dl>
      </CycleFormSet>
      <hr />
      <CycleFormSet :heading="steps[2]">
        <dl>
          <dt>{{ textContent.salutationLabel }}</dt>
          <dd>
            {{ formData.salutation == 'V' ? 'Mevrouw' : 'Meneer' }}
          </dd>
          <dt>{{ textContent.initialsLabel }}</dt>
          <dd>
            {{ formData.initials }}
          </dd>
          <template v-if="formData.preposition && formData.preposition.trim()">
            <dt>{{ textContent.prepositionLabel }}</dt>
            <dd>
              {{ formData.preposition }}
            </dd>
          </template>
          <dt>{{ textContent.lastnameLabel }}</dt>
          <dd>
            {{ formData.lastname }}
          </dd>
          <dt>{{ textContent.birthdateLabel }}</dt>
          <dd>
            {{ formData.birthdate | CycleDate({ locale }) }}
          </dd>
          <dt>{{ textContent.zipcodeLabel }}</dt>
          <dd>
            {{ formData.zipcode }}
          </dd>
          <dt>{{ textContent.housenumberLabel }}</dt>
          <dd>
            {{ [formData.housenumber, formData.housenumberAddition].join(' ').trim() }}
          </dd>
          <dt>{{ textContent.streetLabel }}</dt>
          <dd>
            {{ formData.street }}
          </dd>
          <dt>{{ textContent.cityLabel }}</dt>
          <dd>
            {{ formData.city }}
          </dd>
          <dt>{{ textContent.emailaddressLabel }}</dt>
          <dd>
            {{ formData.emailaddress }}
          </dd>
          <dt>{{ textContent.mailinglistLabel }}</dt>
          <dd>
            {{ formData.mailinglist.includes('y') ? 'Ja' : 'Nee' }}
          </dd>
          <dt>{{ textContent.phonenumberLabel }}</dt>
          <dd>
            {{ formData.phonenumber }}
          </dd>
        </dl>
      </CycleFormSet>
      <hr />
      <CycleFormSet :heading="textContent.paymenttypeTitle">
        <dl>
          <dt>{{ textContent.startdateTitle }}</dt>
          <dd>
            {{ formData.startdate | CycleDate({ locale }) }}
          </dd>
          <dt>{{ textContent.ibanLabel }}</dt>
          <dd>
            {{ formData.iban }}
          </dd>
          <dt>{{ textContent.paymenttermLabel }}</dt>
          <dd>
            {{ formData.paymentterm == 1 ? 'Maand' : 'Jaar' }}
          </dd>
          <dt>{{ textContent.paymenttypeLabel }}</dt>
          <dd>
            {{ formData.paymenttype == 'VM' ? 'Automatische Incasso' : '' }}
          </dd>
        </dl>
      </CycleFormSet>
      <hr />
      <template v-for="(questionText, i) of agreementQuestions">
        <CycleHtml :html="questionText" :key="i" />
      </template>
    </CycleFormPage>
    <template #confirmation>
      <slot name="confirmation">No confirmation is set</slot>
    </template>
  </CycleForm>
</template>

<script>
import CycleForm from './../../forms/CycleForm/CycleForm'
import CycleFormSet from './../../forms/CycleFormSet/CycleFormSet'
import CycleFormPage from './../../forms/CycleFormPage/CycleFormPage'
import CycleInputContainer from './../../forms/CycleInputContainer/CycleInputContainer'

import {
  CycleTextInput,
  CycleRadioInput,
  CycleDateInput,
  CycleButton,
  CycleCheckboxInput
} from '../../../elements'
import CycleConfirmation from '../../../cycle-confirmation/CycleConfirmation'
import { CycleFinalQuestions, CyclePersonalData } from '../shared'
import { CycleList, CycleListItem } from '../../../cycle-list'
import CycleCard from '../../../card/CycleCard'
import { CycleCheckout, CycleCheckoutItem } from '../../CycleCheckout'
import CycleAdviceBlock from '../../../cycle-advice-block/CycleAdviceBlock'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'
import CycleBusstop from '../../../cycle-busstop/CycleBusstop'
import { CycleHtml } from '../../../base'
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
import { debounce } from 'lodash'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import { CycleCurrency } from '../../../../filters/cycle-currency/cycle-currency'
import { CycleDate } from '../../../../filters/cycle-date/cycle-date'
import { intlMixin, dictionaryMixin } from '../../../../mixins'

export default {
  name: 'CycleFunnelLegalAid',
  filters: {
    CycleCurrency,
    CycleDate
  },
  components: {
    CycleForm,
    CycleFormSet,
    CycleFormPage,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CCheckbox: CycleCheckboxInput,
    CDate: CycleDateInput,
    CycleFinalQuestions,
    CyclePersonalData,
    CycleButton,
    CycleCard,
    CycleList,
    CycleListItem,
    CycleConfirmation,
    CycleAdviceBlock,
    CycleTooltip,
    CycleBusstop,
    CycleHtml,
    CycleCheckout,
    CycleCheckoutItem,
    CycleHeading
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    getPremiumsAction: { type: Function, required: false },
    formSubmitAction: { type: Function, required: false },
    chooseInsurerAction: { type: Function, required: false },
    premiumInformation: { type: Object, required: false },
    finalQuestions: { type: Object, required: false }
  },
  computed: {
    steps() {
      return [
        this.textContent.page1title,
        this.textContent.page2title,
        this.textContent.page3title,
        this.textContent.page4title,
        this.textContent.page5title,
        this.textContent.page6title
      ]
    },
    coverages() {
      return this.formData.coverages
    },
    paymentTerm() {
      if (this.formData.paymentterm !== '12') {
        return this.textContent.paymenttermMonth
      }
      return this.textContent.paymenttermYear
    },
    agreementQuestions() {
      const { agreement = [] } = this.finalQuestions
      return agreement.reduce(
        (acc, q) => (q.type === 'CheckBox' ? [...acc, q.description] : acc),
        []
      )
    },
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 90), 'yyyy-MM-dd')
    }
  },
  watch: {
    coverages: debounce(function () {
      this.getPremiumsAction(false)
    }, 1000)
  },
  mounted() {
    this.getPremiumsAction(true)
  }
}
</script>
