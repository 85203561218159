//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dictionaryMixin } from '@/mixins'
export default {
  name: 'CycleBusstop',
  mixins: [dictionaryMixin],
  props: {
    steps: {
      type: [Array, String],
      required: false,
      default: () => []
    },
    currentStep: {
      type: Number,
      required: false,
      default: () => 0
    },
    stopLabel: {
      type: String,
      required: false,
      default: () => 'Stap'
    }
  },
  data() {
    return {
      internalCurrentStep: 0
    }
  },
  computed: {
    computedSteps() {
      return this.steps.map(
        (step, index) => ({
          text: step,
          ariaCurrent: index === this.internalCurrentStep ? 'step' : null
        }),
        this
      )
    }
  },
  watch: {
    currentStep(step) {
      this.internalCurrentStep = step
    }
  },
  mounted() {
    this.internalCurrentStep = this.currentStep
  },
  methods: {
    selectCurrentStep(index) {
      this.$emit('navigation', index)
      this.internalCurrentStep = index
    },
    getStepText(index) {
      return `${this.phrase('navigation.step', 'Stap')} ${index + 1}`
    }
  }
}
