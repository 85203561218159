//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
import { pathOr } from 'ramda'
import { logError } from '../../../../services/Logging'
import { File } from '../../../../models'
import { dictionaryMixin } from '../../../../mixins'

export default {
  name: 'CycleFileInput',
  extends: BaseInput,
  mixins: [dictionaryMixin],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: File,
      required: false
    },
    btnTertiary: {
      type: Boolean,
      default: false,
      required: false
    },
    fileUploadLabel: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      file: undefined,
      isTertiary: this.btnTertiary
    }
  },
  computed: {
    disabled() {
      return this.$attrs.disabled || (this.file && this.file.loading)
    },
    fileInput() {
      return pathOr(undefined, ['$refs', 'file'], this)
    }
  },
  watch: {
    value(val) {
      if (!this.fileInput) return
      if (val) {
        // set input[file] contents
        this.isTertiary = true
        try {
          this.fileInput.files = val.raw
        } catch (e) {
          logError(e)
        }
        this.file = val
      } else {
        // clear input[file] contents
        this.isTertiary = this.btnTertiary
        this.resetFileInput()
      }
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.initFile(files)

      const fileReader = new FileReader()
      fileReader.onload = this.setFileSource
      fileReader.readAsDataURL(this.file.raw[0])
    },
    initFile(fileList) {
      this.file = new File(fileList[0].name, fileList, true)
      this.updateModel()
    },
    setFileSource(e) {
      const [meta, source] = e.target.result.split(',')
      const [mimetype, encoding] = meta.substring(5).split(';') // trim 'data:'
      this.file.source = source
      this.file.mimetype = mimetype
      this.file.encoding = encoding
      this.file.loading = false
      this.updateModel()
    },
    resetFileInput() {
      this.file = undefined
      this.fileInput.value = ''
      this.fileInput.files = undefined
      this.updateModel()
      this.$emit('file-deleted')
    },
    updateModel() {
      this.$emit('change', this.file)
    }
  }
}
