//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleUidMixin } from '@/mixins'
import NavigationSubGroup from './NavigationSubGroup'
export default {
  name: 'NavigationGroup',
  components: {
    NavigationSubGroup
  },
  mixins: [CycleUidMixin],

  props: {
    items: {
      type: Array,
      default: () => []
    },
    groupLink: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasSubGroups() {
      return this.items.length > 0
    },
    hasGroupLink() {
      return !!this.groupLink?.value?.href
    },
    groupLinkHref() {
      return this.groupLink?.value?.href
    },
    groupLinkTitle() {
      return this.groupLink?.value?.title ?? this.groupLink?.text
    }
  },
  methods: {
    getSubGroupHeader(item) {
      return item?.fields?.SubgroupHeader?.value || ''
    }
  }
}
