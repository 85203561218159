<template>
  <CycleFooter
    :menus="menuItems"
    :terms="termItems"
    :body-text="fields.bodytext | ScString"
    :logo="logo"
  />
</template>

<script>
import { CycleFooter } from '@aon/cycle'

export default {
  name: 'CycleFooterSitecore',
  components: {
    CycleFooter
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    menuItems() {
      if (this.fields.menus == null || this.fields.menus.length === 0) {
        return []
      }
      return this.fields.menus.map((menuItem) => {
        const id = menuItem.id
        const title = menuItem.fields.Title?.value
        const items = menuItem.fields.items.map((child) => {
          const id = child.id
          const title = child.fields.Title?.value
          const url = child.fields.Link?.value?.href
          const target = child.fields.Link?.value?.target
          const className = child.fields.Link?.value?.className

          return { id, title, url, target, className }
        })

        return { id, title, items }
      })
    },
    termItems() {
      if (this.fields.terms == null) {
        return []
      }
      return this.fields.terms?.map((item) => {
        const id = item.id
        const title = item.fields?.Title?.value
        const url = item.fields?.Link?.value?.href
        const target = item.fields?.Link?.value?.target || null
        const className = item.fields?.Link?.value?.class || null

        return { id, title, url, target, className }
      })
    },
    logo() {
      return this.fields?.logo?.value
    }
  }
}
</script>
