//
//
//
//

import cycleGtm from '../../cycle-gtm/CycleGtm'

export default {
  name: 'CycleGtmSitecore',
  components: {
    cycleGtm
  },
  props: {
    gtmcode: {
      type: String,
      required: false
    }
  }
}
