//
//
//
//
//
//
//
//
//
//
//
//

import CycleSection from '../cycle-section/CycleSection'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import CycleSubGrid from '../cycle-section/CycleSubGrid'
export default {
  name: 'StoryBookCycleIframe',
  components: { CycleSubGrid, CycleHeading, CycleSection }
}
