<template>
  <CycleForm :on-submit="formSubmitAction" ref="form" v-on="$listeners">
    <template #header="{ state }">
      <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
      <slot name="collectivity-picker" v-if="state.index === 0" />
    </template>
    <CycleFormPage
      :heading="steps[0]"
      :has-prev-button="false"
      :has-next-button="hasVehicleInformation"
      :next-button-text="textContent.page1NextButton"
      :on-submit="handleFirstStep"
    >
      <template v-if="!isValidVehicle">
        <CycleFormConfirmation status="rejected">
          <CycleHtml :html="isValidVehicleMessage" />
        </CycleFormConfirmation>
        <template slot="technicalError">
          <!-- hide technical error if policy is rejected -->
          &nbsp;
        </template>
      </template>
      <CycleFormSet :heading="textContent.licenseplateTitle">
        <CContainer
          :label="textContent.licenseplateLabel"
          :tooltip="textContent.licenseplateTooltip"
        >
          <CLicenseplate v-model.trim="formData.licenseplate" required />
        </CContainer>
        <CContainer nolabel>
          <CycleButton
            @click.prevent="lookupAction"
            :disabled="!licensePlateLengthOk"
            v-testable="'licenseplateButton'"
          >
            {{ textContent.licenseplateButton }}
          </CycleButton>
        </CContainer>
      </CycleFormSet>
      <CycleFormSet
        :heading="textContent.cardetailsTitle"
        v-if="hasVehicleInformation"
        class="form__set--card"
      >
        <dl class="car-details">
          <div class="fieldset__columns">
            <div class="fieldset__column">
              <dt class="car-details__title">Uw auto</dt>
              <dd>
                {{ vehicleInformation.brand }} {{ vehicleInformation.model }}
                <br />
                {{ vehicleInformation.version }}
              </dd>
            </div>
            <div class="fieldset__column">
              <dt class="car-details__title">Bouwjaar</dt>
              <dd>
                {{ vehicleInformation.constructionYear }}
              </dd>
            </div>
            <div class="fieldset__column">
              <dt class="car-details__title">
                {{ textContent.catalogueLabel }}
                <CycleTooltip>
                  <template slot="tooltip-heading">
                    {{ textContent.catalogueLabel }}
                  </template>
                  <template slot="tooltip-content">
                    <CycleHtml :html="textContent.catalogueTooltip" />
                  </template>
                </CycleTooltip>
              </dt>
              <dd>
                {{ vehicleInformation.listPriceIncludingVAT | CycleCurrency({ locale }) }}
              </dd>
            </div>
          </div>
        </dl>
      </CycleFormSet>

      <CycleFormSet :heading="textContent.driverTitle">
        <CContainer :label="textContent.birthdateLabel" :tooltip="textContent.birthdateTooltip">
          <CDate v-model="formData.birthdate" required :max="minAge" :min="maxAge" />
          <template #error-max>
            {{ phrase('error-messages-funnels-005') }}
          </template>
          <template #error-min>
            {{ phrase('error-messages-funnels-007') }}
          </template>
        </CContainer>
        <CContainer :label="textContent.zipcodeLabel" :tooltip="textContent.zipcodeTooltip">
          <CText
            v-model="formData.zipcode"
            required
            postcode
            :placeholder="textContent.zipcodePlaceholder"
          />
        </CContainer>
        <CContainer
          :label="textContent.claimfreeyearsLabel"
          :tooltip="textContent.claimfreeyearsTooltip"
        >
          <CSelect v-model="formData.claimfreeyears" required>
            <COption slot="option" disabled v-text="phrase('generic-select-your-choice')" />
            <COption
              slot="option"
              v-for="item in claimFreeYears"
              :key="item.key"
              :value="item.key"
              v-text="item.value"
            />
          </CSelect>
        </CContainer>
        <CContainer
          :label="textContent.distanceperyearLabel"
          :tooltip="textContent.distanceperyearTooltip"
        >
          <CSelect v-model="formData.distanceperyear" required>
            <COption slot="option" disabled v-text="phrase('generic-select-your-choice')" />
            <COption
              slot="option"
              v-for="item in distancePerYear"
              :key="item.key"
              :value="item.key"
              v-text="item.value"
            />
          </CSelect>
        </CContainer>
      </CycleFormSet>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[1]"
      :has-next-button="false"
      :prev-button-text="textContent.page2PrevButton"
    >
      <CCompare v-if="premiumInformation.length">
        <CCoverage
          v-for="coverage in premiumInformation"
          :key="`coverage_${coverage.configurationId}`"
          :title="coverage.texts.title"
          :tooltip="coverage.texts.tooltip"
          :list="coverage.productUSPs"
          @click.prevent="setCoverage(coverage.configurationId)"
          :link="coverage.isInsurable ? textContent.page2NextButton : null"
          :price="coverage.isInsurable ? coverage.price : null"
          :is-highlighted="coverage.isHighlighted"
          :disabled="!coverage.isInsurable"
          :highlighted-coverage-toggle-open-text="textContent.highlightedCoverageToggleOpenText"
          :highlighted-coverage-toggle-close-text="textContent.highlightedCoverageToggleCloseText"
        />
      </CCompare>
      <template v-else>
        <NotificationBar notification-type="warning">
          <CycleHtml :html="phrase('funnel-error-premiumcalculation')" />
        </NotificationBar>
      </template>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[2]"
      :next-button-text="textContent.page3NextButton"
      :prev-button-text="textContent.page3PrevButton"
      modifier="checkout"
    >
      <template v-if="optionalCoverageInformationSet">
        <CCheckout :heading="textContent.chosenCoverages" hide-checkout-label>
          <CCheckoutItem
            slot="checkout-item"
            :label="optionalCoverageInformation.label"
            :tooltip="optionalCoverageInformation.tooltip"
            :price="optionalCoverageInformation.priceBase"
            selected
          />
        </CCheckout>
        <CCheckout
          :heading="textContent.optionalCoverages"
          :amount="optionalCoverageInformation.priceCurrent"
          :amount-description="textContent.paymenttermMonth"
          :amount-subtext="stripHtml(textContent.premiumTaxesText)"
        >
          <template #checkout-item>
            <CCheckoutItem
              v-for="optionalCoverage in optionalCoverageInformation.optionalCoverages"
              :key="`optionalCoverageInformation-${optionalCoverage.value}`"
              v-model="formData.coverages"
              :value="optionalCoverage.value"
              :label="optionalCoverage.label"
              :tooltip="optionalCoverage.tooltip"
              :price="optionalCoverage.price"
              :selected="optionalCoverage.selected"
            >
              <CContainer
                v-if="optionalCoverage.options && optionalCoverage.options.length"
                render-list
              >
                <CRadio
                  v-for="option in optionalCoverage.options"
                  :key="`optionalCoverageInformation-${optionalCoverage.value}-option-${option.value}`"
                  v-model="formData.accessories"
                  :value="option.value"
                  >{{ option.label }}</CRadio
                >
              </CContainer>
            </CCheckoutItem>
          </template>
        </CCheckout>
      </template>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[3]"
      :prev-button-text="textContent.page4PrevButton"
      :next-button-text="textContent.page4NextButton"
      :has-next-button="!isNotInsurable"
    >
      <CycleFormSet>
        <CContainer :label="textContent.onlyprivateLabel" :tooltip="textContent.onlyprivateTooltip">
          <CRadio v-model="formData.onlyprivate" :value="true" required>Ja</CRadio>
          <CRadio v-model="formData.onlyprivate" :value="false">Nee</CRadio>
        </CContainer>
        <template v-if="isNotPrivate">
          <p class="form__errormessage">{{ textContent.noBusinessText }}</p>
        </template>
        <CContainer
          :label="textContent.regulardriverLabel"
          :tooltip="textContent.regulardriverTooltip"
        >
          <CRadio v-model="formData.regulardriver" :value="true" required>Ja</CRadio>
          <CRadio v-model="formData.regulardriver" :value="false" required>Nee</CRadio>
        </CContainer>
        <CContainer
          :label="textContent.licenseholderLabel"
          :tooltip="textContent.licenseholderTooltip"
        >
          <CRadio v-model="formData.licenseholder" :value="true" required>Ja</CRadio>
          <CRadio v-model="formData.licenseholder" :value="false" required>Nee</CRadio>
        </CContainer>
      </CycleFormSet>
      <template v-if="!isNotInsurable">
        <CyclePersonalData :text-content="textContent" :max="minAge" :min="maxAge" />
        <CContainer>
          <template #label>{{ textContent.mailinglistLabel }}</template>
          <CCheckbox
            v-model="formData.mailinglist"
            value="y"
            :label="textContent.mailinglistText"
          />
        </CContainer>
        <RegularDriver
          :text-content="textContent"
          :header-title="textContent.driverTitle"
          :max="minAge"
          :min="maxAge"
          v-if="!isRegularDriver"
          id="driver"
        />
        <LicenseOwner
          :text-content="textContent"
          header-title="Gegevens kentekenhouder"
          :max="minAge"
          :min="maxAge"
          v-if="!isLicenseHolder"
          id="licenseowner"
        />
        <CycleFormSet :heading="textContent.cardetailsTitle">
          <CContainer :label="textContent.licenseplateLabel">
            <CLicenseplate v-model="formData.licenseplate" disabled />
          </CContainer>
          <CContainer
            :label="textContent.signCodeLabel"
            :tooltip="textContent.signCodeTooltip"
            :is-loading="retrievingSignCode"
            :show-custom-error-message="showSignCodeError"
            :show-success-message="showSignCodeSuccess"
          >
            <CHidden v-model="formData.checkSignCodeResponse" :exactly="expectedResponse" />
            <CText
              v-model.trim="formData.signCode"
              required
              :max-length="4"
              :min-length="4"
              numeric
            />

            <template #error-maxLength>{{ textContent.signCodeValidationErrorText }}</template>
            <template #error-minLength>{{ textContent.signCodeValidationErrorText }}</template>
            <template #loading>{{ phrase('loading-indicator-text') }}</template>
            <template #successMessage>{{ textContent.signCodeValidationSuccessText }}</template>
            <template #errorMessage>{{ textContent.signCodeValidationErrorText }}</template>
          </CContainer>

          <CContainer
            :label="textContent.carsecurityLabel"
            :toolltip="textContent.carsecurityTooltip"
          >
            <CSelect v-model="formData.carsecurity" required>
              <COption slot="option" disabled v-text="phrase('generic-select-your-choice')" />
              <COption
                slot="option"
                v-for="item in carSecurityOptions"
                :key="item.key"
                :value="item.key"
                v-text="item.value"
              />
            </CSelect>
          </CContainer>
        </CycleFormSet>
      </template>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[4]"
      :prev-button-text="textContent.page5PrevButton"
      :next-button-text="textContent.page5NextButton"
    >
      <CycleFormSet :heading="textContent.startdateTitle">
        <CycleHtml :html="textContent.startdateText" />
        <CContainer :validation-label="textContent.startdateTitle">
          <CDate v-model="formData.startdate" :min="minStartDate" :max="maxStartDate" required />
          <template #error-min>
            {{ phrase('error-messages-funnels-002') }}
          </template>
          <template #error-max>
            {{ phrase('error-messages-funnels-003') }}
          </template>
        </CContainer>
      </CycleFormSet>
      <CycleFormSet :heading="textContent.cancelTitle">
        <CycleHtml :html="textContent.cancelText" />
      </CycleFormSet>
      <CycleFormSet :heading="textContent.paymenttypeTitle">
        <CycleHtml :html="textContent.paymenttypeText" />
        <CContainer>
          <template slot="label">
            {{ textContent.ibanLabel }}
            <CycleTooltip v-if="textContent.ibanTooltip">
              <template slot="tooltip-heading">
                {{ textContent.ibanLabel }}
              </template>
              <template slot="tooltip-content">
                <CycleHtml :html="textContent.ibanTooltip" />
              </template>
            </CycleTooltip>
          </template>
          <CText
            v-model="formData.iban"
            required
            iban-dutch
            :placeholder="textContent.ibanPlaceholder"
            autocomplete="off"
          />
        </CContainer>
        <CContainer>
          <template slot="label">{{ textContent.paymenttermMonth }}</template>
          {{ pricePerMonth | CycleCurrency({ locale }) }}
        </CContainer>
        <CycleHtml :html="textContent.existingCustomerText" />
        <CycleHtml :html="textContent.premiumInfoText" />
        <CycleTooltip>
          <template slot="tooltip-heading">
            {{ textContent.premiumTaxesHeader }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.premiumTaxesTooltip" />
          </template>
        </CycleTooltip>
      </CycleFormSet>
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[5]"
      :prev-button-text="textContent.page6PrevButton"
      :next-button-text="textContent.page6NextButton"
    >
      <CycleFinalQuestions :final-questions="finalQuestions" />
    </CycleFormPage>
    <CycleFormPage
      :heading="steps[7]"
      :prev-button-text="textContent.page7PrevButton"
      :next-button-text="textContent.page7NextButton"
    >
      <CycleFunnelOverview :overview="funnelOverview">
        <template #top>
          <CycleHtml :html="textContent.overviewText" />
        </template>
      </CycleFunnelOverview>
      <template v-for="(questionText, i) of agreementQuestions">
        <CycleHtml :html="questionText" :key="i" />
      </template>
    </CycleFormPage>
    <template #confirmation>
      <slot name="confirmation">No confirmation is set</slot>
    </template>
  </CycleForm>
</template>

<script>
import { isEmpty, pathOr } from 'ramda'
import CycleForm from '../../forms/CycleForm/CycleForm'
import CycleFormPage from '../../forms/CycleFormPage/CycleFormPage'
import CycleFormSet from '../../forms/CycleFormSet/CycleFormSet'
import CycleFormConfirmation from '../../forms/CycleFormConfirmation/CycleFormConfirmation'
import { CycleInputContainer } from '../../forms/CycleInputContainer'
import { CycleButton } from '../../../elements/CycleButton'
import { CycleHeading } from '../../../elements/CycleHeading'
import CycleBusstop from '../../../cycle-busstop/CycleBusstop'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'

import { CycleCheckout, CycleCheckoutItem } from '../../CycleCheckout'
import { CycleButtonLink } from '../../../cycle-link'
import { CycleCompareCoverage } from '../../CycleCompareCoverage'
import { CycleCoverage } from '../../CycleCompareCoverage'
import { CycleHtml } from '../../../base'
import { CyclePersonalData, CycleFinalQuestions, CycleFunnelOverview } from '../shared/'
import LicenseOwner from './step4/LicenseOwner'
import RegularDriver from './step4/RegularDriver'

import {
  CycleLicensePlateInput,
  CycleDateInput,
  CycleTextInput,
  CycleRadioInput,
  CycleSelect,
  CycleOption,
  CycleCheckboxInput,
  CycleHiddenInput
} from '../../../elements'

import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import { debounce } from 'lodash'

import { CycleCurrency } from '../../../../filters'
import { intlMixin, dictionaryMixin } from '../../../../mixins'
import { overview } from './helpers'

import { NotificationBar } from '@/components/atoms'

export default {
  name: 'CycleFunnelCar',
  filters: {
    CycleCurrency
  },
  components: {
    CycleFormConfirmation,
    CycleForm,
    CycleFormPage,
    CycleFormSet,
    CContainer: CycleInputContainer,
    CLicenseplate: CycleLicensePlateInput,
    CText: CycleTextInput,
    CDate: CycleDateInput,
    CSelect: CycleSelect,
    COption: CycleOption,
    CRadio: CycleRadioInput,
    CCompare: CycleCompareCoverage,
    CCoverage: CycleCoverage,
    CCheckout: CycleCheckout,
    CCheckoutItem: CycleCheckoutItem,
    CCheckbox: CycleCheckboxInput,
    CLink: CycleButtonLink,
    CycleBusstop,
    CycleHtml,
    CycleTooltip,
    CycleButton,
    CycleHeading,
    CyclePersonalData,
    CycleFinalQuestions,
    RegularDriver,
    LicenseOwner,
    CycleFunnelOverview,
    CHidden: CycleHiddenInput,
    NotificationBar
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    checkSignCodeAndLicensePlate: {
      type: Function,
      required: false,
      default: () => ''
    },
    getPremiumsAction: { type: Function, required: false, default: () => {} },
    checkAcceptance: { type: Function, required: false, default: () => {} },
    getPremiumsForConfigurationAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    getPremiumsForConfigurationSelectionAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    formSubmitAction: { type: Function, required: false, default: () => {} },
    chooseInsurerAction: { type: Function, required: false, default: () => {} },
    lookupAction: { type: Function, required: false, default: () => {} },
    fieldInformation: { type: Object, required: false, default: () => ({}) },
    premiumInformation: { type: Array, required: false, default: () => [] },
    vehicleInformation: { type: Object, required: false, default: () => ({}) },
    finalQuestions: { type: Object, required: false, default: () => ({}) },
    optionalCoverageInformation: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isAcceptedLicensePlate: { type: Boolean, default: true },
    declinedLicensePlateMessage: { type: String, required: false }
  },
  data() {
    return {
      claimFreeYears: Array.from({ length: 34 }, (v, k) => k - 3).map((key) => {
        let value = key
        if (value === 30) value = '30+'
        if (value === -3) value = '-3 of meer'
        return { key, value }
      }),
      expectedResponse: 'YES',
      isAcceptedObject: true,
      declinedObjectMessage: undefined,
      retrievingSignCode: undefined
    }
  },
  computed: {
    funnelOverview() {
      const {
        textContent,
        formData,
        optionalCoverageInformation,
        vehicleInformation,
        fieldInformation,
        currentSecurityValue,
        optionalCoveragesByValue
      } = this
      const textContentKeyMap = {
        'PremiumRequestConfig-Aon-Car-WA': 'coverageWa',
        'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco': 'coverageWaPlus',
        'PremiumRequestConfig-Aon-Car-WA-Casco': 'coverageWaExtended',
        legalAid: 'legalaid',
        passengerDamage: 'passengers'
      }

      return overview({
        textContent,
        formData,
        optionalCoverageInformation,
        vehicleInformation,
        fieldInformation,
        currentSecurityValue,
        optionalCoveragesByValue,
        textContentKeyMap
      })
    },
    licensePlateLengthOk() {
      return !!(this.formData.licenseplate && this.formData.licenseplate.length >= 6)
    },
    pricePerMonth() {
      return this.optionalCoverageInformationSet ? this.optionalCoverageInformation.priceCurrent : 0
    },
    updatedSignCode() {
      return this.formData.signCode
    },
    updatedLicensePlate() {
      return this.formData.licenseplate
    },
    isSignCodeValid() {
      return (
        this.updatedSignCode?.length === 4 &&
        this.formData.checkSignCodeResponse === this.expectedResponse
      )
    },
    steps() {
      return [
        this.textContent.page1title,
        this.textContent.page2title,
        this.textContent.page3title,
        this.textContent.page4title,
        this.textContent.page5title,
        this.textContent.page6title,
        this.textContent.page7title
      ]
    },
    startAdditionalStep4Form() {
      return (
        this.formData.regulardriver !== undefined &&
        this.formData.licenseholder !== undefined &&
        this.formData.onlyprivate !== undefined
      )
    },
    chosenCoverage() {
      return this.formData.coverage
    },
    chosenCoverages() {
      return this.formData.coverages
    },
    chosenAccessories() {
      return this.formData.accessories
    },
    isNotInsurable() {
      return !this.formData.onlyprivate || !this.startAdditionalStep4Form
    },
    isRegularDriver() {
      return !!this.formData.regulardriver
    },
    isLicenseHolder() {
      return !!this.formData.licenseholder
    },
    isNotPrivate() {
      return !this.formData.onlyprivate && this.formData.onlyprivate !== undefined
    },
    hasWaCoverage() {
      return this.formData.coverage != 'coverageWa'
    },
    minAge() {
      return format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    maxAge() {
      return format(addDays(subYears(new Date(), 80), 1), 'yyyy-MM-dd')
    },
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    carAge() {
      const currentYear = new Date().getFullYear()
      return (
        currentYear - parseInt(this.vehicleInformation && this.vehicleInformation.constructionYear)
      )
    },
    hasVehicleInformation() {
      return (
        this.vehicleInformation !== undefined &&
        !!Object.keys(this.vehicleInformation).length &&
        this.vehicleInformation.constructor === Object
      )
    },
    agreementQuestions() {
      if (!this.finalQuestions.getAgreementQuestions) {
        return []
      }

      return this.finalQuestions.getAgreementQuestions.reduce((acc, elem) => {
        if (elem.type === 'CheckBox') {
          return [...acc, elem.description]
        }
        return acc
      }, [])
    },
    distancePerYear() {
      return (this.fieldInformation && this.fieldInformation.CarMilage) || []
    },
    optionalCoverageInformationSet() {
      return !isEmpty(this.optionalCoverageInformation)
    },
    optionalCoveragesByValue() {
      const coverages = pathOr([], ['optionalCoverages'], this.optionalCoverageInformation)
      return coverages.reduce((acc, c) => ({ ...acc, [c.value]: c }), {})
    },
    carSecurityOptions() {
      return (this.fieldInformation && this.fieldInformation.SecurityClass) || []
    },
    currentSecurityValue() {
      const { carsecurity = '' } = this.formData
      return pathOr(
        '',
        ['value'],
        this.carSecurityOptions.find((o) => o.key === carsecurity)
      )
    },
    isValidVehicle() {
      return this.isAcceptedLicensePlate && this.isAcceptedObject
    },
    isValidVehicleMessage() {
      return this.declinedLicensePlateMessage || this.declinedObjectMessage
    },
    showSignCodeError() {
      return !this.isSignCodeValid && this.retrievingSignCode === false
    },
    showSignCodeSuccess() {
      return this.isSignCodeValid && this.retrievingSignCode === false
    }
  },
  watch: {
    chosenCoverage(newCoverage) {
      this.$nextTick(() => {
        // give this.formData a chance to update
        this.getPremiumsForConfigurationAction(newCoverage).then(() => {
          this.$refs.form.isSubmitting = false
          this.$refs.form.next()
        })
      })
    },
    chosenCoverages(newCoverages, oldCoverages) {
      if (this.$refs.form.formState.index === 2 && newCoverages.length !== oldCoverages.length) {
        this.fetchNewPremiumForSelection()
      }
    },
    chosenAccessories() {
      if (this.$refs.form.formState.index === 2) {
        this.fetchNewPremiumForSelection()
      }
    },
    optionalCoverageInformation(newValue) {
      this.$set(this.formData, 'accessories', pathOr(0, ['accessoryValue'], newValue))
    },
    hasWaCoverage(showacc) {
      if (!showacc) {
        const accessoriesIndex = this.formData.coverages.indexOf('accessories')
        if (accessoriesIndex > -1) this.formData.coverages.splice(accessoriesIndex, 1)
      }
    },
    vehicleInformation(newVal) {
      this.$set(this.formData, 'vehicleInformation', newVal)
    },
    updatedSignCode(newVal) {
      if (newVal.length !== 4) {
        this.formData.checkSignCodeResponse = undefined
      } else {
        this.checkSignCodeCombination()
      }
    },
    updatedLicensePlate(newVal) {
      if (newVal) {
        this.formData.checkSignCodeResponse = undefined
        this.formData.signCode = ''
      }
    }
  },
  methods: {
    stripHtml(string) {
      return string.replace(/<\/?[^>]+(>|$)/g, '')
    },
    setCoverage(coverage) {
      this.$refs.form.isSubmitting = true
      this.$set(this.formData, 'coverage', coverage)
      // give the update a chance to propagate
      this.$nextTick(() => this.$emit('coverageChange'))
    },
    checkSignCodeCombination: debounce(
      function () {
        this.retrievingSignCode = true
        return this.checkSignCodeAndLicensePlate()
          .then((result) => {
            this.$set(this.formData, 'checkSignCodeResponse', result)
          })
          .finally(() => {
            this.retrievingSignCode = false
          })
      },
      500,
      { leading: true, trailing: false }
    ),
    async handleFirstStep() {
      const response = await this.checkAcceptance()
      await this.handleObjectAcceptation(response)
      await this.getPremiumsAction()
      this.setDefaultDriver()
    },
    setDefaultDriver() {
      const { zipcode, birthdate } = this.formData
      const driver = {
        zipcode,
        birthdate
      }
      this.$set(this.formData, 'driver', driver)
    },
    handleObjectAcceptation(res) {
      const acceptancePoints = pathOr(0, ['premie', 'points'], res)
      if (Number(acceptancePoints) < 5000) {
        this.isAcceptedObject = true
        return Promise.resolve()
      }
      this.isAcceptedObject = false
      this.declinedObjectMessage = pathOr(0, ['premie', 'displayMessage'], res)
      window.scroll({
        top: () => this.$el.getBoundingClientRect().top,
        behavior: 'smooth'
      })
      return Promise.reject(new Error('rejection'))
    },
    fetchNewPremiumForSelection() {
      this.$refs.form.isSubmitting = true
      this.$nextTick(() => {
        // give this.formData a chance to update
        this.getPremiumsForConfigurationSelectionAction().then(
          () => (this.$refs.form.isSubmitting = false)
        )
      })
    }
  }
}
</script>
