//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FullHouseNumber } from '@/components/atoms'
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { Gender } from '@/constants'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { Person, PaymentTerm, PaymentDetails, ContactDetails, Address } from '@/models'
import { FamilyType } from '../../constants'
import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOverview',
  inject: ['textContent'],
  components: {
    FullHouseNumber,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    address: {
      required: false,
      type: Address
    },
    approvedMailingList: {
      required: true,
      type: Boolean
    },
    contactDetails: {
      required: true,
      type: ContactDetails
    },
    familyType: {
      required: true,
      type: String
    },
    insuredAmount: {
      required: true,
      type: String
    },
    person: {
      required: true,
      type: Person
    },
    paymentDetails: {
      required: true,
      type: PaymentDetails
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    isMale() {
      return this.person.gender === Gender.Male
    },
    isFemale() {
      return this.person.gender === Gender.Vemale
    },
    monthlyPayment() {
      return this.paymentDetails.paymentTerm === PaymentTerm.Monthly
    },
    yearlyPayment() {
      return this.paymentDetails.paymentTerm === PaymentTerm.Yearly
    },
    nameForFamilyType() {
      return `liability.steps.overview.content.familyType.${this.familyType}`
    },
    isValidFamilyType() {
      return this.familyType === FamilyType.Single || this.familyType === FamilyType.Multiple
    }
  }
}
