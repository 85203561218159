import { merge } from 'lodash'
import { logInfo } from '@/services/Logging'
import defaults from '@/constants/defaults'
import { formatOptions, isValid8601Date } from './cycle-date.constants'

function getTimestampFromObject(value) {
  switch (typeof value) {
    case 'number':
      return value

    case 'string':
      if (isValid8601Date.test(value)) {
        return Date.parse(value)
      }
      return NaN
  }

  if (value instanceof Date) {
    return value.getTime()
  }

  return Date.parse(value)
}

/**
 * @param {*} value
 * @param {*} options
 * @returns string
 * @deprecated since v3.5.0
 */
export function CycleDate(value, options = {}) {
  if (value == null) {
    return ''
  }
  const { defaultEnglishLocalisedPath, defaultEnglishLocale, defaultLocale } = defaults
  if (options?.locale === defaultEnglishLocalisedPath) {
    options.locale = defaultEnglishLocale
  }
  const combinedOptions = merge(
    {},
    {
      locale: defaultLocale,
      formatOptions: formatOptions
    },
    options
  )
  const timestamp = getTimestampFromObject(value)

  if (isNaN(timestamp)) {
    logInfo(`CycleDate: couldn't apply filter to ${value}`)

    return value
  }

  try {
    const date = new Date(timestamp)

    return new Intl.DateTimeFormat(combinedOptions.locale, combinedOptions.formatOptions).format(
      date
    )
  } catch (e) {
    logInfo(`CycleDate: couldn't format ${value} using options `, options, e)

    return value
  }
}
