<template>
  <div class="form__pages rfp">
    <form class="form" :class="modifiers" novalidate @submit.prevent="submitAction">
      <header class="form__header" :class="{ 'form__set--max-width': hasProgressbarMaxWidth }">
        <slot name="header" />
      </header>
      <CycleFormConfirmation
        ref="errorContainer"
        :class="{ form__notification: getErrorSeverity(error.severity) === 'rejected' }"
        v-for="error in activeStepErrors"
        :key="error.code"
        :status="getErrorSeverity(error.severity)"
      >
        <CycleHtml :html="error.friendlyMessage" />
      </CycleFormConfirmation>
      <RapidFormValidationContainer
        :is-field-errors-show="isFieldErrorsShow"
        :resolve-validation-methods="resolveValidationMethods"
        ref="validationContainer"
      />
      <div class="form__body">
        <slot />
        <Animations
          v-if="activeStep.animations"
          :animations="activeStep.animations"
          :step-key="activeStep.key"
          :store-data="animationStore"
          :properties="animationProperties"
        />
      </div>

      <div class="form__footer">
        <div class="form__buttons">
          <CycleButton
            v-if="showIfBackButton"
            :modifiers="['back', 'link']"
            v-testable="'prevButton'"
            type="button"
            @click.prevent="showPrevStep"
          >
            {{ activeStepNavigation.backLabel }}
          </CycleButton>

          <CycleButton
            v-if="showIfNextButton"
            v-testable="'nextButton'"
            :modifiers="['next']"
            :disabled="isNextDisabled"
            @click="resetInvalidFields"
          >
            {{ activeStepNavigation.nextLabel }}
          </CycleButton>
        </div>
      </div>

      <slot name="bottomSlot" />

      <BusyIndicator v-if="blockInteraction" :source="getBlockUiLoader" />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CycleButton, BusyIndicator, CycleHtml, Animations } from '@aon/cycle'
import { RapidFormValidationContainer } from '../RapidFormValidationContainer'
import { submitContext } from '../../../constants/api'
import { dynamicExpressionFactory } from '../../../mixins/DynamicExpression'
import {
  fieldDynamicExpressionProps,
  dynamicExpressionPropsGetters
} from '../RapidFormField/RapidFormField.constants'
import { expressionScope } from '../../../constants/expressions'

export default {
  name: 'RapidFormCanvas',
  components: {
    RapidFormValidationContainer,
    BusyIndicator,
    CycleButton,
    CycleHtml,
    Animations
  },
  mixins: [
    dynamicExpressionFactory('isShown', 'showIf', fieldDynamicExpressionProps, true),
    dynamicExpressionFactory(
      'showIfNextButton',
      'showNextButton',
      fieldDynamicExpressionProps,
      true
    ),
    dynamicExpressionFactory(
      'enableIfNextButton',
      'enableNextButton',
      fieldDynamicExpressionProps,
      true
    ),
    dynamicExpressionFactory(
      'showIfBackButton',
      'showBackButton',
      fieldDynamicExpressionProps,
      true
    )
  ],
  provide() {
    return {
      // Legacy injections for form components
      pageName: this.formState.step,
      formValidations: {},
      formTree: { form: {} },
      formErrors: this.getFormErrors
    }
  },
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    modifiers: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      toggleValidation: () => {},
      resetValidation: () => {},
      animationProperties: [...expressionScope]
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      ...dynamicExpressionPropsGetters,
      'activeStepNavigation',
      'activeStep',
      'activeStepErrors',
      'isCommunicating',
      'blockInteraction',
      'businessData',
      'getBlockUiLoader',
      'getFieldValue'
    ]),
    animationStore() {
      const store = Object.assign(
        {},
        ...this.animationProperties.map((key) => ({ [key]: this[key] }))
      )
      return store
    },
    isNextDisabled() {
      return this.isCommunicating || !this.enableIfNextButton ? 'disabled' : false
    },
    isFieldErrorsShow() {
      return this.modifiers?.includes('form--notification-hidden')
    },
    hasProgressbarMaxWidth() {
      return this.modifiers?.includes('progressbar-max-width')
    }
  },
  watch: {
    activeStepErrors: {
      async handler(errors) {
        if (errors?.length) {
          await this.$nextTick()
          this.$refs?.errorContainer?.[0]?.$el?.scrollIntoView({ behavior: 'smooth' })
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('rapidFormPlayer', [
      'showPrevStep',
      'settleCommunication',
      'updateField',
      'clearErrorsByKey'
    ]),
    resolveValidationMethods(toggleValidation, resetValidation) {
      this.toggleValidation = toggleValidation
      this.resetValidation = resetValidation
    },
    async submitAction() {
      await this.settleCommunication()
      await this.$nextTick()
      this.toggleValidation()
      if (this.formState.validationStatus.$invalid) {
        await this.$nextTick()
        this.$refs?.validationContainer?.$el?.scrollIntoView({ behavior: 'smooth' })

        return
      }
      this.onSubmit({ elementKey: this.activeStep.key, submitContext: submitContext.step })
      this.resetValidation()
    },
    getFormErrors() {},
    getErrorSeverity(error) {
      return { Error: 'rejected' }[error] || 'pending'
    },
    resetInvalidFields() {
      this.activeStepNavigation.deleteValueFrom?.split(',').forEach((fieldKey) => {
        const resetValue = typeof this.getFieldValue(fieldKey.trim()) === 'string' ? '' : false
        this.updateField({
          value: resetValue,
          fieldKey: fieldKey.trim()
        })
        this.clearErrorsByKey({ key: fieldKey.trim() })
      })
    }
  }
}
</script>
