import { getDefaultState } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export const myZoneStore = {
  state: getDefaultState,
  getters,
  mutations,
  actions
}
