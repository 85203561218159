import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import siteSettings from '@/store/siteSettings'
import authentication from '@/store/authentication'
import { myZoneStore } from '@/store/MyZone'
import { debounce } from 'lodash'

import {
  SET_FORM_FIELD_VALUE,
  SET_ACTIVE_STEP_BY_INDEX,
  SET_BUSINESS_DATA,
  SET_FORM_STATE,
  SET_COMMUNICATION_START,
  SET_COMMUNICATION_SUCCESS,
  SET_COMMUNICATION_FAILURE
} from '@/components/Modules/RapidFormPlayer/store/mutation-types'
import actions from './actions'
import mutations from './mutations'
import { logWarning } from '@/services/logging'

const rapidFormMutationMatch = [
  new RegExp(`^rapidFormPlayer/${SET_FORM_FIELD_VALUE}`),
  new RegExp(`^rapidFormPlayer/${SET_ACTIVE_STEP_BY_INDEX}`),
  new RegExp(`^rapidFormPlayer/${SET_BUSINESS_DATA}`),
  new RegExp(`^rapidFormPlayer/${SET_FORM_STATE}`),
  new RegExp(`^rapidFormPlayer/${SET_COMMUNICATION_START}`),
  new RegExp(`^rapidFormPlayer/${SET_COMMUNICATION_SUCCESS}`),
  new RegExp(`^rapidFormPlayer/${SET_COMMUNICATION_FAILURE}`)
]

function isRapidFormMutation(type: string): boolean {
  return rapidFormMutationMatch.some((item) => item.test(type))
}

function reduceRapidFormState(state: any) {
  return {
    rapidFormPlayer: state.rapidFormPlayer || {}
  }
}
export const rapidFormStoreKey = 'rapidFormStore'
export const myZoneStoreKey = 'myZoneStore'
export const authenticationStoreKey = 'authentication'
export function clearPersistentState() {
  setTimeout(() => sessionStorage.removeItem(rapidFormStoreKey))
}

export function createStore() {
  const plugins = []

  if (typeof window !== 'undefined' && window.sessionStorage) {
    //Rapid Form
    const vuexRapidForm = new VuexPersistence<any>({
      storage: sessionStorage,
      key: rapidFormStoreKey,
      filter: (mutation) => isRapidFormMutation(mutation.type),
      reducer: (state) => reduceRapidFormState(state),
      saveState: debounce((key: string, state: {}, storage: Storage | undefined) => {
        try {
          storage?.setItem(key, JSON.stringify(state) as any)
        } catch (e) {
          logWarning('Failed to persist state: ', e.message || e)
        }
      }, 500)
    })

    //MyZone
    const vuexMyZone = new VuexPersistence({
      key: myZoneStoreKey,
      storage: window.sessionStorage,
      modules: ['myZoneStore']
    })

    //Authentication
    const vuexAuthentication = new VuexPersistence({
      key: authenticationStoreKey,
      storage: window.sessionStorage,
      modules: ['authentication']
    })

    plugins.push(vuexRapidForm.plugin, vuexMyZone.plugin, vuexAuthentication.plugin)
  }

  const store = new Vuex.Store({
    plugins,
    modules: {
      siteSettings,
      myZoneStore,
      authentication
    },
    actions,
    mutations
  })
  return store
}
