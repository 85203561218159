<template>
  <CycleFormPage
    :on-submit="pageSubmit"
    :heading="textContent.page1title"
    :has-prev-button="false"
    :next-button-text="textContent.page1NextButton"
  >
    <CycleFormSet v-if="isChangeFunnel">
      <CycleInputContainer :label="textContent.startChangeDateLabel">
        <CycleDateInput
          v-model="formData.startDate"
          :tooltip="textContent.startChangeDateTooltip"
          required
          :min="minStartDate"
          :max="maxStartDate"
        />

        <template #error-min>
          {{ phrase('error-messages-funnels-002') }}
        </template>

        <template #error-max>
          {{ phrase('error-messages-funnels-003') }}
        </template>
      </CycleInputContainer>
    </CycleFormSet>

    <CycleFormSet>
      <CContainer
        v-if="!isChangeFunnel"
        :label="textContent.traveltypeLabel"
        :tooltip="textContent.traveltypeTooltip"
        render-list
      >
        <CRadio required v-model="formData.travelType" :value="travelType.Long">
          {{ textContent.traveltypeLong }}
        </CRadio>
        <CRadio required v-model="formData.travelType" :value="travelType.Short">
          {{ textContent.traveltypeShort }}
        </CRadio>
      </CContainer>

      <CContainer
        :label="textContent.householdCompositionLabel"
        :tooltip="textContent.householdCompositionTooltip"
        render-list
      >
        <CRadio required v-model="formData.familyType" :value="familyTypes.Single">
          {{ textContent.householdCompositionSingle }}
        </CRadio>
        <CRadio required v-model="formData.familyType" :value="familyTypes.Multiple">
          {{ textContent.householdCompositionMultiple }}
        </CRadio>
      </CContainer>
      <CContainer
        :label="textContent.departureDateLabel"
        :tooltip="textContent.departureDateTooltip"
        v-if="isShortTravelType"
        render-list
      >
        <CycleDateInput
          v-model="formData.departureDate"
          required
          :max="maxStartDate"
          :min="minStartDate"
        />
        <template #error-min>
          {{ phrase('error-messages-funnels-002', 'De datum mag niet in het verleden liggen.') }}
        </template>
        <template #error-max>
          {{
            phrase(
              'error-messages-funnels-003',
              'De datum mag niet meer dan 365 dagen in de toekomst liggen.'
            )
          }}
        </template>
      </CContainer>
      <CContainer
        v-if="isShortTravelType && hasDepartureDate"
        :label="textContent.returndateLabel"
        :tooltip="textContent.returndateTooltip"
        render-list
      >
        <CycleDateInput
          required
          v-model="formData.returnDate"
          :min="() => minReturnDate"
          :max="() => maxReturnDate"
        />
        <template #error-min>
          {{
            phrase(
              'error-messages-funnels-010',
              'De thuiskomstdatum moet na de vertrekdatum liggen'
            )
          }}
        </template>
        <template #error-max>
          {{ phrase('error-messages-funnels-009', 'U kunt maximaal 365 dagen verzekerd zijn') }}
        </template>
      </CContainer>

      <WarningMessage v-if="showTravelLengthWarning">
        <ProvidedHtml name="warnings.travelLength" />
      </WarningMessage>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import store from '@/stores/profile'

import { addDays, format, differenceInDays } from 'date-fns'
import { intlMixin, dictionaryMixin } from '@/mixins'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '../../../../components/functional'
import { CycleRadioInput, CycleDateInput } from '../../../../components/elements'
import { TravelType, FamilyType } from '../../helpers/constants'
import { WarningMessage } from '@/components/atoms'
import { ProvidedHtml } from '@/components/organisms'
import { storeModuleMixinFactory } from '@/mixins'

export default {
  name: 'FunnelTravelStepSetup',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CContainer: CycleInputContainer,
    CRadio: CycleRadioInput,
    CycleDateInput,
    WarningMessage,
    ProvidedHtml
  },
  mixins: [
    intlMixin,
    dictionaryMixin,
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      gettersMapping: ['familyType', 'prefilledTravelType']
    })
  ],
  props: {
    value: {
      type: Object,
      required: false
    },
    isChangeFunnel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShortTravelType() {
      return this.formData.travelType === TravelType.Short
    },
    familyTypes() {
      return FamilyType
    },
    hasDepartureDate() {
      return !isNaN(new Date(this.formData.departureDate).getTime())
    },
    travelType() {
      return TravelType
    },
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    },
    maxReturnDate() {
      return format(addDays(new Date(this.formData.departureDate), 364), 'yyyy-MM-dd')
    },
    minReturnDate() {
      return format(addDays(new Date(this.formData.departureDate), 1), 'yyyy-MM-dd')
    },
    showTravelLengthWarning() {
      return (
        this.isShortTravelType &&
        differenceInDays(
          new Date(this.formData.returnDate),
          new Date(this.formData.departureDate)
        ) > 19
      )
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.formData.familyType = newValue.familyType
        this.formData.travelType = newValue.travelType
        this.formData.departureDate = newValue.departureDate
        this.formData.returnDate = newValue.returnDate
        if (newValue.startDate) {
          this.formData.startDate = newValue.startDate
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.familyType || !this.prefilledTravelType) {
      return
    }

    this.formData.familyType = this.familyType
    this.formData.travelType = this.prefilledTravelType
    this.formData.startDate = this.minStartDate
    this.$emit('submit', {
      familyType: this.formData.familyType,
      travelType: this.formData.travelType,
      departureDate: this.formData.departureDate,
      returnDate: this.formData.returnDate,
      startDate: this.formData.startDate
    })
  },
  methods: {
    pageSubmit() {
      this.$emit('submit', {
        familyType: this.formData.familyType,
        travelType: this.formData.travelType,
        departureDate: this.formData.departureDate,
        returnDate: this.formData.returnDate,
        startDate: this.formData.startDate
      })
    }
  }
}
</script>
