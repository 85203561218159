<template>
  <div :class="containerClassList" v-bind="attributes">
    <div :class="getImageContainerClass" v-if="hasImageSlot">
      <slot name="image" />
    </div>
    <header :class="baseClass + '__header'" v-if="hasHeaderSlot">
      <slot name="header" />
    </header>
    <div :class="baseClass + '__body'" v-if="hasBodyOrDefaultSlot">
      <slot name="body" />
      <slot />
    </div>
    <footer
      :class="{
        [baseClass + '__footer']: true,
        [baseClass + '__footer--sidebar']: dashboard
      }"
      v-if="hasFooterSlot"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import { blockTypes, modifierProperties } from './CycleContentBlock.constants'

export default {
  name: 'CycleContentBlock',
  props: {
    contrastColor: {
      type: Boolean,
      default: false
    },
    imageModifiers: {
      type: Array,
      default: () => []
    },
    ...modifierProperties.reduce(
      (acc, curr) => ({
        [curr]: {
          type: Boolean,
          default: false
        },
        ...acc
      }),
      {}
    ),
    anchorId: {
      type: String
    }
  },
  computed: {
    blockType() {
      return this.contrastColor ? blockTypes.card : blockTypes.panel
    },
    baseClass() {
      return this.blockType.baseClass
    },
    containerClassList() {
      return modifierProperties.reduce(
        (acc, curr) => ({
          ...acc,
          [`${this.baseClass}--${curr}`]: this.blockType.modifiers.includes(curr) && this[curr]
        }),
        { [this.baseClass]: true }
      )
    },
    hasImageSlot() {
      return this.testSlot('image')
    },
    getImageContainerClass() {
      const mainClassName = this.baseClass + '__image'
      const modifierClassNames = this.imageModifiers
        .map((mod) => mainClassName + '--' + mod)
        .join(' ')
      return `${mainClassName} ${modifierClassNames}`.trim()
    },
    hasHeaderSlot() {
      return this.testSlot('header')
    },
    hasBodyOrDefaultSlot() {
      return this.testSlot('body') || this.testSlot('default')
    },
    hasFooterSlot() {
      return this.testSlot('footer')
    },
    attributes() {
      if (!!this.anchorId) {
        return {
          id: this.anchorId
        }
      }
      return {}
    }
  },
  methods: {
    testSlot(name) {
      return !!this.$scopedSlots[name] || !!this.$slots[name]
    }
  }
}
</script>
