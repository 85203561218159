<template>
  <VideoContainer v-bind="componentData" />
</template>

<script>
import { VideoContainer } from '@aon/cycle'

export default {
  name: 'VideoContainerSitecore',
  components: {
    VideoContainer
  },
  props: {
    fields: {
      type: Object
    }
  },
  computed: {
    componentData() {
      return {
        ...Object.fromEntries(
          Object.entries(this.fields).map(([key, field]) => {
            return [key, field.value || field]
          })
        )
      }
    }
  }
}
</script>
