import { localizedPaths } from './models'
export type SiteSettingsState = {
  currentLanguage: string | undefined
  availableLanguages: localizedPaths
  siteName: string | undefined
  logoutUrl: string | undefined
  dictionary: Object
}

export const state: SiteSettingsState = {
  currentLanguage: '',
  availableLanguages: {},
  siteName: undefined,
  logoutUrl: undefined,
  dictionary: {}
}

export default state
