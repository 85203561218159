<template>
  <ScPlaceholder name="jss-content" :rendering="rendering" />
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import Vue from 'vue'

export default {
  name: 'DocumentsWrapper',
  components: {
    ScPlaceholder: Placeholder
  },
  provide() {
    return {
      setFilter: (filterOptions) => {
        Object.entries(filterOptions).forEach(([key, value]) => {
          Vue.set(this.filterOptions, key, value)
        })
      },
      filterOptions: this.filterOptions,
      setDataFetched: (isDataFetched) => {
        Object.entries(isDataFetched).forEach(([key, value]) => {
          Vue.set(this.isDataFetched, key, value)
        })
      },
      isDataFetched: this.isDataFetched
    }
  },
  props: {
    fields: {
      type: Object
    },
    params: {
      type: Object
    },
    rendering: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filterOptions: {},
      isDataFetched: {}
    }
  }
}
</script>
