<template>
  <portal-target :name="name" />
</template>

<script>
export default {
  name: 'RapidFormPortalWrapper',
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>
