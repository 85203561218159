let canvas,
  ctx,
  cw = 50,
  ch = 50,
  fireworks = [],
  particles = [],
  hue = 120,
  limiterTotal = 5,
  limiterTick = 0,
  intensity = 80,
  timerTick = 0,
  mousedown = false,
  explosion = 30,
  mx,
  my,
  animateFrame = null,
  cancelAnimateFrame = null,
  reqFrame;

class Base {
  random( min, max ) {
    return Math.random() * ( max - min ) + min;
  }
  calculateDistance(p1x, p1y, p2x, p2y) {
    const xDistance = p1x - p2x
    const yDistance = p1y - p2y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  }
  createParticles(x, y) {
    let particleCount = explosion;
    while (particleCount--) {
      particles.push(new Particle(x, y));
    }
  }
}
class Fireline extends Base {
  constructor(sx, sy, tx, ty) {
    super();
    this.x = sx;
    this.y = sy;
    this.sx = sx;
    this.sy = sy;
    this.tx = tx;
    this.ty = ty;
    this.distanceToTarget = this.calculateDistance(sx, sy, tx, ty);
    this.distanceTraveled = 0;
    this.coordinates = [];
    this.coordinateCount = 3;
    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }
    this.angle = Math.atan2(ty - sy, tx - sx);
    this.speed = 2;
    this.acceleration = 1.05;
    this.brightness = this.random(50, 100);
    this.targetRadius = 1;
  }
  update(index) {
    this.coordinates.pop();
    this.coordinates.unshift([this.x, this.y]);
    if (this.targetRadius < 8) {
      this.targetRadius += 0.3;
    } else {
      this.targetRadius = 1;
    }
    this.speed *= this.acceleration;
    const vx = Math.cos(this.angle) * this.speed
    const vy = Math.sin(this.angle) * this.speed;
    this.distanceTraveled = this.calculateDistance(this.sx, this.sy, this.x + vx, this.y + vy);
    if (this.distanceTraveled >= this.distanceToTarget) {
      this.createParticles(this.tx, this.ty);
      fireworks.splice(index, 1);
    } else {
      this.x += vx;
      this.y += vy;
    }
  }
  draw() {
    ctx.beginPath();
    ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    ctx.lineTo(this.x, this.y);
    ctx.strokeStyle = 'hsl(' + hue + ', 100%, ' + this.brightness + '%)';
    ctx.stroke();
  }
}
class Particle extends Base {
  constructor(x, y) {
    super();
    this.x = x;
    this.y = y;
    this.coordinates = [];
    this.coordinateCount = 5;
    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }
    this.angle = this.random(0, Math.PI * 2);
    this.speed = this.random(1, 10);
    this.friction = 0.95;
    this.gravity = 1;
    this.hue = this.random(hue - 50, hue + 50);
    this.brightness = this.random(50, 100);
    this.alpha = 1;
    this.decay = this.random(0.015, 0.03);
  }
  update(index) {
    this.coordinates.pop();
    this.coordinates.unshift([this.x, this.y]);
    this.speed *= this.friction;
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;
    this.alpha -= this.decay;
    if (this.alpha <= this.decay) {
      particles.splice(index, 1);
    }
  }
  draw() {
    ctx.beginPath();
    ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    ctx.lineTo(this.x, this.y);
    ctx.strokeStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + this.alpha + ')';
    ctx.stroke();
  }
}
export default class Firework extends Base {
  constructor(containerId, params) {
    super();
    this.containerId = containerId;
    this.params = params;

    animateFrame =  window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame;
    cancelAnimateFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

    this.init();
    this.setOptions();
  }
  init() {
    canvas = document.getElementById(this.containerId);
    ctx = canvas.getContext( '2d' );
    cw = window.innerWidth;
    ch = window.innerHeight;
    canvas.width = cw;
    canvas.height = ch;
  }
  setOptions() {
    const options = this.params?.fireworkOptions
    if(options?.intensity) {
      intensity = parseFloat(options.intensity);
    }
    if(options?.explosion) {
      explosion = parseFloat(options.explosion);
    }
  }
  requestFrame() {
    reqFrame = animateFrame(x=> {
      this.start();
    });
  }
  start() {
    this.requestFrame();
    hue = this.random(0, 360);
    if(ctx) {
      ctx.globalCompositeOperation = 'destination-out';
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(0, 0, cw, ch);
      ctx.globalCompositeOperation = 'lighter';
    }
    var i = fireworks.length;
    while (i--) {
      fireworks[i].draw();
      fireworks[i].update(i);
    }
    var i = particles.length;
    while (i--) {
      particles[i].draw();
      particles[i].update(i);
    }
    if (timerTick >= intensity) {
      if (!mousedown) {
        fireworks.push(new Fireline(cw / 2, ch, this.random(0, cw), this.random(0, ch / 2)));
        timerTick = 0;
      }
    } else {
      timerTick++;
    }
    if (limiterTick >= limiterTotal) {
      if (mousedown) {
        fireworks.push(new Fireline(cw / 2, ch, mx, my));
        limiterTick = 0;
      }
    } else {
      limiterTick++;
    }
  }
  destroy() {
    canvas = null;
    ctx = null;
    cancelAnimateFrame(reqFrame);
  }
}