<template>
  <div>
    <InsuranceDisplayCards :products="productsForCards" placeholder="jss-products" />
    <CategorizedInsurancesCard :categories="productCategories" placeholder="jss-products" />
  </div>
</template>

<script>
import InsuranceDisplayCards from './InsuranceDisplayCards'
import CategorizedInsurancesCard from './CategorizedInsurancesCard'

export default {
  name: 'CollectivityProductsSitecore',
  components: {
    InsuranceDisplayCards,
    CategorizedInsurancesCard
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    productsForCards() {
      return this.fields?.highlightedProducts ?? []
    },
    productCategories() {
      return this.fields?.productCategories ?? []
    }
  }
}
</script>
