import Vue from 'vue'
import Router from 'vue-router'
import RouteHandler from './RouteHandler.vue'
import { scrollToElementWithAnchorId } from './routerHelper'

Vue.use(Router)

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
// routePatterns contains an allow list instead of a regex due to bug CFPA-9892 causing a collectivity, which matches the regex, to be set as language
export const routePatterns = [
  '/:lang(nl-NL)/:sitecoreRoute*',
  '/:lang(en)/:sitecoreRoute*',
  '/:sitecoreRoute*'
]

export function createRouter() {
  // create an instance of vue-router and configure routes to use the RouteHandler component
  const router = new Router({
    mode: 'history',
    routes: routePatterns.map((routePattern) => {
      return {
        path: routePattern,
        component: RouteHandler,
        props: (route) => ({
          route
        })
      }
    })
  })
  router.afterEach((to) => {
    if (to.hash === '' || to.hash === '#') {
      return
    }
    scrollToElementWithAnchorId(to.hash)
  })
  return router
}
