<template>
  <RadioNavigation v-bind="funnelData" />
</template>

<script>
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-vue'
import { mapValues } from 'lodash'
import * as Cycle from '@aon/cycle'

export default {
  name: 'CycleRadioNavigationSitecore',
  components: {
    ScPlaceholder: Placeholder,
    ScText: Text,
    RadioNavigation: Cycle.CycleRadioNavigationSitecore
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value)
    },
    funnelData() {
      return {
        ...this.textContent,
        ...this.renderingWithoutFields,
        options: this.fields.options
      }
    },
    renderingWithoutFields() {
      return { ...this.rendering, fields: {} }
    }
  }
}
</script>
