<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :next-button-text="contentProvider.getContentByPath('steps.overview.nextButton')"
    :prev-button-text="contentProvider.getContentByPath('steps.overview.backButton')"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.overview.title" />
      </CycleHeading>
    </template>

    <ProvidedHtml name="overview.content.intro" />
    <CycleFormSet>
      <template #heading>
        <ProvidedText name="overview.general.heading" />
      </template>
      <dl>
        <dt><ProvidedText name="personalData.dateOfBirth.label" /></dt>
        <dd>
          {{ overviewData.dateOfBirth | CycleDate({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.setup.householdComposition.label" /></dt>
        <dd>
          {{ overviewData.householdComposition }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.overview.baseCoverage.shortLabel" /></dt>
        <dd>
          {{
            (displayCyberCoverage
              ? overviewData.premiumCoverages.contents
              : overviewData.premiumCoverages.contentsIncludingCyberAid) | CycleCurrency(locale)
          }}
        </dd>
      </dl>
      <dl v-if="displayCyberCoverage">
        <dt><ProvidedText name="steps.overview.cyberCoverage.shortLabel" /></dt>
        <dd>
          {{ overviewData.premiumCoverages.cyberAid | CycleCurrency(locale) }}
        </dd>
      </dl>
      <dl v-if="overviewData.selectedCoverages && overviewData.selectedCoverages.glass">
        <dt><ProvidedText name="steps.overview.glass.shortLabel" /></dt>
        <dd>
          {{ overviewData.premiumCoverages.glass | CycleCurrency(locale) }}
        </dd>
      </dl>
      <dl v-if="overviewData.selectedCoverages && overviewData.selectedCoverages.jewelry">
        <dt>
          <ProvidedText name="steps.overview.jewelry.shortLabel" />
          ({{ overviewData.selectedCoverages.jewelryOption.key | CycleCurrency(currencyFormat) }})
        </dt>
        <dd>
          {{ overviewData.premiumCoverages.bodyJewelry | CycleCurrency(locale) }}
        </dd>
      </dl>
      <dl v-if="overviewData.selectedCoverages && overviewData.selectedCoverages.audioVisual">
        <dt>
          <ProvidedText name="steps.overview.audioVisual.shortLabel" />
          ({{
            overviewData.selectedCoverages.audioVisualOption.key | CycleCurrency(currencyFormat)
          }})
        </dt>
        <dd>
          {{ overviewData.premiumCoverages.audiovisualEquipment | CycleCurrency(locale) }}
        </dd>
      </dl>
      <dl
        v-if="overviewData.selectedCoverages && overviewData.selectedCoverages.specialPossessions"
      >
        <dt>
          <ProvidedText name="steps.overview.specialPossessions.shortLabel" />
          ({{
            overviewData.selectedCoverages.specialPossessionsOption.key
              | CycleCurrency(currencyFormat)
          }})
        </dt>
        <dd>
          {{ overviewData.premiumCoverages.specialPossessions | CycleCurrency(locale) }}
        </dd>
      </dl>
      <dl v-if="overviewData.selectedCoverages && overviewData.selectedCoverages.tenantsOwnership">
        <dt>
          <ProvidedText name="steps.overview.tenantsOwnership.shortLabel" />
          ({{
            overviewData.selectedCoverages.tenantsOwnershipOption.key
              | CycleCurrency(currencyFormat)
          }})
        </dt>
        <dd>
          {{ overviewData.premiumCoverages.tenantsOwnership | CycleCurrency(locale) }}
        </dd>
      </dl>

      <dl>
        <dt>
          <ProvidedText name="overview.labels.premiumMonthly" />
        </dt>
        <dd>
          {{ overviewData.totalPremium | CycleCurrency(locale) }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="overview.buildingData.heading" />
      </template>
      <dl>
        <dt><ProvidedText name="steps.setup.contentsBuildingType.label" /></dt>
        <dd>
          {{ overviewData.contentsBuildingType }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="address.postalCode.label" /></dt>
        <dd>
          {{ overviewData.postalCode }}
        </dd>
      </dl>
      <dl v-if="overviewData.isOwnHome">
        <dt><ProvidedText name="steps.acceptance.ownHome.label" /></dt>
        <dd>
          <ProvidedText :name="phrase('general-yes')" v-if="overviewData.isOwnHome.answer" />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
      <dl v-if="overviewData.isRental && overviewData.isOwnHome && overviewData.isOwnHome.answer">
        <dt><ProvidedText name="steps.acceptance.rental.label" /></dt>
        <dd>
          <ProvidedText :name="phrase('general-yes')" v-if="overviewData.isRental.answer" />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
      <dl v-if="overviewData.isWoodenOrThatchedRoof">
        <dt><ProvidedText name="steps.acceptance.woodenOrThatchedRoof.label" /></dt>
        <dd>
          <ProvidedText
            :name="phrase('general-yes')"
            v-if="overviewData.isWoodenOrThatchedRoof.answer"
          />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
      <dl v-if="overviewData.isRecreationalHome">
        <dt><ProvidedText name="steps.acceptance.recreationalHome.label" /></dt>
        <dd>
          <ProvidedText
            :name="phrase('general-yes')"
            v-if="overviewData.isRecreationalHome.answer"
          />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="overview.personalDetail.heading" />
      </template>
      <dl>
        <dt><ProvidedText name="personalData.salutation.label" /></dt>
        <dd>
          <ProvidedText
            :name="`personalData.salutation${
              overviewData.gender === 'M' ? 'Male' : 'Female'
            }.label`"
          />
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.initials.label" /></dt>
        <dd>
          {{ overviewData.initials }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.infix.label" /></dt>
        <dd>
          {{ overviewData.tussenvoegsel }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.lastName.label" /></dt>
        <dd>
          {{ overviewData.lastName }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.dateOfBirth.label" /></dt>
        <dd>
          {{ overviewData.dateOfBirth | CycleDate({ locale }) }}
        </dd>
      </dl>
      <template v-if="overviewData.address">
        <dl>
          <dt><ProvidedText name="address.postalCode.label" /></dt>
          <dd>
            {{ overviewData.address.postalCode }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.houseNumberAndSuffix.label" /></dt>
          <dd>
            {{ overviewData.address.houseNumber }}
            {{ overviewData.address.houseNumberAddition }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.street.label" /></dt>
          <dd>
            {{ overviewData.address.street }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.city.label" /></dt>
          <dd>
            {{ overviewData.address.city }}
          </dd>
        </dl>
      </template>
      <dl>
        <dt><ProvidedText name="contactDetails.emailAddress.label" /></dt>
        <dd>
          {{ overviewData.emailAddress }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="newsletter.signUp.label" /></dt>
        <dd>
          <ProvidedText :name="phrase('general-yes')" v-if="overviewData.mailingList" />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="contactDetails.phoneNumber.label" /></dt>
        <dd>
          {{ overviewData.phoneNumber }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="overview.paymentType.heading" />
      </template>
      <dl>
        <dt>
          <ProvidedText name="paymentDetails.startDate.label" />
        </dt>
        <dd>
          {{ overviewData.startDate | CycleDate({ locale }) }}
        </dd>
        <dt><ProvidedText name="paymentDetails.iban.label" /></dt>
        <dd>
          {{ overviewData.paymentDetails.iban }}
        </dd>
        <dt><ProvidedText name="paymentDetails.paymentTerm.label" /></dt>
        <dd>
          <ProvidedText name="overview.premiumDurationValues.monthly" />
        </dd>
        <dt><ProvidedText name="paymentDetails.paymentType.label" /></dt>
        <dd>
          <ProvidedText name="overview.paymentAutomaticDebit.label" />
        </dd>
      </dl>
    </CycleFormSet>

    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'
import DetailDataOverview from '@/components/organisms/Form/DetailDataOverview'

export default {
  name: 'HomeContentsStepOverview',
  components: {
    DetailDataOverview,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  inject: ['contentProvider'],
  props: {
    overviewData: {
      required: true,
      type: Object
    },
    value: { type: Object, required: true },
    displayCyberCoverage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currencyFormat: {
        ...this.locale,
        formatOptions: {
          minimumFractionDigits: 0
        }
      }
    }
  },
  computed: {
    totalPremium() {
      return this.overviewData?.premium?.totalPremium
    }
  },
  methods: {
    premiumCoverage(coverage) {
      return this.overviewData?.premium?.coverages[coverage]
    },
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
</script>
