//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleIcon } from '@/components/elements'

export default {
  name: 'DocumentsItem',
  components: { CycleIcon },
  props: {
    isOpen: Boolean,
    isHidden: Boolean
  },
  methods: {
    handleClick() {
      this.$emit('open')
    }
  }
}
