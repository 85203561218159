<template>
  <div>
    <CycleHeading v-if="title" :weight="2">
      {{ title }}
    </CycleHeading>
    <CarouselSsr v-bind="options">
      <div class="slide" v-for="(card, index) in cards" :key="index">
        <CycleCard>
          <template v-if="card.thumbnail" #image>
            <img :src="card.thumbnail" />
          </template>
          <template v-if="card.articleTitle" #header>
            <CycleHeading>
              {{ card.articleTitle }}
            </CycleHeading>
          </template>
          <template v-if="card.textPreview" #body>
            {{ card.textPreview }}
          </template>
          <template v-if="isReadMoreLinkVisibleForCard(card)" #footer>
            <a :href="card.relativePath">
              {{ card.readMoreText }}
            </a>
          </template>
        </CycleCard>
      </div>
    </CarouselSsr>
    <div v-if="isMoreRelatedButtonVisible" class="sidebar__footer">
      <CycleButtonLink :href="moreRelatedButtonHref">
        {{ moreRelatedButtonText }}
      </CycleButtonLink>
    </div>
  </div>
</template>

<script>
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import { CycleButtonLink, CycleCard, CycleHeading } from '@aon/cycle'
import carouselTypes from '@/components/Cycle/Carousel/carouselTypes.json'
import Vue from 'vue'
Vue.use(ssrCarouselCss)

export default {
  name: 'BlogCarousel',
  components: {
    CarouselSsr: SsrCarousel,
    CycleButtonLink,
    CycleCard,
    CycleHeading
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cards() {
      return this.fields?.cards ?? []
    },
    title() {
      return this.fields?.title
    },
    moreRelatedButtonText() {
      return this.fields?.moreRelated
    },
    moreRelatedButtonHref() {
      return this.fields?.moreRelatedUrl
    },
    isMoreRelatedButtonVisible() {
      return (
        this.moreRelatedButtonText?.length > 0 &&
        this.moreRelatedButtonHref?.length > 0 &&
        this.cards.length >= 3
      )
    },
    options() {
      return this.fields?.isGridView
        ? carouselTypes['cards'].options
        : carouselTypes['blog'].options
    }
  },
  methods: {
    isReadMoreLinkVisibleForCard(card) {
      return card?.readMoreText?.length > 0 && card?.relativePath?.length > 0
    }
  }
}
</script>
