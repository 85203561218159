<template>
  <div :class="className">
    <figure class="card-horizontal__image" v-if="image && image.src">
      <OptionalLink :href="imageLink.href" v-if="imageLink">
        <img :src="image.src" :alt="imageAlt" :title="imageTitle" />
      </OptionalLink>
      <img :src="image.src" :alt="imageAlt" :title="imageTitle" v-else />
    </figure>
    <div class="card-horizontal__content">
      <header class="card-horizontal__header" v-if="heading">
        <CycleIcon :icon="icon" class="icon--card-horizontal" v-if="icon" />
        <CycleHeading :weight="headingWeight">
          {{ heading }}
        </CycleHeading>
      </header>
      <div class="card-horizontal__body" v-if="$slots.body">
        <slot name="body" />
      </div>
      <footer class="card-horizontal__footer" v-if="$slots.footer">
        <!-- @slot Room for footer content, like cta buttons -->
        <slot name="footer" />
      </footer>
    </div>
    <span class="badge badge--right" v-if="badgeText">{{ badgeText }}</span>
  </div>
</template>

<script>
import { OptionalLink } from '@/components/atoms'
import { CycleHeading, CycleIcon } from '@/components/elements'

/**
 * A card with image which covers the section horizontally
 *
 * @since 3.9.0
 * @displayName Horizontal Card
 * @see https://jira.aon.nl/browse/CFPA-13527
 */
export default {
  name: 'HorizontalCard',
  components: { CycleHeading, OptionalLink, CycleIcon },
  props: {
    // Content
    /** Fixed heading above the cards contents */
    heading: { type: String, required: true },
    /** Displayed next to the content, left or right is dependent on the `flip` toggle */
    image: { type: Object, required: false },

    // Misc
    /** Providing an imageLink makes the image clickable, pointing it to provided link */
    imageLink: { type: Object },

    /** Flips content order horizontally, default is picture on the right */
    hasFlipModifier: { type: Boolean, default: false },

    hasBlogModifier: { type: Boolean, default: false },
    /** Centers the text in the content */
    hasCenterModifier: { type: Boolean, default: false },
    /** Aligns the content the the middle of the picture/card */
    hasMiddleModifier: { type: Boolean, default: false },
    /** Prevent rendering a background */
    hasTransparentModifier: { type: Boolean, default: false },
    /** Render the image at 33% instead of 50% */
    hasSmallerImageModifier: { type: Boolean, default: false },
    /** Render the image with object-fit set to contain instead of cover */
    hasLogoImageModifier: { type: Boolean, default: false },
    hasIconImageModifier: { type: Boolean, default: false },
    icon: {
      type: String
    },
    badgeText: {
      type: String
    },
    headingWeight: {
      type: Number,
      default: 3
    }
  },
  computed: {
    imageAlt() {
      return this.image?.alt || this.image?.title || this.image?.name || ''
    },
    imageTitle() {
      return this.image?.title || this.image?.name || this.image?.alt || ''
    },
    className() {
      const mainClassName = 'card-horizontal'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--flip`]: this.hasFlipModifier,
        [`${mainClassName}--blog`]: this.hasBlogModifier,
        [`${mainClassName}--center`]: this.hasCenterModifier,
        [`${mainClassName}--middle`]: this.hasMiddleModifier,
        [`${mainClassName}--transparent`]: this.hasTransparentModifier,
        [`${mainClassName}--smallerimage`]: this.hasSmallerImageModifier,
        [`${mainClassName}--logoimage`]: this.hasLogoImageModifier,
        [`${mainClassName}--iconimage`]: this.hasIconImageModifier
      }
    }
  }
}
</script>
