//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { merge } from 'lodash'
import { labelProps, textMap } from '../helpers/labels'
import { uuid4 } from '@/services/UUID'
import { funnelMixinFactory } from '@/mixins/funnel'
import { default as storeModule } from '../store'
import {
  SET_PERSONAL_DATA,
  SET_COVERAGE_OPTIONS,
  SET_FINAL_QUESTIONS,
  SET_POLICY_ACTION
} from '../store/mutation-types'
import { serviceNames, FunnelService } from '@/services/data'
import { FunnelTravelService } from '../services'

import { TravelType } from '../helpers/constants'
import { writePolicyParams } from '../helpers/queryParams'
import { PolicyAction } from '@/constants'

import {
  FunnelTravelStepSetup,
  FunnelTravelStepCoverages,
  FunnelTravelStep5,
  FunnelTravelStepOverviewChange
} from './steps'

import { FormContainer, ServiceInformation } from '@/components/organisms'
import CycleBusstop from '@/components/cycle-busstop/CycleBusstop'
import CycleFunnelConfirmation from '@/components/functional/forms/CycleFunnelConfirmation/CycleFunnelConfirmation'
import { FunnelQuestionsContainer } from '@/modules/FunnelQuestions'
import { QuestionRenderType } from '@/modules/FunnelQuestions/models'
import { Coverages } from '../models'

export default {
  name: 'FunnelTravelChangeSitecore',
  components: {
    FunnelTravelStepSetup,
    FunnelTravelStepCoverages,
    FunnelTravelStep5,
    FunnelTravelStepOverviewChange,
    FunnelQuestionsContainer,
    FormContainer,
    CycleBusstop,
    CycleFunnelConfirmation,
    ServiceInformation
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelTravel',
      storeModule,
      stateMapping: ['travelType'],
      actionsMapping: [
        'initFunnelTravel',
        'handleStepSetup',
        'updateCoverages',
        'updatePremium',
        'submitFunnel',
        'getPolicyInfo'
      ],
      mutationsMapping: {
        SET_PERSONAL_DATA,
        SET_COVERAGE_OPTIONS,
        SET_FINAL_QUESTIONS,
        SET_POLICY_ACTION
      },
      gettersMapping: [
        'premium',
        'fullState',
        'submitResult',
        'stepSetupData',
        'stepCoveragesData',
        'selectedProductCode'
      ],
      services: [
        {
          serviceName: serviceNames.FunnelTravelService,
          serviceDefinition: FunnelTravelService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        }
      ]
    })
  ],
  provide() {
    return { formData: this.formData, textContent: this.textContentMerged }
  },
  props: {
    ...labelProps,
    transaction: {
      type: String,
      required: false,
      default: () => uuid4()
    },
    serviceInfo: {
      type: Array,
      required: false
    },
    textContent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        travelType: undefined,
        familyType: undefined,
        coverages: new Coverages(),
        startDate: new Date()
      },
      questions: {},
      ready: false
    }
  },
  computed: {
    textContentMerged() {
      return merge({}, textMap(this), this.textContent)
    },
    steps() {
      return [
        this.textContentMerged.page1title,
        this.textContentMerged.page2title,
        this.textContentMerged.page5title,
        this.textContentMerged.page6title
      ]
    },
    isMultiTrip() {
      return this.formData.travelType === TravelType.Long
    },
    questionRenderType() {
      return QuestionRenderType
    }
  },
  async beforeMount() {
    await this.initFunnelTravel({
      type: PolicyAction.Change,
      policyNumber: this.$route.query.insurance
    })
    this.ready = true
  },
  methods: {
    async stepSetupSubmit(e) {
      await this.handleStepSetup(e)
      this.updatePremium()
    },
    async formSubmit() {
      await this.submitFunnel(writePolicyParams)
    }
  }
}
