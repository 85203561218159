<template>
  <iframe
    v-if="urlWebsite"
    style="height: 100vh; width: 100%;"
    :src="urlWebsite"
    sandbox="allow-same-origin allow-scripts allow-forms"
  ></iframe>
</template>

<script>
export default {
  name: 'CycleWebsite',
  props: {
    urlWebsite: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
