<template>
  <div v-if="hasProductName" data-testid="policyDetailIntro">
    <CycleHeading
      :weight="1"
      :modifiers="['nomargin', 'centered']"
      data-testid="policyDetailIntroHeading"
    >
      <!-- MYZ-989: Dirty temporary solution. Will be reverted once Biztalk sends correct name -->
      {{ policyInfo.product.name === 'Vuurwapen' ? 'Jachtuitrusting' : policyInfo.product.name }}
    </CycleHeading>
    <p v-if="hasIntro" class="pageintro" data-testid="policyDetailIntroText">
      {{ textContent.introText }}<br /><CycleInsuranceStatusTag :status="policyStatus" />
    </p>
  </div>
  <div class="coreui" v-else>
    <div class="grid">
      <NotificationBar notification-type="warning" data-testid="policyDetailNotFoundMessage">
        {{ textContent.notFoundMessage }}
      </NotificationBar>
    </div>
  </div>
</template>

<script>
import { mapValues } from 'lodash'
import { mapGetters } from 'vuex'
import { CycleHeading, NotificationBar, CycleInsuranceStatusTag } from '@aon/cycle'
import { dictionaryMixin } from '@/mixins/Dictionary'

export default {
  name: 'PolicyDetailIntro',
  components: {
    CycleHeading,
    NotificationBar,
    CycleInsuranceStatusTag
  },
  mixins: [dictionaryMixin],
  provide() {
    return {
      statusLabels: {
        statusInProgress: this.getPhrase('policy-status-1'),
        statusSuspended: this.getPhrase('policy-status-5'),
        statusOngoing: this.getPhrase('policy-status-3'),
        statusCancelled: this.getPhrase('policy-status-6')
      }
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    hasProductName() {
      return this.policyInfo?.product?.name
    },
    hasIntro() {
      return this.textContent?.introText
    },
    policyStatus() {
      return this.policyInfo?.status
    }
  }
}
</script>
