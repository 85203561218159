<template>
  <CategorizedFaqList v-if="hasCategories" :categories="categories" />
</template>
<script>
import { CategorizedFaqList } from './subComponents'
export default {
  name: 'CategorizedFaq',
  components: {
    CategorizedFaqList
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const categories = props.fields.categories ?? []
    const hasCategories = categories?.length > 0
    return {
      categories,
      hasCategories
    }
  }
}
</script>
