//
//
//
//
//
//
//
//
//
//
//
//

import CycleContentBlock from '../cycle-content-block/CycleContentBlock'

export default {
  name: 'CyclePanel',
  components: { CycleContentBlock },
  props: {
    cta: {
      type: Boolean,
      required: false,
      default: false
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    contrast: {
      type: Boolean,
      required: false,
      default: false
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    slotKeys() {
      return Object.keys(this.$slots)
    }
  }
}
