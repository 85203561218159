<template>
  <div class="table-wrapper">
    <EmptyTablePlaceholder v-if="documents.length === 0" no-results-text="Empty" />
    <div class="insurances-table" v-if="documents.length > 0">
      <div class="heading">{{ getText('StatusHeader') }}</div>
      <div class="heading">{{ getText('VerzekeringHeader') }}</div>
      <div class="heading hide-sm">{{ getText('PolisnrHeader') }}.</div>
      <div class="heading hide-sm">{{ getText('BedragHeader') }}</div>
      <div class="heading hide-sm">{{ getText('TermijnHeader') }}</div>
      <div class="heading show-lg">{{ getText('VerzekeraarHeader') }}</div>
      <div class="heading"></div>
    </div>
    <div
      class="row-wrapper"
      v-for="(document, idx) in documentSlice"
      :key="`${document.policyNumber}${idx}`"
      :class="openDetails(`${document.policyNumber}${idx}`)"
    >
      <div class="items" @click="handleDetailClick(`${document.policyNumber}${idx}`)">
        <div class="center status">
          <StatusIndicator :status="showDotType(document.statusCode)"></StatusIndicator>
        </div>
        <div>{{ document.insuranceType }}</div>
        <div class="hide-sm">{{ document.policyNumber }}</div>
        <div class="hide-sm">{{ document.amount }}</div>
        <div class="hide-sm">{{ document.term }}</div>
        <div class="show-lg">{{ document.insurer }}</div>
        <div class="center">
          <span
            class="icon icon--plus"
            :class="openDetails(`${document.policyNumber}${idx}`)"
          ></span>
        </div>
      </div>
      <div class="detail">
        <div :class="openDetails(`${document.policyNumber}${idx}`)">
          <div>
            <p class="show-sm">
              <strong> {{ getText('PolisnrHeader') }}. </strong>: {{ document.policyNumber }}
            </p>
            <p class="show-sm">
              <strong> {{ getText('BedragHeader') }} </strong>: {{ document.amount }}
            </p>
            <p class="show-sm">
              <strong> {{ getText('TermijnHeader') }} </strong>: {{ document.term }}
            </p>
            <p class="hide-lg">
              <strong> {{ getText('VerzekeraarHeader') }} </strong>: {{ document.insurer }}
            </p>
          </div>
          <CycleList modifiers="nomarker">
            <CycleListItem>
              <label>{{ getText('DetailsStatusText') }}:</label>
              <p>{{ document.statusText }}</p>
            </CycleListItem>
            <CycleListItem>
              <label>{{ getText('DetailsDownloadsText') }}</label>
              <div v-if="document.policyDocument">
                <span class="icon icon--document"></span>
                <a class="polis-link" :href="document.policyDocument" target="_blank">
                  {{ getText('DetailsPolisbladText') }}
                </a>
              </div>
              <div v-if="document.policyConditions">
                <span class="icon icon--document"></span>
                <a class="polis-link" :href="document.policyConditions" target="_blank">
                  {{ document.policyConditionText }}
                </a>
              </div>
              <p v-else>{{ document.policyConditionText }}</p>
            </CycleListItem>
            <CycleListItem>
              <label>{{ getText('DetailsServicesContactsText') }}</label>
              <div>
                <span class="icon icon--envelope"></span>
                <a class="polis-link" :href="document.askQuestionLink">
                  {{ getText('DetailsAskQuestionText') }}
                </a>
              </div>
              <div v-if="document.submitChangeLink">
                <span class="icon icon--phone"></span>
                <a class="polis-link" :href="document.submitChangeLink" target="_blank">
                  {{ getText('DetailsSubmitText') }}
                </a>
              </div>
            </CycleListItem>
          </CycleList>
        </div>
      </div>
    </div>

    <CyclePagination
      v-if="hasPagination"
      :total-number-of-items="documents.length"
      :number-of-items-on-page="numberOfLinesOnPage"
      :current-page="pageNumber"
      :pagination-item-text="textContent.paginationItemText"
      :pagination-text="textContent.paginationText"
      @page-change="setPageNumber"
    />
  </div>
</template>

<script>
import {
  CycleList,
  CycleListItem,
  EmptyTablePlaceholder,
  CyclePagination,
  StatusIndicator
} from '@aon/cycle'
import { mapValues } from 'lodash'
import { dictionaryMixin } from '@/mixins/Dictionary'
export default {
  name: 'InsuranceTable',
  components: {
    StatusIndicator,
    CyclePagination,
    CycleList,
    CycleListItem,
    EmptyTablePlaceholder
  },
  mixins: [dictionaryMixin],
  props: {
    documents: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      textContent: () => mapValues(this.fields, (item) => item.value || item),
      pageNumber: 1,
      numberOfLinesOnPage: 10
    }
  },
  computed: {
    hasPagination() {
      if (
        this.numberOfLinesOnPage === 0 ||
        this.numberOfLinesOnPage == null ||
        this.numberOfLinesOnPage == undefined
      ) {
        return false
      }

      return this.documents.length / this.numberOfLinesOnPage > 1
    },
    documentSlice() {
      return this.documents.slice(
        (this.pageNumber - 1) * this.numberOfLinesOnPage,
        this.pageNumber * this.numberOfLinesOnPage
      )
    }
  },
  methods: {
    // Show corresponding dot type based on incoming status
    showDotType(status) {
      //Lopend: 3, expired: 6
      if (status != 3) {
        return 'error'
      }

      return 'confirmed'
    },
    getText(header) {
      return this.getPhrase(header) || header.replace('Header', '')
    },
    setPageNumber(e) {
      this.pageNumber = parseInt(e)
    },
    handleDetailClick(index) {
      if (this.selected.includes(index)) {
        this.selected.splice(this.selected.indexOf(index), 1)
      } else {
        this.selected.push(index)
      }
    },
    openDetails(index) {
      if (this.selected.includes(index)) {
        return 'open'
      }

      return ''
    }
  }
}
</script>
