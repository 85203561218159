<template>
  <CycleFormPage
    :on-submit="pageSubmit"
    :heading="textContent.page2title"
    :next-button-text="textContent.page2NextButton"
    :prev-button-text="textContent.page2PrevButton"
  >
    <CycleCheckout
      :amount="totalPremium"
      :amount-description="totalAmountText"
      :amount-subtext="totalAmountSubtext"
    >
      <template #checkout-item>
        <CycleCheckoutItem
          :label="textContent.basicCoverageLabel"
          :tooltip="textContent.basicCoverageTooltip"
          :description="basicCoverageDescription"
          :price="premiumCoverage('baseCoverage')"
          selected
        >
          <template v-if="isMultiTrip" #checkout-item-input>
            <CContainer survey render-list>
              <CRadio
                v-model="coverages.baseCoverageOption"
                :value="baseCoverageOption.NumberOfDays60"
                >{{ textContent.basicCoverage60DaysLabel }}</CRadio
              >
              <CRadio
                v-model="coverages.baseCoverageOption"
                :value="baseCoverageOption.NumberOfDays180"
                >{{ textContent.basicCoverage180DaysLabel }}</CRadio
              >
              <CRadio
                v-model="coverages.baseCoverageOption"
                :value="baseCoverageOption.NumberOfDays360"
                >{{ textContent.basicCoverage360DaysLabel }}</CRadio
              >
            </CContainer>
          </template>
        </CycleCheckoutItem>

        <CycleCheckoutItem
          v-model="coverages.luggage"
          :label="textContent.travelLugageLabel"
          :tooltip="textContent.travelLugageTooltip"
          :description="textContent.travelLugageDescription"
          :price="premiumCoverage('luggage')"
        >
          <template slot="checkout-item-input">
            <CContainer survey render-list>
              <CRadio v-model="coverages.luggageOption" :value="luggageOption.Default3000">{{
                textContent.travelLugageStandardLabel
              }}</CRadio>
              <CRadio v-model="coverages.luggageOption" :value="luggageOption.Extended5000">{{
                textContent.travelLugageExtendedLabel
              }}</CRadio>
            </CContainer>
          </template>
        </CycleCheckoutItem>

        <CycleCheckoutItem
          v-model="coverages.medicalCosts"
          :label="textContent.travelMedicalCostsLabel"
          :tooltip="textContent.travelMedicalCostsTooltip"
          :description="textContent.travelMedicalCostsDescription"
          :price="premiumCoverage('medicalCosts')"
        />

        <CycleCheckoutItem
          v-model="coverages.replacementTransportAndRepatriation"
          :label="textContent.travelReplacementTransportAndRepatriationLabel"
          :tooltip="textContent.travelReplacementTransportAndRepatriationTooltip"
          :description="textContent.travelReplacementTransportAndRepatriationDescription"
          :price="premiumCoverage('replacementTransportAndRepatriation')"
        />

        <CycleCheckoutItem
          v-model="coverages.accidents"
          :label="textContent.travelAccidentsLabel"
          :tooltip="textContent.travelAccidentsTooltip"
          :description="textContent.travelAccidentsDescription"
          :price="premiumCoverage('accidents')"
        >
          <template slot="checkout-item-input">
            <CContainer survey render-list>
              <CRadio v-model="coverages.accidentsOption" :value="accidentsOption.Default">{{
                textContent.travelAccidentsStandardLabel
              }}</CRadio>
              <CRadio v-model="coverages.accidentsOption" :value="accidentsOption.SpecialSports">{{
                textContent.travelAccidentsExtendedLabel
              }}</CRadio>
            </CContainer>
          </template>
        </CycleCheckoutItem>

        <WarningMessage v-if="showSpecialSportsWarning">
          <ProvidedHtml name="warnings.specialSports" />
        </WarningMessage>

        <CycleCheckoutItem
          v-model="coverages.specialSports"
          :label="textContent.travelSpecialSportsLabel"
          :tooltip="textContent.travelSpecialSportsTooltip"
          :description="textContent.travelSpecialSportsDescription"
          :price="premiumCoverage('specialSports')"
        />

        <CycleCheckoutItem
          v-model="coverages.cancellation"
          :label="textContent.travelCancellationLabel"
          :tooltip="travelCancellationTooltip"
          :description="travelCancellationDescription"
          :price="premiumCoverage('cancellation')"
        >
          <template #checkout-item-input>
            <CContainer survey render-list v-if="isMultiTrip">
              <CRadio
                v-model="coverages.cancellationOption"
                :value="cancellationOption.Option1500"
                >{{ textContent.travelCancellation1500Label }}</CRadio
              >
              <CRadio
                v-model="coverages.cancellationOption"
                :value="cancellationOption.Option3000"
                >{{ textContent.travelCancellation3000Label }}</CRadio
              >
            </CContainer>
            <template v-else-if="coverages.cancellation">
              <CContainer
                survey
                render-list
                :label="textContent.bookingDate"
                :tooltip="textContent.bookingDateTooltip"
              >
                <CDate
                  v-model="formData.bookingDate"
                  :min="minBookingDate"
                  :max="maxBookingDate"
                  :required-if="() => coverages.cancellation"
                />
                <template #error-min>
                  {{
                    phrase(
                      'error-messages-funnels-013',
                      'De datum mag niet meer dan 1 jaar voor de vertrekdatum liggen.'
                    )
                  }}
                </template>
                <template #error-max>{{
                  phrase('error-messages-funnels-014', 'De datum kan niet na vandaag liggen.')
                }}</template>
              </CContainer>
              <CContainer
                survey
                render-list
                :label="textContent.travelSum"
                :tooltip="textContent.travelSumTooltip"
              >
                <CCurrency>
                  <CText
                    validation-path="formData.travelSum"
                    v-model="travelSum"
                    :inline="true"
                    integer
                    required
                    :min-value="validationConstants.MinTravelSum"
                    :max-value="validationConstants.MaxTravelSum"
                    ref="travelSum"
                  />
                </CCurrency>
                <template #error-minValue>
                  {{
                    phrase('error-messages-funnels-011', 'Het verzekerd bedrag is minimaal € 100,-')
                  }}
                </template>
                <template #error-maxValue>
                  {{
                    phrase(
                      'error-messages-funnels-012',
                      'Het verzekerd bedrag is maximaal € 14.000,-'
                    )
                  }}
                </template>
              </CContainer>
            </template>
          </template>
        </CycleCheckoutItem>

        <WarningMessage v-if="showCancellationWarning">
          <ProvidedHtml name="warnings.cancellation" />
        </WarningMessage>

        <CycleCheckoutItem
          v-model="coverages.excessDeductibleRentalCar"
          :label="textContent.travelExcessDeductibleRentalCarLabel"
          :tooltip="textContent.travelExcessDeductibleRentalCarTooltip"
          :description="textContent.travelExcessDeductibleRentalCarDescription"
          :price="premiumCoverage('excessDeductibleRentalCar')"
        />
      </template>
    </CycleCheckout>
  </CycleFormPage>
</template>

<script>
import { addDays, subDays, format } from 'date-fns'

import { ProductPremium } from '../../models'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '../../../../components/functional'
import { CycleCheckout, CycleCheckoutItem } from '../../../../components/functional/CycleCheckout'
import { WarningMessage } from '@/components/atoms'
import { ProvidedHtml } from '@/components/organisms'
import { dictionaryMixin } from '../../../../mixins'
import {
  CycleRadioInput,
  CycleTextInput,
  CycleCurrencyInput,
  CycleDateInput
} from '../../../../components/elements'
import {
  BaseCoverageOption,
  LuggageOption,
  CancellationOption,
  AccidentsOption,
  ValidationConstants
} from '../../helpers/constants'
import { debounce, get, isEqual } from 'lodash'

export default {
  name: 'FunnelTravelStepCoverages',
  inject: ['formData', 'textContent', 'formErrors'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleCheckout,
    CycleCheckoutItem,
    CContainer: CycleInputContainer,
    CRadio: CycleRadioInput,
    CText: CycleTextInput,
    CCurrency: CycleCurrencyInput,
    CDate: CycleDateInput,
    WarningMessage,
    ProvidedHtml
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      required: false,
      type: ProductPremium
    },
    isMultiTrip: {
      type: Boolean
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    baseCoverageOption() {
      return BaseCoverageOption
    },
    luggageOption() {
      return LuggageOption
    },
    cancellationOption() {
      return CancellationOption
    },
    accidentsOption() {
      return AccidentsOption
    },
    validationConstants() {
      return ValidationConstants
    },
    totalPremium() {
      return get(this, ['premium', 'totalPremium'], 0)
    },
    totalAmountText() {
      return this.isMultiTrip
        ? this.textContent.totalAmountMultiTripText
        : this.textContent.totalAmountSingleTripText
    },
    totalAmountSubtext() {
      return this.isMultiTrip
        ? this.textContent.totalAmountMultiTripSubtext
        : this.textContent.totalAmountSingleTripSubtext
    },
    coverages() {
      return this.formData.coverages
    },
    travelSum: {
      get() {
        return this.formData.travelSum
      },
      set(value) {
        this.$set(this.formData, 'travelSum', value)
        const isValid = !this.formErrors(this.$refs.travelSum.nestedName).$invalid
        if (isValid) {
          this.$set(this.formData.coverages, 'travelSum', this.formData.travelSum)
        } else {
          this.$set(this.formData.coverages, 'travelSum', undefined)
        }
      }
    },
    travelCancellationTooltip() {
      return this.isMultiTrip
        ? this.textContent.travelCancellationMultiTripTooltip
        : this.textContent.travelCancellationSingleTripTooltip
    },
    travelCancellationDescription() {
      return this.isMultiTrip
        ? this.textContent.travelCancellationMultiTripDescription
        : this.textContent.travelCancellationSingleTripDescription
    },
    minBookingDate() {
      if (this.formData.departureDate) {
        return format(subDays(new Date(this.formData.departureDate), 365), 'yyyy-MM-dd')
      }
    },
    maxBookingDate() {
      return format(addDays(new Date(), 0), 'yyyy-MM-dd')
    },
    showCancellationWarning() {
      return (
        !this.isMultiTrip &&
        this.coverages.cancellation &&
        new Date(this.formData.bookingDate) < subDays(new Date(), 8)
      )
    },
    showSpecialSportsWarning() {
      return (
        this.coverages.accidents &&
        this.coverages.accidentsOption === AccidentsOption.SpecialSports &&
        !this.coverages.specialSports
      )
    },
    basicCoverageDescription() {
      return this.isMultiTrip
        ? this.textContent.basicCoverageMultiTripDescription
        : this.textContent.basicCoverageSingleTripDescription
    }
  },
  watch: {
    coverages: {
      handler: debounce(function () {
        this.updateCoverages()
      }, 500),
      deep: true
    },
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }
        if (!isEqual(this.formData.coverages, newValue.coverages)) {
          // Prevent unnecessary (infinite loop) watch trigers
          this.formData.coverages = newValue.coverages
        }
        this.formData.travelSum = newValue.travelSum
        this.formData.bookingDate = newValue.bookingDate
      },
      immediate: true
    }
  },
  methods: {
    pageSubmit() {
      this.$emit('submit', {
        bookingDate: this.formData.bookingDate,
        travelSum: this.formData.travelSum,
        coverages: this.formData.coverages
      })
    },
    updateCoverages() {
      this.$emit('coverage-update', {
        coverages: this.formData.coverages,
        travelSum: this.formData.travelSum
      })
    },
    premiumCoverage(coverage) {
      const basePremium = get(this, ['premium', 'coverages', coverage], undefined)
      return basePremium
    }
  }
}
</script>
